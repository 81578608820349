import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  allTrains
} from "../../store/actions/train.actions";
import {
  copyTrainTours,
  getTrainToursByDate
} from "../../store/actions/tour.actions";
import { useHistory, useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AddTicket() {
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;

  const [trains, setTrains] = useState([]);
  const [date, setDate] = useState("");
  const [toursByDate, setToursByDate] = useState([]);
  const [trainId, setTrainId] = useState(0);
  const [days, setDays] = useState(1);
  
  // const [customerCnic, setCustomerCnic] = useState(0)
  const [tourId, setTourId] = useState(0);
  
  

  //hooks start

  //initial hook
  useEffect(() => {
    document.title = "Add Ticket | AA Enterprise";
    fetchTrains();
  }, []);

  //tour fetching hook
  useEffect(() => {
    if (date !== "" && trainId) {
      _getTrainToursByDate();
    }
  }, [date, trainId]);







 

  //fetch functions start

  

  

  async function _getTrainToursByDate() {
    let response = await getTrainToursByDate(date, Number(trainId));
    if (response.status === 200) {
      if (response.data) {
        document.getElementById("display_date").innerHTML = $(
          "#select_date"
        )[0].value.substr(0, 10);
        let activeTours = [];
        for (let i = 0; i < response.data.length; i++) {
          if (!(response.data[i].tour_status === "deactive")) {
            activeTours.push(response.data[i]);
          }
        }
        if (activeTours.length > 0) {
          setToursByDate(activeTours);
        } else {
          toast.warn("no active tours found");
        }
      } else {
        toast.warn("No tours were found on this date");
        setToursByDate([]);
      }
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  //fetch functions end

  //change handlers start
  function selectTrain(e) {
    e.preventDefault();
    setTrainId(Number(document.getElementById("trains_select").value));
    
  }

  function selectDateSubmit(e) {
    e.preventDefault();
    setDate($("#select_date")[0].value.substr(0, 10));
  }

  function trainTourOnChange(e) {
    const { value } = e.target;
    setTourId(Number(value));
  }


  function onDayChange(e){
    const { value } = e.target;
    setDays(value)

  }

  async function copyTours( ){
    
    let copiedTours = await copyTrainTours(tourId , days)
    console.log(copiedTours);
    if (copiedTours.status === 200) {
      
      toast.success(copiedTours.data.message);
    } else if (copiedTours.status >= 400) {
      toast.error(copiedTours.message.message);
    }

  }
  

  
  
  //change hanlders end

  function formSubmit(e) {
    e.preventDefault();
    if (
      tourId &&
      trainId &&
      date !== "" && 
      (!isNaN(days))
    ) {
      
      copyTours()
      

    } else {
      toast.warn("Fill all data");
    }
  }


  useEffect(() => {
    //alert(`the tour id ${tourId}`);
  },[tourId])

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Tours</h2>
          <p class="text-light">Copy Tours</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Copy Tours</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  type="submit"
                  name="create_ticket_form"
                  onSubmit={(e) => {
                    formSubmit(e);
                  }}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={selectTrain}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option
                              key={train.key}
                              value={train.id}
                              data-name={train.name}
                            >
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Date</label>
                          <input
                            id="select_date"
                            type="date"
                            name="date"
                            class="form-control"
                            placeholder="date"
                            required
                          />
                        </div>
                      </div>
                      <div class="mb-3 col-md-12 row">
                        <div class="col-md-4">
                          <button
                            type="submit"
                            name="fetch_coaches_btn"
                            class="btn btn-primary"
                            onClick={(e) => {
                              selectDateSubmit(e);
                            }}
                          >
                            Select
                          </button>
                        </div>
                        <div class="col-md-8">
                          <p>
                            Date:{" "}
                            <i id="display_date" style={{ color: "red" }}></i>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Tour</label>

                            <select
                              id="tours_select"
                              name="tour_id"
                              onChange={trainTourOnChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {toursByDate.map((tour) => {
                                return (
                                  <option key={tour.id} value={tour.id}>
                                    {tour.name} {tour.depart_time.substr(11, 5)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Days</label>
                            <input type="number" value={days} onChange={onDayChange} min="1" class="form-control"/>
                          </div>
                        </div>
                      </div>
                    </div>

                    

                    
                  </div>
                  <button
                    type="submit"
                    name="create_ticket_btn"
                    class="btn btn-primary"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Customer Profile</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">

                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Name</label>
                                        <input type="text" name='name' value={customerProfile.name} class="form-control" disabled  />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Phone</label>
                                        <input type="text" name='phone' value={customerProfile.phone} class="form-control" placeholder="03123456789 ..." />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">CNIC</label>
                                        <input type="text" name='cnic' value={customerProfile.cnic} class="form-control" placeholder="1234567891234  (without dashes)" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">DOB</label>
                                        <input type="date" name='dob' value={customerProfile.dob} class="form-control" placeholder="date of birth" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Gender</label>
                                        <input type="date" name='dob' value={customerProfile.gender} class="form-control" placeholder="date of birth" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Address</label>
                                        <textarea class="form-control" name='address' value={customerProfile.address} rows="4" placeholder='address...'></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
    </>
  );
}

export default AddTicket;
