import axiosInstance from '../../../utils/axios';

class Cargo {

    static addCargoo = (form) => {

        let apiPath = '/api/cargo';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static getCargoByTrainDate = (form, params) => {

        let apiPath = '/api/cargo/list?_count=' + Number(params._count) + '&_pageNo=' + Number(params._pageNo);
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static getCargoById = (id) => {

        let apiPath = '/api/cargo/' + id;
        return axiosInstance.get(apiPath);

    };

    static deleteCargoById = (id) => {

        let apiPath = '/api/cargo/' + id;
        return axiosInstance.delete(apiPath);

    };

    static updateCargoById  = (data,id) => {

        let apiPath = '/api/cargo/' + id;
        return axiosInstance.put(apiPath , {
            ...data
        });

    };
}
export default Cargo;