import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function AcStd98() {
  const layoutSeats = ["17S","1C1S","1C2S","1C3S","1C4S","1C1B","18S","1C5S","1C6S","1C7S","1C2B","1C8S","19S","20S","2C3B","2C4B","2C5B","53B","2C6B","2C7B","21S","2C8B","22S","3C9B","3C10B","3C11B","23S","3C12B","3C13B","3C14B","54B","24S","4C15B","4C16B","4C17B","25S","4C18B","4C19B","4C20B","26S","55B","5C21B","5C22B","5C23B","27S","5C24B","5C25B","5C26B","28S","29S","6C27B","6C28B","6C29B","56B","6C30B","6C31B","6C32B","30S","31S","7C33B","7C34B","7C35B","32S","7C36B","7C37B","7C38B","57B","33S","34S","8C39B","8C40B","8C41B","35S","8C42B","8C43B","8C44B","58B","36S","37S","9C45B","9C46B","9C47B","38S","9C48B","9C49B","9C50B","59B","10C9S","10C10S","10C11S","10C12S","10C51B","39S","10C13S","10C14S","10C15S","10C16S","10C52B"]
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;
  const [seatArr, setSeatArr] = useState([]);

  const [seatStatus, setSeatStatus] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [children, setChildren] = useState(0);
  const [pastData, setPastData] = useState({
    train_id: 0,
    train_name: "",
    date: "",
    tour_id: 0,
    tour_name: "",
    coachTypeId: 0,
    coach_name: "",
  });
  const [vacantSeats, setVacantSeats] = useState({
    difference: [],
    stop_time: "",
  });

  //checkbox function
  function onBoxChange(value) {
    let arr = seatArr;

    if (arr.includes(value)) {
      arr = arr.filter((e) => e !== value);
    } else {
      arr.push(value);
    }

    setSeatArr([...arr]);
  }

  //initial Hook
  useEffect(() => {
    document.title = "Available Seats | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setPastData({
        train_id: location.state.train_id,
        train_name: location.state.train_name,
        date: location.state.date,
        tour_id: location.state.tourId,
        tour_name: location.state.tour_name,
        coachTypeId: location.state.coachTypeId,
        coach_name: location.state.coach_name,
        coach_number: location.state.coach_number,
        starting_city_id: location.state.starting_city_id,
        starting_city_name: location.state.starting_city_name,
        ending_city_id: location.state.ending_city_id,
        ending_city_name: location.state.ending_city_name,
        status: location.state.status,
        cnic: location.state.cnic,
      });
    } else {
      history.push("/add-ticket");
    }
  }, []);

  async function _getVacantSeats() {
    let response = await getVacantSeats(
      pastData.coachTypeId,
      pastData.starting_city_id,
      pastData.ending_city_id
    );
    if (response.status === 200) {
      setVacantSeats(response.data);
      console.log("vacant seats are", response);
    } else if (response.status >= 400) {
      toast.error("Failed");
    }
  }

  function _setBookedSeats() {
    let arr2 = vacantSeats.difference;
    let difference = layoutSeats.filter((x) => !arr2.includes(x));
    setCheckedArray(difference);
  }

  useEffect(() => {
    if (pastData.coachTypeId) {
      _getVacantSeats();
    }
  }, [pastData.coachTypeId]);

  useEffect(() => {
    if (pastData.status || pastData.status !== undefined) {
      setSeatStatus(pastData.status);
      $("#status option:first").text(location.state.status);
      $("#status option:first").val(location.state.status.toLowerCase());
    }
  }, [pastData.status]);

  //Hook to run after vacant seats are fecthed from server
  useEffect(() => {
    if (vacantSeats.stop_time !== "") {
      _setBookedSeats();
    }
  }, [vacantSeats.stop_time]);

  //Test Hook

  function handleSeatStatus(e) {
    const { value } = e.target;
    setSeatStatus(value);
  }

  function handleChildrenChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (value >= 0) {
      setChildren(Number(value));
    } else {
      toast.warn("Enter valid number");
    }
  }

  function submitSeat() {
    if ((children || seatArr.length) && seatStatus !== "") {
      history.push({
        pathname: "add-ticket-customer",
        state: {
          tc_id: pastData.coachTypeId,
          st_city: pastData.starting_city_id,
          end_city: pastData.ending_city_id,
          toBookSeats: seatArr,
          status: seatStatus,
          children: children,
          stateData,
        },
      });
    } else {
      toast.warn("Please select all values");
    }
  }

  useEffect(() => {
    console.log(JSON.stringify(seatArr));
    console.log(seatArr.length);
  }, [seatArr]);

  return (
    <div style={{ overflowX: "auto", overflowY: "hidden" }}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        
      </div>

      {/* button and cabin row */}
      <div className="row">
      <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance 98</h4>
        </div>
      </div>
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 1</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("17S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="81S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("17S") ? true : false}
                        onChange={() => {
                          onBoxChange("17S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">17S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C1S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">1C1S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C2S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">1C2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C3S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1C3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C4S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1C4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C1B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C1B") ? true : false}
                        onChange={() => {
                          onBoxChange("1C1B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1C1B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("18S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="82S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("18S") ? true : false}
                        onChange={() => {
                          onBoxChange("18S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">18S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="12S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C5S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">1C5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="11S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C6S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">1C6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C7S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="10S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C7S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C7S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1C7S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C8S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="9S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C8S") ? true : false}
                        onChange={() => {
                          onBoxChange("1C8S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1C8S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1C2B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="9S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1C2B") ? true : false}
                        onChange={() => {
                          onBoxChange("1C2B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1C2B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("19S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="84S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("19S") ? true : false}
                        onChange={() => {
                          onBoxChange("19S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">19S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right"></div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 2</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("20S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="85S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("20S") ? true : false}
                        onChange={() => {
                          onBoxChange("20S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">20S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2C3B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2C3B") ? true : false}
                        onChange={() => {
                          onBoxChange("2C3B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2C3B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2C4B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2C4B") ? true : false}
                        onChange={() => {
                          onBoxChange("2C4B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2C4B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2C5B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2C5B") ? true : false}
                        onChange={() => {
                          onBoxChange("2C5B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2C5B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("53B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="86S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("53B") ? true : false}
                        onChange={() => {
                          onBoxChange("53B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">53B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2C6B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2C6B") ? true : false}
                        onChange={() => {
                          onBoxChange("2C6B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2C6B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2C7B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2C7B") ? true : false}
                        onChange={() => {
                          onBoxChange("2C7B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2C7B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2C8B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2C8B") ? true : false}
                        onChange={() => {
                          onBoxChange("2C8B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2C8B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("21S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="88S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("21S") ? true : false}
                        onChange={() => {
                          onBoxChange("21S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">21S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right"></div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 3</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("22S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="89S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("22S") ? true : false}
                        onChange={() => {
                          onBoxChange("22S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">22S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3C9B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3C9B") ? true : false}
                        onChange={() => {
                          onBoxChange("3C9B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3C9B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3C10B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3C10B") ? true : false}
                        onChange={() => {
                          onBoxChange("3C10B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3C10B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3C11B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3C11B") ? true : false}
                        onChange={() => {
                          onBoxChange("3C11B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3C11B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("23S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="90S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("23S") ? true : false}
                        onChange={() => {
                          onBoxChange("23S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">23S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3C12B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3C12B") ? true : false}
                        onChange={() => {
                          onBoxChange("3C12B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3C12B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3C13B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3C13B") ? true : false}
                        onChange={() => {
                          onBoxChange("3C13B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3C13B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3C14B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3C14B") ? true : false}
                        onChange={() => {
                          onBoxChange("3C14B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3C14B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}

      {/* button and cabin row */}
      

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("54B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="92S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("54B") ? true : false}
                        onChange={() => {
                          onBoxChange("54B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">54B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right"></div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 4</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("24S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="93S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("24S") ? true : false}
                        onChange={() => {
                          onBoxChange("24S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">24S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4C15B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4C15B") ? true : false}
                        onChange={() => {
                          onBoxChange("4C15B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4C15B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4C16B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4C16B") ? true : false}
                        onChange={() => {
                          onBoxChange("4C16B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4C16B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4C17B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4C17B") ? true : false}
                        onChange={() => {
                          onBoxChange("4C17B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4C17B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("25S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="94S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("25S") ? true : false}
                        onChange={() => {
                          onBoxChange("25S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">25S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4C18B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4C18B") ? true : false}
                        onChange={() => {
                          onBoxChange("4C18B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4C18B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4C19B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4C19B") ? true : false}
                        onChange={() => {
                          onBoxChange("4C19B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4C19B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4C20B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4C20B") ? true : false}
                        onChange={() => {
                          onBoxChange("4C20B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4C20B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}

      {/* button and cabin row */}
      

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("26S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="96S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("26S") ? true : false}
                        onChange={() => {
                          onBoxChange("26S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">26S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right"></div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 5</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("55B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="97S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("55B") ? true : false}
                        onChange={() => {
                          onBoxChange("55B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">55B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5C21B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5C21B") ? true : false}
                        onChange={() => {
                          onBoxChange("5C21B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5C21B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5C22B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5C22B") ? true : false}
                        onChange={() => {
                          onBoxChange("5C22B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5C22B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5C23B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5C23B") ? true : false}
                        onChange={() => {
                          onBoxChange("5C23B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5C23B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("27S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="98S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("27S") ? true : false}
                        onChange={() => {
                          onBoxChange("27S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">27S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5C24B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5C24B") ? true : false}
                        onChange={() => {
                          onBoxChange("5C24B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5C24B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5C25B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5C25B") ? true : false}
                        onChange={() => {
                          onBoxChange("5C25B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5C25B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5C26B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5C26B") ? true : false}
                        onChange={() => {
                          onBoxChange("5C26B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5C26B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("28S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="82S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("28S") ? true : false}
                        onChange={() => {
                          onBoxChange("28S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">28S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 6</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("29S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="83S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("29S") ? true : false}
                        onChange={() => {
                          onBoxChange("29S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">29S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6C27B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6C27B") ? true : false}
                        onChange={() => {
                          onBoxChange("6C27B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6C27B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6C28B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6C28B") ? true : false}
                        onChange={() => {
                          onBoxChange("6C28B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6C28B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6C29B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6C29B") ? true : false}
                        onChange={() => {
                          onBoxChange("6C29B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6C29B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("56B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="84S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("56B") ? true : false}
                        onChange={() => {
                          onBoxChange("56B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">56B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6C30B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6C30B") ? true : false}
                        onChange={() => {
                          onBoxChange("6C30B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6C30B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6C31B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6C31B") ? true : false}
                        onChange={() => {
                          onBoxChange("6C31B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6C31B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6C32B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6C32B") ? true : false}
                        onChange={() => {
                          onBoxChange("6C32B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6C32B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("30S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="86S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("30S") ? true : false}
                        onChange={() => {
                          onBoxChange("30S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">30S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right"></div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 7</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("31S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="87S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("31S") ? true : false}
                        onChange={() => {
                          onBoxChange("31S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">31S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7C33B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7C33B") ? true : false}
                        onChange={() => {
                          onBoxChange("7C33B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7C33B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7C34B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7C34B") ? true : false}
                        onChange={() => {
                          onBoxChange("7C34B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7C34B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7C35B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7C35B") ? true : false}
                        onChange={() => {
                          onBoxChange("7C35B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7C35B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("32S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="88S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("32S") ? true : false}
                        onChange={() => {
                          onBoxChange("32S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">32S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7C36B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7C36B") ? true : false}
                        onChange={() => {
                          onBoxChange("7C36B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7C36B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7C37B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7C37B") ? true : false}
                        onChange={() => {
                          onBoxChange("7C37B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7C37B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7C38B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7C38B") ? true : false}
                        onChange={() => {
                          onBoxChange("7C38B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7C38B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("57B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="89S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("57B") ? true : false}
                        onChange={() => {
                          onBoxChange("57B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">57B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("33S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="90S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("33S") ? true : false}
                        onChange={() => {
                          onBoxChange("33S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">33S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 8</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("34S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="91S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("34S") ? true : false}
                        onChange={() => {
                          onBoxChange("34S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">34S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8C39B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8C39B") ? true : false}
                        onChange={() => {
                          onBoxChange("8C39B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8C39B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8C40B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8C40B") ? true : false}
                        onChange={() => {
                          onBoxChange("8C40B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8C40B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8C41B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8C41B") ? true : false}
                        onChange={() => {
                          onBoxChange("8C41B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8C41B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("35S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="92S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("35S") ? true : false}
                        onChange={() => {
                          onBoxChange("35S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">35S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8C42B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8C42B") ? true : false}
                        onChange={() => {
                          onBoxChange("8C42B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8C42B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8C43B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8C43B") ? true : false}
                        onChange={() => {
                          onBoxChange("8C43B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8C43B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8C44B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8C44B") ? true : false}
                        onChange={() => {
                          onBoxChange("8C44B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8C44B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("58B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="93S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("58B") ? true : false}
                        onChange={() => {
                          onBoxChange("58B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">58B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("36S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="94S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("36S") ? true : false}
                        onChange={() => {
                          onBoxChange("36S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">36S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 9</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("37S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="95S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("37S") ? true : false}
                        onChange={() => {
                          onBoxChange("37S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">37S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9C45B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9C45B") ? true : false}
                        onChange={() => {
                          onBoxChange("9C45B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9C45B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9C46B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9C46B") ? true : false}
                        onChange={() => {
                          onBoxChange("9C46B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9C46B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9C47B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9C47B") ? true : false}
                        onChange={() => {
                          onBoxChange("9C47B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9C47B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("38S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="96S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("38S") ? true : false}
                        onChange={() => {
                          onBoxChange("38S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">38S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9C48B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9C48B") ? true : false}
                        onChange={() => {
                          onBoxChange("9C48B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9C48B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9C49B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9C49B") ? true : false}
                        onChange={() => {
                          onBoxChange("9C49B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9C49B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9C50B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9C50B") ? true : false}
                        onChange={() => {
                          onBoxChange("9C50B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9C50B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-4  ">
            <h5 class="h5c">Cabin 10</h5>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("59B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="97S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("59B") ? true : false}
                        onChange={() => {
                          onBoxChange("59B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">59B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C9S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C9S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C9S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">10C9S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C10S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C10S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C10S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">10C10S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C11S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C11S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C11S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10C11S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C12S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C12S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C12S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10C12S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C51B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C51B") ? true : false}
                        onChange={() => {
                          onBoxChange("10C51B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10C51B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("39S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="98S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("39S") ? true : false}
                        onChange={() => {
                          onBoxChange("39S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">39S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C13S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C13S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C13S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">10C13S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C14S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C14S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C14S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">10C14S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C15S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C15S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C15S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10C15S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C16S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C16S") ? true : false}
                        onChange={() => {
                          onBoxChange("10C16S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10C16S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10C52B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10C52B") ? true : false}
                        onChange={() => {
                          onBoxChange("10C52B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10C52B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2"></div>
        </div>
      </div>

      {/* berths started */}

      {/* berths ended */}

      <div class="card-body pt-0 row">
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Seat Type</h4>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <select
                id="status"
                value={seatStatus}
                onChange={handleSeatStatus}
                name="status"
                class="form-select form-control wide"
              >
                <option value="">Choose...</option>
                <option value="booked">Booked</option>
                <option value="reserved">Reserved</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Children Seats</h4>
          </div>
          <div class="col-6">
            <input
              type="number"
              value={children}
              class="form-control"
              placeholder="Number of childeren tickets"
              name="children"
              onChange={handleChildrenChange}
            />
          </div>
        </div>
        <button
          type="button"
          name="create_ticket_btn"
          class="btn btn-primary"
          onClick={() => {
            submitSeat();
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AcStd98;
