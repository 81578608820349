import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";

import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function Economy() {
  
  const [checkedArray, setCheckedArray] = useState([]);
  
  //checkbox function
  function onBoxChange(value) {
    
  }

 

  return (
    <div style={{overflowX: 'auto'}}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3B") ? true : false}
                        onChange={() => {
                          onBoxChange("3B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2B") ? true : false}
                        onChange={() => {
                          onBoxChange("2B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">2B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1B") ? true : false}
                        onChange={() => {
                          onBoxChange("1B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6B") ? true : false}
                        onChange={() => {
                          onBoxChange("6B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="6BS" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5B") ? true : false}
                        onChange={() => {
                          onBoxChange("5B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4B") ? true : false}
                        onChange={() => {
                          onBoxChange("4B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="4B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">4B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}

      {/* 2 nd row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("61S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="61S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("61S") ? true : false}
                        onChange={() => {
                          onBoxChange("61S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="61S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">61S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="9b"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9B") ? true : false}
                        onChange={() => {
                          onBoxChange("9B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="9b" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8b"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8B") ? true : false}
                        onChange={() => {
                          onBoxChange("8B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="8b" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">8B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7B") ? true : false}
                        onChange={() => {
                          onBoxChange("7B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">7B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("62S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="62S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("62S") ? true : false}
                        onChange={() => {
                          onBoxChange("62S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="62S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">62S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("12B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="12B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("12B") ? true : false}
                        onChange={() => {
                          onBoxChange("12B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="12B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">12B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("11B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="11B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("11B") ? true : false}
                        onChange={() => {
                          onBoxChange("11B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">11B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="10B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10B") ? true : false}
                        onChange={() => {
                          onBoxChange("10B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="10B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>

      {/* 3rd row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("63S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="63S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("63S") ? true : false}
                        onChange={() => {
                          onBoxChange("63S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">63S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("15B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="15B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("15B") ? true : false}
                        onChange={() => {
                          onBoxChange("15B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="15B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">15B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("14B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="14B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("14B") ? true : false}
                        onChange={() => {
                          onBoxChange("14B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="14B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">14B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("13B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="13B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("13B") ? true : false}
                        onChange={() => {
                          onBoxChange("13B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="13B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">13B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("64S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="64S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("64S") ? true : false}
                        onChange={() => {
                          onBoxChange("64S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="64S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">64S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("18B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="18B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("18B") ? true : false}
                        onChange={() => {
                          onBoxChange("18B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">18B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("17B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="17B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("17B") ? true : false}
                        onChange={() => {
                          onBoxChange("17B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="17B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">17B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("16B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("16B") ? true : false}
                        onChange={() => {
                          onBoxChange("16B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="16B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">16B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 4 row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("65S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="65S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("65S") ? true : false}
                        onChange={() => {
                          onBoxChange("65S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="65S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">65S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("21B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="21B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("21B") ? true : false}
                        onChange={() => {
                          onBoxChange("21B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="21B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">21B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("20B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="20B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("20B") ? true : false}
                        onChange={() => {
                          onBoxChange("20B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="20B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">20B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("19B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="19B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("19B") ? true : false}
                        onChange={() => {
                          onBoxChange("19B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">19B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("66S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="66S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("66S") ? true : false}
                        onChange={() => {
                          onBoxChange("66S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="66S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">66S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("24B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="24B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("24B") ? true : false}
                        onChange={() => {
                          onBoxChange("24B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="24B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">24B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("23B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="23B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("23B") ? true : false}
                        onChange={() => {
                          onBoxChange("23B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="23B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">23B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("22B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="22B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("22B") ? true : false}
                        onChange={() => {
                          onBoxChange("22B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="22B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">22B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 5th */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("67S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="67S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("67S") ? true : false}
                        onChange={() => {
                          onBoxChange("67S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="67S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">67S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("27B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="27B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("27B") ? true : false}
                        onChange={() => {
                          onBoxChange("27B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="27B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">27B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("26B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="26B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("26B") ? true : false}
                        onChange={() => {
                          onBoxChange("26B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="26B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">26B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("25B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="25B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("25B") ? true : false}
                        onChange={() => {
                          onBoxChange("25B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="25B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">25B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("68S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="68S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("68S") ? true : false}
                        onChange={() => {
                          onBoxChange("68S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="68S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">68S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("30B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="30B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("30B") ? true : false}
                        onChange={() => {
                          onBoxChange("30B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="30B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">30B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("29B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="29B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("29B") ? true : false}
                        onChange={() => {
                          onBoxChange("29B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="29B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">29B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("28B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="28B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("28B") ? true : false}
                        onChange={() => {
                          onBoxChange("28B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="28B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">28B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 6th row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("69S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="69S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("69S") ? true : false}
                        onChange={() => {
                          onBoxChange("69S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">69S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("33B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="33B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("33B") ? true : false}
                        onChange={() => {
                          onBoxChange("33B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">33B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("32B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="32B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("32B") ? true : false}
                        onChange={() => {
                          onBoxChange("32B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="32B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">32B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("31B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="31B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("31B") ? true : false}
                        onChange={() => {
                          onBoxChange("31B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">31B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("70S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="70S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("70S") ? true : false}
                        onChange={() => {
                          onBoxChange("70S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="70S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">70S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("36B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="36B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("36B") ? true : false}
                        onChange={() => {
                          onBoxChange("36B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="36B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">36B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("35B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="35B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("35B") ? true : false}
                        onChange={() => {
                          onBoxChange("35B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="35B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">35B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("34B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="34B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("34B") ? true : false}
                        onChange={() => {
                          onBoxChange("34B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">34B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 7 row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("71S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="71S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("71S") ? true : false}
                        onChange={() => {
                          onBoxChange("71S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">71S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("39B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="39B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("39B") ? true : false}
                        onChange={() => {
                          onBoxChange("39B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">39B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("38B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="38B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("38B") ? true : false}
                        onChange={() => {
                          onBoxChange("38B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="38B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">38B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("37B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="37B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("37B") ? true : false}
                        onChange={() => {
                          onBoxChange("37B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="37B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">37B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("72S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="72S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("72S") ? true : false}
                        onChange={() => {
                          onBoxChange("72S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">72S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("42B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="42B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("42B") ? true : false}
                        onChange={() => {
                          onBoxChange("42B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="42B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">42B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("41B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("41B") ? true : false}
                        onChange={() => {
                          onBoxChange("41B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="41B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">41B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("40B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="40B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("40B") ? true : false}
                        onChange={() => {
                          onBoxChange("40B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="40B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">40B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 8th row */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("73S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="73S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("73S") ? true : false}
                        onChange={() => {
                          onBoxChange("73S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="73S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">73S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("45B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="45B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("45B") ? true : false}
                        onChange={() => {
                          onBoxChange("45B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="45B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">45B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("44B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="44B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("44B") ? true : false}
                        onChange={() => {
                          onBoxChange("44B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">44B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("43B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="43B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("43B") ? true : false}
                        onChange={() => {
                          onBoxChange("43B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="43B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">43B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("74S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="74S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("74S") ? true : false}
                        onChange={() => {
                          onBoxChange("74S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="74S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">74S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("48B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="48B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("48B") ? true : false}
                        onChange={() => {
                          onBoxChange("48B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">48B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("47B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="47B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("47B") ? true : false}
                        onChange={() => {
                          onBoxChange("47B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="47B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">47B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("46B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="46B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("46B") ? true : false}
                        onChange={() => {
                          onBoxChange("46B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="46B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">46B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 9th row */}

      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("75S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="75S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("75S") ? true : false}
                        onChange={() => {
                          onBoxChange("75S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="75S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">75S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("51B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="51B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("51B") ? true : false}
                        onChange={() => {
                          onBoxChange("51B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">51B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("50B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="50B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("50B") ? true : false}
                        onChange={() => {
                          onBoxChange("50B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="50S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">50B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("49B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="49B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("49B") ? true : false}
                        onChange={() => {
                          onBoxChange("49B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="49B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">49B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("76S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="76S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("76S") ? true : false}
                        onChange={() => {
                          onBoxChange("76S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="76S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">76S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("54B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="54B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("54B") ? true : false}
                        onChange={() => {
                          onBoxChange("54B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="54B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">54B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("53B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="53B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("53B") ? true : false}
                        onChange={() => {
                          onBoxChange("53B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">53B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("52B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="52B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("52B") ? true : false}
                        onChange={() => {
                          onBoxChange("52B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">52B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 10 */}
      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("77S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="77S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("77S") ? true : false}
                        onChange={() => {
                          onBoxChange("77S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">77S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("57B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="57B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("57B") ? true : false}
                        onChange={() => {
                          onBoxChange("57B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">57B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("56B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="56B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("56B") ? true : false}
                        onChange={() => {
                          onBoxChange("56B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">56B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("55B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="55B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("55B") ? true : false}
                        onChange={() => {
                          onBoxChange("55B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="55B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">55B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="irow ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("78S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="78S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("78S") ? true : false}
                        onChange={() => {
                          onBoxChange("78S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">78S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("60B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("60B") ? true : false}
                        onChange={() => {
                          onBoxChange("60B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">60B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("59B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="59B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("59B") ? true : false}
                        onChange={() => {
                          onBoxChange("59B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="59B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">59B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("58B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="58B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("58B") ? true : false}
                        onChange={() => {
                          onBoxChange("58B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="58B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">58B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="irow ">
        
        
        <div class="left">

        <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("73B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("73B") ? true : false}
                        onChange={() => {
                          onBoxChange("73B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">73B</h5>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="right">
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("74B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("74B") ? true : false}
                        onChange={() => {
                          onBoxChange("74B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">74B</h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("75B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("75B") ? true : false}
                        onChange={() => {
                          onBoxChange("75B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">75B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("76B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("76B") ? true : false}
                        onChange={() => {
                          onBoxChange("76B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">76B</h5>
                </div>
              </div>
            </div>
          </div>



        </div>

      </div>


      <div class="irow ">
        
        
        <div class="left">

        <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("77B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("77B") ? true : false}
                        onChange={() => {
                          onBoxChange("77B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">77B</h5>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="right">
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("78B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("78B") ? true : false}
                        onChange={() => {
                          onBoxChange("78B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">78B</h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("79B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("79B") ? true : false}
                        onChange={() => {
                          onBoxChange("79B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">79B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("80B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("80B") ? true : false}
                        onChange={() => {
                          onBoxChange("80B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">80B</h5>
                </div>
              </div>
            </div>
          </div>



        </div>
        
      </div>


      <div class="irow ">
        
        
        <div class="left">

        <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("81B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("81B") ? true : false}
                        onChange={() => {
                          onBoxChange("81B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">81B</h5>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="right">
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("82B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("82B") ? true : false}
                        onChange={() => {
                          onBoxChange("82B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">82B</h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("83B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("83B") ? true : false}
                        onChange={() => {
                          onBoxChange("83B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">83B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("84B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("84B") ? true : false}
                        onChange={() => {
                          onBoxChange("84B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">84B</h5>
                </div>
              </div>
            </div>
          </div>



        </div>
        
      </div>


      <div class="irow ">
        
        
        <div class="left">

        <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("85B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("85B") ? true : false}
                        onChange={() => {
                          onBoxChange("85B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">85B</h5>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="right">
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("86B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("86B") ? true : false}
                        onChange={() => {
                          onBoxChange("86B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">86B</h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("87B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("87B") ? true : false}
                        onChange={() => {
                          onBoxChange("87B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">87B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("88B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("88B") ? true : false}
                        onChange={() => {
                          onBoxChange("88B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">88B</h5>
                </div>
              </div>
            </div>
          </div>



        </div>
        
      </div>


      <div class="irow ">
        
        
        <div class="left">

        <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("89B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("89B") ? true : false}
                        onChange={() => {
                          onBoxChange("89B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">89B</h5>
                </div>
              </div>
            </div>
          </div>

        </div>


        <div className="right">
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("90B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("90B") ? true : false}
                        onChange={() => {
                          onBoxChange("90B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">90B</h5>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("91B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("91B") ? true : false}
                        onChange={() => {
                          onBoxChange("91B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">91B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("92B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("92B") ? true : false}
                        onChange={() => {
                          onBoxChange("92B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">92B</h5>
                </div>
              </div>
            </div>
          </div>



        </div>
        
      </div>






      {/* //ending of irow series */}
      <div class="irow ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      
      {/* <div class="card-body pt-0 row">
             
                <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
            </div> */}
    </div>
  );
}

export default Economy;
