import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { addCity, updateCity } from '../../store/actions/city.actions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AddCity() {
    const location = useLocation()
    const [updateId, setUpdateId] = useState(0);
    const [cityForm, setCityForm] = useState({ name: "", postal_code: "" });
    useEffect(() => {
        document.title = 'Add City | AA Enterprise';

        if (JSON.stringify(location.state) !== undefined) {
            setUpdateId(Number(location.state.id));
            setCityForm({ name: location.state.name, postal_code: Number(location.state.postal_code) })
        }
    }, []);


    function handleFormChange(event) {
        const { name, value } = event.target;
        setCityForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    async function handleFormSubmit(e) {
        e.preventDefault()
        let authentic = authenticate();
        if (authentic) {
            let response;
            if (updateId) {
                response = await updateCity(cityForm, updateId);
            } else {
                response = await addCity(cityForm);
            }
            if (response.status === 200) {
                toast.success("City Added")
                setCityForm({ name: "", postal_code: "" })
                setUpdateId(0)
            } else if (response.status >= 400) {
                toast.error(response.message.message)
            }
        } else {
            toast.warn("Enter valid values")
        }
    }

    function authenticate() {
        if (cityForm.postal_code && Number(cityForm.postal_code) && cityForm.name) {
            return true
        } else {
            return false
        }
    }
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">{updateId ? "Update City" : "Add City"}</h2>
                    <p class="text-light">{updateId ? "Update" : "Add a new"} city to the routes</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{updateId ? "Update City" : "Add City"}</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form id='myForm' name="add_city_form" onSubmit={(e) => handleFormSubmit(e)}>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Name</label>
                                            <input type="text" name='name' onChange={handleFormChange} value={cityForm.name} class="form-control" placeholder="city name" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Postal Code</label>
                                            <input type="text" name='postal_code' onChange={handleFormChange} value={cityForm.postal_code} class="form-control" placeholder="postal code" />
                                        </div>
                                    </div>
                                    <button type="submit" name='add_city_btn' class={updateId ? 'btn btn-success' : 'btn btn-primary'}>{updateId ? "Update City" : "Add City"}</button>
                                    <button id='reset' type="reset" style={{ display: 'none' }} />
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCity
