import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function Open() {
 
  const [checkedArray, setCheckedArray] = useState([]);
 
  function onBoxChange(value) {
    
  }

  
  

  return (
    <div style={{overflowX: 'auto'}}s>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="oprow ">
        <div class="left ac90">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("1OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("2OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("3OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("4OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("5OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("6OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">6S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 2st row */}
      <div class="oprow ">
        <div class="left ac90">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("1OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("2OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("3OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("4OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="4B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">4B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("5OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="5B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("6OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">6B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      

      {/* <div class="card-body pt-0 row">
             
                <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
            </div> */}
    </div>
  );
}

export default Open;
