import React, { useState } from 'react'
import { Switch, Route } from "react-router-dom";
//non-node imports

import PrivateRoute from './ProtectedRouter';
import Reset from './profile/Reset';
import Header from './commonComponents/header/Header';
import Footer from './commonComponents/footer/Footer';
import Navbar from './commonComponents/navbar/Navbar'
import Sidebar from './commonComponents/sidebar/Sidebar'
import Login from './login/Login'
import Index from './index/Index'
import AddAdmin from './admin/AddAdmin'
import AllAdmins from './admin/AllAdmins'
import AdminPermissions from './admin/AdminPermissions'
import AddCity from './city/AddCity';
import AllCities from './city/AllCities';
import AddTransaction from './transactions/AddTransaction';
import AllTransactions from './transactions/AllTransactions';
import AddTicket from './ticket/AddTicket';
import ReserveToBook from './ticket/ReserveToBook';
import PrintTicket from './ticket/PrintTicket';
import TicketCustomer from './ticket/TicketCustomer';
import AllTickets from './ticket/AllTickets';
import EconomyGhouri from './ticket/EconomyGhouri';
import BrakeGhouri from './ticket/BrakeGhouri';
import AddCustomer from './customer/AddCustomer';
import AllCustomers from './customer/AllCustomers'
import AddCoach from './coach/AddCoach';
import AllCoaches from './coach/AllCoaches';
import AddTrain from './train/AddTrain';
import AllTrains from './train/AllTrains';
import TrainStatus from './train/TrainStatus';
// import Forgot from './login/Forgot';
// import Reset from './login/Reset';
import AddTrainCity from './train/AddTrainCity';
import AddMoreCities from './trainCities/AddMoreCities';
import AllTrainCities from './trainCities/AllTrainCities';
import UpdateTrainCity from './trainCities/UpdateTrainCity';
import AddFare from './fare/AddFare';
import BulkUpdateFare from './fare/BulkUpdateFare';
import CheckFare from './fare/CheckFare';
import AllTrainFares from './fare/AllTrainFares';
import CopyFares from './fare/CopyFares';
import UpdateFare from './fare/UpdateFare'
import AddTour from './tour/AddTour';
import AllTours from './tour/AllTours';
import TourStatus from './tour/TourStatus';
import CopyTour from './tour/CopyTour';
import UpdateTour from './tour/UpdateTour';
import AddTourCoach from './tour/AddTourCoach';
import AllTourCoaches from './tour/AllTourCoaches';
import UpdateTourCoach from './tour/UpdateTourCoach';
import AddCargo from './cargo/AddCargo';
import CargoDetails from './cargo/CargoDetails'
import AllCargo from './cargo/AllCargo';
import CargoById from './cargo/CargoById';
import EditCargo from './cargo/EditCargo';
import Reports from './report/Reports';
import ReportByStatus from './report/ReportByStatus';
import ReportCreatedAt from './report/ReportCreatedAt';
import ReportCityWiseUser from './report/ReportCityWiseUser';
import ReportPassengerChart from './report/ReportPassengerChart';
import DiscountedTicketReport from './report/DiscountedTicketReport'
import CargoReport from './report/CargoReport';
import CargoIndividualReport from './report/CargoIndividualReport'
import OtherReports from './report/OtherReports'
import AllPreferences from './preference/AllPreferences';
import UpdatePreference from './preference/UpdatePreference';
import OfflineDevices from './offlineDevices/OfflineDevices';
import PrintOnlineTicket from './ticket/PrintOnlineTicket'

// Layouts
import ACstd72 from "./layouts/AcStd72"
import AcBusiness from "./layouts/AcBusiness"
import Economy from "./layouts/Economy"
import ParloCar from "./layouts/ParloCar"
import Economy2 from "./layouts/Economy2"
import AcStd90 from "./layouts/AcStd90";
import Open from './layouts/Open';
import AcSleeper from './layouts/AcSleeper';
import Economy3 from './layouts/Economy3';
import AcStd96 from './layouts/AcStd96';
import AcStd74 from './layouts/AcStd74';
import AcStd98 from './layouts/AcStd98';
import AcStd101 from './layouts/AcStd101';

// Layouts View Only
import ACstd72View from "./layoutsView/AcStd72"
import AcBusinessView from "./layoutsView/AcBusiness"
import EconomyView from "./layoutsView/Economy"
import ParloCarView from "./layoutsView/ParloCar"
import Economy2View from "./layoutsView/Economy2"
import AcStd90View from "./layoutsView/AcStd90";
import OpenView from './layoutsView/Open';
import AcSleeperView from './layoutsView/AcSleeper';
import Economy3View from './layoutsView/Economy3';
import AcStd96View from './layoutsView/AcStd96';
import AcStd74View from './layoutsView/AcStd74';
import BrakeView from './layoutsView/BrakeGhouri';
import EconomyGhouriView from './layoutsView/EconomyGhouri';
import AcStd101View from './layoutsView/AcStd101'
import AcStd98View from './layoutsView/AcStd98'


import Testing from './testing/Testing';


function Routes() {
    const [noHeader, setNoHeader] = useState(false)
    return (
        <>

            {/* Error pages also go here */}
            <Route exact path='/login'>
                <Login setNoHeader={setNoHeader} />
            </Route>
            {/* <Route exact path='/forgot-password'>
                <Forgot setNoHeader={setNoHeader} />
            </Route>
            
            <Route exact path='/reset-password'>
                <Reset setNoHeader={setNoHeader} />
            </Route> */}

            {!noHeader &&
                <>
                    <div id="preloader">
                        <div class="loader">
                            <div class="loader--dot"></div>
                            <div class="loader--dot"></div>
                            <div class="loader--dot"></div>
                            <div class="loader--dot"></div>
                            <div class="loader--dot"></div>
                            <div class="loader--dot"></div>
                            <div class="loader--text"></div>
                        </div>
                    </div>

                    <div id="main-wrapper">
                        <Navbar />
                        <Header />
                        <Sidebar />

                        <div class="content-body">
                            <div class="container-fluid">
                                <Switch>

                                    {/* Teting */}
                                    <PrivateRoute exact path='/testing' component={Testing} />

                                    {/* Index || Profile */}
                                    <PrivateRoute exact path={['/', '/index']} component={Index} />
                                    <PrivateRoute exact path='/reset' component={Reset} />

                                    {/* Admins */}
                                    <PrivateRoute exact path='/add-admin' component={AddAdmin} />
                                    <PrivateRoute exact path='/update-admin' component={AddAdmin} />
                                    <PrivateRoute exact path='/all-admins' component={AllAdmins} />
                                    <PrivateRoute exact path='/admin-permissions' component={AdminPermissions} />

                                    {/* Cities */}
                                    <PrivateRoute exact path='/add-city' component={AddCity} />
                                    <PrivateRoute exact path='/all-cities' component={AllCities} />

                                    {/* Transactions */}
                                    <PrivateRoute exact path='/add-transaction' component={AddTransaction} />
                                    <PrivateRoute exact path='/all-transactions' component={AllTransactions} />

                                    {/* Tickets */}
                                    <PrivateRoute exact path='/add-ticket' component={AddTicket} />
                                    <PrivateRoute exact path='/all-tickets' component={AllTickets} />
                                    {/* <PrivateRoute exact path='/add-ticket-seat' component={TicketSeat} /> */}
                                    <PrivateRoute exact path='/add-ticket-customer' component={TicketCustomer} />
                                    <PrivateRoute exact path='/economy-ghouri' component={EconomyGhouri} />
                                    <PrivateRoute exact path='/brake-ghouri' component={BrakeGhouri} />
                                    <PrivateRoute exact path='/update-reserve-to-book' component={ReserveToBook} />
                                    <PrivateRoute exact path='/print-ticket' component={PrintTicket} />
                                    <PrivateRoute exact path='/printOnline' component={PrintOnlineTicket} />

                                    {/* Customers */}
                                    <PrivateRoute exact path='/add-customer' component={AddCustomer} />
                                    <PrivateRoute exact path='/all-customers' component={AllCustomers} />

                                    {/* Coaches */}
                                    <PrivateRoute exact path='/add-coach' component={AddCoach} />
                                    <PrivateRoute exact path='/all-coaches' component={AllCoaches} />

                                    {/* Trains */}
                                    <PrivateRoute exact path='/add-train' component={AddTrain} />
                                    <PrivateRoute exact path='/all-trains' component={AllTrains} />
                                    <PrivateRoute exact path='/train-cities' component={AddTrainCity} />
                                    <PrivateRoute exact path='/train-status' component={TrainStatus} />

                                    {/* Train Cities */}
                                    <PrivateRoute exact path='/add-train-city' component={AddMoreCities} />
                                    <PrivateRoute exact path='/all-train-cities' component={AllTrainCities} />
                                    <PrivateRoute exact path='/update-train-city' component={UpdateTrainCity} />

                                    {/* Fares */}
                                    <PrivateRoute exact path='/add-fare' component={AddFare} />
                                    <PrivateRoute exact path='/all-train-fares' component={AllTrainFares} />
                                    <PrivateRoute exact path='/update-fare' component={UpdateFare} />
                                    <PrivateRoute exact path='/bulk-fare' component={BulkUpdateFare} />
                                    <PrivateRoute exact path='/check-fare' component={CheckFare} />
                                    <PrivateRoute exact path='/copy-fares' component={CopyFares} />
                                    {/* Tours */}
                                    <PrivateRoute exact path='/add-tour' component={AddTour} />
                                    <PrivateRoute exact path='/all-tours' component={AllTours} />
                                    <PrivateRoute exact path='/update-tour' component={UpdateTour} />
                                    <PrivateRoute exact path='/tour-status' component={TourStatus} />
                                    <PrivateRoute exact path='/copy-tour' component={CopyTour} />

                                    {/* Tour Coaches */}
                                    <PrivateRoute exact path='/add-tour-coach' component={AddTourCoach} />
                                    <PrivateRoute exact path='/all-tour-coaches' component={AllTourCoaches} />
                                    <PrivateRoute exact path='/update-tour-coach' component={UpdateTourCoach} />

                                    {/* Reports */}
                                    <PrivateRoute exact path='/reports' component={Reports} />
                                    <PrivateRoute exact path='/report-by-status' component={ReportByStatus} />
                                    <PrivateRoute exact path='/report-by-createdAt' component={ReportCreatedAt} />
                                    <PrivateRoute exact path='/report-cityWiseUser' component={ReportCityWiseUser} />
                                    <PrivateRoute exact path='/report-passengerChart' component={ReportPassengerChart} />
                                    <PrivateRoute exact path='/discounted-ticket-report' component={DiscountedTicketReport} />
                                    <PrivateRoute exact path='/cargo-report' component={CargoReport} />
                                    <PrivateRoute exact path='/cargo-individual-report' component={CargoIndividualReport} />
                                    <PrivateRoute exact path='/other-reports' component={OtherReports} />

                                    {/* Cargo */}
                                    <PrivateRoute exact path='/add-cargo' component={AddCargo} />
                                    <PrivateRoute exact path='/update-cargo' component={EditCargo} />
                                    <PrivateRoute exact path='/all-cargo' component={AllCargo} />
                                    <PrivateRoute exact path='/cargo-by-id' component={CargoById} />
                                    <PrivateRoute exact path='/cargo-details' component={CargoDetails} />

                                    {/* Preferences */}
                                    <PrivateRoute exact path='/all-preferences' component={AllPreferences} />
                                    <PrivateRoute exact path='/update-preference' component={UpdatePreference} />
                                 
                                       {/* layouts */}
                                    <PrivateRoute exact path='/acstd72' component={ACstd72} /> {/* Done */}
                                    <PrivateRoute exact path='/acbusinessthal' component={AcBusiness} /> {/* Done */}
                                    <PrivateRoute exact path='/economy1thal' component={Economy} />  {/* Wrong */}
                                    <PrivateRoute exact path='/parlorcarthal' component={ParloCar} />
                                    <PrivateRoute exact path='/economy2thal' component={Economy2} />  {/* Wrong */}
                                    <PrivateRoute exact path='/acstd90thal' component={AcStd90} />   {/* Wrong */}
                                    <PrivateRoute exact path='/open' component={Open} /> 
                                    <PrivateRoute exact path='/acsleeperthal' component={AcSleeper} />  {/* Done */}
                                    <PrivateRoute exact path='/economy3' component={Economy3} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd96' component={AcStd96} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd74' component={AcStd74} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd98' component={AcStd98} />
                                    <PrivateRoute exact path='/acstd101' component={AcStd101} />
                                       {/* layouts Views */}
                                    <PrivateRoute exact path='/acstd72view' component={ACstd72View} /> {/* Done */}
                                    <PrivateRoute exact path='/acbusinessview' component={AcBusinessView} /> {/* Done */}
                                    <PrivateRoute exact path='/economy1view' component={EconomyView} />  {/* Wrong */}
                                    <PrivateRoute exact path='/parlorcarview' component={ParloCarView} />
                                    <PrivateRoute exact path='/economy2view' component={Economy2View} />  {/* Wrong */}
                                    <PrivateRoute exact path='/acstd90view' component={AcStd90View} />   {/* Wrong */}
                                    <PrivateRoute exact path='/openview' component={OpenView} /> 
                                    <PrivateRoute exact path='/acsleeperview' component={AcSleeperView} />  {/* Done */}
                                    <PrivateRoute exact path='/economy3view' component={Economy3View} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd96view' component={AcStd96View} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd74view' component={AcStd74View} />  {/* Done */}
                                    <PrivateRoute exact path='/economyghouriview' component={EconomyGhouriView} />  {/* Done */}
                                    <PrivateRoute exact path='/brakeview' component={BrakeView} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd101view' component={AcStd101View} />  {/* Done */}
                                    <PrivateRoute exact path='/acstd98view' component={AcStd98View} />  {/* Done */}

                                       {/* layouts Views */}
                                       <PrivateRoute exact path='/offline-devices' component={OfflineDevices} /> {/* Done */}
                                </Switch>
                            </div>
                        </div>

                        <Footer />
                    </div>
                </>
            }
        </>


    )
}

export default Routes
