import Offline from '../services/offline/offline';

export const addDevice = (form) => {

    return Offline.addOfflineDevice(form).then(response => {
        return {status: response.status,data:response.data, message: "CIty Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const allDevices = (params) => {

    return Offline.ListOfflineDevice(params).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteDevice = (id) => {

    return Offline.deleteOfflineDevice(id).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};


