import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import logo from "./logo.jpg";
import jsPDF from "jspdf";

function PrintTicket() {
  const [ticketId, setTicketid] = useState(0);

  useEffect(() => {
    document.title = "Generate Ticket | AA Enterprise";
  }, []);
  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  async function makeNewPdf() {
    // const presets = {
    //   train_name: { ln: 11, text: "Train Name:" },
    //   cnic: { ln: 5, text: "CNIC:" },
    //   phone: { ln: 6, text: "Phone:" },
    //   seat: { ln: 8, text: "Seat No:" },
    //   customer_name: { ln: 14, text: "Customer Name:" },
    //   coach: { ln: 6, text: "Coach:" },
    //   departure_time: { ln: 15, text: "Departure Time:" },
    //   arrival_time: { ln: 13, text: "Arrival Time:" },
    //   fare: { ln: 5, text: "Fare:" },
    //   st_city: { ln: 15, text: "Starting City:" },
    //   end_city: { ln: 15, text: "Ending City:" },
    // };
    const doc = new jsPDF("p", "mm", [203, 90]);
    doc.setFontSize("13");
    var startX = 35;
    var finalY = 135;

    //line 1
    doc.text(startX, finalY, "Ticket Id:", null, 90);
    finalY -= 20;
    doc.text(startX, finalY, "25896547", null, 90);
    doc.setFontSize("11");

    startX += 7;
    finalY = 180;

    //line 2
    doc.text(startX, finalY, "Train Name:", null, 90);
    finalY -= 25;
    doc.text(startX, finalY, "Thal Express Up", null, 90);
    finalY -= 40;
    doc.text(startX, finalY, "Coach:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, "Economy Ghouri - 4", null, 90);

    startX += 6;
    finalY = 180;

    //line 2
    doc.text(startX, finalY, "From:", null, 90);
    finalY -= 20;
    doc.text(startX, finalY, "RawalPindi", null, 90);
    finalY -= 45;
    doc.text(startX, finalY, "To:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, "Multan", null, 90);

    startX += 6;
    finalY = 180;

    //line 2
    doc.text(startX, finalY, "Departure:", null, 90);
    finalY -= 20;
    doc.text(startX, finalY, "2022-05-15 4:00 PM", null, 90);
    finalY -= 45;
    doc.text(startX, finalY, "Arrival:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, "2022-05-15 4:00 AM", null, 90);

    startX += 6;
    finalY = 180;

    //line 3
    doc.text(startX, finalY, "Customer Name: ", null, 90);
    finalY -= 30;
    doc.text(startX, finalY, "Muhammad Hassan", null, 90);
    finalY -= 50;
    doc.text(startX, finalY, "CNIC:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, "33102-6430250-9", null, 90);

    //line 3
    doc.setFontSize("11");
    finalY = 140;
    startX += 12;

    doc.text(startX, finalY, "Seat:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, "5S,9B,8C,5OPB", null, 90);

    finalY = 140;
    startX += 5;
    doc.text(startX, finalY, "Fare:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, "4500", null, 90);

    doc.setFontSize("11");
    finalY = 140;
    startX += 5;
    doc.text(startX, finalY, "Created By:", null, 90);
    finalY -= 22;
    doc.text(startX, finalY, "Muhammad Hamza", null, 90);
    finalY = 140;
    startX += 5;
    doc.text(startX, finalY, "Print:", null, 90);
    finalY -= 22;
    doc.text(startX, finalY, (new Date().toLocaleString()), null, 90);

    //side ticket printing
    //line 2

    startX = 35;
    finalY = 45;
    doc.setFontSize("12");

    doc.text(startX, finalY, "ID:", null, 90);
    finalY -= 13;
    doc.text(startX, finalY, "25896547", null, 90);

    startX += 7;
    finalY = 45;
    doc.setFontSize("7");

    doc.text(startX, finalY, "Coach:", null, 90);
    finalY -= 10;
    doc.text(startX, finalY, "Economy Ghouri - 4", null, 90);

    startX += 7;
    finalY = 45;
    doc.setFontSize("8");

    doc.text(startX, finalY, "Seat:", null, 90);
    finalY -= 10;
    doc.text(startX, finalY, "5S,9B,8C,5OPB", null, 90);

    startX += 14;
    finalY = 45;
    doc.setFontSize("12");

    doc.text(startX, finalY, "From:", null, 90);
    finalY -= 13;
    doc.text(startX, finalY, "RawalPindi", null, 90);

    startX += 7;
    finalY = 45;

    doc.text(startX, finalY, "To:", null, 90);
    finalY -= 10;
    doc.text(startX, finalY, "Multan", null, 90);

    startX += 9;
    finalY = 45;
    doc.setFontSize("13");

    doc.text(startX, finalY, "Fare:", null, 90);
    finalY -= 12;
    doc.text(startX, finalY, "4500", null, 90);

    startX += 7;
    finalY = 180;

    // doc.autoPrint()
    // window.open(doc.output('datauristring'));
    // var string = doc.output("datauristring");
    // var x = window.open();

    // x.document.location = string;
    // x.document.open();
    // window.print();
    // window.close();
    //     var base64string = doc.output('datauristring');
    //     debugBase64( base64string );

    // function debugBase64(base64URL){
    //     var win = window.open();
    //     win.document.write('<iframe src="' + base64URL  + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;"></iframe>');
    //     win.window.print()
    //     // win.close()
    // }
    //     // alert("success")
    var blobPdf = new Blob([doc.output("blob")], { type: "application/pdf" });
    var blobUrl = URL.createObjectURL(blobPdf);
    var w = window.open(blobUrl, "_system", "location=yes");
    console.log("wndow is here", w.window);
    // w.window.onafterprint = (event) => {
    //   console.log("After print");
    // };
     
    
    console.log(w.window);
    
    w.window.print();
    await sleep(8000);
    w.window.close();
    w.window.onfocus = (event) => {console.log("Before print");}
    // const mediaQuery = w.window.matchMedia('print');
    // mediaQuery.addEventListener('change',()=>{
    //     let matchtesPrint = w.window.matchMedia('print').matches;
    // console.log("match media is",matchtesPrint);
    // })
    // let matchtesPrint = w.window.matchMedia('print').matches;
    // console.log("match media is",matchtesPrint);
    
    // setTimeout(function(){window.close();}, 1);
    // await sleep(20000);
    // // w.window.onfocus = (w)=>{w.window.close()}; 
    // // w.window.onafterprint = w.window.close;
    // w.window.close();
    
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    if (validate()) {
      // alert("hello")/
      makeNewPdf();
    } else {
      toast.warn("Enter valid ticket id");
    }
  }

  function validate() {
    if (!isNaN(ticketId)) {
      return true;
    }
    return false;
  }

  function onChangeHandler(e) {
    const { value } = e.target;
    setTicketid(Number(value));
  }
  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Ticket</h2>
          <p class="text-light">print ticket</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Print Ticket</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Ticket Id</label>
                      <input
                        type="text"
                        name="ticket_id"
                        value={ticketId}
                        onChange={onChangeHandler}
                        class="form-control"
                        placeholder="enter ticket ID to print"
                      />
                    </div>
                  </div>
                  <button type="submit" name="submit" class="btn btn-primary">
                    Print
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrintTicket;
