import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import DataTable from "react-data-table-component";

import {
  getAllTickets,
  cancelTicket,
  getTicketByTourId,
} from "../../store/actions/ticket.actions";
import { allTrains } from "../../store/actions/train.actions";
import { getTrainToursByDate } from "../../store/actions/tour.actions";
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AllTickets() {
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({});
  const [Users, setUsers] = useState([]);
  const [user, setUser] = useState('');
  const [loggedInUser, setLoggedInUser] = useState({});
  const [Sum, setSum] = useState(0);
  const [rows, setRows] = useState({});
  const [date, setDate] = useState("");
  let [totalRows, setTotalRows] = useState(0);
  const [trainId, setTrainId] = useState(0);
  const [tourId, setTourId] = useState(0);
  const [toursByDate, setToursByDate] = useState([]);
  const [trains, setTrains] = useState([]);
  const [noOfRows, setNoOfRows] = useState(10);
  const [params, setParams] = useState({
    _count: 10,
    _pageNo: 1,
  });
  let reactTable = useRef(null);
  const columns = [
    
    {
      name: "Cust Id",
      selector: (row) => row.cust_id,
      sortable: true,
    },
    {
      name: "CNIC",
      selector: (row) => row.cnic,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Seat Number",
      selector: (row) => row.seat_number,
      sortable: true,
    },
    {
      name: "Ticket Id",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Offline Sr.",
      selector: (row) => row.offline_serial,
      sortable: true,
    },
    {
      name: "From",
      selector: (row) => row.st_city,
      sortable: true,
    },
    {
      name: "To",
      selector: (row) => row.end_city,
      sortable: true,
    },
    {
      name: "Fare",
      selector: (row) => row.fare,
      sortable: true,
    },
    {
      name: "Creator",
      selector: (row) => row.creator,
      sortable: true,
    },
    // {
    //   name: "Date",
    //   selector: (row) => row.date,
    //   sortable: true,
    //   // cell: (d) =>
    //   // (
    //   //     `${d.created_at.substr(0, 10)} ${d.created_at.substr(12, 4)}`
    //   // )
    // },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      cell: (d) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => {
              toast.warn("Cannot edit ticket");
              // history.push({
              //     pathname: 'update-ticket',
              //     state: {
              //         id: d.id,
              //         tour_id: d.tour_id,
              //         coach_type: d.coach_type,
              //         tt_id: d.tt_id,
              //         coach_number: d.coach_number
              //     }
              // })
            }}
            type="button"
          >
            <i class="icon-edit"></i>
          </button>
          <button
            class="btn btn-danger"
            type="button"
            onClick={() => _cancelTicket(d.id)}
          >
            <i class="icon-trash" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    document.title = "All Tickets | AA Enterprise";
    $(".print").hide();
    fetchTrains();
    setLoggedInUser(JSON.parse(localStorage.getItem('user')).user);
  }, []);
  useEffect(() => {
    if (trainId && date !== "") {
      _getTrainToursByDate();
    }
  }, [trainId, date]);
  useEffect(() => {
    if (tourId) {
      _getTicketByTourId();
    }
  }, [tourId]);
  useEffect(() => {

    if (rows.length > 0 && tourId) {
      calculateSum();
      setTableData({
        columns: columns,
        data: rows,
      });
    } else {
      setSum(0);
    }
  }, [rows]);

  useEffect(() => {
    setParams((prevValue) => {
      return {
        ...prevValue,
        _pageNo: pageNo,
      };
    });
  }, [pageNo]);

  useEffect(() => {
    setParams((prevValue) => {
      return {
        ...prevValue,
        _count: noOfRows,
      };
    });
  }, [noOfRows]);
  useEffect(() => {
    if (rows.length && tourId) {
      // if (user.length > 2) {
      //   var filteredArray = rows.filter(function (row) {
      //     return row.creator === user;
      //   });
      //   calculateSum(filteredArray)
      // }
      calculateSum()
    } else {
      setSum(0);
    }
  }, [user, tourId]);

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 100, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function _getTrainToursByDate() {
    let response = await getTrainToursByDate(date, trainId);
    if (response.status === 200) {
      if (response.data) {
        document.getElementById("display_date").innerHTML = $(
          "#select_date"
        )[0].value.substr(0, 10);
        setToursByDate(response.data);
      } else {
        toast.warn("No tours were found on this date");
        setToursByDate([]);
      }
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }

  async function _getAllTickets() {
    let response = await getAllTickets(params);
    if (response.status === 200) {
      setRows(response.data);
      // let unArray = [...new Set(response.data.map((item) => item.creator))];
      let unArray = [...new Set(response.data.map((item) => item.role.toLowerCase() === 'admin' ? item.creator : (item.name.toLowerCase() === loggedInUser.name.toLowerCase() ? item.creator : null)))];
      // loggedInUser
      setUsers(unArray);

      setTotalRows(response.data.length);
    } else if (response.status >= 400) {
      toast.error("Failed to fetch tickets");
    }
  }

  async function _cancelTicket(id) {
    if (JSON.parse(localStorage.getItem("permissionsNumber")).includes(16)) {
      let amount = prompt("Please enter charged amount:");
      if (amount == null || amount == "" || isNaN(amount)) {
        alert("Invalid Selection or Amount");
      } else {
        let response = await cancelTicket(id, amount);
        if (response.status === 200) {
          toast.success("Ticket deleted");

        } else if (response.status >= 400) {
          toast.error("Failed to delete");
        }
      }
    } else {
      toast.warn("You are not allowed to perform this action");
    }
  }

  async function _getTicketByTourId() {

    $('#preloader').delay(333).fadeIn('slow');

    let response = await getTicketByTourId(tourId);

    $('#preloader').delay(333).fadeOut('slow');

    if (response.status === 200) {
      setRows(response.data);
      let unArray = [...new Set(response.data.map((item) => item.creator))];
      if (loggedInUser.role === 'admin') {
        setUsers(unArray);
      } else if (unArray.includes(loggedInUser.name)) {
        setUsers([loggedInUser.name]);
      }

      setTotalRows(response.data.length);
    } else {
      toast.error("Failed to fetch tickets");
      setRows(response.data);
      setTotalRows(response.data.length);
    }
    // alert("done");
  }

  function selectTrain(e) {
    e.preventDefault();
    setTrainId(Number(document.getElementById("trains_select").value));
  }

  function selectDateSubmit(e) {
    e.preventDefault();
    setDate($("#select_date")[0].value.substr(0, 10));
    document.getElementById("display_date").innerHTML = "";
  }
  function trainTourOnChange(e) {
    const { value } = e.target;
    setTourId(Number(value));
  }

  function calculateSum() {
    // alert("called")
    // if (user.length > 2) {
    var filteredArray = rows.filter(function (row) {
      return row.creator === user;
    });
    let rowArray = [...filteredArray];
    let sum = rowArray.reduce(function (sum, current) {
      return sum + current.fare;
    }, 0);
    setSum(sum);
    // }
  }

  function userChange(e) {
    let value = e.target.value;
    setUser(value);
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Tickets</h2>
          <p class="text-light">List of all tickets</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Ticket</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">Select Train</label>
                  <select
                    id="trains_select"
                    name="train_id"
                    onChange={selectTrain}
                    class="form-select form-control wide"
                  >
                    <option value="">Choose...</option>
                    {trains.map((train) => {
                      return (
                        <option
                          key={train.key}
                          value={train.id}
                          data-name={train.name}
                        >
                          {train.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div class="mb-3 col-md-6">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Date</label>
                      <input
                        id="select_date"
                        type="date"
                        name="date"
                        class="form-control"
                        placeholder="date"
                        required
                      />
                    </div>
                  </div>
                  <div class="mb-3 col-md-12 row">
                    <div class="col-md-4">
                      <button
                        type="submit"
                        name="fetch_coaches_btn"
                        class="btn btn-primary"
                        onClick={(e) => {
                          selectDateSubmit(e);
                        }}
                      >
                        Select
                      </button>
                    </div>
                    <div class="col-md-8">
                      <p>
                        Date: <i id="display_date" style={{ color: "red" }}></i>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="mb-3 col-md-6">
                  <div class="basic-form">
                    <div class="row">
                      <div class="mb-3 col-md-12">
                        <label class="form-label">Select Tour</label>
                        <select
                          id="tours_select"
                          name="tour_id"
                          onChange={trainTourOnChange}
                          class="form-select form-control wide"
                        >
                          <option value="">Choose...</option>
                          {toursByDate.map((tour) => {
                            return (
                              <option key={tour.id} value={tour.id}>
                                {tour.name} {tour.depart_time.substr(11, 5)}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">UserWise Total</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-md-6">
                  <label class="form-label">Select User</label>
                  <select
                    id="user_select"
                    name="user_name"
                    onChange={userChange}
                    class="form-select form-control wide"
                  >
                    <option value="">Choose...</option>
                    {Users.map((user) => {
                      return (
                        <option
                          key={user}
                          value={user}
                        >
                          {user}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div class="mb-3 col-md-6">
                  <div class="basic-form">
                    <div class="row">
                      <div class="mb-3 col-md-6">
                        <label class="form-label">Total is</label>
                        <h2 style={{ color: 'red' }}>{Sum}</h2>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Tickets List</h4>
              <button class="btn btn-primary" onClick={() => { _getTicketByTourId() }}>Update Table</button>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <DataTableExtensions {...tableData}>
                  <DataTable
                    data={rows}
                    ref={(r) => (reactTable = r)}
                    columns={columns}
                    highlightOnHover
                    pagination={true}
                    // paginationServer={true}
                    defaultSortField={"name"}
                    
                    paginationTotalRows={totalRows}
                    paginationPerPage={noOfRows}
                    paginationComponentOptions={{
                      noRowsPerPage: false,
                    }}
                    onChangeRowsPerPage={(row) => setNoOfRows(row)}
                    onChangePage={(page) => setPageNo(page)}
                    onFilteredChange={() => {
                      alert("filtered");
                    }}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTickets;
