import React, { useEffect, useState } from "react";
import $ from "jquery";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import {
  getTrainCoaches,
  getTrainToursByDate,
} from "../../store/actions/tour.actions";
import {getTicketFareByData} from "../../store/actions/fare.actions"
import { useHistory, useLocation } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AddTicket() {
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;

  const [trains, setTrains] = useState([]);
  const [date, setDate] = useState("");
  const [toursByDate, setToursByDate] = useState([]);
  const [trainId, setTrainId] = useState(0);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);
  const [cities, setCities] = useState({
    starting_city: stateData ? Number(stateData.starting_city_id) : 0,
    ending_city: 0,
  });
  // const [customerCnic, setCustomerCnic] = useState(0)
  const [tourId, setTourId] = useState(0);
  const [coachTypeId, setCoachTypeId] = useState(0);
  const [tourCoaches, setTourCoaches] = useState([]);
  const [pathname, setPathname] = useState("");
  const [names, setNames] = useState({
    train_name: "",
    coach_name: "",
  });
  const [coachNumber, setCoachNumber] = useState(0);
  let coachTypeNames = [
    "- economy ghouri",
    "- brake",
    "- ac standard 72",
    "- ac business ",
    "- ac sleeper ",
    "- parlor car ",
    "- open",
    "- economy one ",
    "- economy two ",
    "- ac std 90",
    "- economy three",
    "- ac std ns",
    "- economy four",
  ];
  let coachTypeRoutes = [
    "/economy-ghouri",
    "/brake-ghouri",
    "/acstd72",
    "/acbusinessthal",
    "/acsleeperthal",
    "/parlorcarthal",
    "/open",
    "/economy1thal",
    "/economy2thal",
    "/acstd90thal",
    "/economy3",
    "acstd96",
    "/brake-ghouri",
  ];

  //hooks start

  //initial hook
  useEffect(() => {
    document.title = "Add Ticket | AA Enterprise";
    fetchTrains();
  }, []);

  //tour fetching hook
  useEffect(() => {
    if (date !== "" && trainId) {
      _getTrainToursByDate();
      setCoachTypeId(0);
      setTourCoaches([]);
      setCities({
        starting_city: stateData ? Number(stateData.starting_city_id) : 0,
        ending_city: 0,
      });
      setStartingCities([]);
      if (!stateData) {
        setEndingCities([]);
      }
    }
    if (trainId) {
      _getTrainStartingCities();
    }
  }, [date, trainId]);

//tourCoaches fetch hook
  useEffect(() => {
    if (tourId !== 0) {
      _getTourCoaches();
    }
  }, [tourId]);


//ending citiez fetch hook
  useEffect(() => {
    if (cities.starting_city && trainId) _getTrainEndingCities();
  }, [cities.starting_city, trainId]);


  

  //redirect utility hook on pathname variable change
  useEffect(() => {
    if (pathname !== "") {
      history.push({
        pathname: pathname,
        state: {
          train_id: trainId,
          train_name: names.train_name,
          date: date,
          tour_id: tourId,
          tour_name: $("#tours_select option:selected").text(),
          coachTypeId: coachTypeId,
          coach_name: names.coach_name,
          coach_number: coachNumber,
          starting_city_id: cities.starting_city,
          starting_city_name: $("#starting_city option:selected").text(),
          ending_city_id: cities.ending_city,
          ending_city_name: $("#ending_city option:selected").text(),
          status: stateData ? stateData.status : "",
          cnic: stateData ? stateData.cnic : "",
        },
      });
    }
  }, [pathname]);

//effect to set tour if previously selected after all tours are fetched
  useEffect(() => {
    if (JSON.stringify(stateData) !== undefined) {
      setTourId(stateData.tour_id);
      $("#tours_select option:selected").text(stateData.tour_name);
    }
  }, [toursByDate]);

//hook to set tour coach if previously selected
  useEffect(() => {
    if (JSON.stringify(stateData) !== undefined) {
      setCoachTypeId(stateData.coachTypeId);
      // $("#coach_select option:selected").text(stateData.coach_name);
      let coachName = stateData.coach_name;
      setNames((prevValue) => {
        return {
          ...prevValue,
          coach_name: coachName.replace(/[0-9]/g, "").trim(),
        };
      });
    }
  }, [tourCoaches]);


  //hook to handle data from previous ticket
  useEffect(() => {
    if (JSON.stringify(stateData) !== undefined) {
      setTrainId(stateData.train_id);
      $("#trains_select option:selected").text(stateData.train_name);

      setNames((prevValue) => {
        return {
          ...prevValue,
          train_name: stateData.train_name,
        };
      });

      setDate(stateData.date);
      $("#display_date").text(stateData.date);
      $("#select_date").val(stateData.date);

      $("#starting_city option:selected").text(stateData.starting_city_name);
      $("#starting_city option:selected").val(stateData.starting_city_id);

      setCities((prevValue) => {
        return {
          ...prevValue,
          starting_city: Number(stateData.starting_city_id),
        };
      });
    }
  }, [stateData]);

  //fetch functions start

  async function _getTrainEndingCities() {
    let response = await getTrainEndingCities(trainId, cities.starting_city);
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setEndingCities([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(trainId);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }

  async function _getTourCoaches() {
    let response = await getTrainCoaches(tourId, { _count: 30 });
    if (response.status === 200 && response.data.length !== 0) {
      setTourCoaches(response.data.tourCoaches);
    } else if (response.status >= 400) {
      toast.error("no coaches found");
      setTourCoaches([]);
    }
  }

  async function _getTrainToursByDate() {
    let response = await getTrainToursByDate(date, Number(trainId));
    if (response.status === 200) {
      if (response.data) {
        document.getElementById("display_date").innerHTML = $(
          "#select_date"
        )[0].value.substr(0, 10);
        let activeTours = [];
        for (let i = 0; i < response.data.length; i++) {
          if (!(response.data[i].tour_status === "deactive")) {
            activeTours.push(response.data[i]);
          }
        }
        if (activeTours.length > 0) {
          setToursByDate(activeTours);
        } else {
          toast.warn("no active tours found");
        }
      } else {
        toast.warn("No tours were found on this date");
        setToursByDate([]);
      }
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  //fetch functions end

  //change handlers start
  function selectTrain(e) {
    e.preventDefault();
    setTrainId(Number(document.getElementById("trains_select").value));
    let tn = $("#trains_select option:selected").text();
    setNames((prevValue) => {
      return {
        ...prevValue,
        train_name: tn,
      };
    });
  }

  function selectDateSubmit(e) {
    e.preventDefault();
    setDate($("#select_date")[0].value.substr(0, 10));
  }

  function trainTourOnChange(e) {
    const { value } = e.target;
    setTourId(Number(value));
  }

  function TourCoachOnChange(e) {
    // name contains coach number
    const { value, name } = e.target;
    let coachName = $("#coach_select option:selected").text();
    setCoachTypeId(value);
    setCoachNumber(name);
    setNames((prevValue) => {
      return {
        ...prevValue,
        coach_name: coachName.replace(/[0-9]/g, "").trim(),
      };
    });
  }

  function handleCityChange(e) {
    const { name, value } = e.target;
    setCities((prevValue) => {
      return {
        ...prevValue,
        [name]: Number(value),
      };
    });
  }
  //change hanlders end

 async  function formSubmit(e) {
    e.preventDefault();
    if (
      tourId &&
      trainId &&
      coachTypeId &&
      date !== "" &&
      cities.starting_city &&
      cities.ending_city
    ) {
      let dispObject = {
        train_id:trainId,
        st_city:cities.starting_city,
        end_city:cities.ending_city,
        tc_id:coachTypeId,
        seat_number:"test"
      }
      let resp = await getTicketFareByData(dispObject)
      if(resp.status === 200){
        alert(`Seat Fare is ${resp.data.seat_fare} and Berth Fare is ${resp.data.berth_fare}`)
      }else{
        toast.error("Could not fetch fare");
      }

    } else {
      toast.warn("Fill all data");
    }
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Tickets</h2>
          <p class="text-light">Check fare for train</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Check Fare</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  type="submit"
                  name="create_ticket_form"
                  onSubmit={(e) => {
                    formSubmit(e);
                  }}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={selectTrain}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option
                              key={train.key}
                              value={train.id}
                              data-name={train.name}
                            >
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Date</label>
                          <input
                            id="select_date"
                            type="date"
                            name="date"
                            class="form-control"
                            placeholder="date"
                            required
                          />
                        </div>
                      </div>
                      <div class="mb-3 col-md-12 row">
                        <div class="col-md-4">
                          <button
                            type="submit"
                            name="fetch_coaches_btn"
                            class="btn btn-primary"
                            onClick={(e) => {
                              selectDateSubmit(e);
                            }}
                          >
                            Select
                          </button>
                        </div>
                        <div class="col-md-8">
                          <p>
                            Date:{" "}
                            <i id="display_date" style={{ color: "red" }}></i>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Tour</label>

                            <select
                              id="tours_select"
                              name="tour_id"
                              onChange={trainTourOnChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {toursByDate.map((tour) => {
                                return (
                                  <option key={tour.id} value={tour.id}>
                                    {tour.name} {tour.depart_time.substr(11, 5)}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Coach</label>
                            <select
                              id="coach_select"
                              name="coach_type"
                              onChange={TourCoachOnChange}
                              class="form-select form-control wide"
                              value={coachTypeId}
                            >
                              <option value="">Choose...</option>
                              {tourCoaches.map((coach) => {
                                return (
                                  <option
                                    key={coach.id}
                                    value={coach.id}
                                    name={coach.coach_number}
                                  >
                                    {coach.coach_number}
                                    {" - "}
                                    {coach.coach_types.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="starting_city"
                              name="starting_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="ending_city"
                              name="ending_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    name="create_ticket_btn"
                    class="btn btn-primary"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Customer Profile</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">

                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Name</label>
                                        <input type="text" name='name' value={customerProfile.name} class="form-control" disabled  />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Phone</label>
                                        <input type="text" name='phone' value={customerProfile.phone} class="form-control" placeholder="03123456789 ..." />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">CNIC</label>
                                        <input type="text" name='cnic' value={customerProfile.cnic} class="form-control" placeholder="1234567891234  (without dashes)" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">DOB</label>
                                        <input type="date" name='dob' value={customerProfile.dob} class="form-control" placeholder="date of birth" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Gender</label>
                                        <input type="date" name='dob' value={customerProfile.gender} class="form-control" placeholder="date of birth" />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Address</label>
                                        <textarea class="form-control" name='address' value={customerProfile.address} rows="4" placeholder='address...'></textarea>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div> */}
    </>
  );
}

export default AddTicket;
