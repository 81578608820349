import React, { useEffect, useState } from 'react'
import { addTrainCity } from '../../store/actions/train.actions';
import { allCities } from '../../store/actions/city.actions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AddTrainCity() {
    var trainId;
    let params = new URLSearchParams(document.location.search.substring(1));
    trainId = params.get("train_id");

    let [cityOptions, setCityOptions] = useState([])
    const [isStarting, setIsStarting] = useState(0)
    const [isEnding, setIsEnding] = useState(0)
    const [cityParams, setCityParams] = useState({
        _count: 1000,
        _pageNo: 1,
    })
    const [trainCityForm, setTrainCityForm] = useState({
        arrival_time: 0,
        departure_time: 0,
        city_id: 0,
        train_id: 0,
        is_starting: 0,
        is_ending: 0,
        sr_no: 0
    })

    useEffect(() => {
        if (isStarting) {
            setIsEnding(false)
        } else {
            setIsStarting(false)
        }
    }, [isStarting])
    useEffect(() => {
        if (isEnding) {
            setIsStarting(false)
        } else {
            setIsEnding(false)
        }
    }, [isEnding])
    useEffect(() => {
        fetchCities()
    }, [cityParams])

    function handleFormChange(e) {
        let { name, value } = e.target;

        value = parseInt(value);
        setTrainCityForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    async function fetchCities() {
        var cityResponse = await allCities(cityParams);
        if (cityResponse.status === 200) {
            let cities = cityResponse.data.cities;
            setCityOptions(cities)
        } else if (cityResponse.status >= 400) {
            toast.error(cityResponse.message.message)
        }
    }
    // useEffect(() => {
    //     if (trainCityForm.is_ending === isEnding) {
    //         submitForm()
    //     }
    // }, [trainCityForm])

    // async function handleFormSubmit(e) {

    //     setTrainCityForm(prevValue => {
    //         return {
    //             ...prevValue,
    //             train_id: parseInt(trainId),

    //         }
    //     })
    //     if(isEnding) {
    //         setTrainCityForm(prevValue => {
    //             return {
    //                 ...prevValue,
    //                 departure_time: 0
    //             }
    //         })
    //     }
    //     if(isStarting) {
    //         setTrainCityForm(prevValue => {
    //             return {
    //                 ...prevValue,
    //                 arrival_time: 0
    //             }
    //         })
    //     } 

    // }
    async function handleFormSubmit(e) {
        e.preventDefault()
        let authentic = authenticate();
        if (authentic) {
            let reqObj = {

                city_id: Number(trainCityForm.city_id),
                train_id: Number(trainId),
                sr_no: isStarting ? 1 : Number(trainCityForm.sr_no),
                is_starting: Number(isStarting),
                is_ending: Number(isEnding),
                departure_time: isEnding ? 0  : Number(trainCityForm.departure_time) ,
                arrival_time: isStarting ? 0  : Number(trainCityForm.arrival_time)

            }
            let response = await addTrainCity(reqObj);
            if (response.status === 200) {
                toast.success("City added")
                setTrainCityForm({
                    arrival_time: 0,
                    departure_time: 0,
                    city_id: 0,
                    train_id: 0,
                    is_starting: 0,
                    is_ending: 0,
                    sr_no: 0
                })
            } else if (response.status >= 400) {
                toast.error(response.message.message)
            }
        } else {
            toast.warn("Enter valid values")
        }
    }

    function authenticate() {
        if(trainCityForm.is_ending === 0 && trainCityForm.is_starting === 0) {
            if(trainCityForm.arrival_time === 0 || trainCityForm.departure_time === 0) {
                return false;
            }
        } else if((trainCityForm.is_ending === 1 && trainCityForm.departure_time !== 0) || (trainCityForm.is_starting === 1 && trainCityForm.arrival_time !== 0)) {
            return false
        } else {
            return true
        }
    }

    function isStartingHandle(e) {
        setIsStarting(e.target.checked)
    }
    function isEndingHandle(e) {
        setIsEnding(e.target.checked)
    }

    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Trains</h2>
                    <p class="text-light">Add cities for train</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add city to Train</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form type="submit" name="create_train_form" onSubmit={(e) => handleFormSubmit(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Select City</label>
                                            <select id="role" value={trainCityForm.city_id} onChange={handleFormChange} name='city_id' class="form-select form-control wide">
                                                <option value=''>Choose...</option>
                                                {cityOptions.map((city) => {
                                                    return (
                                                        <option value={city.id}>{city.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Serial Number</label>
                                            <input type="number" name='sr_no' onChange={handleFormChange} value={trainCityForm.sr_no} class="form-control" placeholder="City serial number" min='1' max='200' />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Arrival Time</label>
                                            <input type="number" name='arrival_time' onChange={handleFormChange} value={trainCityForm.arrival_time} class="form-control" placeholder="Value in Minutes" min='0' />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Departure Time</label>
                                            <input type="number" name='departure_time' onChange={handleFormChange} value={trainCityForm.departure_time} class="form-control" placeholder="Value in Minutes" min='0' />
                                        </div>
                                        <div class="mb-3 row">
                                            <div class="col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="is_starting" onChange={isStartingHandle} />
                                                    <label class="form-check-label">
                                                        This is Starting City
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="is_ending" onChange={isEndingHandle} />
                                                    <label class="form-check-label">
                                                        This is Ending City
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" name='create_train_btn' class="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTrainCity
