import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCargoById } from "../../store/actions/cargo.actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CargoDetails() {
  const history = useHistory();
  const [cargoId, setCargoId] = useState(0);

  useEffect(() => {
    document.title = "Cargo By ID | AA Enterprise";
  }, []);

  async function _getCargoById() {
    if (cargoId) {
      let res = await getCargoById(cargoId);
      if (res.status === 200 && res.data !== "") {
        let obj = {
          train_number: res.data.train_number,
          booking_time: res.data.booking_time,
          cargo_status: res.data.cargo_status,
          end_city: res.data.end_city,
          st_city: res.data.st_city,
          items: res.data.items,
          party_name: res.data.party_name,
          train_name: res.data.train_name,
          id: res.data.id,
          recv_cnic: res.data.recv_cnic,
          recv_name: res.data.recv_name,
          recv_phone: res.data.recv_phone,
          amount: res.data.amount,
          weight: res.data.weight,
          dataObj: res.data,
        };
        history.push({
          pathname: "/cargo-details",
          state: { cargo: obj },
        });
      } else if (res.status >= 400) {
        toast.error(res.message.message);
      } else {
        toast.warn("No Record found");
      }
    } else {
      toast.warn("Enter cargo ID");
    }
  }

  function handleChange(e) {
    e.preventDefault();
    const { value } = e.target;
    setCargoId(Number(value));
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Cargo</h2>
          <p class="text-light">Get cargo by ID</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Cargo by ID</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">
                        Cargo No <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="number"
                        name="cargo_id"
                        class="form-control"
                        placeholder="cargo no"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary"
                id="cargo_by_id"
                onClick={() => {
                  _getCargoById();
                }}
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CargoDetails;
