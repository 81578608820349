import React, { useState, useEffect } from "react";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import { reportByStatus } from "../../store/actions/report.actions";
import { allUsers } from "../../store/actions/user.actions";
import { allCoaches } from "../../store/actions/coach.actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ReportByStatus() {
  const [trains, setTrains] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);
  const [form, setForm] = useState({
    train_id: 0,
    st_date: "",
    end_date: "",
    status: "",
    creator: 0,
    st_city: 0,
    end_city: 0,
    coach_type_id: 0,
  });
  const [names, setNames] = useState({
    coach_type_name: "",
    creator: "",
    tour_name: "",
    st_city: "",
    end_city: "",
    train_name: "",
    tour_date: "",
    st_date: "",
    end_date: "",
    status: "",
  });

  useEffect(() => {
    document.title = "Reports | AA Enterprise";
    $(".print").hide();
    fetchTrains();
    _allUsers();
    _allCoaches();
  }, []);
  useEffect(() => {
    if (form.train_id) {
      _getTrainStartingCities();
    }
  }, [form.train_id]);
  useEffect(() => {
    if (form.st_city) {
      _getTrainEndingCities();
    }
  }, [form.st_city]);

  async function _allUsers() {
    let response = await allUsers({ _count: 500, _pageNo: 1 });
    if (response.status === 200) {
      setAdmins(response.data.users);
    } else if (response.status >= 400) {
      toast.error("Failed to fetch Admins");
    }
  }

  async function _allCoaches() {
    let response = await allCoaches({ _count: 500, _pageNo: 1 });
    if (response.status === 200 && response.data.length !== 0) {
      setCoaches(response.data.types);
    } else if (response.status >= 400) {
      toast.error("no coaches found");
      setCoaches([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(form.train_id);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }

  async function _getTrainEndingCities() {
    let response = await getTrainEndingCities(form.train_id, form.st_city);
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setEndingCities([]);
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 500, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();

    if (form.train_id) {
      $('#preloader').delay(333).fadeIn('slow')
      let response = await reportByStatus(form);
      $('#preloader').delay(333).fadeOut('slow');
      if (response.status === 200) {
        if (response.data.length === 1) {
          toast.warn("No Records found");
        } else if (response.data.length > 1) {
          downloadPDF(response.data);
        }
      } else if (response.status >= 400) {
        toast.error("Failed to Download");
      }
    } else {
      toast.info("Please select train");
    }
  }

  function downloadPDF(response) {
    const doc = new jsPDF("l");

    var startX = 50;
    var finalY = 10;
    doc.text(startX, finalY, "Booking / Reservation Report");
    finalY += 10;
    let namesPrint = {
      coach_type_name: "Coach Type",
      creator: "Creator",
      tour_name: "Tour Date&Time",
      st_city: "Starting City",
      end_city: "Ending City",
      train_name: "Train Name",
      tour_date: "Tour Date",
      st_date: "Starting Date",
      end_date: "Ending Date",
      status: "Status",
    };

    var i = 1;
    for (const property in names) {
      if (names[property] !== "") {
        if (i % 2 !== 0) {
          //Left
          startX = 25;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + names[property]
          );
        } else {
          //Right
          startX = 115;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + names[property]
          );
          finalY += 12;
        }
        i++;
      } else {
        continue;
      }
    }

    finalY += 10;

    doc.autoTable({
      startY: finalY,
      head: tableHead(response[0]),
      body: tableRows(response),
      theme: "grid",
    });
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize("10");
    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        210 - 10,
        297 - 10,
        null,
        null,
        "right"
      );
    }

    doc.save("ReportByStatus.pdf");

    setStartingCities([]);
    setEndingCities([]);
    $("#trains_select").val("");
    $("#coach_select").val("");
    $("#status_select").val("");
    $("#creator").val("");
    setForm({
      train_id: 0,
      st_date: "",
      end_date: "",
      status: "",
      creator: 0,
      st_city: 0,
      end_city: 0,
      coach_type_id: 0,
    });
    setNames({
      coach_type_name: "",
      creator: "",
      tour_name: "",
      st_city: "",
      end_city: "",
      train_name: "",
      tour_date: "",
      st_date: "",
      end_date: "",
      status: "",
    });
  }

  function tableHead(res) {
    let head = [[], []];
    head[0].push({
      content: res[0],
      colSpan: 1,
      styles: { halign: "center", fillColor: [22, 160, 133] },
    });
    for (let i = 1; i < res.length - 1; i++) {
      head[0].push({
        content: res[i],
        colSpan: 3,
        styles: { halign: "center", fillColor: [22, 160, 133] },
      });
    }
    head[0].push({
      content: res[res.length - 1],
      colSpan: 1,
      styles: { halign: "center", fillColor: [22, 160, 133] },
    });

    head[1].push("");
    for (let i = 1; i < res.length - 1; i++) {
      head[1].push("Male", "Female" , "Child");
    }
    head[1].push("");

    return head;
  }

  function tableRows(response) {
    var body = [];

    for (let i = 1; i < response.length; i++) {
      let array = [];
      array.push(response[i][0]);
      for (let j = 1; j < response[i].length - 1; j++) {
        array.push(response[i][j]);
      }
      array.push(response[i][response[i].length - 1]);

      body.push(array);
    }

    return body;
  }

  function CoachOnChange(e) {
    const { value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        coach_type_id: Number(value),
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        coach_type_name: $("#coach_select :selected").text(),
      };
    });
  }

  function selectTrain() {
    const id = Number(document.getElementById("trains_select").value);
    setForm((prevValue) => {
      return {
        ...prevValue,
        train_id: id,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        train_name: $("#trains_select :selected").text(),
      };
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }
  function selectStatus() {
    const sts = document.getElementById("status_select").value;
    setForm((prevValue) => {
      return {
        ...prevValue,
        status: sts,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        status: sts,
      };
    });
  }
  function selectCreator() {
    const id = Number(document.getElementById("creator").value);
    setForm((prevValue) => {
      return {
        ...prevValue,
        creator: id,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        creator: $("#creator :selected").text(),
      };
    });
  }
  function handleCityChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: Number(value),
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        [name]: $("#" + name + " :selected").text(),
      };
    });
  }
  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Reports</h2>
          <p class="text-light">Filter report of your choice</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={selectTrain}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option key={train.id} value={train.id}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Coach</label>
                            <select
                              id="coach_select"
                              name="coach_type"
                              onChange={CoachOnChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {coaches.map((coach) => {
                                return (
                                  <option key={coach.id} value={coach.id}>
                                    {coach.name} {coach.number}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city"
                              name="st_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city"
                              name="end_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Starting Date</label>
                          <input
                            id="st_date"
                            type="date"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="st_date"
                            class="form-control"
                            placeholder="starting date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Ending Date</label>
                          <input
                            id="end_date"
                            type="date"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="end_date"
                            class="form-control"
                            placeholder="ending date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Status</label>
                      <select
                        id="status_select"
                        name="status"
                        onChange={selectStatus}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="booked">
                          Booked
                        </option>
                        n
                        <option key="2" value="reserved">
                          Reserved
                        </option>
                        <option key="2" value="online_purchased">
                          Online
                        </option>
                        <option key="3" value="cancelled">
                          Cancelled
                        </option>
                      </select>
                    </div>

                    <div class="col-md-6">
                      <label class="form-label">Creator</label>
                      <select
                        id="creator"
                        name="creator"
                        onChange={selectCreator}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {admins.map((admins) => {
                          return (
                            <option key={admins.id} value={admins.id}>
                              {admins.name} / {admins.role}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="row">
                      <div class="col-3">
                        <button
                          type="submit"
                          name="submit"
                          class="btn btn-primary"
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportByStatus;
