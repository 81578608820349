import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import {
  getCargoByTrainDate,
  getCargoById,
  deleteCargoById,
} from "../../store/actions/cargo.actions";
import { allTrains } from "../../store/actions/train.actions";
import { getTrainToursByDate } from "../../store/actions/tour.actions";
import $ from "jquery";

import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AllCargo() {
  const history = useHistory();
  const columns = [
    {
      name: "ID",
      selector: "id",
      sortable: true,
    },
    {
      name: "Party",
      selector: "party_name",
      sortable: true,
    },
    {
      name: "From",
      selector: "st_city",
      sortable: true,
    },
    {
      name: "To",
      selector: "end_city",
      sortable: true,
    },
    {
      name: "Weight",
      selector: "weight",
      sortable: true,
    },
    {
      name: "Amount",
      selector: "amount",
      sortable: true,
    },
    {
      name: "Booking Time",
      selector: "booking_time",
      sortable: true,
      format: (d) =>
        `${d.booking_time.substr(0, 10)} ${d.booking_time.substr(12, 4)}`,
    },
    {
      name: "Type",
      selector: "cargo_status",
      sortable: true,
    },
    {
      name: "Status",
      selector: "delivery_status",
      sortable: true,
    },
    {
      name: "Delivered By",
      selector: "delivered_by",
      sortable: true,
    },
    {
      name: "Delivered At",
      selector: "delivered_at",
      sortable: true,
      cell: (d) => {
        if (d.delivered_at != null) {
          return `${d.delivered_at.substr(0, 10)} ${d.delivered_at.substr(
            12,
            4
          )}`;
        }
      },
    },
    {
      name: "Created By",
      selector: "created_by",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (d) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push({
                pathname: "/update-cargo",
                state: {
                  id: d.id,
                  end_city: d.end_city,
                  cargo_status: d.cargo_status,
                  delivery_status: d.delivery_status,
                  train_id: d.train_id,
                  st_city: d.st_city_id,
                  end_city_id: d.end_city_id,
                  recv_cnic: d.recv_cnic,
                  recv_name: d.recv_name,
                  recv_phone: d.recv_phone,
                },
              });
            }}
            type="button"
          >
            <i class="icon-edit"></i>
          </button>
          {/* <button class="btn btn-danger" type="button" onClick={() => _deleteCargo(d.id)} ><i class="icon-trash" aria-hidden="true"></i></button> */}
        </>
      ),
    },
  ];

  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({});
  const [params, setParams] = useState({
    _count: 15,
    _pageNo: 1,
  });
  const [rows, setRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [trains, setTrains] = useState([]);
  const [form, setForm] = useState({
    train_id: 0,
    st_date: "",
    end_date: "",
    cargo_id: 0,
  });

  useEffect(() => {
    document.title = "All Cargo | AA Enterprise";
    $(".print").hide();
    fetchTrains();
  }, []);
  useEffect(() => {
    setParams((prevValue) => {
      return {
        ...prevValue,
        _pageNo: pageNo,
      };
    });
  }, [pageNo]);

  useEffect(() => {
    if (Number(form.train_id)) {
      _getCargoByTrainDate(params);
    }
  }, [params]);

  useEffect(() => {
    setTableData({
      columns: columns,
      data: rows,
    });
  }, [rows]);

  async function _getCargoByTrainDate() {
    if (validate()) {
      let obj = {
        train_id: Number(form.train_id),
        st_date: form.st_date,
        end_date: form.end_date,
      };
      let res = await getCargoByTrainDate(obj, params);
      if (res.status === 200) {
        setTotalRows(res.data.count);
        setRows(res.data.reportData);
      } else if (res.status >= 400) {
        toast.error(res.message.message);
      }
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 500, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  function validate() {
    //_getCargoByTrainDate
    if (Number(form.train_id) && form.st_date !== "" && form.end_date !== "") {
      return true;
    }
    toast.warn("Some fields are empty");
    return false;
  }

  function handleChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value.trim(),
      };
    });
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Cargo</h2>
          <p class="text-light">List of all cargo</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Cargo by Date</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4">
                  <label class="form-label">
                    Select Train <span style={{ color: "red" }}>*</span>
                  </label>
                  <select
                    id="trains_select"
                    name="train_id"
                    onChange={handleChange}
                    class="form-select form-control wide"
                  >
                    <option value="">Choose...</option>
                    {trains.map((train) => {
                      return (
                        <option key={train.id} value={train.id}>
                          {train.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">
                        From <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        id="st_date"
                        type="date"
                        name="st_date"
                        class="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">
                        To <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        id="end_date"
                        type="date"
                        name="end_date"
                        class="form-control"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-primary"
                id="cargo_by_train-date"
                onClick={() => {
                  _getCargoByTrainDate();
                }}
              >
                Sumbit
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Cargo by No</h4>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Cargo No <span style={{ color: 'red' }}>*</span></label>
                      <input type="number" name='cargo_id' class="form-control" placeholder="cargo no" onChange={handleChange} required />
                    </div>
                  </div>
                </div>

              </div>
              <button className="btn btn-primary" id="cargo_by_id" onClick={() => { _getCargoById() }}>Sumbit</button>
            </div>
          </div>
        </div>
      </div> */}

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Booked Cargo List</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <DataTableExtensions {...tableData} exportHeaders={true}>
                  <DataTable
                    data={rows}
                    columns={columns}
                    defaultSortField={"id"}
                    pagination={true}
                    paginationServer={true}
                    highlightOnHover
                    paginationTotalRows={totalRows}
                    paginationPerPage={15}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPageNo(page)}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCargo;
