import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { allUsers } from '../../store/actions/user.actions'
import { addTransaction } from '../../store/actions/transaction.actions';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AddTransaction() {
    const [users, setUsers] = useState([]);
    const [between, setBetween] = useState({
        given_by: 0,
        amount: 0
    });
    let params = {
        _count: 500,
        _pageNo: 1
    };



    useEffect(() => {
        document.title = "Add Transactions | AA Enterprise";
        _allUsers();
    }, []);

    async function _allUsers() {
        let response = await allUsers(params);
        if (response.status === 200) {
            setUsers(response.data.users)
        } else if (response.status >= 400) {
            toast.error("Failed to fetch Users");
        }
    }

    async function formSubmit(e) {
        e.preventDefault();

        if (validate()) {
            let response = await addTransaction(between);
            if (response.status === 200) {
                toast.success("Transaction Successful")
                setBetween({
                    given_by: 0,
                    amount: 0
                })
                document.getElementById('given_by').options[0].selected = 'selected';
            } else if (response.status >= 400) {
                toast.error("Transaction Error");
            }
        } else {
            toast.warn("Fill all fields");
        }
    }

    function validate() {
        if (between.amount && between.given_by) {
            return true;
        }
        return false;
    }

    function onChangeHandler(e) {
        const { name, value } = e.target;
        setBetween(prevValue => {
            return {
                ...prevValue,
                [name]: Number(value)
            }
        })
    }

    return (
        <div>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Transactions</h2>
                    <p class="text-light">Add a new transaction</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add Transaction</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">
                                <form type="submit" name="add_transaction_form" onSubmit={(e) => { formSubmit(e) }}>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Amount</label>
                                            <input type="text" name='amount' value={between.amount} onChange={onChangeHandler} class="form-control" placeholder="transaction amount (PKR)" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Given By</label>
                                            <select id="given_by" name="given_by" onChange={onChangeHandler} class="form-select form-control wide">
                                                <option selected>Choose...</option>
                                                {users.map((user) => {
                                                    return (
                                                        <option key={user.key} value={user.id} >{user.name} - {user.role}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <button type="submit" name='add_transaction_btn' class="btn btn-primary">Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddTransaction
