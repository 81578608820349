import React, { useState, useEffect } from 'react'
import DataTable from "react-data-table-component";
import $ from 'jquery'

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { allTrains } from '../../store/actions/train.actions';
import { getTrainToursByDate, activateTour } from '../../store/actions/tour.actions'

toast.configure({
    autoClose: 4000,
    draggable: true
});

function TourStatus() {
    const [date, setDate] = useState('')
    const columns = [
        {
            name: "Name",
            selector: "name"
        },
        {
            name: "Departure Time",
            selector: "depart_time",
            sortable: true,
            cell: (d) =>
            (
                d.depart_time = (d.depart_time.replace("T", " ")).substr(0, 16)
            )
        },
        {
            name: "Status",
            selector: "tour_status",
            sortable: true,
            cell: (d) =>
            (
                d.tour_status === 'deactive' ? <span class="btn btn-warning">Deactive</span> : <span class="btn btn-success">Active</span>
            )
        },
        {
            name: 'Actions',
            selector: "actions",
            cell: (d) =>
            (
                <>
                    <button class="btn btn-success" type="button" onClick={() => { _activateTour(d.id) }} >Activate</button>
                </>
            )
        }
    ];
    const [trains, setTrains] = useState([]);
    const [rows, setRows] = useState([])
    const [trainId, setTrainId] = useState(0);
    const [tableData, setTableData] = useState({})

    useEffect(() => {
        document.title = "Tour Status | AA Enterprise";
        $('.print').hide()
        fetchTrains()
    }, [])
    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])
    useEffect(() => {
        if (date !== '' && trainId) {
            _getTrainToursByDate()
        }
    }, [trainId, date])

    async function fetchTrains() {
        let fetch_trains = await allTrains({ _count: 150, _pageNo: 1 });
        if (fetch_trains.status === 200) {
            setTrains(fetch_trains.data.trains)
        } else if (fetch_trains.status >= 400) {
            toast.error(fetch_trains.message.message)
        }
    }

    async function _getTrainToursByDate() {
        let response = await getTrainToursByDate(date, trainId)
        if (response.status === 200) {
            if (response.data === null) {
                toast.warn("No tours found on this date")
                setRows([])
            } else {
                setRows(response.data)
            }
        } else if (response.status >= 400) {
            toast.error("Error fetching tours")
        }
    }

    async function _activateTour(id) {
        let response = await activateTour(id);
        if (response.status === 200) {
            _getTrainToursByDate()
        } else if (response.status >= 400) {
            toast.error(response.message.message)
        }
    }

    function _setTrainId() {
        setTrainId(Number(document.getElementById("trains_select").value));
    }

    function selectDateSubmit(e) {
        e.preventDefault();
        setDate($('#select_date')[0].value.substr(0, 10));
        document.getElementById("display_date").innerHTML = $('#select_date')[0].value.substr(0, 10);
    }

    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Tours</h2>
                    <p class="text-light">List of all trains tours</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Tours</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">

                                <div class="mb-3 col-md-6">
                                    <div class="basic-form">
                                        <form type="submit" name="fetch_coaches" onSubmit={(e) => selectDateSubmit(e)}>
                                            <div class="row">
                                                <div class="mb-3 col-md-12">
                                                    <label class="form-label">Date</label>
                                                    <input id="select_date" type="date" name='depart_time' class="form-control" placeholder="Departure Time" required />
                                                </div>
                                            </div>
                                            <button type="submit" name='select_date_btn' class="btn btn-primary">Select</button>
                                            <div class='row'>
                                                <p>Selected Date: <i id="display_date" style={{ color: 'red' }}></i></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-6">
                                    <label class="form-label">Select Train</label>
                                    <select id="trains_select" name='train_id' onChange={() => { _setTrainId() }} class="form-select form-control wide">
                                        <option value=''>Choose...</option>
                                        {trains.map((train) => {
                                            return (
                                                <option key={train.key} value={train.id} data-name={train.name}>{train.name}</option>
                                            )
                                        })}
                                    </select>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Tour Status</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        defaultSortField={'name'}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TourStatus
