import axiosInstance from '../../../utils/axios';

class Customer {

    static addCustomer = (form) => {

        let apiPath = '/api/cust';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static allCustomers = (form) => {

        let apiPath = '/api/cust?_count=' + parseInt(form._count) + '&_pageNo=' + parseInt(form._pageNo) + '&name=' + form.name + '&cnic=' + form.cnic + '&gender=' + form.gender + '&id=' + form.id + '&phone=' + form.phone;
        return axiosInstance.get(apiPath).then(response => { return response })

    };

    static updateCustomer = (form, id) => {

        let apiPath = '/api/cust/' + parseInt(id);
        return axiosInstance.put(apiPath, {
            ...form
        })
            .then(response => { return response })

    };

    static getCustomerProfile = (getID) => {

        let apiPath = '/api/cust/' + parseInt(getID);
        return axiosInstance.get(apiPath).then(response => { return response })

    };

    static deleteCustomer = (id) => {

        let apiPath = '/api/cust/' + parseInt(id);
        return axiosInstance.delete(apiPath).then(response => { return response })

    };

    static getCustomerProfileByCnic = (cnic) => {

        let apiPath = '/api/cust/?cnic=' + cnic;
        return axiosInstance.get(apiPath).then(response => { return response })

    };

}
export default Customer;