import React, { useEffect, useState, useRef } from "react";
import { allTrains } from "../../store/actions/train.actions";
import { bulkUpdateFares } from "../../store/actions/fare.actions";
import { allCoaches } from "../../store/actions/coach.actions";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function BulkUpdateFare() {
  const [trainId, setTrainId] = useState(0);
  const [trains, setTrains] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [typeId, setTypeId] = useState(0);
  const typeref = useRef();
  const opref = useRef();
  const valueref = useRef();
  const [fetchParams, setfetchParams] = useState({
    _count: 150,
    _pageNo: 1,
  });

  useEffect(() => {
    document.title = "Bulk Update Fare Fare | AA Enterprise";
  }, []);

  useEffect(() => {
    fetchTrains();
    _allCoaches();
  }, [fetchParams]);

  async function _allCoaches() {
    let response = await allCoaches(fetchParams);
    if (response.status === 200) {
      if (response.data.types) {
        setCoaches(response.data.types);
      } else {
        toast.info("Please add coaches first");
      }
    } else if (response.status >= 400) {
      toast.error("Failed to get Coaches");
    }
  }

  function validate(obj) {
    if (
      obj.train_id &&
      obj.city1_id &&
      obj.city2_id &&
      obj.seat_fare &&
      obj.berth_fare &&
      obj.coach_type
    ) {
      return true;
    }
    return false;
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains(fetchParams);
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  //first function
  function selectTrain(e) {
    e.preventDefault();
    setTrainId(Number(document.getElementById("trains_select").value));
    setTypeId(Number(document.getElementById("coach_type").value));
    document.getElementById("display_train").innerHTML = $("#trains_select")
      .find("option:selected")
      .text();
  }

  async function submitForm(e) {
    e.preventDefault();
    let type = typeref.current.value;
    let op = opref.current.value;
    let number = Number(valueref.current.value);

    if (isNaN(number)) {
      alert("Invlaid Value to Update.");
      return;
    }

    if (trainId == 0 || typeId == 0) {
      toast.error("Please Select Train and Coach first");
      return;
    }

    let data = {
      train_id: trainId,
      type_id: typeId,
      type,
      op,
      number,
    };
    $('#preloader').delay(333).fadeIn('slow');
    let updateResp = await bulkUpdateFares(data);
    $('#preloader').delay(333).fadeOut('slow');
    if (updateResp.status === 200) {
      toast.success("Fares updated successfully");
      typeref.current.value = "";
      opref.current.value = "";
      valueref.current.value = "";
      setTrainId(0)
      setTypeId(0)
      window.location.reload();
    } else {
      toast.error(updateResp.message.message);
    }
    console.log("the data is ", data);
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Fares</h2>
          <p class="text-light">Add fare for train cities</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Update fares for Cities</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  type="submit"
                  name="fetch_trains"
                  onSubmit={(e) => submitForm(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option value={train.id} data-name={train.name}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Coach type</label>
                      <select
                        id="coach_type"
                        name="coach_type"
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {coaches.map((coach) => {
                          return <option value={coach.id}>{coach.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                  <button
                    type="button"
                    name="fetch_trains_btn"
                    class="btn btn-primary"
                    onClick={(e) => selectTrain(e)}
                  >
                    Select Train & Coach
                  </button>

                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <p>
                        Selected Train is:{" "}
                        <i id="display_train" style={{ color: "red" }}></i>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <label class="form-label">Select Type</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        class="form-select form-control wide"
                        ref={typeref}
                      >
                        <option value="">Choose...</option>

                        <option value="percentage">Percentage</option>
                        <option value="amount">Amount</option>
                      </select>
                    </div>

                    <div class="mb-3 col-md-4">
                      <label class="form-label">Select Operation</label>
                      <select
                        id="coach_type"
                        name="coach_type"
                        class="form-select form-control wide"
                        ref={opref}
                      >
                        <option value="">Choose...</option>
                        <option value="add">Add</option>
                        <option value="sub">Subtract</option>
                      </select>
                    </div>
                    <div class="mb-3 col-md-4">
                      <label class="form-label">Enter Value</label>
                      <input
                        class="form-select form-control wide"
                        ref={valueref}
                        type="number"
                        min="1"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <button
                      type="submit"
                      name="fetch_trains_btn"
                      class="btn btn-warning mt-5"
                      onClick={(e) => submitForm(e)}
                    >
                      Update Fares
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BulkUpdateFare;
