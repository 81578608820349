import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { updateTour } from '../../store/actions/tour.actions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function UpdateTour() {
    const history = useHistory()
    const [updateId, setUpdateId] = useState(0);
    const location = useLocation()
    const [tourForm, setTourForm] = useState({
        depart_time: "",
        arrival_time: "",
        booking_start_time: "",
        reserve_stop_time: ""
    });


    useEffect(() => {
        document.title = 'Update Tour | AA Enterprise';

        if (JSON.stringify(location.state) !== undefined) {
            setUpdateId(Number(location.state.id));
            setTourForm({
                depart_time: location.state.depart_time.replace(" ", "T"),
                arrival_time: location.state.arrival_time.substr(0, 16).replace(" ", "T"),
                booking_start_time: location.state.booking_start_time.replace(" ", "T"),
                reserve_stop_time: location.state.reserve_stop_time.replace(" ", "T")
            })
        }
    }, []);

    async function onSubmitForm(e) {
        e.preventDefault();
        if (validate()) {
            let response = await updateTour(tourForm, updateId);
            if (response.status === 200) {
                toast.success(response.message)
                history.push('/all-tours')
            } else if (response.status >= 400) {
                toast.error("Tour Failed")
            }
        } else {
            toast.warn("Please fill all fields")
        }
    }

    function validate() {
        if (updateId && tourForm.depart_time && tourForm.arrival_time && tourForm.booking_start_time && tourForm.reserve_stop_time) {
            return true;
        }
        return false
    }

    function onChangeHandler(event) {
        const { name, value } = event.target;
        setTourForm(prevValue => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }


    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Tour</h2>
                    <p class="text-light">Update a train tour</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Update Tour</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">
                                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Train Arrival Time</label>
                                            <input type="datetime-local" name='arrival_time' value={tourForm.arrival_time} onChange={onChangeHandler} class="form-control" placeholder="Arrival Time" required />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Train Departure Time</label>
                                            <input type="datetime-local" name='depart_time' value={tourForm.depart_time} onChange={onChangeHandler} class="form-control" placeholder="Departure Time" required />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Booking Start Time</label>
                                            <input type="datetime-local" name='booking_start_time' value={tourForm.booking_start_time} onChange={onChangeHandler} class="form-control" placeholder="Booking Start Time" required />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Booking Stop Time</label>
                                            <input type="datetime-local" name='reserve_stop_time' value={tourForm.reserve_stop_time} onChange={onChangeHandler} class="form-control" placeholder="Reserve Stop Time" required />
                                        </div>

                                    </div>
                                    <button type="submit" name='submit' class='btn btn-success'>Update</button>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateTour
