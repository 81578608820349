import axiosInstance from '../../../utils/axios';

class Tour {

    static getVacantSeats = (tc_id, st_city, end_city) => {

        let apiPath = '/api/tickets/vacant/' + tc_id + '/' + st_city + '/' + end_city;
        return axiosInstance.get(apiPath);

    };

    static generateTicket = (form) => {

        let apiPath = '/api/tickets/';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static getAllTickets = (params) => {

        let apiPath = '/api/tickets?_count=' + Number(params._count) + '&_pageNo=' + Number(params._pageNo)
        return axiosInstance.get(apiPath);

    };

    static cancelTicket = (id, amount) => {

        let apiPath = `/api/tickets/cancel/${id}/${amount}`
        return axiosInstance.post(apiPath);

    };

    static getTicketById = (id) => {

        let apiPath = '/api/tickets/getOne/' + id;
        return axiosInstance.get(apiPath);

    };

    static getTicketByTourId = (tour_id) => {

        let apiPath = `/api/tickets/${tour_id}?_count=3000&_pageNo=1`;
        return axiosInstance.get(apiPath);

    };

    static bookReservedTicket = (ticket_id) => {

        let apiPath = '/api/tickets/' + ticket_id;
        return axiosInstance.put(apiPath, {
            status: 'booked'
        });

    };

    static getTicketDetailsById = (id) => {

        let apiPath = '/api/tickets/getOne/' + id;
        return axiosInstance.get(apiPath, {
            status: 'booked'
        });

    };

    static printOnlineTicket = (data) => {

        let apiPath = '/api/tickets/online';
        return axiosInstance.post(apiPath, {
            ...data
        });

    };

    static checkDiscountByTour = (id) => {

        let apiPath = '/api/tickets/discount/' + id;
        return axiosInstance.get(apiPath);

    };

}
export default Tour;
