import React, { useState, useEffect } from "react";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { allUsers } from "../../store/actions/user.actions";
import { allCities } from "../../store/actions/city.actions";
import { discountedTicketReport } from "../../store/actions/report.actions";
import { allTrains } from "../../store/actions/train.actions";

function DiscountedTicketReport() {
  const [admins, setAdmins] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);
  const [trains, setTrains] = useState([]);
  const [form, setForm] = useState({
    st_date: "",
    end_date: "",
    creator: 0,
    st_city: 0,
    end_city: 0,
    type: "",
    train_id: 0,
  });

  useEffect(() => {
    document.title = "Reports | AA Enterprise";
    $(".print").hide();
    _allUsers();
    _allCities();
    fetchTrains();
  }, []);

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function _allUsers() {
    let response = await allUsers({ _count: 500, _pageNo: 1 });
    if (response.status === 200) {
      setAdmins(response.data.users);
    } else if (response.status >= 400) {
      toast.error("Failed to fetch Admins");
    }
  }

  async function _allCities() {
    let response = await allCities({ _count: 500, _pageNo: 1 });
    if (response.status === 200) {
      setStartingCities(response.data.cities);
      setEndingCities(response.data.cities);
    } else if (response.status >= 400) {
      toast.error("Failed to get Cities");
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    if (validate()) {
      $('#preloader').delay(333).fadeIn('slow');
      let response = await discountedTicketReport(form);
      $('#preloader').delay(333).fadeOut('slow');
      if (response.status === 200) {
        downloadPDF(response.data);
      } else if (response.status >= 400) {
        if (response.status === 400) {
          toast.error("No records forund");
        } else {
          toast.error("Failed to Download");
        }
      }
    }
  }

  function downloadPDF(response) {
    const doc = new jsPDF();

    var startX = 60;
    var finalY = 10;
    doc.text(startX, finalY, "Discounted / Cancelled Report");
    finalY += 10;
    let namesPrint = {
      st_date: "Starting Date",
      end_date: "Ending Date",
      creator: "Creator",
      st_city: "Starting City",
      end_city: "Ending City",
      type: "Type",
      train_id: "Train Id",
    };

    var i = 1;
    for (const property in form) {
      if (form[property]) {
        if (i % 2 !== 0) {
          //Left
          startX = 25;

          if (property === "st_city") {
            doc.text(
              startX,
              finalY,
              namesPrint[property] + ": " + response[1][7]
            );
          } else if (property === "end_city") {
            doc.text(
              startX,
              finalY,
              namesPrint[property] + ": " + response[1][8]
            );
          } else {
            doc.text(
              startX,
              finalY,
              namesPrint[property] + ": " + form[property]
            );
          }
        } else {
          //Right
          startX = 115;
          if (property === "st_city") {
            doc.text(
              startX,
              finalY,
              namesPrint[property] + ": " + response[1][7]
            );
          } else if (property === "end_city") {
            doc.text(
              startX,
              finalY,
              namesPrint[property] + ": " + response[1][8]
            );
          } else {
            doc.text(
              startX,
              finalY,
              namesPrint[property] + ": " + form[property]
            );
          }

          finalY += 12;
        }
        i++;
      } else {
        continue;
      }
    }

    finalY += 10;

    let head = response[0];
    doc.autoTable({
      startY: finalY,
      head: [head],
      body: tableRows(response),
      theme: "grid",
    });
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize("10");
    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        210 - 10,
        297 - 10,
        null,
        null,
        "right"
      );
    }
    doc.save("discounted_cancelled_report.pdf");

    $("#type_select").val("");
    $("#creator").val("");
    setForm({
      st_date: "",
      end_date: "",
      creator: 0,
      st_city: 0,
      end_city: 0,
      type: "",
      train_id: 0,
    });
  }

  function tableRows(response) {
    var body = [];
    for (let i = 1; i < response.length; i++) {
      var array = [];
      for (let j = 0; j < response[i].length; j++) {
        array.push(response[i][j]);
      }
      body.push(array);
    }
    return body;
  }

  function validate() {
    if (
      form.st_city ||
      form.st_date ||
      form.end_city ||
      form.end_date ||
      form.creator ||
      form.train_id
    ) {
      return true;
    }
    toast.warn("Select a feild");
    return false;
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Reports</h2>
          <p class="text-light">Filter report of discounted tickets</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option
                              key={train.key}
                              value={train.id}
                              data-name={train.name}
                            >
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-6">
                      <label class="form-label">Creator</label>
                      <select
                        id="creator"
                        name="creator"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {admins.map((admins) => {
                          return (
                            <option key={admins.id} value={admins.id}>
                              {admins.name} / {admins.role}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Starting Date</label>
                          <input
                            id="st_date"
                            type="date"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="st_date"
                            class="form-control"
                            placeholder="starting date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Ending Date</label>
                          <input
                            id="end_date"
                            type="date"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="end_date"
                            class="form-control"
                            placeholder="ending date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city"
                              name="st_city"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city"
                              name="end_city"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Type</label>
                      <select
                        id="type_select"
                        name="type"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="discounted">
                          Discounted
                        </option>
                        <option key="2" value="cancelled">
                          Cancelled
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DiscountedTicketReport;
