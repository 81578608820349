import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { allCoaches } from "../../store/actions/coach.actions";
import { updateTourCoach } from "../../store/actions/tour.actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery'

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function UpdateTourCoach() {
  const location = useLocation();
  const history = useHistory();
  const [updateId, setUpdateId] = useState(0);
  const [coaches, setCoaches] = useState([]);
  const [updateForm, setUpdateForm] = useState({
    tour_id: 0,
    coach_type: 0,
    tt_id: 0,
    coach_number: 0,
    is_online: false,
  });

  useEffect(() => {
    document.title = "Update Tour | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setUpdateId(Number(location.state.id));
      console.log("state is" , location.state)
      setUpdateForm({
        tour_id: location.state.tour_id,
        coach_type: location.state.coach_type,
        coach_number: location.state.coach_number,
        is_online: location.state.is_online,
      });
      if(location.state.is_online){
        $('input[name="is_online"]').prop('checked', true);
      }
      // setUpdateForm(prevValue => {
      //     return {
      //         ...prevValue,
      //         coach_type: Number(location.state.tour_id),
      //     }
      // });
      var a = document.getElementById("caoch_select");
      a.options[0].text = location.state.coach_type_name;
      a.options[0].value = location.state.coach_type;
    }

    _allCoaches();
  }, []);

  async function _allCoaches() {
    let response = await allCoaches({ _count: 150, _pageNo: 1 });
    if (response.status === 200) {
      setCoaches(response.data.types);
    } else if (response.status >= 400) {
      toast.error("Coaches fetch failed");
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    if (validate()) {
      console.log(updateForm, updateId);
      let response = await updateTourCoach(updateForm, updateId);
      if (response.status === 200) {
        toast.success(response.message);
        history.push("/all-tour-coaches");
      } else if (response.status >= 400) {
        toast.error("Update Failed");
        alert(response.message.message);
      }
    } else {
      toast.warn("Please fill all fields");
    }
  }

  function validate() {
    if (updateId && updateForm.tour_id && updateForm.coach_type && updateForm.coach_number) {
      return true;
    }
    return false;
  }

  function onChangeHandler(e) {
    const { name, value } = e.target;
    setUpdateForm((prevValue) => {
      return {
        ...prevValue,
        [name]: "is_online" ? e.target.checked : Number(value),
      };
    });
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Tour Coach</h2>
          <p class="text-light">Update a train tour coach</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Update Tour Coach</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Coach</label>
                      <select
                        id="caoch_select"
                        name="coach_type"
                        value={updateForm.coach_type}
                        onChange={onChangeHandler}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {coaches.map((coach) => {
                          return (
                            <option key={coach.id} value={coach.id}>
                              {coach.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Coach Number</label>
                      <input
                        type="text"
                        name="coach_number"
                        value={updateForm.coach_number}
                        onChange={onChangeHandler}
                        class="form-control"
                        placeholder="coach number"
                        required
                      />
                    </div>
                    <div class="mb-3 col-md-6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="is_online"
                          onChange={onChangeHandler}
                        />
                        <label class="form-check-label">Allowed Online</label>
                      </div>
                    </div>
                  </div>
                  <button type="submit" name="submit" class="btn btn-success">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateTourCoach;
