import React, { useState, useEffect } from "react";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import { reportPassengerChart } from "../../store/actions/report.actions";
import { allCoaches } from "../../store/actions/coach.actions";
import { allUsers } from "../../store/actions/user.actions";
import {
  getTrainToursByDate,
  getTrainCoaches,
} from "../../store/actions/tour.actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ReportByStatus() {
  const [trains, setTrains] = useState([]);
  const [toursByDate, setToursByDate] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);
  const [date, setDate] = useState("");
  const [form, setForm] = useState({
    train_id: 0,
    st_city: 0,
    end_city: 0,
    tour_id: 0,
    coach_id: 0,
  });
  const [names, setNames] = useState({
    train_name: "",
    st_city: "",
    end_city: "",
    tour_id: "",
    coach_id: "",
  });

  //fetch functions
  async function _allCoaches() {
    let response = await allCoaches({ _count: 500, _pageNo: 1 });
    if (response.status === 200 && response.data.length !== 0) {
      setCoaches(response.data.types);
    } else if (response.status >= 400) {
      toast.error("no coaches found");
      setCoaches([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(form.train_id);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }

  async function _getTrainEndingCities() {
    let response = await getTrainEndingCities(form.train_id, form.st_city);
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setEndingCities([]);
    }
  }
  async function _getTrainToursByDate() {
    let response = await getTrainToursByDate(date, Number(form.train_id));
    if (response.status === 200) {
      if (response.data) {
        document.getElementById("display_date").innerHTML = $(
          "#select_date"
        )[0].value.substr(0, 10);
        let activeTours = [...response.data];

        if (activeTours.length > 0) {
          setToursByDate(activeTours);
        } else {
          toast.warn("no active tours found");
        }
      } else {
        toast.warn("No tours were found on this date");
        setToursByDate([]);
      }
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 500, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function _getTourCoaches() {
    let response = await getTrainCoaches(form.tour_id, { _count: 30 });
    if (response.status === 200 && response.data.length !== 0) {
      setCoaches(response.data.tourCoaches);
    } else if (response.status >= 400) {
      toast.error("no coaches found");
      setCoaches([]);
    }
  }

  //chnage handlers

  function selectTrain() {
    const id = Number(document.getElementById("trains_select").value);
    setForm((prevValue) => {
      return {
        ...prevValue,
        train_id: id,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        train_name: $("#trains_select :selected").text(),
      };
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    if (name === "tour_id") {
      let time = $("#tours_select :selected").text().split("-")[1];
      setNames((prevValue) => {
        return {
          ...prevValue,
          [name]: time,
        };
      });
    } else {
      setNames((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    }
  }

  function handleCityChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: Number(value),
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        [name]: $("#" + name + " :selected").text(),
      };
    });
  }
  function CoachOnChange(e) {
    const { value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        coach_id: Number(value),
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        coach_id: $("#coach_select :selected").text(),
      };
    });
  }

  function selectDateSubmit(e) {
    e.preventDefault();
    setDate($("#select_date")[0].value.substr(0, 10));
    document.getElementById("display_date").innerHTML = $(
      "#select_date"
    )[0].value.substr(0, 10);
  }

  //Change Function Ends

  //Final Function
  async function onSubmitForm(e) {
    e.preventDefault();
    let dispObject = { ...form };
    dispObject.tour_id = Number(dispObject.tour_id);
    if (dispObject.train_id === 0 || dispObject.tour_id === 0) {
      toast.error("Train Id , Tour ID is required");
      return;
    }
    console.log("form is", form);
    if (form.train_id) {
      if (dispObject.coach_id === 0) delete dispObject.coach_id;
      if (dispObject.st_city === 0) delete dispObject.st_city;
      if (dispObject.end_city === 0) delete dispObject.end_city;
      $('#preloader').delay(333).fadeIn('slow');
      let response = await reportPassengerChart(dispObject);
      $('#preloader').delay(333).fadeOut('slow');
      if (response.status === 200) {
        if (response.data.length === 0) {
          toast.warn("No Records found");
        } else if (response.data.length > 0) {
          downloadPDF(response.data);
          setForm({
            train_id: 0,
            st_city: 0,
            end_city: 0,
            tour_id: 0,
            coach_id: 0,
          });
          setNames({
            train_name: "",
            st_city: "",
            end_city: "",
            tour_id: "",
            coach_id: "",
          });
          setDate("");
          window.location.reload();
        }
      } else if (response.status >= 400) {
        toast.error("Failed to Download");
      }
    } else {
      toast.info("Please select train");
    }
  }

  //hooks
  useEffect(() => {
    document.title = "Reports | AA Enterprise";
    $(".print").hide();
    fetchTrains();
  }, []);

  useEffect(() => {
    if (form.train_id) {
      _getTrainStartingCities();
    }
  }, [form.train_id]);
  useEffect(() => {
    if (form.st_city) {
      _getTrainEndingCities();
    }
  }, [form.st_city]);
  useEffect(() => {
    if (date !== "" && form.train_id) {
      _getTrainToursByDate();
    }
  }, [form.train_id, date]);

  useEffect(() => {
    if (form.tour_id !== 0) {
      _getTourCoaches();
    }
  }, [form.tour_id]);

  //create pdf function
  function downloadPDF(response) {
    const doc = new jsPDF("l");

    var startX = 110;
    var finalY = 10;
    doc.setFontSize("18");
    doc.text(startX, finalY, "Passenger Chart Report");
    doc.setFontSize("11");
    finalY += 15;
    let namesPrint = {
      train_name: "Train Name: ",
      st_city: "Starting City: ",
      end_city: "Ending City: ",
      tour_id: `Depart Time: ${date}-`,
      coach_id: "Coach Type: ",
    };

    var i = 1;
    for (const property in names) {
      if (names[property] !== "") {
        if (i % 2 !== 0) {
          //Left
          startX = 55;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + names[property]
          );
        } else {
          //Right
          startX = 145;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + names[property]
          );
          finalY += 12;
        }
        i++;
      } else {
        continue;
      }
    }

    finalY += 10;
    for (let coach of response) {
      doc.text(125, finalY, coach.name);
      finalY += 10;
      doc.autoTable({
        startY: finalY,
        head: [coach.headers], //tableHead(response[0]),
        body: coach.data, //tableRows(response),
        theme: "grid",
      });
      finalY = doc.lastAutoTable.finalY + 20;
    }

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize("10");
    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        210 - 10,
        297 - 10,
        null,
        null,
        "right"
      );
    }

    doc.save("cityWiseUserReport.pdf");
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Reports</h2>
          <p class="text-light">Filter report of your choice</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Passenger Chart Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <div class="row">
                  <div class="col-md-4">
                    <label class="form-label">Select Train</label>
                    <select
                      id="trains_select"
                      name="train_id"
                      onChange={selectTrain}
                      class="form-select form-control wide"
                    >
                      <option value="">Choose...</option>
                      {trains.map((train) => {
                        return (
                          <option key={train.id} value={train.id}>
                            {train.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div class="mb-3 col-md-4">
                    <div class="basic-form">
                      <form
                        type="submit"
                        name="fetch_coaches"
                        onSubmit={(e) => selectDateSubmit(e)}
                      >
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">
                              Select Departure Date
                            </label>
                            <input
                              id="select_date"
                              type="date"
                              name="depart_time"
                              class="form-control"
                              placeholder="Departure Time"
                              required
                            />
                          </div>
                        </div>
                        <button
                          type="submit"
                          name="fetch_trains_btn"
                          class="btn btn-primary"
                        >
                          Select
                        </button>
                        <div class="row">
                          <p>
                            Selected Date:{" "}
                            <i id="display_date" style={{ color: "red" }}></i>
                          </p>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div class="mb-3 col-md-4">
                    <div class="basic-form">
                      <div class="row">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Select Tour</label>

                          <select
                            id="tours_select"
                            name="tour_id"
                            onChange={handleChange}
                            class="form-select form-control wide"
                          >
                            <option value="">Choose...</option>
                            {toursByDate.map((tour) => {
                              return (
                                <option key={tour.id} value={tour.id}>
                                  {tour.name} - {tour.depart_time.substr(11, 5)}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Coach</label>
                            <select
                              id="coach_select"
                              name="coach_id"
                              onChange={CoachOnChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {coaches.map((coach) => {
                                return (
                                  <option
                                    key={coach.id}
                                    value={coach.id}
                                    name={coach.coach_number}
                                  >
                                    {coach.coach_number}
                                    {" - "}
                                    {coach.coach_types.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city"
                              name="st_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city"
                              name="end_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportByStatus;
