import Coach from '../services/coach/coach';

export const addCoach = (form) => {

    return Coach.addCoach(form).then(response => {
        return {status: response.status,data:response.data, message: "Coach Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const updateCoach = (form, id) => {

    return Coach.updateCoach(form, id).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const allCoaches = (form) => {

    return Coach.allCoaches(form).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteCoach = (form) => {

    return Coach.deleteCoach(form).then(response => {
        return {status: response.status,data:response.data, message: "Coach Deleted"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getCoachProfile = (id) => {

    return Coach.getCoachProfile(id).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};