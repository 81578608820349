import axiosInstance from '../../../utils/axios';

class User {
   
    static addUser = (form) => {
    
        let apiPath = '/api/users';
        return axiosInstance.post(apiPath , {
            ...form
        });
    
    };

    static allUsers = (form) => {

        let apiPath = '/api/users?_count='+parseInt(form._count)+'&_pageNo='+parseInt(form._pageNo);
        return axiosInstance.get(apiPath).then(response => {return response})

    };

    static updateUser = (form, id) => {

        let apiPath = '/api/users/'+parseInt(id);
        return axiosInstance.put(apiPath , {
            ...form
        })
        .then(response => {return response})

    };

    static getUserProfile = (getID) => {

        let apiPath = '/api/users/'+parseInt(getID);
        return axiosInstance.get(apiPath).then(response => {return response})

    };

    static forgotUserPassword = (email) => {
        
        let apiPath = '/api/users/forgot';
        return axiosInstance.post(apiPath , email);
    
    };
    
    static resetUserPassword = (form) => {
        
        let apiPath = '/api/users/reset';
        return axiosInstance.post(apiPath , {
            ...form
        });
    
    };

    static getMyBookingToday = (date) => {
        
        let apiPath = '/api/users/mybooking/'+date;
        return axiosInstance.get(apiPath);
    
    };
    
    static changePassword = (form) => {
        
        let apiPath = '/api/auth/change-password';
        return axiosInstance.put(apiPath , {
            ...form
        });
    
    };

    static loginUser = (form) => {

        let apiPath = '/api/auth/login';
        form.type = "online"
        return axiosInstance.post(apiPath , {
            ...form
        })
        .then(response => {return response})

    };

    static logout = () => {

        let apiPath = '/api/auth/logout';
        return axiosInstance.get(apiPath)
        .then(response => {return response})

    };

    
}
export default User;