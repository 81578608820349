import User from '../services/user/user';


export const addUser = (form) => {

    return User.addUser(form).then(response => {
        return {status: response.status,data:response.data, message: "Request Success"};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const allUsers = (form) => {

    return User.allUsers(form).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const updateUser = (form, id) => {

    return User.updateUser(form, id).then(response => {
        return {status: response.status,data:response.data, message: "user Updated successfully"};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const getMyBookingAmountToday = (date) => {

    return User.getMyBookingToday(date).then(response => {
        return {status: response.status,data:response.data};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const getUserProfile = (getID) => {

    return User.getUserProfile(getID).then(response => {
        return {status: response.status,data:response.data, message: "Request Success"};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const forgotUserPassword = (email) => {

    return User.forgotUserPassword(email).then(response => {
        return {status: response.status, data:response.data, message: "Check you Email to Reset Password"};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const resetUserPassword = (form) => {

    return User.resetUserPassword(form).then(response => {
        return {status: response.status, data:response.data, message: response.message};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const changePassword = (form) => {

    return User.changePassword(form).then(response => {
        return {status: response.status, data:response.data, message: response.message};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};

export const loginUser = (form) => {

    return User.loginUser(form).then(response => {
        return {status: response.status, data:response, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const logout = () => {

    return User.logout().then(response => {
        return {status: response.status, data:response, message: "success"};
    }).catch(e => { 
        return {status: e.response.status, message: e.response.data};
    })

};


