import React, { useState, useEffect } from "react";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { allTrains, getTrainCities } from "../../store/actions/train.actions";
import { reportcityWiseUser } from "../../store/actions/report.actions";
import { allUsers } from "../../store/actions/user.actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ReportByStatus() {
  const [trains, setTrains] = useState([]);
  const [trainCities, setTrainCities] = useState([]);
  const [form, setForm] = useState({
    train_id: 0,
    st_date: "",
    end_date: "",
    city: 0,
    role: "",
  });
  const [names, setNames] = useState({
    train_name: "",
    st_date: "",
    end_date: "",
    role: "",
    city: "",
  });

  useEffect(() => {
    document.title = "Reports | AA Enterprise";
    $(".print").hide();
    fetchTrains();
  }, []);

  useEffect(() => {
    if (form.train_id) {
      _getTrainCities();
    }
  }, [form.train_id]);

  async function _getTrainCities() {
    let response = await getTrainCities({
      trainId: form.train_id,
      _count: 200,
      _pageNo: 1,
    });
    if (response.status === 200 && response.data.length !== 0) {
      setTrainCities(response.data.trainCities);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setTrainCities([]);
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 500, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    if (form.train_id === 0 || form.st_date === "" || form.end_date === "") {
      toast.error("Train Id , St_Date and End_Date is required");
      return;
    }
    if (form.train_id) {
      let dispObject = { ...form };
      if (dispObject.city === 0) delete dispObject.city;
      if (dispObject.role === "") delete dispObject.role;
      $('#preloader').delay(333).fadeIn('slow');
      let response = await reportcityWiseUser(dispObject);
      $('#preloader').delay(333).fadeOut('slow');
      if (response.status === 200) {
        if (response.data.length === 1) {
          toast.warn("No Records found");
        } else if (response.data.bodyArray.length > 1) {
          downloadPDF(response.data);
        }
      } else if (response.status >= 400) {
        toast.error("Failed to Download");
      }
    } else {
      toast.info("Please select train");
    }
  }

  function downloadPDF(response) {
    const doc = new jsPDF();

    var startX = 70;
    var finalY = 10;
    doc.setFontSize("18");
    doc.text(startX, finalY, "City Wise User Report");
    doc.setFontSize("11");
    finalY += 15;
    let namesPrint = {
      city: "City",
      train_name: "Train Name",
      st_date: "Starting Date",
      end_date: "Ending Date",
      role: "Role",
    };

    var i = 1;
    for (const property in names) {
      if (names[property] !== "") {
        if (i % 2 !== 0) {
          //Left
          startX = 25;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + names[property]
          );
        } else {
          //Right
          startX = 115;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + names[property]
          );
          finalY += 12;
        }
        i++;
      } else {
        continue;
      }
    }

    finalY += 10;

    doc.autoTable({
      startY: finalY,
      head: [response.headers], //tableHead(response[0]),
      body: response.bodyArray, //tableRows(response),
      theme: "grid",
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize("10");
    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        210 - 10,
        297 - 10,
        null,
        null,
        "right"
      );
    }
    
    doc.save("cityWiseUserReport.pdf");
  }

  function selectTrain() {
    const id = Number(document.getElementById("trains_select").value);
    setForm((prevValue) => {
      return {
        ...prevValue,
        train_id: id,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        train_name: $("#trains_select :selected").text(),
      };
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function handleCityChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: Number(value),
      };
    });
    setNames((prevValue) => {
      return {
        ...prevValue,
        [name]: $("#" + name + " :selected").text(),
      };
    });
  }
  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Reports</h2>
          <p class="text-light">Filter report of your choice</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={selectTrain}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option key={train.id} value={train.id}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">City</label>
                            <select
                              id="city"
                              name="city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {trainCities.map((city) => {
                                return (
                                  <option
                                    key={city.city_id}
                                    value={city.city_id}
                                  >
                                    {city.cities.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Starting Date</label>
                          <input
                            id="st_date"
                            type="date"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="st_date"
                            class="form-control"
                            placeholder="starting date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Ending Date</label>
                          <input
                            id="end_date"
                            type="date"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            name="end_date"
                            class="form-control"
                            placeholder="ending date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Designation</label>
                      <select
                        id="status_select"
                        name="role"
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        <option value="admin">Admin</option>
                        <option value="station_manager">Station Manager</option>
                        <option value="booking_agent">Booking Agent</option>
                        <option value="tt">TT</option>
                      </select>
                    </div>

                    <div class="row">
                      <div class="col-3">
                        <button
                          type="submit"
                          name="submit"
                          class="btn btn-primary"
                        >
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportByStatus;
