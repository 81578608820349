import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isAuthenticUser } from '../utils/validateUser';
import jsCookie from 'js-cookie'

import React from "react";

function checkPermissions(path) {

  // console.log(Array.isArray(path));
  // path.includes('/index') || //Removed this from below if to check

  if (path.includes('/forgot-password') || path.includes('/login')) {
    return true;
  }

  let permissions = JSON.parse(localStorage.getItem('permissions'))

  if (permissions == null)
    window.location.href = '/login';
  else
    if (Array.isArray(path)) {
      for (var i = 0; i < path.length; i++) {
        if (permissions.includes(path[i].substr(1, path[i].length - 1))) {
          return true;
        }
      }
    } else {
      if (permissions.includes(path.substr(1, path.length - 1))) {
        return true;
      }
    }
  return false

}

function PrivateRoute({ component: Component, path, ...rest }) {
  let authentic = isAuthenticUser();
  let checkPermission = checkPermissions(path)
  console.log(path);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (authentic) {
          if (checkPermission) {
            return <Component {...rest} {...props} />;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/index',
                }}
              />
            );
          }

        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
              }}
            />
          );
        }
      }}
    />
  )
}

export default PrivateRoute;