import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function Economy2() {
  const layoutSeats = [
    "4S",
    "3S",
    "2S",
    "1B",
    "81S",
    "8S",
    "7S",
    "6S",
    "5B",
    "82S",
    "12S",
    "11S",
    "10S",
    "9B",
    "83S",
    "16S",
    "15S",
    "14S",
    "13B",
    "84S",
    "20S",
    "19S",
    "18S",
    "17B",
    "85S",
    "24S",
    "23S",
    "22S",
    "21B",
    "86S",
    "28S",
    "27S",
    "26S",
    "25B",
    "87S",
    "32S",
    "31S",
    "30S",
    "29B",
    "88S",
    "36S",
    "35S",
    "34S",
    "33B",
    "89S",
    "40S",
    "39S",
    "38S",
    "37B",
    "90S",
    "44S",
    "43S",
    "42S",
    "41B",
    "91S",
    "48S",
    "47S",
    "46S",
    "45B",
    "92S",
    "52S",
    "51S",
    "50S",
    "49B",
    "93S",
    "56S",
    "55S",
    "54S",
    "53B",
    "94S",
    "60S",
    "59S",
    "58S",
    "57B",
    "95S",
    "64S",
    "63S",
    "62S",
    "61B",
    "96S",
    "68S",
    "67S",
    "66S",
    "65B",
    "97S",
    "72S",
    "71S",
    "70S",
    "69B",
    "98S",
    "76S",
    "75S",
    "74S",
    "73B",
    "80S",
    "79S",
    "78S",
    "77B",
    "99B",
    "100B",
    "101B",
    "102B",
    "103B",
    "104B",
    "105B",
    "106B",
    "107B",
    "108B",
    "109B",
    "110B",
    "111B",
    "112B",
    "113B",
    "114B",
    "115B",
    "116B",
    "117B",
    "118B"
  ];
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;
  const [seatArr, setSeatArr] = useState([]);

  const [seatStatus, setSeatStatus] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [children, setChildren] = useState(0);
  const [pastData, setPastData] = useState({
    train_id: 0,
    train_name: "",
    date: "",
    tour_id: 0,
    tour_name: "",
    coachTypeId: 0,
    coach_name: "",
  });
  const [vacantSeats, setVacantSeats] = useState({
    difference: [],
    stop_time: "",
  });

  //checkbox function
  function onBoxChange(value) {
    let arr = seatArr;

    if (arr.includes(value)) {
      arr = arr.filter((e) => e !== value);
    } else {
      arr.push(value);
    }

    setSeatArr([...arr]);
  }

  //initial Hook
  useEffect(() => {
    document.title = "Available Seats | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setPastData({
        train_id: location.state.train_id,
        train_name: location.state.train_name,
        date: location.state.date,
        tour_id: location.state.tourId,
        tour_name: location.state.tour_name,
        coachTypeId: location.state.coachTypeId,
        coach_name: location.state.coach_name,
        coach_number: location.state.coach_number,
        starting_city_id: location.state.starting_city_id,
        starting_city_name: location.state.starting_city_name,
        ending_city_id: location.state.ending_city_id,
        ending_city_name: location.state.ending_city_name,
        status: location.state.status,
        cnic: location.state.cnic,
      });
    } else {
      history.push("/add-ticket");
    }
  }, []);

  async function _getVacantSeats() {
    let response = await getVacantSeats(
      pastData.coachTypeId,
      pastData.starting_city_id,
      pastData.ending_city_id
    );
    if (response.status === 200) {
      setVacantSeats(response.data);
    } else if (response.status >= 400) {
      toast.error("Failed");
    }
  }

  function _setBookedSeats() {
    let arr2 = vacantSeats.difference;
    console.log("difference is" , arr2);
    let difference = layoutSeats.filter((x) => !arr2.includes(x));
    setCheckedArray(difference);
  }

  useEffect(() => {
    if (pastData.coachTypeId) {
      _getVacantSeats();
    }
  }, [pastData.coachTypeId]);

  useEffect(() => {
    if (pastData.status || pastData.status !== undefined) {
      setSeatStatus(pastData.status);
      $("#status option:first").text(location.state.status);
      $("#status option:first").val(location.state.status.toLowerCase());
    }
  }, [pastData.status]);

  //Hook to run after vacant seats are fecthed from server
  useEffect(() => {
    if (vacantSeats.stop_time !== "") {
      _setBookedSeats();
    }
  }, [vacantSeats.stop_time]);

  //Test Hook
  

  function handleSeatStatus(e) {
    const { value } = e.target;
    setSeatStatus(value);
  }

  function handleChildrenChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (value >= 0) {
      setChildren(Number(value));
    } else {
      toast.warn("Enter valid number");
    }
  }

  function submitSeat() {
    if ((children || seatArr.length) && seatStatus !== "") {
      history.push({
        pathname: "add-ticket-customer",
        state: {
          tc_id: pastData.coachTypeId,
          st_city: pastData.starting_city_id,
          end_city: pastData.ending_city_id,
          toBookSeats: seatArr,
          status: seatStatus,
          children: children,
          stateData,
        },
      });
    } else {
      toast.warn("Please select all values");
    }
  }

  

  return (
    <div style={{overflowX: 'auto'}}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4S") ? true : false}
                        onChange={() => {
                          onBoxChange("4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3S") ? true : false}
                        onChange={() => {
                          onBoxChange("3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2S") ? true : false}
                        onChange={() => {
                          onBoxChange("2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1B"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => {
                          onBoxChange("1B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("81S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="81S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("81S") ? true : false}
                        onChange={() => {
                          onBoxChange("81S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">81S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8S") ? true : false}
                        onChange={() => {
                          onBoxChange("8S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7S") ? true : false}
                        onChange={() => {
                          onBoxChange("7S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6S") ? true : false}
                        onChange={() => {
                          onBoxChange("6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5B") ? true : false}
                        onChange={() => {
                          onBoxChange("5B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("82S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="82S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("82S") ? true : false}
                        onChange={() => {
                          onBoxChange("82S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">82S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("12S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="12S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("12S") ? true : false}
                        onChange={() => {
                          onBoxChange("12S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">12S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("11S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="11S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("11S") ? true : false}
                        onChange={() => {
                          onBoxChange("11S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">11S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="10S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10S") ? true : false}
                        onChange={() => {
                          onBoxChange("10S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="9B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9B") ? true : false}
                        onChange={() => {
                          onBoxChange("9B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">9B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("83S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="83S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("83S") ? true : false}
                        onChange={() => {
                          onBoxChange("83S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">83S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("16S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("16S") ? true : false}
                        onChange={() => {
                          onBoxChange("16S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">16S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("15S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="15S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("15S") ? true : false}
                        onChange={() => {
                          onBoxChange("15S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">15S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("14S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="14S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("14S") ? true : false}
                        onChange={() => {
                          onBoxChange("14S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">14S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("13B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="13B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("13B") ? true : false}
                        onChange={() => {
                          onBoxChange("13B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">13B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("84S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="84S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("84S") ? true : false}
                        onChange={() => {
                          onBoxChange("84S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">84S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("20S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="20S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("20S") ? true : false}
                        onChange={() => {
                          onBoxChange("20S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">20S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("19S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="19S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("19S") ? true : false}
                        onChange={() => {
                          onBoxChange("19S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">19S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("18S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="18S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("18S") ? true : false}
                        onChange={() => {
                          onBoxChange("18S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">18S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("17B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="17B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("17B") ? true : false}
                        onChange={() => {
                          onBoxChange("17B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">17B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("85S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="85S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("85S") ? true : false}
                        onChange={() => {
                          onBoxChange("85S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">85S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("24S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="24S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("24S") ? true : false}
                        onChange={() => {
                          onBoxChange("24S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">24S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("23S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="23S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("23S") ? true : false}
                        onChange={() => {
                          onBoxChange("23S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">23S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("22S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="22S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("22S") ? true : false}
                        onChange={() => {
                          onBoxChange("22S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">22S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("21B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="21B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("21B") ? true : false}
                        onChange={() => {
                          onBoxChange("21B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">21B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("86S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="86S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("86S") ? true : false}
                        onChange={() => {
                          onBoxChange("86S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">86S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("28S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="28S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("28S") ? true : false}
                        onChange={() => {
                          onBoxChange("28S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">28S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("27S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="27S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("27S") ? true : false}
                        onChange={() => {
                          onBoxChange("27S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">27S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("26S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="26S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("26S") ? true : false}
                        onChange={() => {
                          onBoxChange("26S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">26S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("25B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="25B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("25B") ? true : false}
                        onChange={() => {
                          onBoxChange("25B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">25B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("87S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="87S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("87S") ? true : false}
                        onChange={() => {
                          onBoxChange("87S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">87S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("32S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="32S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("32S") ? true : false}
                        onChange={() => {
                          onBoxChange("32S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">32S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("31S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="31S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("31S") ? true : false}
                        onChange={() => {
                          onBoxChange("31S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">31S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("30S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="30S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("30S") ? true : false}
                        onChange={() => {
                          onBoxChange("30S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">30S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("29B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="29B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("29B") ? true : false}
                        onChange={() => {
                          onBoxChange("29B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">29B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("88S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="88S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("88S") ? true : false}
                        onChange={() => {
                          onBoxChange("88S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">88S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("36S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="36S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("36S") ? true : false}
                        onChange={() => {
                          onBoxChange("36S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">36S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("35S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="35S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("35S") ? true : false}
                        onChange={() => {
                          onBoxChange("35S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">35S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("34S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="34S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("34S") ? true : false}
                        onChange={() => {
                          onBoxChange("34S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">34S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("33B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="33S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("33B") ? true : false}
                        onChange={() => {
                          onBoxChange("33B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">33B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("89S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="89S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("89S") ? true : false}
                        onChange={() => {
                          onBoxChange("89S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">89S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("40S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="40S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("40S") ? true : false}
                        onChange={() => {
                          onBoxChange("40S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">40S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("39S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("39S") ? true : false}
                        onChange={() => {
                          onBoxChange("39S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">39S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("38S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="42S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("38S") ? true : false}
                        onChange={() => {
                          onBoxChange("38S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">38S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("37B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("37B") ? true : false}
                        onChange={() => {
                          onBoxChange("37B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">37B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("90S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="90S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("90S") ? true : false}
                        onChange={() => {
                          onBoxChange("90S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">90S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("44S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="44S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("44S") ? true : false}
                        onChange={() => {
                          onBoxChange("44S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">44S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("43S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="43S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("43S") ? true : false}
                        onChange={() => {
                          onBoxChange("43S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">43S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("42S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="42S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("42S") ? true : false}
                        onChange={() => {
                          onBoxChange("42S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">42S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("41B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("41B") ? true : false}
                        onChange={() => {
                          onBoxChange("41B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">41B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("91S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="91S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("91S") ? true : false}
                        onChange={() => {
                          onBoxChange("91S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">91S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("48S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="48S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("48S") ? true : false}
                        onChange={() => {
                          onBoxChange("48S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">48S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("47S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="47S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("47S") ? true : false}
                        onChange={() => {
                          onBoxChange("47S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">47S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("46S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="46S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("46S") ? true : false}
                        onChange={() => {
                          onBoxChange("46S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">46S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("45B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="45B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("45B") ? true : false}
                        onChange={() => {
                          onBoxChange("45B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">45B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("92S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="92S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("92S") ? true : false}
                        onChange={() => {
                          onBoxChange("92S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">92S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("52S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="52S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("52S") ? true : false}
                        onChange={() => {
                          onBoxChange("52S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">52S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("51S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="51S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("51S") ? true : false}
                        onChange={() => {
                          onBoxChange("51S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">51S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("50S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="50S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("50S") ? true : false}
                        onChange={() => {
                          onBoxChange("50S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">50S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("49B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="49B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("49B") ? true : false}
                        onChange={() => {
                          onBoxChange("49B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">49B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("93S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="93S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("93S") ? true : false}
                        onChange={() => {
                          onBoxChange("93S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">93S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("56S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="56S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("56S") ? true : false}
                        onChange={() => {
                          onBoxChange("56S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">56S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("55S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="55S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("55S") ? true : false}
                        onChange={() => {
                          onBoxChange("55S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">55S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("54S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="54S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("54S") ? true : false}
                        onChange={() => {
                          onBoxChange("54S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">54S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("53B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="53B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("53B") ? true : false}
                        onChange={() => {
                          onBoxChange("53B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">53B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("94S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="94S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("94S") ? true : false}
                        onChange={() => {
                          onBoxChange("94S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">94S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("60S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("60S") ? true : false}
                        onChange={() => {
                          onBoxChange("60S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">60S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("59S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="59S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("59S") ? true : false}
                        onChange={() => {
                          onBoxChange("59S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">59S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("58S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="58S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("58S") ? true : false}
                        onChange={() => {
                          onBoxChange("58S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">58S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("57B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="57B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("57B") ? true : false}
                        onChange={() => {
                          onBoxChange("57B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">57B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("95S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="95S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("95S") ? true : false}
                        onChange={() => {
                          onBoxChange("95S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">95S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("64S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="64S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("64S") ? true : false}
                        onChange={() => {
                          onBoxChange("64S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">64S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("63S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="63S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("63S") ? true : false}
                        onChange={() => {
                          onBoxChange("63S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">63S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("62S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="62S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("62S") ? true : false}
                        onChange={() => {
                          onBoxChange("62S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">62S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("61B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="61B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("61B") ? true : false}
                        onChange={() => {
                          onBoxChange("61B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">61B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("96S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="96S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("96S") ? true : false}
                        onChange={() => {
                          onBoxChange("96S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">96S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("68S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="66S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("68S") ? true : false}
                        onChange={() => {
                          onBoxChange("68S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">68S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("67S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="65S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("67S") ? true : false}
                        onChange={() => {
                          onBoxChange("67S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">67S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("66S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="64S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("66S") ? true : false}
                        onChange={() => {
                          onBoxChange("66S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">66S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("65B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="63S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("65B") ? true : false}
                        onChange={() => {
                          onBoxChange("65B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">65B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("97S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="97S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("97S") ? true : false}
                        onChange={() => {
                          onBoxChange("97S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">97S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("72S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="72S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("72S") ? true : false}
                        onChange={() => {
                          onBoxChange("72S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">72S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("71S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="71S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("71S") ? true : false}
                        onChange={() => {
                          onBoxChange("71S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">71S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("70S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="70S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("70S") ? true : false}
                        onChange={() => {
                          onBoxChange("70S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">70S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("69B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="69B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("69B") ? true : false}
                        onChange={() => {
                          onBoxChange("69B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">69B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("98S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="98S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("98S") ? true : false}
                        onChange={() => {
                          onBoxChange("98S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">98S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("76S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="76S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("76S") ? true : false}
                        onChange={() => {
                          onBoxChange("76S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">76S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("75S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="75S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("75S") ? true : false}
                        onChange={() => {
                          onBoxChange("75S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">75S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("74S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="74S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("74S") ? true : false}
                        onChange={() => {
                          onBoxChange("74S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">74S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("73B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="73B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("73B") ? true : false}
                        onChange={() => {
                          onBoxChange("73B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">73B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("80S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="80S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("80S") ? true : false}
                        onChange={() => {
                          onBoxChange("80S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">80S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("79S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="79S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("79S") ? true : false}
                        onChange={() => {
                          onBoxChange("79S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">79S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("78S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="78S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("78S") ? true : false}
                        onChange={() => {
                          onBoxChange("78S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">78S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("77B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="77B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("77B") ? true : false}
                        onChange={() => {
                          onBoxChange("77B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">77B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}




      {/* berths started */}

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("99B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("99B") ? true : false}
                        onChange={() => {
                          onBoxChange("99B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">99B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("100B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("100B") ? true : false}
                        onChange={() => {
                          onBoxChange("100B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">100B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("101B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("101B") ? true : false}
                        onChange={() => {
                          onBoxChange("101B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">101B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("102B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("102B") ? true : false}
                        onChange={() => {
                          onBoxChange("102B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">102B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("103B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("103B") ? true : false}
                        onChange={() => {
                          onBoxChange("103B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">103B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("104B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("104B") ? true : false}
                        onChange={() => {
                          onBoxChange("104B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">104B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("105B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("105B") ? true : false}
                        onChange={() => {
                          onBoxChange("105B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">105B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("106B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("106B") ? true : false}
                        onChange={() => {
                          onBoxChange("106B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">106B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("107B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("107B") ? true : false}
                        onChange={() => {
                          onBoxChange("107B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">107B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("108B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("108B") ? true : false}
                        onChange={() => {
                          onBoxChange("108B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">108B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("109B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("109B") ? true : false}
                        onChange={() => {
                          onBoxChange("109B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">109B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("110B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("110B") ? true : false}
                        onChange={() => {
                          onBoxChange("110B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">110B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("111B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("111B") ? true : false}
                        onChange={() => {
                          onBoxChange("111B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">111B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("112B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("112B") ? true : false}
                        onChange={() => {
                          onBoxChange("112B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">112B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("113B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("113B") ? true : false}
                        onChange={() => {
                          onBoxChange("113B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">113B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("114B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("114B") ? true : false}
                        onChange={() => {
                          onBoxChange("114B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">114B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("115B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("115B") ? true : false}
                        onChange={() => {
                          onBoxChange("115B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">115B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("116B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("116B") ? true : false}
                        onChange={() => {
                          onBoxChange("116B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">116B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("117B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("117B") ? true : false}
                        onChange={() => {
                          onBoxChange("117B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">117B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("118B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("118B") ? true : false}
                        onChange={() => {
                          onBoxChange("118B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">118B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* berths ended */}

      <div class="card-body pt-0 row">
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Seat Type</h4>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <select
                id="status"
                value={seatStatus}
                onChange={handleSeatStatus}
                name="status"
                class="form-select form-control wide"
              >
                <option value="">Choose...</option>
                <option value="booked">Booked</option>
                <option value="reserved">Reserved</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Children Seats</h4>
          </div>
          <div class="col-6">
            <input
              type="number"
              value={children}
              class="form-control"
              placeholder="Number of childeren tickets"
              name="children"
              onChange={handleChildrenChange}
            />
          </div>
        </div>
        <button
          type="button"
          name="create_ticket_btn"
          class="btn btn-primary"
          onClick={() => {
            submitSeat();
          }}
        >
          Next
        </button>
      </div>

      {/* <div class="card-body pt-0 row">
             
                <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
            </div> */}
    </div>
  );
}

export default Economy2;
