import React from "react";
import { Link } from "react-router-dom";
import { isAuthenticUser } from "../../../utils/validateUser";

function Sidebar() {
  function _checkPermissions(checkAllowed) {
    let permissionsNumber = JSON.parse(
      localStorage.getItem("permissionsNumber")
    );
    if (permissionsNumber) {
      for (let i = 0; i < checkAllowed.length; i++) {
        if (permissionsNumber.includes(checkAllowed[i])) {
          return true;
        }
      }
    }
    return false;
  }
  return (
    <>
      {/* Sidebar Starts */}
      <div class="deznav">
        <div class="deznav-scroll">
          {isAuthenticUser() && (
            <ul class="metismenu" id="menu">
              {_checkPermissions([1, 2, 3, 4]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Admin</span>
                  </a>
                  <ul
                    aria-expanded="false"
                    class="mm-collapse"
                    style={{ height: "16px" }}
                  >
                    {_checkPermissions([1]) && (
                      <li>
                        <Link to="/add-admin">Create Admin</Link>
                      </li>
                    )}
                    {/* <li><a class="has-arrow" href="javascript:void()" aria-expanded="false">Error</a>
                                                <ul aria-expanded="false">
                                                    <li><a href="/admin/create">Create</a></li>
                                                    <li><a href="/admin/all-admins">All Admins</a></li>
                                                </ul>
                                            </li> */}
                    {_checkPermissions([2, 3]) && (
                      <li>
                        <Link to="/all-admins">All Admins</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}

              {_checkPermissions([17, 18, 19, 20]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">City</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([17]) && (
                      <li>
                        <Link to="/add-city">Add City</Link>
                      </li>
                    )}
                    {_checkPermissions([18, 19]) && (
                      <li>
                        <Link to="/all-cities">All Cities</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([13, 14, 15, 16]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Ticket</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([13]) && (
                      <>
                        <li>
                          <Link to="/add-ticket">New Ticket</Link>
                        </li>
                        <li>
                          <Link to="/update-reserve-to-book">
                            Book Reserved Tickets
                          </Link>
                        </li>
                        {_checkPermissions([50]) && (
                          <li>
                          <Link to="/printOnline">
                            Print Online Ticket
                          </Link>
                        </li>
                        )}
                        
                        <li>
                          <Link to="/check-fare">Check Fare</Link>
                        </li>
                        {/* <li><Link to='/print-ticket'>Print Ticket</Link></li> */}
                      </>
                    )}
                    {_checkPermissions([14]) && (
                      <li>
                        <Link to="/all-tickets">All Tickets</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([5, 6, 7, 8]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Transaction</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([5]) && (
                      <li>
                        <Link to="/add-transaction">Add Transaction</Link>
                      </li>
                    )}
                    {_checkPermissions([6, 7, 8]) && (
                      <li>
                        <Link to="/all-transactions">All Transactions</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([13, 14]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Customer</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([13]) && (
                      <li>
                        <Link to="/add-customer">Add Customer</Link>
                      </li>
                    )}
                    {_checkPermissions([14]) && (
                      <li>
                        <Link to="/all-customers">All Customers</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([30]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Preference</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([31]) && (
                      <>
                        <li>
                          <Link to="/all-preferences">All Preferences</Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
              {/* {_checkPermissions([17, 18]) &&
                                <li>
                                    <a class="has-arrow ai-icon" href="javascript:void()" aria-expanded="false">
                                        <i class="flaticon-022-copy"></i>
                                        <span class="nav-text">Coach</span>
                                    </a>
                                    <ul aria-expanded="false" class="mm-collapse">
                                        {_checkPermissions([17]) &&
                                            <li><Link to='/add-coach'>Add Coach</Link></li>
                                        }
                                        {_checkPermissions([18]) &&
                                            <li><Link to='all-coaches'>All Coaches</Link></li>
                                        }

                                    </ul>
                                </li>
                            } */}
              {_checkPermissions([17, 18, 19, 20]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Train</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([17]) && (
                      <>
                        <li>
                          <Link to="/add-train">Add Train</Link>
                        </li>
                        <li>
                          <Link to="/train-status">Train Status</Link>
                        </li>
                      </>
                    )}
                    {_checkPermissions([18]) && (
                      <li>
                        <Link to="/all-trains">All Trains</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([17, 18, 19, 20]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Train Cities</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([17]) && (
                      <li>
                        <Link to="/add-train-city">Add City</Link>
                      </li>
                    )}
                    {_checkPermissions([18, 19]) && (
                      <li>
                        <Link to="/all-train-cities">Train Cities</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([17, 18, 19, 20]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Fare</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([17]) && (
                      <li>
                        <Link to="/add-fare">Add Fare</Link>
                      </li>
                    )}
                    {_checkPermissions([18, 19]) && (
                      <li>
                        <Link to="/all-train-fares">All train Fares</Link>
                      </li>
                    )}
                    {_checkPermissions([42]) && (
                      <li>
                        <Link to="/bulk-fare">Bulk Update Fare</Link>
                      </li>
                    )}
                    {_checkPermissions([48]) && (
                      <li>
                        <Link to="/copy-fares">Copy Fares</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([9, 10, 11, 12, 41]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Tour</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([9]) && (
                      <>
                        <li>
                          <Link to="/add-tour">Add Tour</Link>
                        </li>
                        <li>
                          <Link to="/tour-status">Tour Status</Link>
                        </li>
                      </>
                    )}
                    {_checkPermissions([10, 11]) && (
                      <li>
                        <Link to="/all-tours">All Tours</Link>
                      </li>
                    )}
                    {_checkPermissions([9, 10, 11]) && (
                      <li>
                        <Link to="/add-tour-coach">Link Coach</Link>
                      </li>
                    )}
                    {_checkPermissions([10, 11]) && (
                      <li>
                        <Link to="/all-tour-coaches">All Linked Coaches</Link>
                      </li>
                    )}
                    {_checkPermissions([41]) && (
                      <li>
                        <Link to="/copy-tour">Copy Tours</Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([21, 22]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Reports</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([21]) && (
                      <>
                        <li>
                          <Link to="/reports">Reports</Link>
                        </li>
                        <li>
                          <Link to="/report-by-status">Report By Status</Link>
                        </li>
                        <li>
                          <Link to="/discounted-ticket-report">
                            Discounted Ticket Report
                          </Link>
                        </li>
                        <li>
                          <Link to="/cargo-report">Cargo Report</Link>
                        </li>
                        <li>
                          <Link to="/cargo-individual-report">
                            Cargo Individual Report
                          </Link>
                        </li>
                        <li>
                          <Link to="/other-reports">Total Reports</Link>
                        </li>
                        <li>
                          <Link to="/report-by-createdAt">
                            Created At Report
                          </Link>
                        </li>
                        <li>
                          <Link to="/report-cityWiseUser">
                            cityWise User Report
                          </Link>
                        </li>
                      </>
                    )}
                    {_checkPermissions([22]) && (
                      <>
                        <li>
                          <Link to="/report-passengerChart">
                            Passenger Chart
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}

              {_checkPermissions([25, 26, 27, 28]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Cargo</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([25]) && (
                      <>
                        <li>
                          <Link to="/add-cargo">Add Cargo</Link>
                        </li>
                      </>
                    )}
                    {_checkPermissions([26, 27, 28]) && (
                      <>
                        <li>
                          <Link to="/all-cargo">All Cargo</Link>
                        </li>
                        <li>
                          <Link to="/cargo-by-id">Cargo by ID</Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
              
              {_checkPermissions([47]) && (
                <li>
                  <a
                    class="has-arrow ai-icon"
                    href="javascript:void()"
                    aria-expanded="false"
                  >
                    <i class="flaticon-022-copy"></i>
                    <span class="nav-text">Layout</span>
                  </a>
                  <ul aria-expanded="false" class="mm-collapse">
                    {_checkPermissions([47]) && (
                      <>
                        <li>
                          <Link to="/acstd72view">Ac STD 72</Link>
                        </li>
                        <li>
                          <Link to="/acbusinessview">AcBusiness</Link>
                        </li>
                        <li>
                          <Link to="/economy1view">Economy 1</Link>
                        </li>
                        <li>
                          <Link to="/parlorcarview">ParloCar</Link>
                        </li>
                        <li>
                          <Link to="/economy2view">Economy2</Link>
                        </li>
                        <li>
                          <Link to="/acstd90view">AcStd90</Link>
                        </li>
                        <li>
                          <Link to="/openview">Open</Link>
                        </li>
                        <li>
                          <Link to="/acsleeperview">AcSleeper</Link>
                        </li>
                        <li>
                          <Link to="/economy3view">Economy 3</Link>
                        </li>
                        <li>
                          <Link to="/acstd96view">Ac STD 96</Link>
                        </li>
                        <li>
                          <Link to="/acstd72newview">Ac STD 74</Link>
                        </li>
                        <li>
                          <Link to="/brakeview">Brake</Link>
                        </li>
                        <li>
                          <Link to="/economyghouriview">Economy Ghouri</Link>
                        </li>
                        <li>
                          <Link to="/acstd98view">AC STD 98</Link>
                        </li>
                        <li>
                          <Link to="/acstd101view">AC STD 101</Link>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
              )}
              {_checkPermissions([49]) && (
                <li>
                <Link to="/offline-devices">Offline Devices</Link>
              </li>
              )}
            </ul>
          )}
          <div class="copyright">
            <p>
              <strong>AA Enterprise Ticketing Dashboard</strong> © 2021 All
              Rights Reserved
            </p>
            <p class="fs-12">Made by BrownDevelopers.com</p>
          </div>
        </div>
      </div>
      {/* Sidebar Ends */}
    </>
  );
}

export default Sidebar;
