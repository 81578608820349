import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import {
  getTrainFares,
  deleteTrainFare,
  deleteMultipleTrainFares
} from "../../store/actions/fare.actions";
import { allCoaches } from "../../store/actions/coach.actions";
import $ from "jquery";

import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AllTrainFares() {
  const history = useHistory();
  const [trainId, setTrainId] = useState(0);
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState({});
  const [trains, setTrains] = useState([]);
  const [selectedFares, setSelectedFares] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);

  const [form, setForm] = useState({
    st_city: 0,
    end_city: 0,
    coach_id: 0,
  });
  const [fetchParams, setfetchParams] = useState({
    _count: 15000,
    _pageNo: 1,
  });

  async function fetchTrains() {
    let fetch_trains = await allTrains(fetchParams);
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  //fetch functions
  async function _allCoaches() {
    let response = await allCoaches({ _count: 500, _pageNo: 1 });
    if (response.status === 200 && response.data.length !== 0) {
      setCoaches(response.data.types);
    } else if (response.status >= 400) {
      toast.error("no coaches found");
      setCoaches([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(trainId);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }

  async function _getTrainEndingCities() {
    let response = await getTrainEndingCities(trainId, form.st_city);
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setEndingCities([]);
    }
  }

  async function _getTrainFares() {
    let dispObject = { ...form };

    if (dispObject.coach_id === 0) {
      delete dispObject.coach_id;
    } else {
      dispObject.coach_type = dispObject.coach_id;
      delete dispObject.coach_id;
    }

    if (dispObject.st_city === 0) delete dispObject.st_city;
    if (dispObject.end_city === 0) delete dispObject.end_city;

    dispObject.train_id = trainId;

    let res = await getTrainFares(dispObject, fetchParams);
    if (res.status === 200 ) {
      setRows(res.data.trainFares);
      
    } else if (res.status >= 400 ) {
      toast.error("Failed to fetch Data");
    }
  }

  async function delTrainFare(id) {
    if (JSON.parse(localStorage.getItem("permissionsNumber").includes(18))) {
      if (window.confirm("Delete this fare?")) {
        let res = await deleteTrainFare(id);
        if (res.status === 200) {
          _getTrainFares();
        } else if (res.status >= 400) {
          toast.error("Error Deleting Fare");
        }
      }
    } else {
      toast.warn("You are not allowed to perform this action");
    }
  }

  function handleCityChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: Number(value),
      };
    });
  }

  function CoachOnChange(e) {
    const { value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        coach_id: Number(value),
      };
    });
  }

  function OnTrainChange(e) {
    const { value } = e.target;
    setTrainId(value);
  }

  function selectTrain(e) {
    e.preventDefault();
    setTrainId(document.getElementById("trains_select").value);
    document.getElementById("display_train").innerHTML = $("#trains_select")
      .find("option:selected")
      .text();
  }



  function checkFare(oldFares, id) {
    let fareId = id;
    let newArr = [...oldFares];
    if (!newArr.includes(fareId)) {
      newArr.push(fareId);
      setSelectedFares(newArr);
      $(`#fareId-${fareId}`).prop("checked", true);
    } else {
      let filterArr = newArr.filter((item) => item !== fareId);
      setSelectedFares(filterArr);
    }
  }


  function selectAll(){
    // alert("into func")
    let rowIds = new Array();
    for(let row of rows){
      $(`#fareId-${row.id}`).prop("checked", true);
      rowIds.push(row.id);
    }
    setSelectedFares(rowIds)

  }

  function updateBatchFare() {
    history.push({
      pathname: "/update-fare",
      state: {
        ids: selectedFares,
      },
    });
  }

  async function deleteMultipleFares(){
    if(window.confirm("Do you want to delete all selected fares?")){
      let bulkDelete = await deleteMultipleTrainFares(selectedFares);
    if(bulkDelete.status === 200){
      toast.success(bulkDelete.message);
      _getTrainFares();
    }else{
      toast.error("An error occured while deleting.")
    }
    }
   
      
  }

  async function onSubmitForm(e) {
    e.preventDefault();

    if (!trainId) {
      toast.error("Train Id  is required");
      return;
    }
    console.log("form is", form);

    _getTrainFares();
  }

  useEffect(() => {
    document.title = "All Train Fares | AA Enterprise";
    $(".print").hide();
    _allCoaches();
  }, []);

  useEffect(() => {
    fetchTrains();
  }, [fetchParams]);

  // useEffect(() => {
  //   if (trainId) {
  //     _getTrainFares();
  //   }
  // }, [trainId]);

  useEffect(() => {
    setTableData({
      columns: columns,
      data: rows,
    });
  }, [rows]);

  useEffect(() => {
    if (trainId) {
      _getTrainStartingCities();
    }
  }, [trainId]);

  useEffect(() => {
    if (form.st_city) {
      _getTrainEndingCities();
    }
  }, [form.st_city]);

  //   useEffect(() => {
  //     console.log("selected fares are", selectedFares);
  //   }, [selectedFares]);

  const columns = [
    {
      name: "Select",
      selector: "select",
      cell: (d) => (
        <>
          <input
            type="checkbox"
            id={`fareId-${d.id}`}
            onChange={() => {
              setSelectedFares((prevValue) => {
                checkFare(prevValue, d.id);
                return prevValue;
              });
            }}
            class="form-check-input"
          />
        </>
      ),
    },
    {
      name: "From",
      selector: "city1_name",
      sortable: true,
    },
    {
      name: "To",
      selector: "city2_name",
      sortable: true,
    },
    {
      name: "Coach",
      selector: "coach_type_name",
      sortable: true,
    },
    {
      name: "Seat Fare",
      selector: "seat_fare",
      sortable: true,
    },
    {
      name: "Berth Fare",
      selector: "berth_fare",
      sortable: true,
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (d) => (
        <>
          {/* <button
            className="btn btn-primary"
            onClick={() => {
              history.push({
                pathname: "/update-fare",
                state: {
                  id: d.id,
                  train_id: d.train_id,
                  city1_id: d.city1_id,
                  city2_id: d.city2_id,
                  seat_fare: d.seat_fare,
                  berth_fare: d.berth_fare,
                  coach_type: d.coach_type_id,
                },
              });
            }}
            type="button"
          >
            <i class="icon-edit"></i>
          </button> */}
          <button
            class="btn btn-danger"
            type="button"
            onClick={() => delTrainFare(d.id)}
          >
            <i class="icon-trash" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
  ];

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Fares</h2>
          <p class="text-light">Add fare for train cities</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Trains</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        class="form-select form-control wide"
                        onChange={OnTrainChange}
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option value={train.id} data-name={train.name}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Coach</label>
                            <select
                              id="coach_select"
                              name="coach_id"
                              onChange={CoachOnChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {coaches.map((coach) => {
                                return (
                                  <option
                                    key={coach.id}
                                    value={coach.id}
                                    name={coach.name}
                                  >
                                    {coach.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city"
                              name="st_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city"
                              name="end_city"
                              onChange={handleCityChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary"
                      >
                        Select
                      </button>
                    </div>
                  </div>
                </form>

                <div class="row">
                  <div class="mb-3 col-md-6">
                    <p>
                      Selected Train is:{" "}
                      <i id="display_train" style={{ color: "red" }}></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Fare Table</h4>
              <button
                className="btn btn-success btn-sm"
                onClick={() => {
                  selectAll();
                }}
              >
                Select All
              </button>
              <button
                className="btn btn-info btn-sm"
                onClick={() => {
                  updateBatchFare();
                }}
              >
                Update Fares
              </button>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  deleteMultipleFares();
                }}
              >
                Delete Selected
              </button>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <DataTableExtensions {...tableData} exportHeaders={true}>
                  <DataTable
                    data={rows}
                    columns={columns}
                    highlightOnHover
                    defaultSortField={"name"}
                    paginationPerPage={1500}
                    // pagination={false}
                    // paginationComponentOptions={{
                    //     noRowsPerPage: true
                    // }}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTrainFares;
