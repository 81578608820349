import React, { useState, useEffect } from "react";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import {
  getCustomerProfileByCnic,
  addCustomer,
} from "../../store/actions/customer.actions";
import { getTrainToursByDate } from "../../store/actions/tour.actions";
import { addCargo } from "../../store/actions/cargo.actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

function AddCargo() {
  const [counter, setCounter] = useState(0);
  const [tempNumber, setTempNumber] = useState([]);
  const [currentCounter, setCurrentCounter] = useState(1);
  const [validateForm, setValidateForm] = useState(false);
  const [collectItems, setCollectItems] = useState(false);
  const [itemsObj, setItemsObj] = useState();
  const [items, setItems] = useState({});
  const elem = (id, rate, weight, miscellaneous, amount) => (
    <div class="row" id={id}>
      <div class="col-xl-12">
        <div class="card shadow">
          <div class="card-header">
            <h4 class="card-title">{id}</h4>
            <button
              class="btn btn-outline-danger btn-sm remove_btn"
              type="button"
              onClick={() => {
                removeItem(id);
              }}
            >
              <i class="icon-trash"></i>
            </button>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="mb-3 col-md-2">
                <label class="form-label">
                  Item Name <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  name={id}
                  value={items[id]}
                  onChange={handleItemChange}
                  class=""
                  placeholder="name"
                />
              </div>

              <div class="mb-3 col-md-2">
                <label class="form-label">
                  Weight-KG <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name={weight}
                  min="0"
                  value={items[weight]}
                  onChange={handleItemChange}
                  class=""
                  placeholder="Weight"
                />
              </div>

              <div class="mb-3 col-md-2">
                <label class="form-label">
                  Rate <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="number"
                  name={rate}
                  min="0"
                  value={items[rate]}
                  onChange={handleItemChange}
                  class=""
                  placeholder="per kg"
                />
              </div>

              <div class="mb-3 col-md-3">
                <label class="form-label">Miscellaneous Charges</label>
                <input
                  type="number"
                  name={miscellaneous}
                  min="0"
                  value={items[miscellaneous]}
                  onChange={handleItemChange}
                  class=""
                  placeholder="extra charges"
                />
              </div>

              {/* <div class="mb-3 col-md-3">
                <label class="form-label">Amount: </label>
                <input type="number" min="0" value={items[amount]} class="" placeholder="item total amount" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  const [itemsElem, setItemsElem] = useState([]);
  const [trains, setTrains] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [endingCities, setEndingCities] = useState([]);
  // const [toursByDate, setToursByDate] = useState([]);
  const [form, setForm] = useState({
    train_id: 0,
    // date: '',
    starting_city: 0,
    ending_city: 0,
    // tour_id: 0,
    amount_status: "",
    total_amount: 0,
    party_id: 0,
    recv_cnic: "",
    recv_name: "",
    recv_phone: "",
    booking_time: "",
    party_name: "",
  });
  const [customerProfile, setCustomerProfile] = useState({
    name: "",
    phone: "",
    dob: "",
    cnic: "",
    address: "",
    gender: "",
  });
  const [oldCustomer, setOldCustomer] = useState(false);
  const [customerAdded, setCustomerAdded] = useState(false);
  const [addNewCustomer, setAddNewCustomer] = useState(false);

  useEffect(() => {
    document.title = "Add Cargo | AA Enterprise";
    fetchTrains();
  }, []);
  useEffect(() => {
    // if (form.date && form.train_id) {
    //   // setToursByDate([]);
    //   setForm(prevValue => {
    //     return {
    //       ...prevValue,
    //       starting_city: 0,
    //       ending_city: 0,
    //       // tour_id: 0
    //     }
    //   })
    //   _getTrainToursByDate();
    // }
    if (form.train_id) {
      setEndingCities([]);
      _getTrainStartingCities();
    }
  }, [form.train_id]);
  useEffect(() => {
    if (form.starting_city) {
      _getTrainEndingCities();
    }
  }, [form.starting_city]);
  useEffect(() => {
    //calculateItemAmount && calcuateTotalAmount
    if (tempNumber) {
      var fieldsList = [
        "weight" + currentCounter,
        "rate" + currentCounter,
        "miscellaneous" + currentCounter,
        "amount" + currentCounter,
      ];

      if (
        items[fieldsList[0]] !== undefined &&
        items[fieldsList[1]] !== undefined
      ) {
        var amount = 0;
        if (items[fieldsList[2]] !== undefined) {
          //with miscellaneous charges
          amount =
            Number(items[fieldsList[0]]) * Number(items[fieldsList[1]]) +
            Number(items[fieldsList[2]]);
        } else {
          //without miscellaneous charges
          amount = Number(items[fieldsList[0]] * items[fieldsList[1]]);
        }
        //setting item amount and total amount
        setItems((preveValue) => {
          return {
            ...preveValue,
            [fieldsList[3]]: amount,
          };
        });
      }
    }
  }, [tempNumber]);
  useEffect(() => {
    if (Object.keys(items).length) {
      var amount = 0;
      for (let i = 1; i <= counter; i++) {
        if ($("#item" + i).length) {
          let item = "amount" + i;
          amount += Number(items[item]);
        }
      }
      if (!isNaN(amount)) {
        setForm((preveValue) => {
          return {
            ...preveValue,
            total_amount: isNaN(amount) ? 0 : amount,
          };
        });
      }
    }
  }, [items]);
  useEffect(() => {
    if (validateForm) {
      for (let i = 1; i <= counter; i++) {
        if ($("#item" + i).length) {
          var fieldsList = ["weight" + i, "rate" + i];
          if (
            items[fieldsList[0]] === undefined ||
            items[fieldsList[1]] === undefined
          ) {
            toast.warn("Some required fields are empty");
            setValidateForm(false);
            break;
          }
        }
      }
    }
  }, [validateForm]);
  useEffect(() => {
    if (collectItems) {
      var itemsList = [];
      var sr_no = 1;
      for (var i = 1; i <= counter; i++) {
        if ($("#item" + i).length) {
          var fieldsList = [
            "item" + i,
            "weight" + i,
            "rate" + i,
            "amount" + i,
            "miscellaneous" + i,
          ];
          let item = {
            sr_no: sr_no,
            name: items[fieldsList[0]],
            weight: Number(items[fieldsList[1]]),
            rate: Number(items[fieldsList[2]]),
            amount: Number(items[fieldsList[3]]),
            misc:
              items[fieldsList[4]] === undefined
                ? 0
                : Number(items[fieldsList[4]]),
          };
          sr_no += 1;
          itemsList.push(item);
        }
      }
      setItemsObj(itemsList);
    }
    // setItemsArray
  }, [collectItems]);
  useEffect(() => {
    if (itemsObj !== undefined) {
      _submitForm();
    }
  }, [itemsObj]);

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 500, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  // async function _getTrainToursByDate() {
  //   let response = await getTrainToursByDate(form.date, form.train_id);
  //   if (response.status === 200) {
  //     if (response.data) {
  //       // document.getElementById("display_date").innerHTML = $('#select_date')[0].value.substr(0, 10);
  //       setToursByDate(response.data);
  //     } else {
  //       toast.warn("No tours were found on this date")
  //       setToursByDate([])
  //     }
  //   } else if (response.status >= 400) {
  //     toast.error(response.message.message)
  //   }
  // }

  async function _getTrainEndingCities() {
    let response = await getTrainEndingCities(
      form.train_id,
      form.starting_city
    );
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setEndingCities([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(form.train_id);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }

  async function _submitForm() {
    if (validateForm) {
      let today = new Date();
      let date =
        today.getFullYear() +
        "-" +
        (today.getMonth() + 1) +
        "-" +
        today.getDate();

      let formData = {
        train_id: Number(form.train_id),
        booking_time: date,
        party_id: Number(form.party_id),
        st_city: Number(form.starting_city),
        end_city: Number(form.ending_city),
        cargo_status: form.amount_status,
        items: itemsObj,
        recv_cnic: form.recv_cnic,
        recv_name: form.recv_name,
        recv_phone: form.recv_phone,
      };
      let response = await addCargo(formData);
      if (response.status === 200) {
        let st_city = Number(form.starting_city);

        // Removing Items carts
        for (let i = 1; i <= counter; i++) {
          if ($(".remove_btn").length) {
            removeItem("item" + i);
          }
        }

        setCounter(0);
        setItems({});
        setForm((preveValue) => {
          return {
            ...preveValue,
            ending_city: 0,
            total_amount: 0,
            party_id: 0,
            amount_status: "",
          };
        });
        setCustomerAdded(false);
        setAddNewCustomer(false);
        setOldCustomer(false);
        setCustomerProfile({
          name: "",
          phone: "",
          dob: "",
          cnic: "",
          address: "",
          gender: "",
        });
        _getTrainEndingCities();
        // $("#trains_amount").val('');
        $("#trains_amount option:selected").prop("selected", false);
        $("#trains_amount option:first").prop("selected", "selected");
        $("#ending_city option:selected").prop("selected", false);
        $("#ending_city option:first").prop("selected", "selected");
        setCurrentCounter(1);
        setTempNumber([]);
        alert("Cargo Number: " + response.data.id);
        toast.success("Cargo Booked");
        // history.push('/add-cargo');
      } else if (response.status >= 400) {
        console.log("response: ", response);
        toast.error(response.message);
      }
      setValidateForm(false);
      setCollectItems(false);
      setItemsObj();
    }
  }

  async function addNewCustomerFunc(e) {
    e.preventDefault();
    if (authenticateCustomerForm()) {
      let response = await addCustomer(customerProfile);
      if (response.status === 200) {
        toast.success(response.message);
        setCustomerAdded(true);
        setForm((preveValue) => {
          return {
            ...preveValue,
            party_name: response.data.name,
            party_id: response.data.id,
          };
        });
        $("#create_customer_btn").hide();
        $("#newName").prop("disabled", true);
        $("#newPhone").prop("disabled", true);
        $("#newCnic").prop("disabled", true);
        $("#newDob").prop("disabled", true);
        $("#newTextarea").prop("disabled", true);
        $("#newGender").prop("disabled", true);
      } else if (response.status >= 400) {
        toast.error(response.message.message);
      }
    } else {
      toast.warn("Please enter correct data");
    }
  }

  async function _fetchPartyData(e) {
    // To fetch cutomer profile, not generating ticket here
    e.preventDefault();
    if (
      customerProfile.cnic.length !== 13 ||
      customerProfile.cnic.includes("-")
    ) {
      toast.warn("Invalid CNIC ( OR remove '-' )");
    } else {
      let response = await getCustomerProfileByCnic(customerProfile.cnic);
      if (response.status === 200) {
        if (response.data.count) {
          setForm((preveValue) => {
            return {
              ...preveValue,
              party_id: Number(response.data.customers[0].id),
            };
          });
          setCustomerProfile(response.data.customers[0]);
          setOldCustomer(true);
          setAddNewCustomer(false);
          setCustomerAdded(true);
        } else {
          $("#create_customer_btn").show();
          $("#newName").prop("disabled", false);
          $("#newPhone").prop("disabled", false);
          $("#newCnic").prop("disabled", false);
          $("#newDob").prop("disabled", false);
          $("#newTextarea").prop("disabled", false);
          $("#newGender").prop("disabled", false);
          toast.warn("Customer not found");
          toast.info("Please enter customer details");
          setCustomerProfile((prevValue) => {
            return {
              ...prevValue,
              name: "",
              phone: "",
              dob: "",
              address: "",
              gender: "",
            };
          });
          setCustomerAdded(false);
          setAddNewCustomer(true);
          setOldCustomer(false);
        }
      } else if (response.status >= 400) {
        toast.error("Failed to fetch customer profile");
      }
    }

    // let response = await getCustomerProfile(id);
    // if (response.status === 200 && response.data.length !== 0) {
    //   setForm((preveValue) => {
    //     return {
    //       ...preveValue,
    //       "party_name": response.data.name
    //     }
    //   })
    // } else {
    //   toast.error("Invalid Party ID");
    //   setForm((preveValue) => {
    //     return {
    //       ...preveValue,
    //       "party_name": ''
    //     }
    //   })
    // }
  }

  function authenticateCustomerForm() {
    delete customerProfile.id;
    if (
      customerProfile.name.length !== 0 &&
      customerProfile.cnic.length !== 0 &&
      customerProfile.gender.length !== 0 &&
      !isNaN(Number(customerProfile.cnic))
    ) {
      return true;
    } else {
      return false;
    }
  }

  function onSubmitForm(e) {
    e.preventDefault();
    if (validate()) {
      if (validateForm) {
        if (!customerAdded) {
          toast.warn("Please add party");
        } else {
          setCollectItems(true);
        }
      }
    } else {
      console.log("Returned False");
    }
  }

  function addItem() {
    let newCounter = counter + 1;
    let name = "amount" + newCounter;
    setItems((preveValue) => {
      return {
        ...preveValue,
        [name]: 0,
      };
    });
    let amount = "amount" + newCounter;
    let id = "item" + newCounter;
    let weight = "weight" + newCounter;
    let rate = "rate" + newCounter;
    let miscellaneous = "miscellaneous" + newCounter;
    let newElem = elem(id, rate, weight, miscellaneous, amount);
    setItemsElem([...itemsElem, newElem]);
    setCounter(counter + 1);
  }

  function removeItem(id) {
    $("#" + id).remove();
    setItems((preveValue) => {
      return {
        ...preveValue,
        [id]: "",
      };
    });
  }

  function validate() {
    if (
      form.train_id &&
      form.starting_city &&
      form.ending_city &&
      form.party_id &&
      form.amount_status !== ""
    ) {
      // && form.booking_time !== ''
      if (!form.total_amount) {
        toast.warn("Please enter Items");
        return false;
      }
      setValidateForm(true);
      setTimeout(() => {}, 2200);
      return true;
    } else {
      if (!form.starting_city) {
        toast.warn("Select starting city");
        return false;
      }
      if (!form.ending_city) {
        toast.warn("Select ending city");
        return false;
      }
      if (!form.party_id) {
        toast.warn("Select party");
        return false;
      }
      toast.warn("Some fields are empty");
      return false;
    }
  }

  function handleChange(e) {
    e.preventDefault();
    var { name, value } = e.target;
    // if (name === 'date') {
    //   value = value.replace('T', ' ');
    // }
    console.log("form: ", form);
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value.trim(),
      };
    });
    // if (name === 'tour_id') {
    //   for (let i = 0; i < toursByDate.length; i++) {
    //     let txt = $('#tours_select').find(":selected").text();
    //     if (toursByDate[i].depart_time.trim().includes(txt.trim().substr(-5, 5))) {
    //       setForm((preveValue) => {
    //         return {
    //           ...preveValue,
    //           "booking_time": toursByDate[i].depart_time.substr(0, 19).replace('T', ' ')
    //         }
    //       })
    //     }
    //   }
    // }
    if (name === "cnic") {
      setCustomerProfile((prevValue) => {
        return {
          ...prevValue,
          cnic: value,
        };
      });
    }
  }

  function handleItemChange(e) {
    e.preventDefault();
    const { name, value } = e.target;
    let id = name.match(/\d+/)[0];
    setCurrentCounter(Number(id));
    console.log("items: ", items);
    setItems((prevValue) => {
      return {
        ...prevValue,
        [name]: value.trim(),
      };
    });
    setTempNumber([...tempNumber, 1]);
  }

  function onChangeNewHandler(event) {
    const { name, value } = event.target;
    setCustomerProfile((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Cargo</h2>
          <p class="text-light">Add a new Cargo</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Add Cargo</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="col-md-4">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option key={train.id} value={train.id}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    {/* <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Date</label>
                          <input id="select_date" type="date" name='date' class="form-control" placeholder="date" onChange={handleChange} required />
                        </div>
                      </div>
                    </div> */}

                    {/* <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Select Tour</label>
                            <select id="tours_select" name='tour_id' onChange={handleChange} class="form-select form-control wide">
                              <option value=''>Choose...</option>
                              {toursByDate.map((tour) => {
                                return (
                                  <option key={tour.id} value={tour.id} >{tour.name} {tour.depart_time.substr(11, 5)}</option>
                                )
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="starting_city"
                              name="starting_city"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="ending_city"
                              name="ending_city"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Recv Name</label>
                            <input
                              type="text"
                              name="recv_name"
                              onChange={handleChange}
                              class=" form-control wide"
                              value={form.recv_name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label class="form-label">Recv CNIC</label>
                      <input
                        type="text"
                        name="recv_cnic"
                        onChange={handleChange}
                        class=" form-control wide"
                        value={form.recv_cnic}
                      />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label">Recv Phone</label>
                      <input
                        type="text"
                        name="recv_phone"
                        onChange={handleChange}
                        class=" form-control wide"
                        value={form.recv_phone}
                      />
                    </div>

                    <div class="col-md-4">
                      <label class="form-label">Amount</label>
                      <select
                        id="trains_amount"
                        name="amount_status"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="paid">
                          Paid
                        </option>
                        <option key="2" value="topay">
                          To Pay
                        </option>
                      </select>
                    </div>

                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label class="form-label">CNIC</label>
                      <input
                        type="text"
                        name="cnic"
                        value={customerProfile.cnic}
                        onChange={handleChange}
                        class="form-control"
                        placeholder="enter party CNIC"
                      />
                    </div>
                    <div class="mt-5 col-md-2">
                      <button
                        type="submit"
                        onClick={(e) => _fetchPartyData(e)}
                        class="btn btn-primary p-2"
                      >
                        Add Party
                      </button>
                    </div>

                    {/* <div class="card-body">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-12">
                            <label class="form-label">CNIC</label>
                            <div class="row">
                              <div class="col-md-4">
                                <input type="text" name='cnic' value={customerProfile.cnic} onChange={handleChange} class="form-control" placeholder="enter party CNIC" />
                              </div>
                              <div class="col-md-2">
                                <button type="submit" onClick={(e) => _fetchPartyData(e)} class="btn btn-primary">Add Party</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    {oldCustomer && (
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="card">
                            <div class="card-header">
                              <h4 class="card-title">Customer</h4>
                            </div>

                            <div class="card-body">
                              <div class="basic-form">
                                {/* <form name="create_customer_form" onSubmit={(e) => submitCustomer(e)}> */}

                                <div class="row">
                                  <div class="mb-3 col-md-4">
                                    <label class="form-label">Name</label>
                                    <input
                                      type="text"
                                      name="name"
                                      value={customerProfile.name}
                                      class="form-control"
                                      placeholder="name"
                                      disabled
                                    />
                                  </div>
                                  <div class="mb-3 col-md-4">
                                    <label class="form-label">Phone</label>
                                    <input
                                      type="text"
                                      name="phone"
                                      value={customerProfile.phone}
                                      class="form-control"
                                      placeholder="03123456789 ..."
                                      disabled
                                    />
                                  </div>
                                  <div class="mb-3 col-md-4">
                                    <label class="form-label">CNIC</label>
                                    <input
                                      type="text"
                                      name="cnic"
                                      value={customerProfile.cnic}
                                      class="form-control"
                                      placeholder="1234567891234  (without dashes)"
                                      disabled
                                    />
                                  </div>
                                  <div class="mb-3 col-md-4">
                                    <label class="form-label">DOB</label>
                                    <input
                                      type="date"
                                      name="dob"
                                      value={customerProfile.dob}
                                      class="form-control"
                                      placeholder="date of birth"
                                      disabled
                                    />
                                  </div>
                                  <div class="mb-3 col-md-4">
                                    <label class="form-label">Address</label>
                                    <textarea
                                      class="form-control"
                                      name="address"
                                      value={customerProfile.address}
                                      rows="4"
                                      placeholder="address..."
                                      disabled
                                    ></textarea>
                                    {/* <textarea name="address" value={customerForm.address} onChange={onChangeHandler} rows="3" cols="50" placeholder='address...' /> */}
                                  </div>
                                  <div class="mb-3 col-md-4">
                                    <label class="form-label">Gender</label>
                                    <select
                                      id="gender"
                                      name="gender"
                                      value={customerProfile.gender}
                                      class="form-select form-control wide"
                                      disabled
                                    >
                                      <option value="">Choose...</option>
                                      <option value="male">Male</option>
                                      <option value="female">Female</option>
                                      <option value="others">Others</option>
                                    </select>
                                  </div>
                                </div>
                                {/* <button type="submit" name='create_customer_btn' class={updateId ? "btn btn-success" : "btn btn-primary"}>{updateId ? "Update" : "Submit"}</button> */}
                                {/* </form> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {addNewCustomer && (
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="card">
                            <div class="card-header">
                              <h4 class="card-title">Add Customer</h4>
                            </div>

                            <div class="card-body">
                              <div class="basic-form">
                                <div name="create_customer_form">
                                  <div class="row">
                                    <div class="mb-3 col-md-6">
                                      <label class="form-label">
                                        Name{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        id="newName"
                                        type="text"
                                        name="name"
                                        value={customerProfile.name}
                                        onChange={onChangeNewHandler}
                                        class="form-control"
                                        placeholder="name"
                                      />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                      <label class="form-label">Phone</label>
                                      <input
                                        id="newPhone"
                                        type="text"
                                        name="phone"
                                        value={customerProfile.phone}
                                        onChange={onChangeNewHandler}
                                        class="form-control"
                                        placeholder="03123456789 ..."
                                      />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                      <label class="form-label">
                                        CNIC{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <input
                                        id="newCnic"
                                        type="text"
                                        name="cnic"
                                        value={customerProfile.cnic}
                                        onChange={onChangeNewHandler}
                                        class="form-control"
                                        placeholder="1234567891234  (without dashes)"
                                        disabled
                                      />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                      <label class="form-label">DOB</label>
                                      <input
                                        id="newDob"
                                        type="date"
                                        name="dob"
                                        value={customerProfile.dob}
                                        onChange={onChangeNewHandler}
                                        class="form-control"
                                        placeholder="date of birth"
                                      />
                                    </div>
                                    <div class="mb-3 col-md-6">
                                      <label class="form-label">
                                        Gender{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </label>
                                      <select
                                        id="newGender"
                                        name="gender"
                                        value={customerProfile.gender}
                                        onChange={onChangeNewHandler}
                                        class="form-select form-control wide"
                                      >
                                        <option value="">Choose...</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                        <option value="others">Others</option>
                                      </select>
                                    </div>
                                    <div class="mb-3 col-md-6">
                                      <label class="form-label">Address</label>
                                      <textarea
                                        id="newTextarea"
                                        class="form-control"
                                        name="address"
                                        value={customerProfile.address}
                                        onChange={onChangeNewHandler}
                                        rows="4"
                                        placeholder="address..."
                                      ></textarea>
                                      {/* <textarea name="address" value={customerForm.address} onChange={onChangeHandler} rows="3" cols="50" placeholder='address...' /> */}
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    id="create_customer_btn"
                                    name="create_customer_btn"
                                    onClick={(e) => addNewCustomerFunc(e)}
                                    class="btn btn-primary"
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div id="items" className="p-0 m-0">
                      {itemsElem.map((arr) => {
                        return arr;
                      })}
                    </div>
                  </div>
                  <div class="row">
                    <div class="mt-3 col-md-6 col-6">
                      <label class="form-label">Total: </label>
                      <p class="lead">{form.total_amount}</p>
                    </div>
                    <div className="col-md-5 col-3"></div>
                    <div className="col-md-1 col-3">
                      <button
                        class="btn btn-outline-success btn-sm"
                        type="button"
                        onClick={() => {
                          addItem();
                        }}
                      >
                        <i class="icon-plus"></i>
                      </button>
                    </div>
                  </div>

                  <button type="submit" name="submit" class="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddCargo;
