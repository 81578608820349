import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import {
  updateCargoById,
  getCargoById,
} from "../../store/actions/cargo.actions";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";

function EditCargo() {
  const history = useHistory();
  const location = useLocation();
  const [endingCities, setEndingCities] = useState([]);
  // const [toursByDate, setToursByDate] = useState([]);
  const [form, setForm] = useState({
    ending_city: 0,
    train_id: 0,
    id: 0,
    cargo_status: "",
    delivery_status: "",
    end_city_id: 0,
    recv_cnic: "",
    recv_name: "",
    recv_phone: "",
  });

  useEffect(() => {
    document.title = "Edit Cargo | AA Enterprise";
    if (JSON.stringify(location.state) !== undefined) {
      let data = location.state;
      // console.log(data);
      setForm({
        cargo_status: data.cargo_status,
        delivery_status: data.delivery_status,
        train_id: data.train_id,
        id: data.id,
        st_city: data.st_city,
        recv_cnic: data.recv_cnic,
        recv_name: data.recv_name,
        recv_phone: data.recv_phone,
      });
      _getTrainEndingCities(data.train_id, data.st_city);
    } else {
      history.push("/index");
    }
  }, []);

  async function _getTrainEndingCities(train_id, st_city) {
    let response = await getTrainEndingCities(train_id, st_city);
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Invalid Selection of Starting City");
      setEndingCities([]);
    }
  }

  useEffect(() => {
    if (endingCities.length > 0) {
      setForm((prevValue) => {
        return {
          ...prevValue,
          ending_city: location.state.end_city_id,
        };
      });
    }
  }, [endingCities]);

  // async function getCargoById(id) {
  //   let cargo = await getCargoById
  // }

  async function submitForm(e) {
    e.preventDefault();

    let formData = {
      end_city: Number(form.ending_city),
      cargo_status: form.cargo_status,
      delivery_status: form.delivery_status,
    };

    let response = await updateCargoById(formData, form.id);
    if (response.status === 200) {
      toast.success("Cargo Updated");
      history.push("/all-cargo");
    } else if (response.status >= 400) {
      // console.log("response: ", response);
      toast.error(response.message);
    }
  }

  function handleChange(e) {
    e.preventDefault();
    var { name, value } = e.target;
    // if (name === 'date') {
    //   value = value.replace('T', ' ');
    // }
    // console.log("form: ", form);
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value.trim(),
      };
    });
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Cargo</h2>
          <p class="text-light">Edit Cargo</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Edit Cargo</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => submitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="ending_city"
                              name="ending_city"
                              onChange={handleChange}
                              class="form-select form-control wide"
                              value={form.ending_city}
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <label class="form-label">Cargo Type</label>
                      <select
                        id="trains_amount"
                        name="cargo_status"
                        onChange={handleChange}
                        class="form-select form-control wide"
                        value={form.cargo_status}
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="paid">
                          Paid
                        </option>
                        <option key="2" value="topay">
                          To Pay
                        </option>
                      </select>
                    </div>
                    <div class="col-md-4">
                      <label class="form-label">Delivery Status</label>
                      <select
                        id="trains_amount"
                        name="delivery_status"
                        onChange={handleChange}
                        class="form-select form-control wide"
                        value={form.delivery_status}
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="delivered">
                          Delivered
                        </option>
                        <option key="2" value="undelivered">
                          Un-Delivered
                        </option>
                      </select>
                    </div>
                  </div>

                  <button type="submit" name="submit" class="btn btn-primary">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditCargo;
