import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function ParloCar() {
  
  const [checkedArray, setCheckedArray] = useState([]);
  
  function onBoxChange(value) {
    
  }

  
  return (
    <div style={{overflowX: 'auto'}}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('A01S')?'rgb(255, 0, 0)':''}}  >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="A01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('A01S')?true:false}
                        onChange={() => { onBoxChange('A01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">A01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('A02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="A02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('A02S')?true:false}
                        onChange={() => { onBoxChange('A02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">A02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('A03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="A03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('A03S')?true:false}
                        onChange={() => { onBoxChange('A03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">A03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('A04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="A04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('A04S')?true:false}
                        onChange={() => { onBoxChange('A04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="A04S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">A04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('B01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="B01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('B01S')?true:false}
                        onChange={() => { onBoxChange('B01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="B01S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">B01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('B02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="B02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('B02S')?true:false}
                        onChange={() => { onBoxChange('B02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">BO2S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('B03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="B03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('B03S')?true:false}
                        onChange={() => { onBoxChange('B03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">B03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('B04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="B04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('B04S')?true:false}
                        onChange={() => { onBoxChange('B04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="B04S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">B04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('C01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="C01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('C01S')?true:false}
                        onChange={() => { onBoxChange('C01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">C01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('C02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="C02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('C02S')?true:false}
                        onChange={() => { onBoxChange('C02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">C02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('C03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="C03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('C03S')?true:false}
                        onChange={() => { onBoxChange('C03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">C03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('C04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="C04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('C04S')?true:false}
                        onChange={() => { onBoxChange('C04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">C04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('D01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="D01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('D01S')?true:false}
                        onChange={() => { onBoxChange('D01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">D01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('D02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="D02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('D02S')?true:false}
                        onChange={() => { onBoxChange('D02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">D02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('D03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="D03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('D03S')?true:false}
                        onChange={() => { onBoxChange('D03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">D03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('D04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="D04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('D04S')?true:false}
                        onChange={() => { onBoxChange('D04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DO4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('E01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="E01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('E01S')?true:false}
                        onChange={() => { onBoxChange('E01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">E01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('E02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="E02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('E02S')?true:false}
                        onChange={() => { onBoxChange('E02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">E02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('E03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="E03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('E03S')?true:false}
                        onChange={() => { onBoxChange('E03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">E03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('E04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="E04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('E04S')?true:false}
                        onChange={() => { onBoxChange('E04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">E04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('F01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="F01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('F01S')?true:false}
                        onChange={() => { onBoxChange('F01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">F01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('F02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="F02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('F02S')?true:false}
                        onChange={() => { onBoxChange('F02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">F02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('F03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="F03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('F03S')?true:false}
                        onChange={() => { onBoxChange('F03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">F03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('F04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="F04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('F04S')?true:false}
                        onChange={() => { onBoxChange('F04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">F04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('G01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="G01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('G01S')?true:false}
                        onChange={() => { onBoxChange('G01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">G01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('G02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="G02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('G02S')?true:false}
                        onChange={() => { onBoxChange('G02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">G02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('G03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="G03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('G03S')?true:false}
                        onChange={() => { onBoxChange('G03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">G03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('G04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="G04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('G04S')?true:false}
                        onChange={() => { onBoxChange('G04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">G04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('H01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="H01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('H01S')?true:false}
                        onChange={() => { onBoxChange('H01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">H01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('H02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="H02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('H02S')?true:false}
                        onChange={() => { onBoxChange('H02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">H02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('H03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="H03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('H03S')?true:false}
                        onChange={() => { onBoxChange('H03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">H03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('H04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="H04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('H04S')?true:false}
                        onChange={() => { onBoxChange('H04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">H04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('I01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="I01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('I01S')?true:false}
                        onChange={() => { onBoxChange('I01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">I01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('I02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="I02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('I02S')?true:false}
                        onChange={() => { onBoxChange('I02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">IO2S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('I03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="I03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('I03S')?true:false}
                        onChange={() => { onBoxChange('I03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">I03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('I04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="104S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('I04S')?true:false}
                        onChange={() => { onBoxChange('I04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">I04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('J01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="J01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('J01S')?true:false}
                        onChange={() => { onBoxChange('J01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">J01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('J02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="J02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('J02S')?true:false}
                        onChange={() => { onBoxChange('J02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">J02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('J03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="J03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('J03S')?true:false}
                        onChange={() => { onBoxChange('J03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">J03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('J04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="J04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('J04S')?true:false}
                        onChange={() => { onBoxChange('J04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">J04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('K01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="K01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('K01S')?true:false}
                        onChange={() => { onBoxChange('K01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">K01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('K02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="K02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('K02S')?true:false}
                        onChange={() => { onBoxChange('K02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">K02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('K03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="K03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('K03S')?true:false}
                        onChange={() => { onBoxChange('K03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">K03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('K04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="K04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('K04S')?true:false}
                        onChange={() => { onBoxChange('K04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">K04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('L01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="L01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('L01S')?true:false}
                        onChange={() => { onBoxChange('L01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">L01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('L02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="L02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('L02S')?true:false}
                        onChange={() => { onBoxChange('L02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">L02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('L03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="L03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('L03S')?true:false}
                        onChange={() => { onBoxChange('L03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">L03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('L04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="L04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('L04S')?true:false}
                        onChange={() => { onBoxChange('L04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">L04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('M01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="M01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('M01S')?true:false}
                        onChange={() => { onBoxChange('M01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">M01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('M02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="M02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('M02S')?true:false}
                        onChange={() => { onBoxChange('M02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">M02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('M03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="M03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('M03S')?true:false}
                        onChange={() => { onBoxChange('M03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">M03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('M04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="M04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('M04S')?true:false}
                        onChange={() => { onBoxChange('M04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">M04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box" style={{background:checkedArray?.includes('N01S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="N01S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('N01S')?true:false}
                        onChange={() => { onBoxChange('N01S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">N01S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('N02S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="N02S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('N02S')?true:false}
                        onChange={() => { onBoxChange('N02S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">N02S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('N03S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="N03S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('N03S')?true:false}
                        onChange={() => { onBoxChange('N03S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">N03S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box" style={{background:checkedArray?.includes('N04S')?'rgb(255, 0, 0)':''}} >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="N04S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={ checkedArray?.includes('N04S')?true:false}
                        onChange={() => { onBoxChange('N04S') }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">N04S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
      {/* <div class="card-body pt-0 row">
          <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
      </div> */}
    </div>
  );
}

export default ParloCar;
