import axiosInstance from '../../../utils/axios';

class Report {

    static downloadPdf = (id) => {

        let apiPath = '/api/tickets/earning/' + id;
        return axiosInstance.get(apiPath)
            .then(response => { return response })

    };

    static reportByStatus = (form) => {

        let apiPath = '/api/reports/reportByStatus';
        return axiosInstance.post(apiPath, {
            train_id: Number(form.train_id),
            st_city: form.st_city,
            end_city: form.end_city,
            status: form.status,
            creator: form.creator,
            st_date: form.st_date,
            end_date: form.end_date,
            coach_type: form.coach_type_id
        });

    };

    static createdAtReport = (form) => {

        let apiPath = '/api/reports/createdAtByStatus';
        return axiosInstance.post(apiPath, {
            train_id: Number(form.train_id),
            st_city: form.st_city,
            end_city: form.end_city,
            status: form.status,
            creator: form.creator,
            st_date: form.st_date,
            end_date: form.end_date,
            coach_type: form.coach_type_id
        });

    };

    static cityWiseUserReport = (form) => {

        let apiPath = '/api/reports/cityWiseUser';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static passengerChartReport = (form) => {

        let apiPath = '/api/reports/passengerChart';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static discountedTicketReport = (form) => {

        let apiPath = '/api/reports/discTickets';
        return axiosInstance.post(apiPath, {
            st_date: form.st_date,
            end_date: form.end_date,
            creator: Number(form.creator),
            st_city: Number(form.st_city),
            end_city: Number(form.end_city),
            type: form.type
        });

    };

    static cargoReport = (form) => {

        let apiPath = '/api/reports/cargo';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static cargoIndividualReport = (form) => {

        let apiPath = '/api/reports/cargo_ind';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static trainEarningReport = (form) => {

        let apiPath = '/api/reports/train_earning';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static totalEarningReport = (form) => {

        let apiPath = '/api/reports/total_earning';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

}
export default Report;