import axiosInstance from '../../../utils/axios';

class Train {

    static addTrain = (form) => {

        let apiPath = '/api/trains';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static allTrains = (form) => {

        let apiPath = '/api/trains?_count=' + parseInt(form._count) + '&_pageNo=' + parseInt(form._pageNo);
        return axiosInstance.get(apiPath).then(response => { return response })

    };

    static updateTrain = (form, id) => {

        let apiPath = '/api/users/' + parseInt(id);
        return axiosInstance.put(apiPath, {
            ...form
        })
            .then(response => { return response })

    };

    static getTrainProfile = (id) => {

        let apiPath = '/api/trains/' + Number(id);
        return axiosInstance.get(apiPath).then(response => { return response })

    };

    static deleteTrain = (getID) => {

        let apiPath = '/api/trains/' + parseInt(getID);
        return axiosInstance.delete(apiPath).then(response => { return response })

    };

    static addTrainCity = (form) => {

        let apiPath = '/api/trainCities';
        return axiosInstance.post(apiPath, {
            ...form
        })
            .then(response => { return response })

    };

    static getTrainCities = (object) => {
        
        let apiPath = `/api/trainCities/${object.trainId}?_count=${parseInt(object._count)}&_pageNo=${parseInt(object._pageNo)}`;
        return axiosInstance.get(apiPath)
            .then(response => { return response })

    };

    static deleteTrainCity = (id) => {

        let apiPath = '/api/trainCities/' + id;
        return axiosInstance.delete(apiPath)
            .then(response => { return response })

    };

    static updateTrain = (form, id) => {

        let apiPath = '/api/trains/' + id;
        return axiosInstance.put(apiPath, {
            ...form
        })

    };

    static updateTrainCity = (form, id) => {

        let apiPath = '/api/trainCities/' + id;
        return axiosInstance.put(apiPath, {
            ...form
        })

    };

    static activateTrain = (id) => {

        let apiPath = '/api/trains/activate/' + id;
        return axiosInstance.get(apiPath)

    };

    static getTrainStartingCities = (train_id) => {

        let apiPath = '/api/trainCities/starting/' + train_id;
        return axiosInstance.get(apiPath)

    };

    static getTrainEndingCities = (train_id, starting_city) => {

        let apiPath = '/api/trainCities/ending/' + train_id + '/' + starting_city;
        return axiosInstance.get(apiPath)

    };

}
export default Train;