import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { updateTrainfare, getFareById } from "../../store/actions/fare.actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function UpdateFare() {
  const location = useLocation();
  const history = useHistory();
  const [updateIds, setUpdateIds] = useState([]);
  const [currUpdateIndex, setcurrUpdateIndex] = useState(0);
  const [updateId, setUpdateId] = useState(0);
  const [updateFareForm, setUpdateFareForm] = useState({
    st_city: "",
    end_city: "",
    coach_type: "",
    seat_fare: 0,
    berth_fare: 0,
  });

  useEffect(() => {
    document.title = "Update Fare | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      if (location.state.ids === undefined) {
        history.push({ pathname: "/all-train-fares" });
      }
      setUpdateIds([...location.state.ids]);
      setUpdateId(Number(location.state.ids[0]));
    }
  }, []);

  useEffect(() => {
    // console.log("update ids are", updateIds);
    if (updateIds.length > 0) {
      fetchCurrIndexDetails();
    }
  }, [updateIds]);
  useEffect(() => {
    if (currUpdateIndex != 0) {
      fetchCurrIndexDetails();
    }
  }, [currUpdateIndex]);

  async function fetchCurrIndexDetails() {
    let currentFare = await getFareById(updateIds[currUpdateIndex]);
    if (currentFare.status === 200) {
      setUpdateFareForm(currentFare.data);
      setUpdateId(currentFare.data.id);
    } else {
      toast.error("Unable to fetch Fare details");
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    if (validate(updateFareForm)) {
    //   console.log("value before update is ", updateFareForm);
      let {seat_fare , berth_fare} = updateFareForm;
      let response = await updateTrainfare({seat_fare , berth_fare}, updateId);
      if (response.status === 200) {
        toast.success("Fare Updated");
        if (currUpdateIndex === updateIds.length - 1) {
          toast.success("All Fares Updated Successfully");
          history.push("/all-train-fares");
        } else {
          setcurrUpdateIndex((prevValue) => prevValue + 1);
        }
      } else if (response.status >= 400) {
        toast.error("Failed to Update");
      }
    } else {
      toast.warn("Please insert all entries");
    }
  }

  function handleCoachFare(e) {
    let { name, value } = e.target;

    value = parseInt(value);
    if (isNaN(value)) {
      setUpdateFareForm((prevValue) => {
        return {
          ...prevValue,
          [name]: 0,
        };
      });
    } else {
      setUpdateFareForm((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    }
  }

  function validate(obj) {
    if (obj.id && obj.seat_fare && obj.berth_fare) {
      return true;
    }
    return false;
  }
  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Fares</h2>
          <p class="text-light">Update fare fro city coach</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Update Fare</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  name="add_city_fare_form"
                  onSubmit={(e) => onSubmitForm(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <label class="form-label">
                        Starting City:{updateFareForm.st_city}
                      </label>
                    </div>
                    <div class="mb-3 col-md-4">
                      <label class="form-label">
                        Ending City:{updateFareForm.end_city}
                      </label>
                    </div>
                    <div class="mb-3 col-md-4">
                      <label class="form-label">
                        Coach Type:{updateFareForm.coach_type}
                      </label>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Seat Fare</label>
                      <input
                        type="text"
                        name="seat_fare"
                        value={updateFareForm.seat_fare}
                        onChange={handleCoachFare}
                        class="form-control"
                      />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Berth Fare</label>
                      <input
                        type="text"
                        name="berth_fare"
                        value={updateFareForm.berth_fare}
                        onChange={handleCoachFare}
                        class="form-control"
                      />
                    </div>
                  </div>

                  <button type="submit" name="submit" class="btn btn-success">
                    Save & Continue
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateFare;
