import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory } from "react-router-dom";
import {
  allTrains,
  getTrainStartingCities
} from "../../store/actions/train.actions";
import {
  getTrainFares,
  copyFares
} from "../../store/actions/fare.actions";
import { allCoaches } from "../../store/actions/coach.actions";
import $ from "jquery";

import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function CopyFares() {
  const history = useHistory();
  const [trainId, setTrainId] = useState(0);


  const [trains, setTrains] = useState([]);
 
  const [coaches, setCoaches] = useState([]);
  const [startingCities, setStartingCities] = useState([]);


  const [form, setForm] = useState({
    st_city: 0,
    st_coach: 0,
    end_coach: 0,
  });
  const [fetchParams, setfetchParams] = useState({
    _count: 5500,
    _pageNo: 1,
  });
  

  async function fetchTrains() {
    let fetch_trains = await allTrains(fetchParams);
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  //fetch functions
  async function _allCoaches() {
    let response = await allCoaches({ _count: 500, _pageNo: 1 });
    if (response.status === 200 && response.data.length !== 0) {
      setCoaches(response.data.types);
    } else if (response.status >= 400) {
      toast.error("no coaches found");
      setCoaches([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(trainId);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }



  async function copyTrainFares() {
    let dispObject = { ...form };

    if (dispObject.st_coach === 0) delete dispObject.st_coach;

    if (dispObject.end_coach === 0) delete dispObject.end_coach;
    if (dispObject.st_city === 0) delete dispObject.st_city;

    dispObject.train_id = trainId;

    
    $('#preloader').delay(333).fadeIn('slow');
    
    let res = await copyFares(dispObject);
    
    $('#preloader').delay(333).fadeOut('slow');
    
    
    if (res.status === 200 ) {
        toast.success("Fares Copied successfully.")
      
    } else if (res.status >= 400 ) {
      toast.error(`Failed to Copy Fares ${res.message ? res.message : null}`);
    }
  }

  

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: Number(value),
      };
    });
  }



  function OnTrainChange(e) {
    const { value } = e.target;
    setTrainId(value);
  }

  function selectTrain(e) {
    e.preventDefault();
    setTrainId(document.getElementById("trains_select").value);
    document.getElementById("display_train").innerHTML = $("#trains_select")
      .find("option:selected")
      .text();
  }



 

  async function onSubmitForm(e) {
    e.preventDefault();

    if (!trainId) {
      toast.error("Train Id  is required");
      return;
    }
    if(form.st_coach === form.end_coach){
      toast.error("Starting and ending coach can not be same");
      return;
    }
    let confirmed = window.confirm("Are you sure you want to copy this?");
    if(!confirmed) {
      return;
    }
    copyTrainFares()
    console.log("form is", form,confirmed);

  
  }

  useEffect(() => {
    document.title = "All Train Fares | AA Enterprise";
    $(".print").hide();
    _allCoaches();
  }, []);

  useEffect(() => {
    fetchTrains();
  }, [fetchParams]);

  

  

  useEffect(() => {
    if (trainId) {
      _getTrainStartingCities();
    }
  }, [trainId]);



  //   useEffect(() => {
  //     console.log("selected fares are", selectedFares);
  //   }, [selectedFares]);

  

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Fares</h2>
          <p class="text-light">Add fare for train cities</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Trains</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        class="form-select form-control wide"
                        onChange={OnTrainChange}
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option value={train.id} data-name={train.name}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Coach From</label>
                            <select
                              id="coach_select"
                              name="st_coach"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {coaches.map((coach) => {
                                return (
                                  <option
                                    key={coach.id}
                                    value={coach.id}
                                    name={coach.name}
                                  >
                                    {coach.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Coach To:</label>
                            <select
                              id="coach_select"
                              name="end_coach"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {coaches.map((coach) => {
                                return (
                                  <option
                                    key={coach.id}
                                    value={coach.id}
                                    name={coach.name}
                                  >
                                    {coach.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-4">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">Starting City</label>
                            <select
                              id="st_city"
                              name="st_city"
                              onChange={handleChange}
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-warning"
                      >
                        Start Copy
                      </button>
                    </div>
                  </div>
                </form>

                <div class="row">
                  <div class="mb-3 col-md-6">
                    <p>
                      Selected Train is:{" "}
                      <i id="display_train" style={{ color: "red" }}></i>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      
    </>
  );
}

export default CopyFares;
