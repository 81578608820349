import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function AcStd90() {
  const layoutSeats = ["1S","2S","3S","56S","55S","4S","5S","6S","58S","57S","7S","8S","9S","60S","59S","10S","11S","12S","62S","61S","13S","14S","15S","64S","63S","16S","17S","18S","66S","65S","19S","20S","21S","68S","67S","22S","23S","24S","70S","69S","25S","26S","27S","72S","71S","28S","29S","30S","74S","73S","31S","32S","33S","76S","75S","34S","35S","36S","78S","77S","37S","38S","39S","80S","79S","40S","41S","42S","82S","81S","43S","44S","45S","84S","83S","46S","47S","48S","86S","85S","49S","50S","51S","88S","87S","52S","53S","54S","90S","89S"];
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;
  const [seatArr, setSeatArr] = useState([]);

  const [seatStatus, setSeatStatus] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [children, setChildren] = useState(0);
  const [pastData, setPastData] = useState({
    train_id: 0,
    train_name: "",
    date: "",
    tour_id: 0,
    tour_name: "",
    coachTypeId: 0,
    coach_name: "",
  });
  const [vacantSeats, setVacantSeats] = useState({
    difference: [],
    stop_time: "",
  });

  //checkbox function
  function onBoxChange(value) {
    let arr = seatArr;

    if (arr.includes(value)) {
      arr = arr.filter((e) => e !== value);
    } else {
      arr.push(value);
    }

    setSeatArr([...arr]);
  }

  //initial Hook
  useEffect(() => {
    document.title = "Available Seats | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setPastData({
        train_id: location.state.train_id,
        train_name: location.state.train_name,
        date: location.state.date,
        tour_id: location.state.tourId,
        tour_name: location.state.tour_name,
        coachTypeId: location.state.coachTypeId,
        coach_name: location.state.coach_name,
        coach_number: location.state.coach_number,
        starting_city_id: location.state.starting_city_id,
        starting_city_name: location.state.starting_city_name,
        ending_city_id: location.state.ending_city_id,
        ending_city_name: location.state.ending_city_name,
        status: location.state.status,
        cnic: location.state.cnic,
      });
    } else {
      history.push("/add-ticket");
    }
  }, []);

  async function _getVacantSeats() {
    let response = await getVacantSeats(
      pastData.coachTypeId,
      pastData.starting_city_id,
      pastData.ending_city_id
    );
    if (response.status === 200) {
      setVacantSeats(response.data);
    } else if (response.status >= 400) {
      toast.error("Failed");
    }
  }

  function _setBookedSeats() {
    let arr2 = vacantSeats.difference;
    let difference = layoutSeats.filter((x) => !arr2.includes(x));
    setCheckedArray(difference);
  }

  useEffect(() => {
    if (pastData.coachTypeId) {
      _getVacantSeats();
    }
  }, [pastData.coachTypeId]);

  useEffect(() => {
    if (pastData.status || pastData.status !== undefined) {
      setSeatStatus(pastData.status);
      $("#status option:first").text(location.state.status);
      $("#status option:first").val(location.state.status.toLowerCase());
    }
  }, [pastData.status]);

  //Hook to run after vacant seats are fecthed from server
  useEffect(() => {
    if (vacantSeats.stop_time !== "") {
      _setBookedSeats();
    }
  }, [vacantSeats.stop_time]);

  

  function handleSeatStatus(e) {
    const { value } = e.target;
    setSeatStatus(value);
  }

  function handleChildrenChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (value >= 0) {
      setChildren(Number(value));
    } else {
      toast.warn("Enter valid number");
    }
  }

  function submitSeat() {
    if ((children ||  seatArr.length) && seatStatus !== "") {
      history.push({
        pathname: "add-ticket-customer",
        state: {
          tc_id: pastData.coachTypeId,
          st_city: pastData.starting_city_id,
          end_city: pastData.ending_city_id,
          toBookSeats: seatArr,
          status: seatStatus,
          children: children,
          stateData,
        },
      });
    } else {
      toast.warn("Please select all values");
    }
  }

 
  

  return (
    <div style={{overflowX: 'auto'}}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="prow ">
        <div class="left ac90">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('1S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('1S') }}
                        disabled={checkedArray?.includes("1S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('2S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('2S') }}
                        disabled={checkedArray?.includes("2S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"   style={{background:checkedArray?.includes('3S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('3S') }}
                        disabled={checkedArray?.includes("3S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('56S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="56S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('56S') }}
                        disabled={checkedArray?.includes("56S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="56S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">56S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('55S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="55S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange("55S") }}
                        disabled={checkedArray?.includes("55S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="55S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">55S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('4S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('4S') }}
                        disabled={checkedArray?.includes("4S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="4S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('5S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('5S') }}
                        disabled={checkedArray?.includes("5S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('6S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('6S') }}
                        disabled={checkedArray?.includes("6S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">6S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('58S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="58S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('58S') }}
                        disabled={checkedArray?.includes("58S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">58S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('57S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="57S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('57S') }}
                        disabled={checkedArray?.includes("57S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">57S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('7S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('7S') }}
                        disabled={checkedArray?.includes("7S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">7S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('8S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('8S') }}
                        disabled={checkedArray?.includes("8S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('9S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="9S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('9S') }}
                        disabled={checkedArray?.includes("9S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">9S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('60S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('60S') }}
                        disabled={checkedArray?.includes("60S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">60S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('59S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="59S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('59S') }}
                        disabled={checkedArray?.includes("59S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">59S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('10S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="10S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('10S') }}
                        disabled={checkedArray?.includes("10S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('11S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="11S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('11S') }}
                        disabled={checkedArray?.includes("11S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">11S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('12S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="12S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('12S') }}
                        disabled={checkedArray?.includes("12S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">12S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('62S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="62S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('62S') }}
                        disabled={checkedArray?.includes("62S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">62S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('61S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="61S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('61S') }}
                        disabled={checkedArray?.includes("61S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">61S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('13S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="13S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('13S') }}
                        disabled={checkedArray?.includes("13S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">13S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('14S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="14S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('14S') }}
                        disabled={checkedArray?.includes("14S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">14S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('15S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="15S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('15S') }}
                        disabled={checkedArray?.includes("15S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">15S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('64S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="64S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('64S') }}
                        disabled={checkedArray?.includes("64S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">64S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('63S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="63S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('63S') }}
                        disabled={checkedArray?.includes("63S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">63S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('16S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('16S') }}
                        disabled={checkedArray?.includes("16S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">16S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('17S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="17S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('17S') }}
                        disabled={checkedArray?.includes("17S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">17S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('18S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="18S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('18S') }}
                        disabled={checkedArray?.includes("18S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">18S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('66S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="66S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('66S') }}
                        disabled={checkedArray?.includes("66S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">66S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('65S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="65S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('65S') }}
                        disabled={checkedArray?.includes("65S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">65S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('19S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="19S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('19S') }}
                        disabled={checkedArray?.includes("19S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">19S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('20S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="20S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('20S') }}
                        disabled={checkedArray?.includes("20S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">20S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('21S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="21S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('21S') }}
                        disabled={checkedArray?.includes("21S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">21S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('68S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="68S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('68S') }}
                        disabled={checkedArray?.includes("68S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">68S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('67S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="67S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('67S') }}
                        disabled={checkedArray?.includes("67S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">67S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('22S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="22S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('22S') }}
                        disabled={checkedArray?.includes("22S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">22S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('23S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="23S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('23S') }}
                        disabled={checkedArray?.includes("23S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">23S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('24S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="24S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('24S') }}
                        disabled={checkedArray?.includes("24S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">24S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('70S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="70S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('70S') }}
                        disabled={checkedArray?.includes("70S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="70S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">70S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('69S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="69S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('69S') }}
                        disabled={checkedArray?.includes("69S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">69S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('25S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="25S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('25S') }}
                        disabled={checkedArray?.includes("25S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">25S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('26S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="26S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('26S') }}
                        disabled={checkedArray?.includes("26S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">26S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('27S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="27S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('27S') }}
                        disabled={checkedArray?.includes("27S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">27S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('72S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="72S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('72S') }}
                        disabled={checkedArray?.includes("72S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">72S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('71S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="71S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('71S') }}
                        disabled={checkedArray?.includes("71S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">71S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="trow">
          <button>Table</button>
          <button>Table</button>
      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('28S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="28S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('28S') }}
                        disabled={checkedArray?.includes("28S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">28S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('29S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="29S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('29S') }}
                        disabled={checkedArray?.includes("29S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">29S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('30S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="30S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('30S') }}
                        disabled={checkedArray?.includes("30S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">30S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('74S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="74S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('74S') }}
                        disabled={checkedArray?.includes("74S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">74S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('73S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="73S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('73S') }}
                        disabled={checkedArray?.includes("73S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">73S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('31S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="31S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('31S') }}
                        disabled={checkedArray?.includes("31S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">31S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  " >
            <div class="box" style={{background:checkedArray?.includes('32S')?'rgb(255, 0, 0)':''}}>
              <div class="row"  >
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="32S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('32S') }}
                        disabled={checkedArray?.includes("32S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">32S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('33S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="33S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('33S') }}
                        disabled={checkedArray?.includes("33S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">33S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('76S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="76S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('76S') }}
                        disabled={checkedArray?.includes("76S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="76S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">76S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('75S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="75S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('75S') }}
                        disabled={checkedArray?.includes("75S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">75S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('34S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="34S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('34S') }}
                        disabled={checkedArray?.includes("34S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">34S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('35S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="35S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('35S') }}
                        disabled={checkedArray?.includes("35S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">35S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('36S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="36S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('36S') }}
                        disabled={checkedArray?.includes("36S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">36S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('78S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="78S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('78S') }}
                        disabled={checkedArray?.includes("78S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">78S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('77S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="77S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('77S') }}
                        disabled={checkedArray?.includes("77S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">77S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('37S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="37S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('37S') }}
                        disabled={checkedArray?.includes("37S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">37S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('38S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="38S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('38S') }}
                        disabled={checkedArray?.includes("38S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">38S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('39S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="39S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('39S') }}
                        disabled={checkedArray?.includes("39S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">39S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('80S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="80S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('80S') }}
                        disabled={checkedArray?.includes("80S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">80S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('79S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="79S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('79S') }}
                        disabled={checkedArray?.includes("79S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">79S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('40S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="40S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('40S') }}
                        disabled={checkedArray?.includes("40S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">40S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('41S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('41S') }}
                        disabled={checkedArray?.includes("41S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">41S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('42S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="42S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('42S') }}
                        disabled={checkedArray?.includes("42S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">42S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('82S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="82S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('82S') }}
                        disabled={checkedArray?.includes("82S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">82S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('81S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="81S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('81S') }}
                        disabled={checkedArray?.includes("81S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">81S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('43S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="43S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('43S') }}
                        disabled={checkedArray?.includes("43S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">43S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('44S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="44S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('44S') }}
                        disabled={checkedArray?.includes("44S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">44S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('45S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="45S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('45S') }}
                        disabled={checkedArray?.includes("45S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">45S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('84S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="84S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('84S') }}
                        disabled={checkedArray?.includes("84S") ? true : false}
                      
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">84S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('83S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="83S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('83S') }}
                        disabled={checkedArray?.includes("83S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">83S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('46S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="46S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('46S') }}
                        disabled={checkedArray?.includes("46S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">46S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('47S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="47S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('47S') }}
                        disabled={checkedArray?.includes("47S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">47S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('48S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="48S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('48S') }}
                        disabled={checkedArray?.includes("48S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">48S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('86S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="86S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('86S') }}
                        disabled={checkedArray?.includes("86S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">86S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('85S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="85S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('85S') }}
                        disabled={checkedArray?.includes("85S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="85S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">85S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('49S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="49S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('49S') }}
                        disabled={checkedArray?.includes("49S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">49S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('50S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="50S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('50S') }}
                        disabled={checkedArray?.includes("50S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">50S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('51S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="51S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('51S') }}
                        disabled={checkedArray?.includes("51S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">51S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('88S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="88S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('88S') }}
                        disabled={checkedArray?.includes("88S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">88S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('87S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="87S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('87S') }}
                        disabled={checkedArray?.includes("87S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">87S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="prow ">
        <div class="left">
          <div class="col-2">
            <div class="box"  style={{background:checkedArray?.includes('52S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="52S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('52S') }}
                        disabled={checkedArray?.includes("52S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">52S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('53S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="53S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('53S') }}
                        disabled={checkedArray?.includes("53S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">53S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('54S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="54S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('54S') }}
                        disabled={checkedArray?.includes("54S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">54S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
         
          <div class="col-2  ">
            <div class="box"  style={{background:checkedArray?.includes('90S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="90S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('90S') }}
                        disabled={checkedArray?.includes("90S") ? true : false}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">90S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div class="box"   style={{background:checkedArray?.includes('89S')?'rgb(255, 0, 0)':''}}>
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="89S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => { onBoxChange('89S') }}
                        disabled={checkedArray?.includes("89S") ? true : false}
                      />
                    </label>
                  </div>
                  {/* this is how you can change things.? */}
                </div>
                <div name="89S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">89S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="trow">
          <button>Table</button>
          <button>Table</button>
      </div>
      <div class="card-body pt-0 row">
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Seat Type</h4>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <select
                id="status"
                value={seatStatus}
                onChange={handleSeatStatus}
                name="status"
                class="form-select form-control wide"
              >
                <option value="">Choose...</option>
                <option value="booked">Booked</option>
                <option value="reserved">Reserved</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Children Seats</h4>
          </div>
          <div class="col-6">
            <input
              type="number"
              value={children}
              class="form-control"
              placeholder="Number of childeren tickets"
              name="children"
              onChange={handleChildrenChange}
            />
          </div>
        </div>
        <button
          type="button"
          name="create_ticket_btn"
          class="btn btn-primary"
          onClick={() => {
            submitSeat();
          }}
        >
          Next
        </button>
      </div>

     
      {/* <div class="card-body pt-0 row">
             
                <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
            </div> */}
    </div>
  );
}

export default AcStd90;
