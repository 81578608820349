import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import DataTable from 'react-data-table-component';

import { getTrainToursByDate, getTourCoaches, deleteTourCoach } from '../../store/actions/tour.actions'
import { allTrains } from "../../store/actions/train.actions";

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { useHistory } from 'react-router-dom';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AllTourCoaches() {
    const [date, setDate] = useState('')
    const history = useHistory()
    const [tableData, setTableData] = useState({});
    const [form, setForm] = useState({
        tour_id: 0,
        train_id: 0
    });
    const [toursByDate, setToursByDate] = useState([]);
    const [rows, setRows] = useState({})
    const [trains, setTrains] = useState([]);

    const columns = [
        {
            name: "Coach",
            selector: "coach_types.name",
            sortable: true,
        },
        {
            name: "Coach Number",
            selector: "coach_number",
            sortable: true

        },
        {
            name: "Is Online",
            selector: "is_online",
            sortable: true,
            cell: (d) =>
            (
                d.is_online ? 'Yes' : 'No'   
            )
        },
        {
            name: "TT",
            selector: "users",
            sortable: true,
            cell: (d) =>
            (
                d.name ? d.name : "NULL"
            )
        },
        {
            name: "Actions",
            selector: "actions",
            cell: (d) =>
            (
                <><button className="btn btn-primary" onClick={() => {
                    history.push({
                        pathname: 'update-tour-coach',
                        state: {
                            id: d.id,
                            tour_id: d.tour_id,
                            coach_type: d.coach_types.id,
                            coach_number: d.coach_number,
                            coach_type_name: d.coach_types.name,
                            is_online:d.is_online
                        }
                    })
                }} type="button"><i class="icon-edit"></i></button>
                    <button class="btn btn-danger" type="button" onClick={() => _deleteTourCoach(d.id)} ><i class="icon-trash" aria-hidden="true"></i></button>
                </>
            )
        }
    ];

    useEffect(() => {
        document.title = "All Tour Coaches | AA Enterprise";
        $('.print').hide()
        fetchTrains();
    }, [])
    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])
    useEffect(() => {
        if (date !== '' && form.train_id) {
            _getTrainToursByDate();
        }
    }, [form.train_id, date])
    useEffect(() => {
        if (form.tour_id) {
            _getTrainCoaches();
        } else {
            setRows([]);
        }
    }, [form.tour_id])


    async function fetchTrains() {
        let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
        if (fetch_trains.status === 200) {
            setTrains(fetch_trains.data.trains);
        } else if (fetch_trains.status >= 400) {
            toast.error(fetch_trains.message.message);
        }
    }

    async function _getTrainToursByDate() {
        let response = await getTrainToursByDate(date, Number(form.train_id));
        if (response.status === 200) {
            if (response.data) {
                document.getElementById("display_date").innerHTML = $(
                    "#select_date"
                )[0].value.substr(0, 10);
                let activeTours = [];
                for (let i = 0; i < response.data.length; i++) {
                    if (!(response.data[i].tour_status === "deactive")) {
                        activeTours.push(response.data[i]);
                    }
                }
                if (activeTours.length > 0) {
                    setToursByDate(activeTours);
                } else {
                    toast.warn("no active tours found");
                }
            } else {
                toast.warn("No tours were found on this date");
                setToursByDate([]);
                setRows([]);
            }
        } else if (response.status >= 400) {
            toast.error(response.message.message);
        }
    }

    async function _getTrainCoaches() {
        if (form.tour_id) {
            let params = {
                _count: 1500,
                _pageNo: 1
            }
            let response = await getTourCoaches(form.tour_id, params)
            if (response.status === 200) {
                setRows(response.data.tourCoaches);
            } else if (response.status >= 400) {
                toast.error("failed");
            }
        } else {
            toast.warn("Select tour");
        }
    }

    async function _deleteTourCoach(id) {
        if (JSON.parse(localStorage.getItem('permissionsNumber').includes(10))) {
            if (window.confirm("Delet this Tour Coach?")) {
                let response = await deleteTourCoach(id);
                if (response.status === 200) {
                    toast.success("Coach Deleted")
                    _getTrainCoaches()
                } else if (response.status >= 400) {
                    toast.error("Deleting Failed");
                }
            }
        } else {
            toast.warn("You are not allowed to perform this action")
        }
    }


    function handleChange(e) {
        console.log("Triggering change")
        const { value, name } = e.target;
        setForm((prevValue) => {
            return {
                ...prevValue,
                [name]: value,
            };
        });
    }
    function selectDateSubmit(e) {
        e.preventDefault();
        setDate($('#select_date')[0].value.substr(0, 10));
        document.getElementById("display_date").innerHTML = $('#select_date')[0].value.substr(0, 10);
    }


    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Tour Coach</h2>
                    <p class="text-light">Add a train tour coach</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Tours</h4>
                        </div>
                        <div class="card-body">
                            <div class="row">

                                <div class="mb-3 col-md-4">
                                    <label class="form-label">Select Train</label>
                                    <select id="trains_select" name="train_id" onChange={handleChange} class="form-select form-control wide" >
                                        <option value="">Choose...</option>
                                        {trains.map((train) => {
                                            return (
                                                <option key={train.key} value={train.id} data-name={train.name} >
                                                    {train.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div class="mb-3 col-md-4">
                                    <div class="basic-form">
                                        <form type="submit" name="fetch_coaches" onSubmit={(e) => selectDateSubmit(e)}>
                                            <div class="row">
                                                <div class="mb-3 col-md-12">
                                                    <label class="form-label">Select Departure Date</label>
                                                    <input id="select_date" type="date" name='depart_time' class="form-control" placeholder="Departure Time" required />
                                                </div>
                                            </div>
                                            <button type="submit" name='fetch_trains_btn' class="btn btn-primary">Select</button>
                                            <div class='row'>
                                                <p>Selected Date: <i id="display_date" style={{ color: 'red' }}></i></p>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="mb-3 col-md-4">
                                    <div class="basic-form">
                                        <div class="row">
                                            <div class="mb-3 col-md-12">
                                                <label class="form-label">Select Tour</label>

                                                <select id="tours_select" name="tour_id" onChange={handleChange} class="form-select form-control wide" >
                                                    <option value="">Choose...</option>
                                                    {toursByDate.map((tour) => {
                                                        return (
                                                            <option key={tour.id} value={tour.id}>
                                                                {tour.name} {tour.depart_time.substr(11, 5)}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Linked Coaches List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        pagination={true}
                                        paginationServer={true}
                                        defaultSortField={'name'}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllTourCoaches
