import Report from '../services/report/report';
import axios from 'axios';

export const downloadPdf = (id) => {

    return Report.downloadPdf(id).then(response => {
        return { status: response.status, data: response.data, message: "Download Success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const reportByStatus = (form) => {

    return Report.reportByStatus(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const reportCreatedAt = (form) => {

    return Report.createdAtReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const reportcityWiseUser = (form) => {

    return Report.cityWiseUserReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const reportPassengerChart = (form) => {

    return Report.passengerChartReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const discountedTicketReport = (form) => {

    return Report.discountedTicketReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const cargoReport = (form) => {

    return Report.cargoReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const cargoIndividualReport = (form) => {

    return Report.cargoIndividualReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const trainEarningReport = (form) => {

    return Report.trainEarningReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const totalEarningReport = (form) => {

    return Report.totalEarningReport(form).then(response => {
        return { status: response.status, data: response.data, message: response.message };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};