import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { addCity, updateCity } from '../../store/actions/city.actions';
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import { allCities, deleteCity } from '../../store/actions/city.actions';
import {addDevice , allDevices , deleteDevice} from '../../store/actions/offline.actions';
import { useHistory } from "react-router-dom";
import $ from 'jquery';

import DataTableExtensions from "react-data-table-component-extensions";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AddCity() {
    
    const [deviceForm, setDeviceForm] = useState({ name: "", mac: "" });
    const [rows, setRows] = useState([])
    let response = [];
    const [totalRows, setTotalRows] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [tableData, setTableData] = useState({})
    const [params, setParams] = useState({
        _count: 15,
        _pageNo: pageNo,
    })
    const history = useHistory()

    const columns = [
        {
            name: "Device Name",
            selector: "name",
            sortable: true
        },
        {
            name: "MAC",
            selector: "mac",
            sortable: true
        },
        {
            name: "Actions",
            selector: "actions",
            cell: (d) =>
            (
                <button className="btn btn-danger" onClick={() => {
                    delDevice(d.id)
                }} type="button"><i class="icon-trash"></i></button>
            )
        }
    ];
    

    async function fetchData() {
        response = await allDevices(params);
        if (response.status === 200) {
            setTotalRows(response.data.count);
            setRows(response.data.devices)
        } else if (response.status >= 400) {
            toast.error(response.message.message)
        }
    }

    async function delDevice(id) {
        let res = await deleteDevice(id);
        if (res.status === 200) {
            toast.success(res.message)
            fetchData()
        } else if (res.status >= 400) {
            toast.error(res.message.message)
        }
    }
    function handleFormChange(event) {
        const { name, value } = event.target;
        setDeviceForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    async function handleFormSubmit(e) {
        e.preventDefault()
        let authentic = authenticate();
        if (authentic) {
            let response = await addDevice(deviceForm);
            
            if (response.status === 200) {
                toast.success("Device Added")
                setDeviceForm({ name: "", mac: "" })
                fetchData()
            } else if (response.status >= 400) {
                toast.error(response.message.message)
            }
        } else {
            toast.warn("Enter valid values")
        }
    }

    function authenticate() {
        if (deviceForm.mac  && deviceForm.name) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        document.title = 'Offline Devices | AA Enterprise';

        
    }, []);

    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _pageNo: pageNo
            }
        })
    }, [pageNo]);

    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])

    useEffect(() => {
        fetchData()
    }, [params]);
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Offline Devices</h2>
                    <p class="text-light">Manage Authorized Devices</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add Device</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form id='myForm' name="add_city_form" onSubmit={(e) => handleFormSubmit(e)}>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Name</label>
                                            <input type="text" name='name' onChange={handleFormChange} value={deviceForm.name} class="form-control" placeholder="device name" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">MAC Address</label>
                                            <input type="text" name='mac' onChange={handleFormChange} value={deviceForm.mac} class="form-control" placeholder="MAC Address" />
                                        </div>
                                    </div>
                                    <button type="submit" name='add_city_btn' class= 'btn btn-primary'>Add Device</button>
                                    <button id='reset' type="reset" style={{ display: 'none' }} />
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Devices List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        pagination={true}
                                        paginationServer={true}
                                        defaultSortField={'name'}
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={15}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPageNo(page)}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCity
