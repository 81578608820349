
import Cargo from '../services/cargo/cargo';

export const addCargo = (form) => {

  return Cargo.addCargoo(form).then(response => {
    return { status: response.status, data: response.data, message: response.message };
  }).catch(e => {
    return { status: e.response.status, message: e.response.data.message };
  })

};

export const getCargoByTrainDate = (form, params) => { 


  return Cargo.getCargoByTrainDate(form, params).then(response => {
    return { status: response.status, data: response.data, message: response.message };
  }).catch(e => {
    return { status: e.response.status, message: e.response.data };
  })

};

export const getCargoById = (id) => {

  return Cargo.getCargoById(id).then(response => {
    return { status: response.status, data: response.data, message: response.message };
  }).catch(e => {
    return { status: e.response.status, message: e.response.data };
  })

};

export const updateCargoById = (data,id) => {

  return Cargo.updateCargoById(data,id).then(response => {
    return { status: response.status, data: response.data, message: response.message };
  }).catch(e => {
    return { status: e.response.status, message: e.response.data };
  })

};


export const deleteCargoById = (id) => {

  return Cargo.deleteCargoById(id).then(response => {
    return { status: response.status, data: response.data, message: response.message };
  }).catch(e => {
    return { status: e.response.status, message: e.response.data };
  })

};