import React, { useEffect, useState } from 'react'
// import jsPDF from 'jspdf'
// import 'jspdf-autotable'
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import { allTrains, deleteTrain } from '../../store/actions/train.actions';
import $ from 'jquery'

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});


function AllTrains() {
    const history = useHistory()
    const columns = [
        {
            name: "Name",
            selector: "name",
            sortable: true
        },
        {
            name: "Display Name",
            selector: "display_name",
            sortable: true
        },
        {
            name: "Number",
            selector: "number",
            sortable: true
        },
        {
            name: 'Actions',
            selector: "actions",
            cell: (d) =>
            (
                <><button className="btn btn-primary" onClick={() => {
                    history.push({
                        pathname: '/add-train',
                        state: {
                            id: d.id,
                            name: d.name,
                            display_name: d.display_name,
                            number: d.number,
                            company_logo: d.company_logo
                        }
                    })
                }} type="button"><i class="icon-edit"></i></button>
                    <button class="btn btn-danger" type="button" onClick={() => delTrain(d.id)} ><i class="icon-trash" aria-hidden="true"></i></button></>
            )
        }
    ];
    const [rows, setRows] = useState([])
    let [totalRows, setTotalRows] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [tableData, setTableData] = useState({})
    const [params, setParams] = useState({
        _count: 15,
        _pageNo: 1,
    })

    useEffect(() => {
        document.title = 'All Trains | AA Enterprise';
        $('.print').hide()
    }, []);
    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _pageNo: pageNo
            }
        })
    }, [pageNo]);
    useEffect(() => {
        fetchData()
    }, [params]);
    // useEffect(() => {
    //     if (JSON.stringify(response) !== '{}') {
    //         renderTableRows();
    //     }
    // }, [response])
    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])

    async function fetchData() {
        let response2 = await allTrains(params);
        if (response2.status === 200) {
            setRows(response2.data.trains)
            setTotalRows(response2.data.count);
        } else if (response2.status >= 400) {
            toast.error(response2.message.message)
        }
    }

    async function delTrain(id) {
        if (JSON.parse(localStorage.getItem('permissionsNumber')).includes(20)) {
            if (window.confirm("Delete Train?")) {
                let res = await deleteTrain(id);
                if (res.status === 200) {
                    toast.success("Train Deleted");
                    fetchData()
                } else if (res.status >= 400) {
                    toast.error(res.message.message)
                }
            }
        } else {
            toast.warn("YOu are not allowed to perform this action")
        }
    }

    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Trains</h2>
                    <p class="text-light">List of all trains</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Train List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        pagination={true}
                                        paginationServer={true}
                                        defaultSortField={'name'}
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={15}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPageNo(page)}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="btn-group btn-group-sm" role="group"><button type="button" class="btn btn-success" onClick={downloadPdf}>Download PDF</button></div> */}
        </>
    )
}

export default AllTrains
