import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import DataTable from "react-data-table-component";
import { allUsers, getUserProfile, updateUser } from '../../store/actions/user.actions';
import $ from 'jquery'

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AllAdmins() {
    const history = useHistory()
    // {
    //     name: "Date",
    //     selector: (row) => row.created_at,
    //     sortable: true,
    //     cell: (d) => 
    //     (
    //         d.created_at.substring(0, 10) + ' ' + d.created_at.substring(11, 19)
    //     )
    // },
    const columns = [
        {
            name: "Name",
            selector: (row) => row.name,
            sortable: true
        },
        {
            name: "Username",
            selector: (row) => row.user_name,
            sortable: true
        },
        {
            name: "Station",
            selector: (row) => row.station,
            sortable: true
        },
        {
            name: "Email",
            selector: (row) => row.email,
            sortable: true
        },
        {
            name: "Phone",
            selector: (row) => row.phone,
            sortable: true
        },
        {
            name: "Due Balance",
            selector: (row) => row.due_balance,
            sortable: true
        },
        {
            name: "Permissions",
            selector: (row) => row.permissions,
            sortable: true
        },
        {
            name: "Designation",
            selector: (row) => row.role,
            sortable: true
        },
        {
            name: "Status",
            selector: (row) => row.status,
            sortable: true,
            cell: (d) =>
            (
                d.status === 0 ? "Disabled" : d.status === 1 ? "Active" : ''

            )
        },
        {
            name: "Actions",
            selector: (row) => row.actions,
            cell: (d) =>
            (
                <><button className="btn btn-primary" onClick={() => {
                    history.push(`/update-admin?id=${d.id}`)
                }} type="button"><i class="icon-edit"></i></button>
                    <button className="btn btn-danger" onClick={() => {
                        _disableUser(d.id);
                    }} type="button"><i class="icon-trash"></i></button></>
            )
        }
    ];

    const [pageNo, setPageNo] = useState(1);
    const [tableData, setTableData] = useState({})
    const [params, setParams] = useState({
        _count: 15,
        _pageNo: pageNo,
    })
    const [rows, setRows] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    let response = [];

    useEffect(() => {
        document.title = 'All Admins | AA Enterprise';
        $('.print').hide()
    }, []);
    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _pageNo: pageNo
            }
        })
    }, [pageNo]);

    useEffect(() => {
        fetchData()
    }, [params]);

    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])

    async function fetchData() {
        response = await allUsers(params);
        if (response.status === 200) {
            setTotalRows(response.data.count);
            console.log("response: ", response.data.users)
            setRows(response.data.users)
        } else if (response.status >= 400) {
            toast.error("Error")
        }
    }

    async function _disableUser(user_id) {
        if (user_id) {
            if (window.confirm("Sure Delete this user?")) {
                let response = await getUserProfile(user_id);
                if (response.status === 200) {
                    let obj = {
                        ...response,
                        status: 0
                    }
                    let updateresponse = await updateUser(obj, user_id)
                    if (updateresponse.status === 200) {
                        toast.success("profile updated successfully");
                        fetchData();
                    } else if (updateresponse.status >= 400) {
                        toast.error(updateresponse.message.message);
                    }
                } else if (response.status >= 400) {
                    toast.error(response.message.message);
                }
            }
        }
    }

    // function tableHead() {
    //     let array = []
    //     for (let i = 0; i < columns.length; i++) {
    //         array.push(columns[i].selector);
    //     }
    //     return array
    // }

    // function tableRows() {
    //     let array = []
    //     let data = response.data.users
    //     for (let i = 0; i < data.length; i++) {
    //         let nestedArray = [];
    //         nestedArray.push(data[i].name)
    //         nestedArray.push(data[i].user_name)
    //         nestedArray.push(data[i].email)
    //         nestedArray.push(data[i].phone)
    //         nestedArray.push(data[i].permissions)
    //         nestedArray.push(data[i].role)
    //         array.push(nestedArray)
    //     }
    //     return array
    // }

    // function downloadPdf() {
    //     const doc = new jsPDF()
    //     doc.autoTable({
    //         head: [tableHead()],
    //         body: tableRows(),
    //     })
    //     doc.save('all_admins.pdf')
    // }


    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Admins</h2>
                    <p class="text-light">List of all admins</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Admin List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        // data={rows}
                                        // columns={columns}
                                        defaultSortField={'name'}
                                        pagination={true}
                                        paginationServer={true}
                                        highlightOnHover
                                        dense
                                        striped
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={15}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPageNo(page)}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="btn-group btn-group-sm" role="group"><button type="button" class="btn btn-success" onClick={downloadPdf}>Download PDF</button></div> */}
        </>
    )
}

export default AllAdmins
