import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import $ from "jquery";
import { allTrains } from "../../store/actions/train.actions";
import { useHistory } from "react-router-dom";
import {
  getTrainCities,
  deleteTrainCity,
} from "../../store/actions/train.actions";

import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AllTrainCities() {
  const history = useHistory();
  const columns = [
    {
      name: "City Name",
      selector: "cities.name",
    },
    {
      name: "Sr. No",
      selector: "sr_no",
      sortable: true,
    },
    {
      name: "Arrival Time",
      selector: "arrival_time",
    },
    {
      name: "Departure Time",
      selector: "departure_time",
    },
    {
      name: "Starting City",
      selector: "is_starting",
    },
    {
      name: "Ending City",
      selector: "is_ending",
    },
    {
      name: "Actions",
      selector: "actions",
      cell: (d) => (
        <>
          <button
            className="btn btn-primary"
            onClick={() => {
              history.push({
                pathname: "/update-train-city",
                state: {
                  id: d.id,
                  name: d.cities.name,
                  sr_no: d.sr_no,
                  arrival_time: d.arrival_time,
                  departure_time: d.departure_time,
                  is_starting: d.is_starting,
                  is_ending: d.is_ending,
                },
              });
            }}
            type="button"
          >
            <i class="icon-edit"></i>
          </button>
          <button
            class="btn btn-danger"
            type="button"
            onClick={() => delCity(d.id)}
          >
            <i class="icon-trash" aria-hidden="true"></i>
          </button>
        </>
      ),
    },
  ];
  const [pageNo, setPageNo] = useState(1);
  let [totalRows, setTotalRows] = useState(0);
  const [trainId, setTrainId] = useState(0);
  const [trains, setTrains] = useState([]);
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState({});
  const [params, setParams] = useState({
    _count: 15,
    _pageNo: pageNo,
  });

  useEffect(() => {
    document.title = "Train Cities | AA Enterprise";
    $(".print").hide();
  }, []);
  useEffect(() => {
    fetchTrains();
  }, [params]);
  useEffect(() => {
    if (trainId) {
      fetchtrainCities();
    }
  }, [trainId]);
  useEffect(() => {
    setTableData({
      columns: columns,
      data: rows,
    });
  }, [rows]);

  async function fetchTrains() {
    let fetch_trains = await allTrains(params);
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function fetchtrainCities() {
    let res = await getTrainCities({ trainId, ...params });
    if (res.status === 200) {
      setRows(res.data.trainCities);
      setTotalRows(res.data.count)
    } else if (res.status >= 400) {
      toast.error(res.message.message);
    }
  }
  async function delCity(id) {
    if (JSON.parse(localStorage.getItem("permissionsNumber")).includes(17)) {
      if (window.confirm("Delete this city from Route?")) {
        let res = await deleteTrainCity(id);
        if (res.status === 200) {
          fetchtrainCities();
        } else if (res.status >= 400) {
          toast.error(res.message.message);
        }
      }
    }
  }
  function selectTrain(e) {
    e.preventDefault();
    setTrainId(document.getElementById("trains_select").value);
    document.getElementById("display_train").innerHTML = $("#trains_select")
      .find("option:selected")
      .text();
  }

  useEffect(() => {
    setParams((prevValue) => {
      return {
        ...prevValue,
        _pageNo: pageNo,
      };
    });
  }, [pageNo]);

  useEffect(() => {
    if (trainId !== 0) {
      fetchtrainCities();
    }
  }, [params]);

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Trains</h2>
          <p class="text-light">Delete cities for train</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Trains</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  type="submit"
                  name="fetch_trains"
                  onSubmit={(e) => selectTrain(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option
                              key={train.key}
                              value={train.id}
                              data-name={train.name}
                            >
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <button
                    type="submit"
                    name="fetch_trains_btn"
                    class="btn btn-primary"
                  >
                    Select
                  </button>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <p>
                        Selected Train is:{" "}
                        <i id="display_train" style={{ color: "red" }}></i>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Cities' List</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <DataTableExtensions {...tableData} exportHeaders={true}>
                  <DataTable
                    pagination={true}
                    paginationServer={true}
                    paginationTotalRows={totalRows}
                    paginationPerPage={15}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPageNo(page)}
                    data={rows}
                    columns={columns}
                    highlightOnHover
                    keyField="id"
                    defaultSortField={"sr_no"}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllTrainCities;
