import Customer from '../services/customer/customer';

export const addCustomer = (form) => {

    return Customer.addCustomer(form).then(response => {
        return { status: response.status, data: response.data, message: "Customer Created" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const allCustomers = (form) => {
    if (!form.gender) {
        form.gender = ''
    }
    if (!form.name) {
        form.name = ''
    }
    if (!form.cnic) {
        form.cnic = ''
    }
    if (!form.id) {
        form.id = ''
    }
    if (!form.phone) {
        form.phone = ''
    }

    return Customer.allCustomers(form).then(response => {
        return { status: response.status, data: response.data, message: "Request Success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const updateCustomer = (form, id) => {

    return Customer.updateCustomer(form, id).then(response => {
        return { status: response.status, data: response.data, message: "Customer Updated successfully" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const getCustomerProfile = (getID) => {

    return Customer.getCustomerProfile(getID).then(response => {
        return { status: response.status, data: response.data, message: "Request Success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const deleteCustomer = (id) => {

    return Customer.deleteCustomer(id).then(response => {
        return { status: response.status, data: response.data, message: "Request Success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const getCustomerProfileByCnic = (cnic) => {

    return Customer.getCustomerProfileByCnic(cnic).then(response => {
        return { status: response.status, data: response.data, message: "Request Success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};
