import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function Open() {
  const layoutSeats = [
    "1OPS",
    "2OPS",
    "3OPS",
    "4OPS",
    "5OPS",
    "6OPS",
    "1OPB",
    "2OPB",
    "3OPB",
    "4OPB",
    "5OPB",
    "6OPB",
  ];
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;
  const [seatArr, setSeatArr] = useState([]);

  const [seatStatus, setSeatStatus] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [children, setChildren] = useState(0);
  const [pastData, setPastData] = useState({
    train_id: 0,
    train_name: "",
    date: "",
    tour_id: 0,
    tour_name: "",
    coachTypeId: 0,
    coach_name: "",
  });
  const [vacantSeats, setVacantSeats] = useState({
    difference: [],
    stop_time: "",
  });

  //checkbox function
  function onBoxChange(value) {
    let arr = seatArr;

    if (arr.includes(value)) {
      arr = arr.filter((e) => e !== value);
    } else {
      arr.push(value);
    }

    setSeatArr([...arr]);
  }

  //initial Hook
  useEffect(() => {
    document.title = "Available Seats | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setPastData({
        train_id: location.state.train_id,
        train_name: location.state.train_name,
        date: location.state.date,
        tour_id: location.state.tourId,
        tour_name: location.state.tour_name,
        coachTypeId: location.state.coachTypeId,
        coach_name: location.state.coach_name,
        coach_number: location.state.coach_number,
        starting_city_id: location.state.starting_city_id,
        starting_city_name: location.state.starting_city_name,
        ending_city_id: location.state.ending_city_id,
        ending_city_name: location.state.ending_city_name,
        status: location.state.status,
        cnic: location.state.cnic,
      });
    } else {
      history.push("/add-ticket");
    }
  }, []);

  async function _getVacantSeats() {
    let response = await getVacantSeats(
      pastData.coachTypeId,
      pastData.starting_city_id,
      pastData.ending_city_id
    );
    if (response.status === 200) {
      setVacantSeats(response.data);
    } else if (response.status >= 400) {
      toast.error("Failed");
    }
  }

  function _setBookedSeats() {
    let arr2 = vacantSeats.difference;
    let difference = layoutSeats.filter((x) => !arr2.includes(x));
    setCheckedArray(difference);
  }

  useEffect(() => {
    if (pastData.coachTypeId) {
      _getVacantSeats();
    }
  }, [pastData.coachTypeId]);

  useEffect(() => {
    if (pastData.status || pastData.status !== undefined) {
      setSeatStatus(pastData.status);
      $("#status option:first").text(location.state.status);
      $("#status option:first").val(location.state.status.toLowerCase());
    }
  }, [pastData.status]);

  //Hook to run after vacant seats are fecthed from server
  useEffect(() => {
    if (vacantSeats.stop_time !== "") {
      //_setBookedSeats();
    }
  }, [vacantSeats.stop_time]);

  

  function handleSeatStatus(e) {
    const { value } = e.target;
    setSeatStatus(value);
  }

  function handleChildrenChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (value >= 0) {
      setChildren(Number(value));
    } else {
      toast.warn("Enter valid number");
    }
  }

  function submitSeat() {
    if ((children || seatArr.length) && seatStatus !== "") {
      history.push({
        pathname: "add-ticket-customer",
        state: {
          tc_id: pastData.coachTypeId,
          st_city: pastData.starting_city_id,
          end_city: pastData.ending_city_id,
          toBookSeats: seatArr,
          status: seatStatus,
          children: children,
          stateData,
        },
      });
    } else {
      toast.warn("Please select all values");
    }
  }

  

  return (
    <div style={{overflowX: 'auto'}}s>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="oprow ">
        <div class="left ac90">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("1OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("2OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("3OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("4OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("5OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6OPS")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6OPS") ? true : false}
                        onChange={() => {
                          onBoxChange("6OPS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">6S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 2st row */}
      <div class="oprow ">
        <div class="left ac90">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("1OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("1OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("2OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">2B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("3OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("4OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="4B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">4B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("5OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="5B" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5B</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6OPB")
                  ? "rgb(255, 214, 214)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6OPB") ? true : false}
                        onChange={() => {
                          onBoxChange("6OPB");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">6B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body pt-0 row">
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Seat Type</h4>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <select
                id="status"
                value={seatStatus}
                onChange={handleSeatStatus}
                name="status"
                class="form-select form-control wide"
              >
                <option value="">Choose...</option>
                <option value="booked">Booked</option>
                <option value="reserved">Reserved</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Children Seats</h4>
          </div>
          <div class="col-6">
            <input
              type="number"
              value={children}
              class="form-control"
              placeholder="Number of childeren tickets"
              name="children"
              onChange={handleChildrenChange}
            />
          </div>
        </div>
        <button
          type="button"
          name="create_ticket_btn"
          class="btn btn-primary"
          onClick={() => {
            submitSeat();
          }}
        >
          Next
        </button>
      </div>

      {/* <div class="card-body pt-0 row">
             
                <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
            </div> */}
    </div>
  );
}

export default Open;
