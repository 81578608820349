import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom';
import jsCookie from 'js-cookie';
import { logout } from '../../../store/actions/user.actions';
import { isAuthenticUser } from '../../../utils/validateUser';

function Header() {
    let history = useHistory();
    let [out, setOut] = useState(false);
    let [user, setUser] = useState({});

    useEffect(() => {
        if (isAuthenticUser()) {
            setUser(JSON.parse(localStorage.getItem('user')).user);
        }
    }, [])

    async function callLogout() {
        jsCookie.remove('login');
        let response = await logout();
        if (response.status === 200) {
            localStorage.removeItem('user');
            localStorage.removeItem('permissions');
            localStorage.removeItem('permissionsArray');
            localStorage.removeItem('permissionsNumber');
            history.push('/login');
        } else if (response.status >= 400) {
            alert(response.message.message)
        }
    }

    useEffect(() => {
        if (out) {
            callLogout()
        }
    }, [out])

    return (
        <>
            {/* Header Start */}
            <div class="header">
                <div class="header-content">
                    <nav class="navbar navbar-expand">
                        <div class="collapse navbar-collapse justify-content-between">
                            <div class="header-left">
                            </div>
                            <ul class="navbar-nav header-right">
                                <li class="nav-item dropdown header-profile">
                                    <a class="nav-link" href="javascript:void(0);" role="button" data-bs-toggle="dropdown">
                                        {user.name && (<div class="header-info me-3">
                                            <span class="fs-16 font-w600 ">{user.name}</span>
                                            <small class="text-end fs-14 font-w400">{user.role}</small>
                                        </div>)}
                                        <img src="images/profile/pic1.jpg" width="20" alt="" />
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                        <Link to="/reset" className="dropdown-item ai-icon">
                                            <svg id="icon-user1" xmlns="http://www.w3.org/2000/svg" class="text-primary" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
                                            <span class="ms-2">Change Password</span>
                                        </Link>
                                        <button class="dropdown-item ai-icon" onClick={() => { setOut(true) }}>
                                            <svg id="icon-logout" xmlns="http://www.w3.org/2000/svg" class="text-danger" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                            <span class="ms-2">Logout </span>
                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
            {/* Header End */}
        </>
    )
}

export default Header;
