import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { addCoach, updateCoach } from '../../store/actions/coach.actions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});


function AddCoach() {
    const location = useLocation()
    const history = useHistory()
    const [updateId, setUpdateId] = useState(0);
    const [coachForm, setCoachForm] = useState({
        name: '',
        capacity: ''
    })

    useEffect(() => {
        document.title = 'Add Coach | AA Enterprise';

        if (JSON.stringify(location.state) !== undefined) {
            setUpdateId(Number(location.state.id));
            setCoachForm({ name: location.state.name, capacity: Number(location.state.capacity) })
        }
    }, []);

    function handleFormChange(event) {
        const { name, value } = event.target;
        setCoachForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    async function handleFormSubmit(e) {
        e.preventDefault()
        let authentic = authenticate();
        if (authentic) {
            let response;
            if (updateId) {
                response = await updateCoach(coachForm, updateId);
            } else {
                response = await addCoach(coachForm);
            }
            if (response.status === 200) {
                if (updateId) {
                    toast.success("Coach Updated")
                    history.push('/all-coaches')
                } else {
                    toast.success("Coach added")
                    setCoachForm({ name: "", capacity: "" })
                }
            } else if (response.status >= 400) {
                toast.error("Some Error")
            }
        } else {
            toast.warn("Enter valid values")
        }
    }

    function authenticate() {
        const digits_only = string => [...string].every(c => '0123456789'.includes(c));
        if (coachForm.capacity && digits_only(coachForm.capacity) && coachForm.name) {
            return true
        } else {
            return false
        }
    }

    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Coaches</h2>
                    <p class="text-light">{updateId ? "Update the coach" : "Add a new coach"}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{updateId ? "Update Coach" : "Create New Coach"}</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">

                                <form type="submit" name="create_coach_form" onSubmit={(e) => handleFormSubmit(e)}>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Coach Type</label>
                                            <input type="text" name='name' onChange={handleFormChange} value={coachForm.name} class="form-control" placeholder="coach type ..." />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Capacity</label>
                                            <input type="text" name='capacity' onChange={handleFormChange} value={coachForm.capacity} class="form-control" placeholder="passenger capacity" />
                                        </div>
                                    </div>
                                    <button type="submit" name='create_coach_btn' class={updateId ? "btn btn-success" : "btn btn-primary"}>{updateId ? "Update" : "Submit"}</button>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCoach
