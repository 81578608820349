import axiosInstance from '../../../utils/axios';

class Transaction {
   
    static addTransaction = (form) => {
    
        let apiPath = '/api/trans';
        return axiosInstance.post(apiPath , {
            ...form
        });
    
    };
    
    static allTransactions = (params) => {
    
        let apiPath = '/api/trans?_count='+parseInt(params._count)+'&_pageNo='+parseInt(params._pageNo);
        return axiosInstance.get(apiPath);
    
    };
    
    static deleteTransaction = (id) => {
    
        let apiPath = '/api/trans?'+Number(id);
        return axiosInstance.delete(apiPath);
    
    };
    
}
export default Transaction;