import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./EconomyGhouri.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function EconomyGhouri() {
  

  function bookTicket(e) {
    
  }

  
  return (
    <>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="1S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("1S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="1S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>1S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="2S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("2S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="2S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>2S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="3S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("3S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="3S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>3S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="4S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("4S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="4S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>4S</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-2 bg offset-2 bg-white"
          style={{ border: "2px solid black" }}
        >
          <p class="text-uppercase text-center empty">Empty</p>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="5S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("5S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="5S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>5S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="6S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("6S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="6S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>6S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="7S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("7S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="7S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>7S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="8S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("8S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="8S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>8S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="81S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("81S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="81S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>81S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="9S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("9S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="9S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>9S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="10S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("10S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="10S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>10S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="11S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("11S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="11S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>11S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="12S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("12S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="12S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>12S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="82S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("82S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="82S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>82S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="13S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("13S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="13S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>13S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="14S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("14S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="14S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>14S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="15S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("15S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="15S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>15S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="16S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("16S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="16S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>16S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="83S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("83S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="83S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>83S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="17S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("17S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="17S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>17S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="18S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("18S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="18S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>18S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="19S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("19S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="19S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>19S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="20S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("20S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="20S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>20S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="84S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("84S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="84S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>84S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="21S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("21S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="21S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>21S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="22S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("22S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="22S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>22S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="23S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("23S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="23S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>23S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="24S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("24S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="24S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>24S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="85S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("85S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="85S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>85S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="25S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("25S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="25S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>25S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="26S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("26S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="26S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>26S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="27S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("27S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="27S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>27S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="28S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("28S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="28S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>28S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="86S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("86S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="86S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>86S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="29S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("29S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="29S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>29S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="30S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("30S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="30S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>30S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="31S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("31S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="31S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>31S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="32S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("32S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="32S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>32S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="87S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("87S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="87S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>87S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="33S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("33S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="33S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>33S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="34S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("34S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="34S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>34S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="35S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("35S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="35S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>35S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="36S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("36S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="36S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>36S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="88S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("88S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="88S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>88S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="37S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("37S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="37S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>37S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="38S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("38S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="38S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>38S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="39S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("39S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="39S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>39S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="40S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("40S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="40S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>40S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="89S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("89S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="89S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>89S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="41S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("41S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="41S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>41S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="42S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("42S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="42S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>42S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="43S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("43S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="43S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>43S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="44S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("44S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="44S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>44S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="90S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("90S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="90S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>90S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="45S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("45S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="45S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>45S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="46S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("46S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="46S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>46S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="47S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("47S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="47S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>47S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="48S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("48S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="48S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>48S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="91S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("91S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="91S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>91S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="49S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("49S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="49S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>49S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="50S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("50S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="50S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>50S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="51S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("51S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="51S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>51S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="52S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("52S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="52S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>52S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="92S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("92S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="92S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>92S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="53S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("53S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="53S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>53S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="54S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("54S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="54S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>54S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="55S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("55S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="55S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>55S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="56S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("56S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="56S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>56S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="93S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("93S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="93S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>93S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="57S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("57S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="57S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>57S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="58S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("58S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="58S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>58S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="59S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("59S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="59S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>59S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="60S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("60S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="60S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>60S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="94S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("94S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="94S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>94S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="61S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("61S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="61S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>61S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="62S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("62S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="62S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>62S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="63S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("63S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="63S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>63S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="64S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("64S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="64S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>64S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="95S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("95S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="95S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>95S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="65S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("65S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="65S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>65S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="66S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("66S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="66S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>66S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="67S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("67S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="67S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>67S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="68S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("68S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="68S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>68S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="96S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("96S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="96S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>96S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="69S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("69S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="69S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>69S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="70S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("70S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="70S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>70S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="71S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("71S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="71S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>71S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="72S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("72S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="72S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>72S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="97S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("97S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="97S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>97S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="73S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("73S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="73S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>73S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="74S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("74S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="74S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>74S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="75S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("75S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="75S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>75S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="76S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("76S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="76S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>76S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-2">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="98S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("98S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="98S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>98S</h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="77S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("77S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="77S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>77S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="78S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("78S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="78S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>78S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="79S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("79S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="79S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>79S</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box">
            <div class="row">
              <div class="col-4">
                <div class="form-check">
                  <label class="form-check-label p">
                    <input
                      id="80S"
                      type="checkbox"
                      class="form-check-input"
                      onClick={() => {
                        bookTicket("80S");
                      }}
                    />
                  </label>
                </div>
              </div>
              <div name="80S" class="col-4">
                <img src={seat} alt="seat-img" class="center" />
              </div>
              <div class="col-4">
                <h5>80S</h5>
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-2 bg offset-2 bg-white"
          style={{ border: "2px solid black" }}
        >
          <p class="text-uppercase text-center empty">Empty</p>
        </div>
      </div>
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="1B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("1B");
                  }}
                />
              </label>
            </div>
            <div name="1B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>1B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="2B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("2B");
                  }}
                />
              </label>
            </div>
            <div name="2B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>2B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="3B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("3B");
                  }}
                />
              </label>
            </div>
            <div name="3B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>3B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="4B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("4B");
                  }}
                />
              </label>
            </div>
            <div name="4B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>4B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="5B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("5B");
                  }}
                />
              </label>
            </div>
            <div name="5B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>5B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="6B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("6B");
                  }}
                />
              </label>
            </div>
            <div name="6B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>6B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="7B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("7B");
                  }}
                />
              </label>
            </div>
            <div name="7B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>7B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="8B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("8B");
                  }}
                />
              </label>
            </div>
            <div name="8B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>8B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="9B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("9B");
                  }}
                />
              </label>
            </div>
            <div name="9B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>9B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="10B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("10B");
                  }}
                />
              </label>
            </div>
            <div name="10B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>10B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="11B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("11B");
                  }}
                />
              </label>
            </div>
            <div name="11B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>11B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="12B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("12B");
                  }}
                />
              </label>
            </div>
            <div name="12B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>12B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="13B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("13B");
                  }}
                />
              </label>
            </div>
            <div name="13B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>13B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="14B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("14B");
                  }}
                />
              </label>
            </div>
            <div name="14B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>14B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="15B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("15B");
                  }}
                />
              </label>
            </div>
            <div name="15B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>15B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="16B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("16B");
                  }}
                />
              </label>
            </div>
            <div name="16B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>16B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="17B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("17B");
                  }}
                />
              </label>
            </div>
            <div name="17B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>17B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>

        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="18B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("18B");
                  }}
                />
              </label>
            </div>
            <div name="18B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>18B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-2 bg-berth"></div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-3">Entrance</h4>
        </div>
      </div>

      <div class="row row-border-bottom">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

     
    </>
  );
}

export default EconomyGhouri;
