import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { addTrain, updateTrain } from '../../store/actions/train.actions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});


function AddTrain() {

    const location = useLocation()
    const [updateId, setUpdateId] = useState(0);
    const history = useHistory()
    const [trainForm, setTrainForm] = useState({
        name: '',
        display_name: '',
        number: '',
        company_logo: {}
    });
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()


    useEffect(() => {
        document.title = 'Add Train | AA Enterprise'
    }, [trainForm])
    useEffect(() => {
        if (JSON.stringify(location.state) !== undefined) {
            setUpdateId(Number(location.state.id));
            setTrainForm({ name: location.state.name, display_name: location.state.display_name, number: location.state.number, company_logo: location.state.company_logo })
        }
    }, [])
    useEffect(() => {
        setTrainForm(prevValue => {
            return {
                ...prevValue,
                company_logo: selectedFile,
            }
        })
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    function handleFormChange(e) {
        const { name, value } = e.target;
        setTrainForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    async function handleFormSubmit(e) {
        e.preventDefault()
        let authentic = authenticate();
        if (authentic) {
            let response;
            if (updateId) {
                response = await updateTrain(trainForm, updateId);
            } else {
                response = await addTrain(trainForm);
            }
            if (response.status === 200) {
                if (updateId) {
                    toast.success("Train Updated");
                    setUpdateId(0)
                    history.push("/all-trains")
                } else {
                    toast.success("Train added")
                    setTrainForm({
                        name: '',
                        display_name: '',
                        number: '',
                        company_logo: ''
                    })
                    setSelectedFile('')
                    setUpdateId(0)
                }
                // history.push(`/train-cities?train_id=${response.data.id}`)
            } else if (response.status >= 400) {
                toast.error("Some Error")
            }
        } else {
            toast.warn("Enter valid values")
        }
    }

    function authenticate() {
        if (trainForm.name && trainForm.display_name && trainForm.number) {
            return true
        } else {
            return false
        }
    }
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Trains</h2>
                    <p class="text-light">{updateId ? "Update the train" : "Add a new train"}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{updateId ? "Update Train" : "Create New train"}</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form type="submit" name="create_train_form" onSubmit={(e) => handleFormSubmit(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Name</label>
                                            <input type="text" name='name' onChange={handleFormChange} value={trainForm.name} class="form-control" placeholder="train name" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Display name</label>
                                            <input type="text" name='display_name' onChange={handleFormChange} value={trainForm.display_name} class="form-control" placeholder="to display on Ticket etc..." />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Number</label>
                                            <input type="text" name='number' onChange={handleFormChange} value={trainForm.number} class="form-control" placeholder="train number" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Company logo</label>
                                            <input id='file' type="file" name='company_logo' class="form-control" placeholder="logo" alt='company logo' onChange={onSelectFile} />
                                            <label class="form-label">Preview:</label>
                                            {selectedFile && <img src={preview} alt='imgPreview' />}
                                        </div>

                                    </div>
                                    <button type="submit" name='create_train_btn' class={updateId ? "btn btn-success" : "btn btn-primary"}>{updateId ? "Update" : "Submit"}</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTrain
