import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { updateUser, addUser } from "../../store/actions/user.actions";
import { allTrains } from "../../store/actions/train.actions";

function AdminPermissions() {
  const location = useLocation();
  const history = useHistory();
  const [permissionsArray, setPermissionsArray] = useState([]);
  const [pastData, setPastData] = useState({});
  const [updateId, setUpdateId] = useState(0);
  const [trains, setTrains] = useState([]);
  const [allowedTrains, setAllowedTrains] = useState([]);

  useEffect(() => {
    document.title = "Admin Permissions | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      let data = location.state;
      console.log("state data is ", data);
      let admin = {
        name: data.name,
        user_name: data.user_name,
        email: data.email,
        phone: data.phone,
        password: data.password,
        role: data.role,
        updateId: data.updateId,
        station: data.station,
        printer_type: data.printer_type,
      };
      if (!admin.password) {
        delete admin.password;
      }
      setPastData(admin);
      if (data.updateId) {
        setPermissionsArray(JSON.parse(data.permissions));
        setAllowedTrains(JSON.parse(data.allowed_trains));
      }
      setUpdateId(data.updateId);
      fetchTrains();
    } else {
      history.push("/add-admin");
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(pastData.permissions) !== undefined) {
      _addAdmin();
    }
  }, [pastData.permissions]);

  useEffect(() => {
    console.log("new array is", permissionsArray);
  }, [permissionsArray]);

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function _addAdmin() {
    let response = "";
    if (updateId) {
      response = await updateUser(pastData, updateId);
    } else {
      response = await addUser(pastData);
    }
    if (response.status === 200) {
      if (updateId) {
        toast.success("Admin Updated");
      } else {
        toast.success("New admin created");
      }
      history.push("/add-admin");
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }

  function onSubmitForm(e) {
    e.preventDefault();
    if (permissionsArray.length > 0) {
      if (pastData.password) {
        setPastData({
          name: pastData.name,
          user_name: pastData.user_name,
          email: pastData.email,
          password: pastData.password,
          phone: pastData.phone,
          role: pastData.role,
          permissions: JSON.stringify(permissionsArray),
          station: pastData.station,
          allowed_trains: JSON.stringify(allowedTrains),
          printer_type: pastData.printer_type,
        });
      } else {
        setPastData({
          name: pastData.name,
          user_name: pastData.user_name,
          email: pastData.email,
          password: pastData.password,
          phone: pastData.phone,
          role: pastData.role,
          permissions: JSON.stringify(permissionsArray),
          station: pastData.station,
          allowed_trains: JSON.stringify(allowedTrains),
          printer_type: pastData.printer_type,
        });
      }
    } else {
      toast.warn("please add permissions for admin");
    }
  }

  function checkValue(e) {
    let permission = Number(e.target.name);
    if (e.target.checked) {
      //Add / True
      setPermissionsArray((prevArray) => [...prevArray, permission]);
    } else {
      //Remove / False
      setPermissionsArray(permissionsArray.filter((item) => item !== permission));
    }
  }

  function checkTrainValue(e) {
    let permission = Number(e.target.name);
    if (e.target.checked) {
      //Add / True
      setAllowedTrains((prevArray) => [...prevArray, permission]);
    } else {
      //Remove / False
      setAllowedTrains(allowedTrains.filter((item) => item !== permission));
    }
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Admins</h2>
          <p class="text-light">{updateId ? "Update Admin Permissions" : "Add Admin permissions"}</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">{updateId ? "Update Admin" : "Add New Admin"}</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="row mb-4">
                      <div class="col-6 text-center">Permissions List</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <label class="form-label">Create</label>
                          </div>
                          <div class="col-3">
                            <label class="form-label">Read</label>
                          </div>
                          <div class="col-3">
                            <label class="form-label">Update</label>
                          </div>
                          <div class="col-3">
                            <label class="form-label">Delete</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center">Admin Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(1)}
                                checked={permissionsArray.includes(1)}
                                name="1"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(2)}
                                checked={permissionsArray.includes(2)}
                                name="2"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(3)}
                                checked={permissionsArray.includes(3)}
                                name="3"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(4)}
                                checked={permissionsArray.includes(4)}
                                name="4"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center">Transaction Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(5)}
                                checked={permissionsArray.includes(5)}
                                name="5"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(6)}
                                checked={permissionsArray.includes(6)}
                                name="6"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(7)}
                                checked={permissionsArray.includes(7)}
                                name="7"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(8)}
                                checked={permissionsArray.includes(8)}
                                name="8"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center">Tour Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(9)}
                                checked={permissionsArray.includes(9)}
                                name="9"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(10)}
                                checked={permissionsArray.includes(10)}
                                name="10"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(11)}
                                checked={permissionsArray.includes(11)}
                                name="11"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(12)}
                                checked={permissionsArray.includes(12)}
                                name="12"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center">Ticket Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(13)}
                                checked={permissionsArray.includes(13)}
                                name="13"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(14)}
                                checked={permissionsArray.includes(14)}
                                name="14"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(15)}
                                checked={permissionsArray.includes(15)}
                                name="15"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(16)}
                                checked={permissionsArray.includes(16)}
                                name="16"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center">Train Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(17)}
                                checked={permissionsArray.includes(17)}
                                name="17"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(18)}
                                checked={permissionsArray.includes(18)}
                                name="18"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(19)}
                                checked={permissionsArray.includes(19)}
                                name="19"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(20)}
                                checked={permissionsArray.includes(20)}
                                name="20"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 text-center">Pref Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3"></div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(30)}
                                checked={permissionsArray.includes(30)}
                                name="30"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(31)}
                                checked={permissionsArray.includes(31)}
                                name="31"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3"></div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center">Cargo Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(25)}
                                checked={permissionsArray.includes(25)}
                                name="25"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(26)}
                                checked={permissionsArray.includes(26)}
                                name="26"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(27)}
                                checked={permissionsArray.includes(27)}
                                name="27"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(28)}
                                checked={permissionsArray.includes(28)}
                                name="28"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <div class="col-6 text-center">Special Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <label class="form-label">Financial Reports</label>
                          </div>
                          <div class="col-3">
                            <label class="form-label">Administrative Reports</label>
                          </div>
                          <div class="col-3">Copy Fares</div>
                          <div class="col-3">
                            <label class="form-label">Ticket Discount</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center"></div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(21)}
                                checked={permissionsArray.includes(21)}
                                name="21"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(22)}
                                checked={permissionsArray.includes(22)}
                                name="22"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-danger">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(48)}
                                checked={permissionsArray.includes(48)}
                                name="48"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(23)}
                                checked={permissionsArray.includes(23)}
                                name="23"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <div class="col-6 text-center">Operational Permissions</div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <label class="form-label">Copy Tour </label>
                          </div>
                          <div class="col-6">
                            <label class="form-label">Bulk Update Fares</label>
                          </div>

                          <div class="col-3">Online Ticket</div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6 text-center"></div>
                      <div class="col-6">
                        <div class="row">
                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(41)}
                                checked={permissionsArray.includes(41)}
                                name="41"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(42)}
                                checked={permissionsArray.includes(42)}
                                name="42"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>

                          <div class="col-3">
                            <div class="form-check custom-checkbox mb-3 checkbox-success">
                              <input
                                type="checkbox"
                                value={permissionsArray.includes(50)}
                                checked={permissionsArray.includes(50)}
                                name="50"
                                onChange={checkValue}
                                class="form-check-input"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-5">
                      <div class="col-6 text-center">Trains Allowed</div>

                      <div class="col-6">
                        <div class="row">
                          <table class="table table-responsive table-bordered table-striped">
                            {trains.map((train) => {
                              return (
                                <tr>
                                  <td>
                                    <div class="form-check custom-checkbox mb-3 checkbox-success">
                                      <input
                                        type="checkbox"
                                        value={allowedTrains.includes(train["id"])}
                                        checked={allowedTrains.includes(train["id"])}
                                        name={train["id"]}
                                        onChange={checkTrainValue}
                                        class="form-check-input"
                                      />
                                    </div>
                                  </td>
                                  <td>{train.name}</td>
                                </tr>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" name="submit" class={updateId ? "btn btn-success" : "btn btn-primary"}>
                    {updateId ? "Update" : "Submit"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPermissions;
