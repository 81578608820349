import Fare from '../services/fare/fare';

export const getPendingFareByTrain = (id , typeId) => {

    return Fare.getPendingFareByTrain(id , typeId).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const addTrainFare = (form) => {

    return Fare.addTrainFare(form).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getTrainFares = (data, params) => {

    return Fare.getTrainFares(data, params).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteTrainFare = (id) => {

    return Fare.deleteTrainFare(id).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteMultipleTrainFares = (fareIds) => {

    return Fare.deleteMultipleTrainFares(fareIds).then(response => {
        return {status: response.status,data:response.data, message: response.data.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const updateTrainfare = (form, id) => {

    return Fare.updateTrainfare(form, id).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const copyFares = (form) => {

    return Fare.copyTrainFares(form).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getFareById = (id) => {

    return Fare.getById(id).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const bulkUpdateFares = (data) => {

    return Fare.bulkUpdate(data).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};


export const getTicketFareByData = (data) => {

    return Fare.getTicketFare(data).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};