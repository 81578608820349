import Transaction from '../services/transaction/transaction';

export const addTransaction = (form) => {

    return Transaction.addTransaction(form).then(response => {
        return {status: response.status,data:response.data, message: "Transaction Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const allTransactions = (params) => {

    return Transaction.allTransactions(params).then(response => {
        return {status: response.status,data:response.data, message: "Transaction Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteTransaction = (id) => {

    return Transaction.deleteTransaction(id).then(response => {
        return {status: response.status,data:response.data, message: "Transaction Deleted"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};