import axiosInstance from '../../../utils/axios';

class City {
    
    static addCity = (form) => {
    
        let apiPath = '/api/cities';
        return axiosInstance.post(apiPath , {
            ...form
        });
    
    };

    static allCities = (params) => {

        let apiPath = `/api/cities?_count=${params._count}&_pageNo=${params._pageNo}`;
        return axiosInstance.get(apiPath).then(response => {return response})
    
    };

    static deleteCity = (id) => {
    
        let apiPath = '/api/cities/'+parseInt(id);
        return axiosInstance.delete(apiPath).then(response => {return response})
    
    };
    
    static getCityProfile = (id) => {

        let apiPath = '/api/cities/'+parseInt(id);
        return axiosInstance.get(apiPath)
    
    };
    
    static updateCity = (form, id) => {
    
        let apiPath = '/api/cities/'+id;
        return axiosInstance.put(apiPath , {
            ...form
        });
    
    };
    
}
export default City;