import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function Economy2() {
  const layoutSeats = [
    "4S",
    "3S",
    "2S",
    "1S",
    "81S",
    "8S",
    "7S",
    "6S",
    "5S",
    "82S",
    "12S",
    "11S",
    "10S",
    "9S",
    "83S",
    "16S",
    "15S",
    "14S",
    "13S",
    "84S",
    "20S",
    "19S",
    "18S",
    "17S",
    "85S",
    "24S",
    "23S",
    "22S",
    "21S",
    "86S",
    "28S",
    "27S",
    "26S",
    "25S",
    "87S",
    "32S",
    "31S",
    "30S",
    "29S",
    "88S",
    "36S",
    "35S",
    "34S",
    "33S",
    "89S",
    "40S",
    "39S",
    "38S",
    "37S",
    "90S",
    "44S",
    "43S",
    "42S",
    "41S",
    "91S",
    "48S",
    "47S",
    "46S",
    "45S",
    "92S",
    "52S",
    "51S",
    "50S",
    "49S",
    "93S",
    "56S",
    "55S",
    "54S",
    "53S",
    "94S",
    "60S",
    "59S",
    "58S",
    "57S",
    "95S",
    "64S",
    "63S",
    "62S",
    "61S",
    "96S",
    "68S",
    "67S",
    "66S",
    "65S",
    "97S",
    "72S",
    "71S",
    "70S",
    "69S",
    "98S",
    "76S",
    "75S",
    "74S",
    "73S",
    "80S",
    "79S",
    "78S",
    "77S",
    "UP1B",
    "UP2B",
    "UP3B",
    "UP4B",
    "UP5B",
    "UP6B",
    "UP7B",
    "UP8B",
    "UP9B",
    "UP10B",
    "UP11B",
    "UP12B",
    "UP13B",
    "UP14B",
    "UP15B",
    "UP16B",
    "UP17B",
    "UP18B",
    "UP19B",
    "UP20B",
  ];
  const history = useHistory();
  const location = useLocation();
  let stateData = location.state;
  const [seatArr, setSeatArr] = useState([]);

  const [seatStatus, setSeatStatus] = useState("");
  const [checkedArray, setCheckedArray] = useState([]);
  const [children, setChildren] = useState(0);
  const [pastData, setPastData] = useState({
    train_id: 0,
    train_name: "",
    date: "",
    tour_id: 0,
    tour_name: "",
    coachTypeId: 0,
    coach_name: "",
  });
  const [vacantSeats, setVacantSeats] = useState({
    difference: [],
    stop_time: "",
  });

  //checkbox function
  function onBoxChange(value) {
    let arr = seatArr;

    if (arr.includes(value)) {
      arr = arr.filter((e) => e !== value);
    } else {
      arr.push(value);
    }

    setSeatArr([...arr]);
  }

  //initial Hook
  useEffect(() => {
    document.title = "Available Seats | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setPastData({
        train_id: location.state.train_id,
        train_name: location.state.train_name,
        date: location.state.date,
        tour_id: location.state.tourId,
        tour_name: location.state.tour_name,
        coachTypeId: location.state.coachTypeId,
        coach_name: location.state.coach_name,
        coach_number: location.state.coach_number,
        starting_city_id: location.state.starting_city_id,
        starting_city_name: location.state.starting_city_name,
        ending_city_id: location.state.ending_city_id,
        ending_city_name: location.state.ending_city_name,
        status: location.state.status,
        cnic: location.state.cnic,
      });
    } else {
      history.push("/add-ticket");
    }
  }, []);

  async function _getVacantSeats() {
    let response = await getVacantSeats(
      pastData.coachTypeId,
      pastData.starting_city_id,
      pastData.ending_city_id
    );
    if (response.status === 200) {
      setVacantSeats(response.data);
      console.log("vacant seats are", response);
    } else if (response.status >= 400) {
      toast.error("Failed");
    }
  }

  function _setBookedSeats() {
    let arr2 = vacantSeats.difference;
    let difference = layoutSeats.filter((x) => !arr2.includes(x));
    setCheckedArray(difference);
  }

  useEffect(() => {
    if (pastData.coachTypeId) {
      _getVacantSeats();
    }
  }, [pastData.coachTypeId]);

  useEffect(() => {
    if (pastData.status || pastData.status !== undefined) {
      setSeatStatus(pastData.status);
      $("#status option:first").text(location.state.status);
      $("#status option:first").val(location.state.status.toLowerCase());
    }
  }, [pastData.status]);

  //Hook to run after vacant seats are fecthed from server
  useEffect(() => {
    if (vacantSeats.stop_time !== "") {
      _setBookedSeats();
    }
  }, [vacantSeats.stop_time]);

  //Test Hook

  function handleSeatStatus(e) {
    const { value } = e.target;
    setSeatStatus(value);
  }

  function handleChildrenChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (value >= 0) {
      setChildren(Number(value));
    } else {
      toast.warn("Enter valid number");
    }
  }

  function submitSeat() {
    if ((children || seatArr.length) && seatStatus !== "") {
      history.push({
        pathname: "add-ticket-customer",
        state: {
          tc_id: pastData.coachTypeId,
          st_city: pastData.starting_city_id,
          end_city: pastData.ending_city_id,
          toBookSeats: seatArr,
          status: seatStatus,
          children: children,
          stateData,
        },
      });
    } else {
      toast.warn("Please select all values");
    }
  }


  return (
    <div style={{ overflowX: "auto" }}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("4S") ? true : false}
                        onChange={() => {
                          onBoxChange("4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("3S") ? true : false}
                        onChange={() => {
                          onBoxChange("3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("2S") ? true : false}
                        onChange={() => {
                          onBoxChange("2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="1S"
                        type="checkbox"
                        class="form-check-input"
                        onChange={() => {
                          onBoxChange("1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("81S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="81S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("81S") ? true : false}
                        onChange={() => {
                          onBoxChange("81S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">81S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("8S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="8S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("8S") ? true : false}
                        onChange={() => {
                          onBoxChange("8S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">8S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("7S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="7S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("7S") ? true : false}
                        onChange={() => {
                          onBoxChange("7S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">7S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("6S") ? true : false}
                        onChange={() => {
                          onBoxChange("6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("5S") ? true : false}
                        onChange={() => {
                          onBoxChange("5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">5S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("82S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="82S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("82S") ? true : false}
                        onChange={() => {
                          onBoxChange("82S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">82S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("12S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="12S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("12S") ? true : false}
                        onChange={() => {
                          onBoxChange("12S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">12S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("11S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="11S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("11S") ? true : false}
                        onChange={() => {
                          onBoxChange("11S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">11S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("10S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="10S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("10S") ? true : false}
                        onChange={() => {
                          onBoxChange("10S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">10S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("9S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="9S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("9S") ? true : false}
                        onChange={() => {
                          onBoxChange("9S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">9S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("83S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="83S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("83S") ? true : false}
                        onChange={() => {
                          onBoxChange("83S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">83S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("16S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="16S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("16S") ? true : false}
                        onChange={() => {
                          onBoxChange("16S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">16S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("15S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="15S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("15S") ? true : false}
                        onChange={() => {
                          onBoxChange("15S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">15S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("14S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="14S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("14S") ? true : false}
                        onChange={() => {
                          onBoxChange("14S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">14S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("13S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="13B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("13S") ? true : false}
                        onChange={() => {
                          onBoxChange("13S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">13S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("84S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="84S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("84S") ? true : false}
                        onChange={() => {
                          onBoxChange("84S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">84S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("20S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="20S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("20S") ? true : false}
                        onChange={() => {
                          onBoxChange("20S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">20S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("19S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="19S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("19S") ? true : false}
                        onChange={() => {
                          onBoxChange("19S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">19S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("18S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="18S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("18S") ? true : false}
                        onChange={() => {
                          onBoxChange("18S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">18S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("17S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="17B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("17S") ? true : false}
                        onChange={() => {
                          onBoxChange("17S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">17S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("85S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="85S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("85S") ? true : false}
                        onChange={() => {
                          onBoxChange("85S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">85S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("24S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="24S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("24S") ? true : false}
                        onChange={() => {
                          onBoxChange("24S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">24S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("23S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="23S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("23S") ? true : false}
                        onChange={() => {
                          onBoxChange("23S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">23S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("22S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="22S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("22S") ? true : false}
                        onChange={() => {
                          onBoxChange("22S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">22S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("21S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="21B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("21S") ? true : false}
                        onChange={() => {
                          onBoxChange("21S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">21S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("86S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="86S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("86S") ? true : false}
                        onChange={() => {
                          onBoxChange("86S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">86S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("28S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="28S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("28S") ? true : false}
                        onChange={() => {
                          onBoxChange("28S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">28S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("27S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="27S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("27S") ? true : false}
                        onChange={() => {
                          onBoxChange("27S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">27S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("26S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="26S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("26S") ? true : false}
                        onChange={() => {
                          onBoxChange("26S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">26S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("25S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="25B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("25S") ? true : false}
                        onChange={() => {
                          onBoxChange("25S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">25S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("87S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="87S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("87S") ? true : false}
                        onChange={() => {
                          onBoxChange("87S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">87S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("32S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="32S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("32S") ? true : false}
                        onChange={() => {
                          onBoxChange("32S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">32S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("31S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="31S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("31S") ? true : false}
                        onChange={() => {
                          onBoxChange("31S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">31S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("30S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="30S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("30S") ? true : false}
                        onChange={() => {
                          onBoxChange("30S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">30S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("29S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="29B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("29S") ? true : false}
                        onChange={() => {
                          onBoxChange("29S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">29S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("88S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="88S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("88S") ? true : false}
                        onChange={() => {
                          onBoxChange("88S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">88S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("36S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="36S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("36S") ? true : false}
                        onChange={() => {
                          onBoxChange("36S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">36S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("35S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="35S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("35S") ? true : false}
                        onChange={() => {
                          onBoxChange("35S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">35S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("34S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="34S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("34S") ? true : false}
                        onChange={() => {
                          onBoxChange("34S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">34S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("33S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="33S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("33S") ? true : false}
                        onChange={() => {
                          onBoxChange("33S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">33S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("89S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="89S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("89S") ? true : false}
                        onChange={() => {
                          onBoxChange("89S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">89S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("40S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="40S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("40S") ? true : false}
                        onChange={() => {
                          onBoxChange("40S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">40S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("39S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("39S") ? true : false}
                        onChange={() => {
                          onBoxChange("39S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">39S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("38S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="42S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("38S") ? true : false}
                        onChange={() => {
                          onBoxChange("38S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">38S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("37S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("37S") ? true : false}
                        onChange={() => {
                          onBoxChange("37S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">37S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("90S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="90S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("90S") ? true : false}
                        onChange={() => {
                          onBoxChange("90S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">90S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("44S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="44S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("44S") ? true : false}
                        onChange={() => {
                          onBoxChange("44S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">44S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("43S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="43S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("43S") ? true : false}
                        onChange={() => {
                          onBoxChange("43S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">43S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("42S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="42S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("42S") ? true : false}
                        onChange={() => {
                          onBoxChange("42S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">42S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("41S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="41B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("41S") ? true : false}
                        onChange={() => {
                          onBoxChange("41S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">41S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("91S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="91S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("91S") ? true : false}
                        onChange={() => {
                          onBoxChange("91S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">91S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("48S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="48S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("48S") ? true : false}
                        onChange={() => {
                          onBoxChange("48S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">48S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("47S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="47S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("47S") ? true : false}
                        onChange={() => {
                          onBoxChange("47S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">47S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("46S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="46S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("46S") ? true : false}
                        onChange={() => {
                          onBoxChange("46S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">46S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("45S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="45B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("45S") ? true : false}
                        onChange={() => {
                          onBoxChange("45S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">45S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("92S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="92S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("92S") ? true : false}
                        onChange={() => {
                          onBoxChange("92S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">92S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("52S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="52S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("52S") ? true : false}
                        onChange={() => {
                          onBoxChange("52S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">52S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("51S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="51S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("51S") ? true : false}
                        onChange={() => {
                          onBoxChange("51S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">51S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("50S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="50S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("50S") ? true : false}
                        onChange={() => {
                          onBoxChange("50S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">50S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("49S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="49B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("49S") ? true : false}
                        onChange={() => {
                          onBoxChange("49S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">49S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("93S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="93S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("93S") ? true : false}
                        onChange={() => {
                          onBoxChange("93S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">93S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("56S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="56S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("56S") ? true : false}
                        onChange={() => {
                          onBoxChange("56S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">56S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("55S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="55S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("55S") ? true : false}
                        onChange={() => {
                          onBoxChange("55S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">55S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("54S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="54S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("54S") ? true : false}
                        onChange={() => {
                          onBoxChange("54S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">54S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("53S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="53B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("53S") ? true : false}
                        onChange={() => {
                          onBoxChange("53S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">53S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("94S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="94S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("94S") ? true : false}
                        onChange={() => {
                          onBoxChange("94S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">94S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("60S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("60S") ? true : false}
                        onChange={() => {
                          onBoxChange("60S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">60S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("59S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="59S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("59S") ? true : false}
                        onChange={() => {
                          onBoxChange("59S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">59S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("58S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="58S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("58S") ? true : false}
                        onChange={() => {
                          onBoxChange("58S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">58S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("57S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="57S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("57S") ? true : false}
                        onChange={() => {
                          onBoxChange("57S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">57S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("95S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="95S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("95S") ? true : false}
                        onChange={() => {
                          onBoxChange("95S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">95S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("64S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="64S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("64S") ? true : false}
                        onChange={() => {
                          onBoxChange("64S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">64S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("63S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="63S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("63S") ? true : false}
                        onChange={() => {
                          onBoxChange("63S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">63S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("62S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="62S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("62S") ? true : false}
                        onChange={() => {
                          onBoxChange("62S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">62S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("61S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="61S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("61S") ? true : false}
                        onChange={() => {
                          onBoxChange("61S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">61S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("96S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="96S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("96S") ? true : false}
                        onChange={() => {
                          onBoxChange("96S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">96S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("68S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="66S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("68S") ? true : false}
                        onChange={() => {
                          onBoxChange("68S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">68S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("67S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="65S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("67S") ? true : false}
                        onChange={() => {
                          onBoxChange("67S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">67S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("66S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="64S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("66S") ? true : false}
                        onChange={() => {
                          onBoxChange("66S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">66S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("65S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="63S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("65S") ? true : false}
                        onChange={() => {
                          onBoxChange("65S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">65S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("97S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="97S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("97S") ? true : false}
                        onChange={() => {
                          onBoxChange("97S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">97S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("72S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="72S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("72S") ? true : false}
                        onChange={() => {
                          onBoxChange("72S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">72S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("71S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="71S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("71S") ? true : false}
                        onChange={() => {
                          onBoxChange("71S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">71S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("70S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="70S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("70S") ? true : false}
                        onChange={() => {
                          onBoxChange("70S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">70S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("69S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="69B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("69S") ? true : false}
                        onChange={() => {
                          onBoxChange("69S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">69S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 1st row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("98S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="98S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("98S") ? true : false}
                        onChange={() => {
                          onBoxChange("98S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">98S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("76S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="76S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("76S") ? true : false}
                        onChange={() => {
                          onBoxChange("76S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">76S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("75S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="75S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("75S") ? true : false}
                        onChange={() => {
                          onBoxChange("75S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">75S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("74S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="74S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("74S") ? true : false}
                        onChange={() => {
                          onBoxChange("74S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">74S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("73S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="73B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("73S") ? true : false}
                        onChange={() => {
                          onBoxChange("73S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">73S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2row ">
        <div class="left">
          <div class="col-2"></div>
        </div>

        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("80S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="80S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("80S") ? true : false}
                        onChange={() => {
                          onBoxChange("80S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">80S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("79S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="79S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("79S") ? true : false}
                        onChange={() => {
                          onBoxChange("79S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">79S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("78S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="78S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("78S") ? true : false}
                        onChange={() => {
                          onBoxChange("78S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">78S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("77S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="77B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("77S") ? true : false}
                        onChange={() => {
                          onBoxChange("77S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">77S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="e2trow ">
        <div className="right">
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}

      {/* berths started */}

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP1B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP1B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP1B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP1B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP2B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP2B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP2B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP2B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP3B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP3B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP3B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP3B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP4B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP4B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP4B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP4B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP5B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP5B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP5B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP5B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP6B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP6B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP6B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP6B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP7B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP7B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP7B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP7B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP8B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP8B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP8B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP8B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP9B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="UP9B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("UP9B") ? true : false}
                        onChange={() => {
                          onBoxChange("UP9B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP9B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP10B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP10B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP10B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP10B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP11B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP11B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP11B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP11B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP12B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP12B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP12B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP12B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP13B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP13B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP13B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP13B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP14B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP14B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP14B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP14B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP15B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP15B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP15B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP15B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="e2row ">
        <div class="left">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP16B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP16B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP16B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP16B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="right">
          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP17B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP17B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP17B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP17B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP18B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP18B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP18B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP18B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP19B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP19B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP19B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP19B</h5>
                </div>
              </div>
            </div>
          </div>

          <div class="col-2">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("UP20B")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="60B"
                        type="checkbox"
                        class="form-check-input"
                        disabled={
                          checkedArray?.includes("UP20B") ? true : false
                        }
                        onChange={() => {
                          onBoxChange("UP20B");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="60B" class="col-4">
                  <img src={berth} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">UP20B</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* berths ended */}

      <div class="card-body pt-0 row">
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Seat Type</h4>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <select
                id="status"
                value={seatStatus}
                onChange={handleSeatStatus}
                name="status"
                class="form-select form-control wide"
              >
                <option value="">Choose...</option>
                <option value="booked">Booked</option>
                <option value="reserved">Reserved</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Children Seats</h4>
          </div>
          <div class="col-6">
            <input
              type="number"
              value={children}
              class="form-control"
              placeholder="Number of childeren tickets"
              name="children"
              onChange={handleChildrenChange}
            />
          </div>
        </div>
        <button
          type="button"
          name="create_ticket_btn"
          class="btn btn-primary"
          onClick={() => {
            submitSeat();
          }}
        >
          Next
        </button>
      </div>

      {/* <div class="card-body pt-0 row">
             
                <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
            </div> */}
    </div>
  );
}

export default Economy2;
