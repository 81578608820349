import axiosInstance from '../../../utils/axios';

class Fare {
    
    static getPendingFareByTrain = (id , typeId) => {
    
        let apiPath = `/api/trainFares/pendingFares/${id}/${typeId}`;
        return axiosInstance.get(apiPath);
    
    };

    static addTrainFare = (form) => {
    
        let apiPath = '/api/trainFares';
        return axiosInstance.post(apiPath, {
            ...form
        });
    
    };
    
    static getTrainFares = (data, params) => {
    
        var apiPath = '';
        if(params === undefined || params === null) {
            apiPath = '/api/trainFares/'+data.train_id;
        } else {
            apiPath = '/api/trainFares/'+data.train_id+'?_count='+params._count+'&__pageNo='+params._pageNo;
        }
        return axiosInstance.post(apiPath , {
            ...data
        });
    
    };
    
    static deleteTrainFare = (id) => {
    
        let apiPath = '/api/trainFares/'+id;
        return axiosInstance.delete(apiPath);
    
    };

    static deleteMultipleTrainFares = (fareIds) => {
    
        let apiPath = '/api/trainFares/many';
        return axiosInstance.post(apiPath , {fares:fareIds});
    
    };
    
    static updateTrainfare = (form, id) => {
    
        let apiPath = '/api/trainFares/'+id;
        return axiosInstance.put(apiPath, {
            ...form
        });
    
    };


    static copyTrainFares = (form) => {
    
        let apiPath = '/api//trainFares/copyFares';
        return axiosInstance.post(apiPath, {
            ...form
        });
    
    };

    static getById = (id) => {
    
        let apiPath = `/api/trainFares/getFareById/${id}`;
        return axiosInstance.get(apiPath);
    
    };

    static bulkUpdate = (data) => {
    
        let apiPath = '/api/trainFares/updateFares';
        return axiosInstance.put(apiPath, {
            ...data
        });
    
    };
    

    static getTicketFare = (data) => {
    
        let apiPath = '/api/tickets/fare';
        return axiosInstance.post(apiPath, {
            ...data
        });
    
    };
}
export default Fare;
