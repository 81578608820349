import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { updatePref } from '../../store/actions/preference.actions';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdatePreference() {
    var updateName;
    let params = new URLSearchParams(document.location.search.substring(1));
    updateName = params.get("id");
    const location = useLocation();
    const history = useHistory();
    const [updateId, setUpdateId] = useState(0);
    const [prefForm, setPrefForm] = useState({
        name: '',
        value: 0,
        description: ''
    });
    useEffect(() => {
        document.title = 'Update Preference | AA Enterprise';
        if (JSON.stringify(location.state) !== undefined) {
            setUpdateId(Number(location.state.id));
            setPrefForm({ name: location.state.name, value: Number(location.state.value), description: location.state.description })
        } else {
            history.push('/all-preferences');
        }
    }, []);

    function handleFormChange(event) {
        const { name, value } = event.target;
        setPrefForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    async function handleFormSubmit(e) {
        e.preventDefault()
        if (validate()) {
            console.log("updateId, prefForm: ", updateId, prefForm);
            const response = await updatePref(updateId, prefForm);
            if (response.status === 200) {
                toast.success("Updated Successfully");
                history.push('/all-preferences');
            } else if (response.status >= 400) {
                toast.error(response.message.message);
            }
            console.log("Response: ", response);
        }
    }

    function validate() {
        if (prefForm.name !== "" && isNaN(prefForm.value) && updateId) {
            if (isNaN(prefForm.value)) {
                toast.warn("Value must be a number")
            } else {
                toast.warn("Enter valid values");
            }
            return false;
        }
        return true;
    }



    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Update Preferences</h2>
                    {/* <p class="text-light">Add a new city to the routes</p> */}
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Update Preference</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form id='myForm' name="add_city_form" onSubmit={(e) => handleFormSubmit(e)}>
                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Name</label>
                                            <input type="text" name='name' onChange={handleFormChange} value={prefForm.name} class="form-control" placeholder="name (Unique)" disabled />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Value (Number)</label>
                                            <input type="text" name='value' onChange={handleFormChange} value={prefForm.value} class="form-control" placeholder="value" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Description</label>
                                            <textarea name="description" value={prefForm.description} onChange={handleFormChange} rows="3" cols="50" placeholder='description' />
                                        </div>
                                    </div>
                                    <button type="submit" name='add_city_btn' class="btn btn-success">Update</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePreference
