import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import jsCookie from "js-cookie";
import { loginUser } from "../../store/actions/user.actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "./logo.jpg";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function Login(props) {
  const simpleValidator = useRef(new SimpleReactValidator());
  const [loginForm, setLoginForm] = useState({ user_name: "", password: "" });

  useEffect(() => {
    document.title = "Login | AA Enterprise";
    props.setNoHeader(true);
    document.body.classList.add("vh-100");
    document.getElementById("root").classList.add("authincation");
    document.getElementById("root").classList.add("h-100");

    localStorage.clear();
    sessionStorage.clear();
    jsCookie.remove("login");
    jsCookie.remove("access");
  }, []);

  function handleFormChange(event) {
    const { name, value } = event.target;
    simpleValidator.current.showMessageFor(name);
    setLoginForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  async function login() {
    let response = await loginUser(loginForm);
    if (response.status === 200) {
      jsCookie.set("login", "yes");
      localStorage.setItem("user", JSON.stringify(response.data.data));
      props.setNoHeader(false);
      //Permissions
      let permissions = JSON.parse(response.data.data.user.permissions);
      let permissionsArray = {
        //Admins
        1: "add-admin,admin-permissions",
        2: "all-admins",
        3: "update-admin",
        4: "",

        //Transactions
        5: "add-transaction",
        6: "all-transactions",
        7: "update-transaction",
        8: "delete-transaction",

        // Tour
        9: "add-tour,tour-status,add-tour-coach",
        10: "all-tours,all-tour-coaches",
        11: "update-tour,update-tour-coach",
        12: "delete-tour",

        //Ticket
        13: "add-ticket,add-ticket-seat,add-ticket-customer,add-customer,economy-ghouri,brake-ghouri,update-reserve-to-book,print-ticket,check-fare,acstd74,acstd98,acstd101",
        14: "all-tickets,all-customers",
        15: "",
        16: "",

        // Train
        17: "add-train,train-cities,train-status,add-train-city,add-city,add-fare", //,add-coach   removed so that user cannot access, add these to access
        18: "all-trains,all-train-cities,all-cities,all-train-fares", //all-coaches,
        19: "update-train-city,update-fare",
        20: "",

        //Finantial report
        21: "reports,report-by-status,discounted-ticket-report,cargo-report,cargo-individual-report,other-reports,report-by-createdAt,report-cityWiseUser",

        //Administrative Report
        22: "report-passengerChart,cargo-report,report-cityWiseUser,cargo-individual-report",

        // tikcet discount
        23: "discount",
        // add 24 for preferences
        24: "",

        25: "add-cargo",
        26: "all-cargo,cargo-by-id,cargo-details",
        27: "update-cargo",
        28: "delete-cargo",

        //Access to all;
        29: "reset",
        69: "testing",

        //Preferences (Only for admins)
        30: "all-preferences",
        31: "update-preference",
        32: "testRoute",
        33: "acstd72",
        34: "acbusinessthal",
        35: "economy1thal",
        36: "parlorcarthal",
        37: "economy2thal",
        38: "acstd90thal",
        39: "open",
        40: "acsleeperthal",
        41: "copy-tour",
        42: "bulk-fare",
        43: "economy3",
        44: "acstd96",
        47:'acbusinessview,acstd72view,acstd98view,acstd101view,economy1view,parlorcarview,economy2view,acstd90view,openview,acsleeperview,economy3view,acstd96view,acstd74view,economyghouriview,brakeview',
        48:'copy-fares',
        49:'offline-devices',
        50:'printOnline'

      };

      let includesArray = [];
      if (permissions.includes(13)) {
        permissions.push(33);
        permissions.push(34);
        permissions.push(35);
        permissions.push(36);
        permissions.push(37);
        permissions.push(38);
        permissions.push(39);
        permissions.push(40);
        permissions.push(43);
        permissions.push(44);
      }
      for (let i = 0; i < permissions.length; i++) {
        let split = permissionsArray[permissions[i]].split(",");
        if (split.length > 1) {
          for (let j = 0; j < split.length; j++) {
            includesArray.push(split[j]);
          }
        } else {
          includesArray.push(permissionsArray[permissions[i]]);
        }
      }
      
      includesArray.push("reset");
      includesArray.push("index");
      permissions.push(29);
      localStorage.setItem("permissions", JSON.stringify(includesArray));
      localStorage.setItem("permissionsNumber", JSON.stringify(permissions));

      window.location.href = "/index";
    } else if (response.status >= 400) {
      toast.warn("Wrong Credentials");
    }
  }

  function handleFormSubmit(e) {
    e.preventDefault();
    if (simpleValidator.current.allValid()) {
      login();
    } else {
      simpleValidator.current.showMessages();
    }
  }

  function myFunction() {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
      {/* <div class="authincation h-100"> */}
      <div class="container h-100">
        <div class="row justify-content-center h-100 align-items-center pt-20">
          <div class="col-md-6">
            <div class="authincation-content">
              <div class="row no-gutters">
                <div class="col-xl-12">
                  <div class="auth-form">
                    <div class="text-center mb-3">
                      {/* <a href="/login"> */}
                      <img src={logo} alt="" style={{ width: "auto" }} />
                      {/* </a> */}
                    </div>
                    <h4 class="text-center mb-4">Sign in your account</h4>
                    <form id="login-form" onSubmit={(e) => handleFormSubmit(e)}>
                      <div class="mb-3">
                        <label class="mb-1">
                          <strong>User Name</strong>
                        </label>
                        <input
                          type="text"
                          onChange={handleFormChange}
                          name="user_name"
                          value={loginForm.user_name}
                          class="form-control"
                          placeholder="hello@example.com"
                        />
                        {simpleValidator.current.message(
                          "user_name",
                          loginForm.user_name,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                      <div class="mb-3">
                        <label class="mb-1">
                          <strong>Password</strong>
                        </label>
                        <input
                          id="password"
                          type="password"
                          onChange={handleFormChange}
                          name="password"
                          value={loginForm.password}
                          class="form-control"
                          placeholder="Password"
                        />
                        {simpleValidator.current.message(
                          "password",
                          loginForm.password,
                          "required",
                          { className: "text-danger" }
                        )}
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          onClick={() => {
                            myFunction();
                          }}
                        />{" "}
                        Show Password
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-primary btn-block">
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
}

export default Login;
