import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import DataTable from "react-data-table-component";
import { Link, useHistory } from 'react-router-dom';
import { allCoaches, deleteCoach } from '../../store/actions/coach.actions'
import $ from 'jquery'

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});



function AllCoaches() {
    const history = useHistory()
    const [rows, setRows] = useState([])
    let response = [];
    let [totalRows, setTotalRows] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [tableData, setTableData] = useState({})
    const [params, setParams] = useState({
        _count: 15,
        _pageNo: pageNo,
    })

    const columns = [
        {
            name: "Coach Type",
            selector: "name",
            sortable: true
        },
        {
            name: "Capacity",
            selector: "capacity",
            sortable: true
        },
        {
            name: "Actions",
            selector: "actions",
            cell: (d) =>
            (
                <button className="btn btn-primary" onClick={() => {
                    history.push({
                        pathname: '/add-coach',
                        state: {
                            id: d.id,
                            name: d.name,
                            capacity: d.capacity
                        }
                    })
                }} type="button"><i class="icon-edit"></i></button>
            )
        }
    ];

    useEffect(() => {
        document.title = 'All Coaches | AA Enterprise';
        $('.print').hide()
        fetchData()
    }, []);

    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _pageNo: pageNo
            }
        })
    }, [pageNo]);

    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])

    useEffect(() => {
        fetchData()
    }, [params]);

    async function fetchData() {
        response = await allCoaches(params);
        if (response.status === 200) {
            setTotalRows(response.data.count);
            setRows(response.data.types)
        } else if (response.status >= 400) {
            totalRows.error(response.message.message)
        }
    }

    async function delCoach(id) {
        let res = await deleteCoach(id);
        if (res.status === 200) {
            toast.success("Coach Deleted")
            fetchData()
        } else if (response.status >= 400) {
            toast.error(res.message.message)
        }
    }

    // function renderTableRows() {
    //     setRows([])
    //     let data = response.data.types;
    //     for (let i = 0; i < data.length; i++) {
    //         let id = data[i].id
    //         data[i].actions = <><Link className="btn btn-primary" to={{
    //             pathname: '/add-coach',
    //             state: {
    //                 id: id,
    //                 name: data[i].name,
    //                 capacity: data[i].capacity
    //             }
    //         }
    //         } type="button"><i class="icon-edit"></i></Link> <button class="btn btn-danger" type="button" onClick={() => delCoach(id)} ><i class="icon-trash" aria-hidden="true"></i></button> </>;
    //         setRows((prevValue) => {
    //             return [...prevValue, data[i]]
    //         })
    //     }
    // }

    // function tableHead() {
    //     let array = []
    //     for (let i = 0; i < columns.length; i++) {
    //         array.push(columns[i].selector);
    //     }
    //     return array
    // }

    // function tableRows() {
    //     let array = []
    //     let data = response.data
    //     for (let i = 0; i < data.length; i++) {
    //         let nestedArray = [];
    //         nestedArray.push(data[i].name)
    //         nestedArray.push(data[i].postal_code)
    //         array.push(nestedArray)
    //     }
    //     return array
    // }

    // function downloadPdf() {
    //     const doc = new jsPDF()
    //     doc.autoTable({
    //         head: [tableHead()],
    //         body: tableRows(),
    //     })
    //     doc.save('all_coaches.pdf')
    // }
    return (
        <div>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Coaches</h2>
                    <p class="text-light">List of all coaches</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Coach List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        pagination={true}
                                        paginationServer={true}
                                        defaultSortField={'name'}
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={15}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPageNo(page)}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div class="btn-group btn-group-sm" role="group"><button type="button" class="btn btn-success" onClick={downloadPdf}>Download PDF</button></div> */}
        </div>
    )
}

export default AllCoaches
