import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { changePassword } from '../../store/actions/user.actions'

function Reset() {
  const history = useHistory();
  const [form, setForm] = useState({
    current_password: '',
    new_password: '',
    confirm_password: ''
  });

  useEffect(() => {
    document.title = 'Reset Password | AA Enterprise';
  }, [])

  async function onSubmitForm(e) {
    e.preventDefault();

    if (validate()) {
      let obj = {
        old_password: form.current_password,
        new_password: form.new_password
      }
      let response = await changePassword(obj);
      if (response.status === 200) {
        toast.success("Password changed successfully");
        history.push('index');
      } else if (response.status >= 400) {
        toast.error(response.message.message);
        setForm({
          current_password: '',
          new_password: '',
          confirm_password: ''
        })
      }
    }
  }

  function validate() {
    if (!(form.current_password.length && form.new_password.length && form.confirm_password.length)) {
      toast.warn("Invalid Form");
      return false;
    }
    if (form.confirm_password !== form.new_password) {
      toast.warn("Password do not match");
      return false;
    }
    return true;
  }

  function onChangeHandler(event) {
    const { name, value } = event.target;
    setForm(prevValue => {
      return {
        ...prevValue,
        [name]: value
      }
    })
  }

  function myFunction(id) {
    var x = document.getElementById(id);
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Change Password</h2>
          <p class="text-light">change user password</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-3"></div>
        <div class="col-xl-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Change Password</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">

                <div class="row">
                  <form name="reset-password-form" onSubmit={(e) => onSubmitForm(e)}>

                    <div class="col-md-12">
                      <label class="form-label">Current Password</label>
                      <input id="current_password" type="password" name='current_password' value={form.current_password} onChange={onChangeHandler} class="form-control" placeholder="current password" />
                    </div>
                    <input class="mb-4" type="checkbox" onClick={() => { myFunction("current_password") }} /> <i>Show</i>

                    <div class="col-md-12">
                      <label class="form-label">New Password</label>
                      <input id="new_password" type="password" name='new_password' value={form.new_password} onChange={onChangeHandler} class="form-control" placeholder="new password" />
                    </div>
                    <input class="mb-4" type="checkbox" onClick={() => { myFunction("new_password") }} /> <i>Show</i>

                    <div class="col-md-12">
                      <label class="form-label">Confirm Password</label>
                      <input id="confirm_password" type="password" name='confirm_password' value={form.confirm_password} onChange={onChangeHandler} class="form-control" placeholder="confirm password" />
                    </div>
                    <input class="mb-4" type="checkbox" onClick={() => { myFunction("confirm_password") }} /> <i>Show</i>

                    <div class="text-center">
                      <button type="submit" name='submit' class="btn btn-primary btn-block">Submit</button>
                    </div>

                  </form>
                </div>

              </div>
            </div>

          </div>
        </div>
        <div class="col-xl-3"></div>
      </div>
    </>
  )
}

export default Reset
