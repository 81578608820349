import Train from '../services/train/train';

export const addTrain = (form) => {

    return Train.addTrain(form).then(response => {
        return { status: response.status, data: response.data, message: "Train Added" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const allTrains = (params) => {

    return Train.allTrains(params).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const deleteTrain = (id) => {

    return Train.deleteTrain(id).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

// export const getTrainProfile = (id) => {

//     return Train.getTrainProfile(id).then(response => {
//         return { status: response.status, data: response.data, message: "success" };
//     }).catch(e => {
//         return { status: e.response.status, message: e.response.data };
//     })

// };

export const addTrainCity = (form) => {

    return Train.addTrainCity(form).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const getTrainCities = (object) => {

    return Train.getTrainCities(object).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const deleteTrainCity = (id) => {

    return Train.deleteTrainCity(id).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const updateTrain = (form, id) => {

    return Train.updateTrain(form, id).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const updateTrainCity = (form, id) => {
    console.log("In: ", form);

    return Train.updateTrainCity(form, id).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const activateTrain = (id) => {

    return Train.activateTrain(id).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const getTrainStartingCities = (train_id) => {

    return Train.getTrainStartingCities(train_id).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const getTrainEndingCities = (train_id, starting_city) => {

    return Train.getTrainEndingCities(train_id, starting_city).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};