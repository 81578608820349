import React, { useEffect, useState } from 'react'
import 'jspdf-autotable'
import $ from 'jquery'

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import DataTable from "react-data-table-component";
import { allTransactions, deleteTransaction } from '../../store/actions/transaction.actions'

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AllTransactions() {
    const [tableData, setTableData] = useState({})
    const [rows, setRows] = useState([])
    const [totalRows, setTotalRows] = useState(0);
    const [pageNo, setPageNo] = useState(1);
    const [noOfRows, setNoOfRows] = useState(10)
    const [params, setParams] = useState({
        _count: noOfRows,
        _pageNo: pageNo,
    })
    const columns = [

        {
            name: "Given By",
            selector: (row) => row.users_cash_transactions_given_byTousers.name,
            sortable: true
        },
        {
            name: "Recieved By",
            selector: (row) => row.users_cash_transactions_recieved_byTousers.name,
            sortable: true
        },
        {
            name: "Date",
            selector: (row) => row.created_at,
            sortable: true,
            cell: (d) => 
            (
                d.created_at.substring(0, 10) + ' ' + d.created_at.substring(11, 19)
            )
        },
        {
            name: "Amount",
            selector: (row) => row.amount,
            sortable: true
        },
        // {
        //     name: "Actions",
        //     selector: (row) => row.actions,
        //     cell: (d) =>
        //     (
        //         <button class="btn btn-danger" type="button" onClick={() => _deleteTransaction(d.record_id)}>
        //             <i class="icon-trash" aria-hidden="true"></i>
        //         </button>
        //     )
        // }
    ];

    useEffect(() => {
        document.title = 'All Transactions | AA Enterprise';
        $('.print').hide()
    }, []);
    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])
    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _pageNo: pageNo
            }
        })
    }, [pageNo]);
    useEffect(() => {
        _allTransactions()
    }, [params]);
    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _count: noOfRows
            }
        })
    }, [noOfRows])

    async function _deleteTransaction(id) {
        if(window.confirm("Delet Transaction?")) {
            let response = await deleteTransaction(id);
            if(response.status === 200) {
                toast.success("Transaction deleted");
                _allTransactions()
            } else if (response.status >= 400) {
                toast.error("Error deleting transaction");
            }
        }

    }

    async function _allTransactions() {
        let response = await allTransactions(params)
        if (response.status === 200) {
            console.log(response.data.trans);
            setRows(response.data.trans);
            setTotalRows(response.count);
        } else if (response.status >= 400) {
            toast.error("Error getting transactions ");
        }
    }

    return (
        <div>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Transactions</h2>
                    <p class="text-light">List of all transactions</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Transaction List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        pagination={true}
                                        paginationServer={true}
                                        defaultSortField={'name'}
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={noOfRows}
                                        // paginationComponentOptions={{
                                        //     noRowsPerPage: true
                                        // }}
                                        onChangeRowsPerPage={row => setNoOfRows(row)}
                                        onChangePage={page => setPageNo(page)}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllTransactions
