import React, { useState, useEffect } from 'react'

import DataTable from "react-data-table-component";
import $ from 'jquery'

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { allTrains, activateTrain } from '../../store/actions/train.actions';

toast.configure({
    autoClose: 4000,
    draggable: true
});

function TrainStatus() {
    const columns = [
        {
            name: "Name",
            selector: "name",
            sortable: true
        },
        {
            name: "Display Name",
            selector: "display_name",
            sortable: true
        },
        {
            name: "Number",
            selector: "number",
            sortable: true
        },
        {
            name: "Status",
            selector: "train_status",
            sortable: true,
            cell: (d) =>
            (
                d.train_status === 'deactive' ? <span class="btn btn-warning">Deactive</span> : <span class="btn btn-success">Active</span>
            )
        },
        {
            name: 'Actions',
            selector: "actions",
            cell: (d) =>
            (
                <button class="btn btn-success" type="button" onClick={() => { _activateTrain(d.id) }} >Activate</button>
            )
        }
    ];
    const [tableData, setTableData] = useState({})
    const [rows, setRows] = useState([])

    useEffect(() => {
        document.title = 'Train Status | AA Enterprise';
        $('.print').hide()
        _allTrains()
    }, []);
    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])

    async function _allTrains() {
        let response = await allTrains({ _count: 150, _pageNo: 1 });
        if (response.status === 200) {
            setRows(response.data.trains)
        } else if (response.status >= 400) {
            toast.error(response.message.message)
        }
    }

    async function _activateTrain(id) {
        let response = await activateTrain(id);
        if (response.status === 200) {
            toast.success("Train Activated")
            _allTrains()
        } else if (response.status >= 400) {
            toast.error(response.message.message)
        }
    }


    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Trains</h2>
                    <p class="text-light">List of all trains</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Train Status</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        defaultSortField={'name'}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TrainStatus
