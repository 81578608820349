import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { useHistory } from "react-router-dom";
import $ from 'jquery';

import { allPref } from '../../store/actions/preference.actions';

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true
});

function AllPreferences() {

  const [rows, setRows] = useState([])
  const [totalRows, setTotalRows] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [tableData, setTableData] = useState({})
  const [params, setParams] = useState({
    _count: 15,
    _pageNo: pageNo,
  })
  const history = useHistory()


  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true
    },
    {
      name: "Value",
      selector: (row) => row.value,
      sortable: true
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      cell: (d) =>
      (
        <button className="btn btn-primary" onClick={() => {
          history.push({
            pathname: '/update-preference',
            state: { id: d.id, name: d.name, value: d.value, description: d.description }
          })
        }} type="button"><i class="icon-edit"></i></button>
      )
    }
  ];




  useEffect(() => {
    document.title = 'All Preferences | AA Enterprise';
    $('.print').hide()
    $('.data-table-extensions-action').hide()
  }, []);
  useEffect(() => {
    setParams((prevValue) => {
      return {
        ...prevValue,
        _pageNo: pageNo
      }
    })
  }, [pageNo]);
  useEffect(() => {
    setTableData({
      columns: columns,
      data: rows
    })
  }, [rows])
  useEffect(() => {
    _allPref()
  }, [params]);




  async function _allPref() {
    const response = await allPref(params);
    if (response.status === 200) {
      setRows(response.data.prefs);
      setTotalRows(response.data.count);
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }





  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Preferences</h2>
          <p class="text-light">List of all preferences</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Preferences' List</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <DataTableExtensions {...tableData}>
                  <DataTable
                    data={rows}
                    columns={columns}
                    highlightOnHover
                    pagination={true}
                    paginationServer={true}
                    defaultSortField={'name'}
                    paginationTotalRows={totalRows}
                    paginationPerPage={15}
                    paginationComponentOptions={{
                      noRowsPerPage: true
                    }}
                    onChangePage={page => setPageNo(page)}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllPreferences
