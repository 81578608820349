import React, { useEffect, useState } from "react";
import { allTrains } from "../../store/actions/train.actions";
import {
  getPendingFareByTrain,
  addTrainFare,
} from "../../store/actions/fare.actions";
import { getCityProfile } from "../../store/actions/city.actions";
import { getCoachProfile } from "../../store/actions/coach.actions";
import { allCoaches } from '../../store/actions/coach.actions'
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AddFare() {
  const [trainId, setTrainId] = useState(0);
  const [trains, setTrains] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [typeId , setTypeId] = useState(0);
  const [currentCoach, setCurrentCoach] = useState({
    coach_name: "",
    coach_id: 0,
  });
  const [fetchParams, setfetchParams] = useState({
    _count: 150,
    _pageNo: 1,
  });
  const [currentCities, setCurrentCities] = useState({
    city1_id: 0,
    city2_id: 0,
    city1_name: "",
    city2_name: "",
  });
  const [coachFare, setCoachFare] = useState({
    berth_fare: 0,
    seat_fare: 0,
  });

  const [data, setData] = useState({});
  const [dataRows, setDataRows] = useState(0);
  const [ittirationPast, setIttirationPast] = useState(0);


  useEffect(() => {
    document.title = "Add Fare | AA Enterprise";
  }, []);


  useEffect(() => {
    fetchTrains();
    _allCoaches();
  }, [fetchParams]);



  useEffect(() => {
    if (trainId && typeId) {
      _getPendingFareByTrain();
    }
  }, [trainId, ittirationPast]);


  useEffect(() => {
    if (JSON.stringify(data) !== "{}" && data !== []) {
      setCurrentCities({
        city1_name: "",
        city2_name: "",
        city1_id: data[0].city1_id,
        city2_id: data[0].city2_id,
      });
      setCurrentCoach({
        coach_name: "",
        coach_id: data[0].coach_type,
      });
    }
  }, [data]);
  useEffect(() => {
    if (currentCities.city1_id !== 0) {
      _getCityProfile();
      _getCoachProfile();
    }
  }, [currentCities.city1_id, currentCities.city2_id, currentCoach.coach_id]);

  async function _getCityProfile() {
    let res1 = await getCityProfile(currentCities.city1_id);
    let res2 = await getCityProfile(currentCities.city2_id);
    if (res1.status === 200) {
      setCurrentCities((prevValue) => {
        return {
          ...prevValue,
          city1_name: res1.data.name,
        };
      });
    } else if (res1.status >= 400) {
      toast.error("City1 fetch failed");
    }
    if (res2.status === 200) {
      setCurrentCities((prevValue) => {
        return {
          ...prevValue,
          city2_name: res2.data.name,
        };
      });
    } else if (res2.status >= 400) {
      toast.error("City2 fetch failed");
    }
  }



  async function _allCoaches() {
    let response = await allCoaches(fetchParams);
    if (response.status === 200) {
      if (response.data.types) {
        setCoaches(response.data.types);
      } else {
        toast.info("Please add coaches first");
      }
    } else if (response.status >= 400) {
      toast.error("Failed to get Coaches");
    }
  }



  // }
  async function _getCoachProfile() {
    let response = await getCoachProfile(currentCoach.coach_id);
    if (response.status === 200) {
      setCurrentCoach((prevValue) => {
        return {
          ...prevValue,
          coach_name: response.data.name,
        };
      });
    } else if (response.status >= 400) {
      toast.error("Failed to fetch profile");
    }
  }




  async function _getPendingFareByTrain() {
    let response = await getPendingFareByTrain(trainId , typeId);
    if (response.status === 200) {
      if (response.data.length) {
        // let current = response.data.length
        setDataRows(response.data.length);
        // setCurrentRow()
        setData(response.data);
      } else {
        toast.info("All fares for this trains are defined");
      }
    } else if (response.status >= 400) {
      toast.error("Error");
    }
  }




  async function onSubmitForm(e) {
    e.preventDefault();
    let reqObj = {
      train_id: Number(trainId),
      city1_id: Number(currentCities.city1_id),
      city2_id: Number(currentCities.city2_id),
      seat_fare: Number(coachFare.seat_fare),
      berth_fare: Number(coachFare.berth_fare),
      coach_type: Number(currentCoach.coach_id),
    };
    if (validate(reqObj)) {
      let response = await addTrainFare(reqObj);
      if (response.status === 200) {
        toast.success("Fare Added");
        setIttirationPast(ittirationPast + 1);
        setDataRows(dataRows - 1);
      } else if (response.status >= 400) {
        toast.error("Adding Failed");
      }
    } else {
      toast.warn("Please insert all entries");
    }
    // let response = await
  }



  function validate(obj) {
    if (
      obj.train_id &&
      obj.city1_id &&
      obj.city2_id &&
      obj.seat_fare &&
      obj.berth_fare &&
      obj.coach_type
    ) {
      return true;
    }
    return false;
  }



  function handleCoachFare(e) {
    let { name, value } = e.target;

    value = parseInt(value);
    setCoachFare((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }




  async function fetchTrains() {
    let fetch_trains = await allTrains(fetchParams);
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }



  //first function
  function selectTrain(e) {
    e.preventDefault();
    setTrainId(Number(document.getElementById("trains_select").value));
    setTypeId(Number(document.getElementById("coach_type").value));
    document.getElementById("display_train").innerHTML = $("#trains_select")
      .find("option:selected")
      .text();
  }



  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Fares</h2>
          <p class="text-light">Add fare for train cities</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Define fares for Cities</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  type="submit"
                  name="fetch_trains"
                  onSubmit={(e) => selectTrain(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option value={train.id} data-name={train.name}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div class="mb-3 col-md-6">
                    <label class="form-label">Select Coach type</label>
                    <select
                      id="coach_type"
                      name="coach_type"
                      class="form-select form-control wide"
                    >
                      <option value="">Choose...</option>
                      {coaches.map((coach) => {
                        return <option value={coach.id}>{coach.name}</option>;
                      })}
                    </select>
                  </div>

                  <button
                    type="submit"
                    name="fetch_trains_btn"
                    class="btn btn-primary"
                  >
                    Select
                  </button>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <p>
                        Selected Train is:{" "}
                        <i id="display_train" style={{ color: "red" }}></i>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Define Fare</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  name="add_city_fare_form"
                  onSubmit={(e) => onSubmitForm(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">From</label>
                      <input
                        type="text"
                        name="city1_id"
                        value={currentCities.city1_name}
                        class="form-control"
                        readonly
                        disabled
                      />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label class="form-label">To</label>
                      <input
                        type="text"
                        name="city2_id"
                        value={currentCities.city2_name}
                        class="form-control"
                        readonly
                        disabled
                      />
                    </div>
                    {/* <div class="col-12"> */}
                    {/* <div class="row"> */}
                  </div>
                  <div class="row">
                    <div class="mb-3 col-lg-4 col-md-4">
                      <label class="form-label">Coach Type:</label>
                      <input
                        type="text"
                        name="coach_type"
                        value={currentCoach.coach_name}
                        class="form-control"
                        readonly
                        disabled
                      />
                    </div>
                    <div class="mb-3 col-lg-4 col-md-4">
                      <label class="form-label">Birth Fare</label>
                      <input
                        type="number"
                        name="berth_fare"
                        value={coachFare.berth_fare}
                        onChange={handleCoachFare}
                        class="form-control"
                      />
                    </div>
                    <div class="mb-3 col-lg-4 col-md-4">
                      <label class="form-label">Seat Fare</label>
                      <input
                        type="number"
                        name="seat_fare"
                        value={coachFare.seat_fare}
                        onChange={handleCoachFare}
                        class="form-control"
                      />
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                  <button type="submit" name="submit" class="btn btn-primary">
                    Submit
                  </button>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <p>
                        Remaining:{" "}
                        <i id="remaining" style={{ color: "red" }}>
                          {dataRows}
                        </i>
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddFare;
