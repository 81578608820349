import React, { useState, useEffect } from "react";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  trainEarningReport,
  totalEarningReport,
} from "../../store/actions/report.actions";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";

function OtherReports() {
  const [trains, setTrains] = useState([]);
  const [totalStartingCities, setTotalStartingCities] = useState([]);
  const [totalEndingCities, setTotalEndingCities] = useState([]);
  const [trainStartingCities, setTrainStartingCities] = useState([]);
  const [trainEndingCities, setTrainEndingCities] = useState([]);
  const [totalEarningForm, setTotalEarningForm] = useState({
    train_id: 0,
    st_date: "",
    end_date: "",
    st_city: 0,
    end_city: 0,
    st_city0_name: "",
    end_city0_name: "",
    train_id0_name: "",
  });
  const [trainEarningForm, setTrainEarningForm] = useState({
    train_id: 0,
    passenger_count: 0,
    st_date: "",
    end_date: "",
    st_city: 0,
    end_city: 0,
    st_city1_name: "",
    end_city1_name: "",
    train_id1_name: "",
  });

  useEffect(() => {
    document.title = "Total Report | AA Enterprise";
    $(".print").hide();
    fetchTrains();
  }, []);

  useEffect(() => {
    if (totalEarningForm.train_id) {
      _getTrainStartingCities(0);
    }
  }, [totalEarningForm.train_id]);
  useEffect(() => {
    if (totalEarningForm.st_city) {
      _getTrainEndingCities(0);
    }
  }, [totalEarningForm.st_city]);

  useEffect(() => {
    if (trainEarningForm.train_id) {
      _getTrainStartingCities(1);
    }
  }, [trainEarningForm.train_id]);
  useEffect(() => {
    if (trainEarningForm.st_city) {
      _getTrainEndingCities(1);
    }
  }, [trainEarningForm.st_city]);

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function _getTrainEndingCities(isTrainReport) {
    let response = "";
    if (isTrainReport) {
      response = await getTrainEndingCities(
        trainEarningForm.train_id,
        trainEarningForm.st_city
      );
    } else {
      response = await getTrainEndingCities(
        totalEarningForm.train_id,
        totalEarningForm.st_city
      );
    }
    if (response.status === 200) {
      if (isTrainReport) {
        setTrainEndingCities(response.data);
      } else {
        setTotalEndingCities(response.data);
      }
    } else if (response.status >= 400) {
      toast.error("Some error");
      if (isTrainReport) {
        setTrainEndingCities([]);
      } else {
        setTotalEndingCities([]);
      }
    }
  }

  async function _getTrainStartingCities(isTrainReport) {
    let response = "";
    if (isTrainReport) {
      response = await getTrainStartingCities(trainEarningForm.train_id);
    } else {
      response = await getTrainStartingCities(totalEarningForm.train_id);
    }
    if (response.status === 200 && response.data.length !== 0) {
      if (isTrainReport) {
        setTrainStartingCities(response.data);
      } else {
        setTotalStartingCities(response.data);
      }
    } else if (response.status >= 400) {
      toast.error("Some error");
      if (isTrainReport) {
        setTrainStartingCities([]);
      } else {
        setTotalStartingCities([]);
      }
    }
  }

  async function handleFormSubmit(e, isTrainReport) {
    e.preventDefault();
    if (validate(isTrainReport)) {
      let obj = {};
      if (isTrainReport) {
        obj = {
          ...trainEarningForm,
          end_city: Number(trainEarningForm.end_city),
          st_city: Number(trainEarningForm.st_city),
          train_id: Number(trainEarningForm.train_id),
          passenger_count: Number(trainEarningForm.passenger_count),
        };
      } else {
        obj = {
          ...totalEarningForm,
          end_city: Number(totalEarningForm.end_city),
          st_city: Number(totalEarningForm.st_city),
          train_id: Number(totalEarningForm.train_id),
        };
      }

      let response = {};
      if (isTrainReport) {
        $('#preloader').delay(333).fadeIn('slow');
        response = await trainEarningReport(obj);
        $('#preloader').delay(333).fadeOut('slow');
      } else {
        $('#preloader').delay(333).fadeIn('slow');
        response = await totalEarningReport(obj);
        $('#preloader').delay(333).fadeOut('slow');
      }

      if (response.status === 200) {
        if (isTrainReport) {
          downloadPdf(response.data, 1);
        } else {
          downloadPdf(response.data, 0);
        }
      } else if (response.status >= 400) {
        toast.error(response.message.message);
      }
    }
  }

  function downloadPdf(response, isTrainReport) {
    const doc = new jsPDF();
    var form = {};
    let pdfName = "train_earning_report";
    let namesPrint = {
      st_date: "Starting Date",
      end_date: "Ending Date",
    };

    var startX = 60;
    var finalY = 10;

    if (isTrainReport) {
      doc.text(startX, finalY, "Train Earning Report");
      form = trainEarningForm;
      namesPrint["passenger_count"] = "Passengers";
      namesPrint["train_id1_name"] = "Train";
      namesPrint["st_city1_name"] = "From";
      namesPrint["end_city1_name"] = "To";
    } else {
      doc.text(startX, finalY, "Total Earning Report");
      form = totalEarningForm;
      pdfName = "total_earning_report";
      namesPrint["train_id0_name"] = "Train";
      namesPrint["st_city0_name"] = "From";
      namesPrint["end_city0_name"] = "To";
    }

    finalY += 10;

    var i = 1;
    for (const property in form) {
      if (
        form[property] &&
        property !== "st_city" &&
        property !== "end_city" &&
        property !== "train_id"
      ) {
        if (i % 2 !== 0) {
          //Left
          startX = 25;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + form[property]
          );
        } else {
          //Right
          startX = 115;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + form[property]
          );
          finalY += 12;
        }
        i++;
      }
    }

    finalY += 10;

    if (isTrainReport) {
      doc.autoTable({
        startY: finalY,
        head: [
          ["Cash Received", "No. of Passengers", "Percentage of Bussiness"],
        ],
        body: [
          [
            response.cash_recieved,
            response.no_of_passenger,
            response.percentage_of_business,
          ],
        ],
        theme: "grid",
      });
    } else {
      doc.autoTable({
        startY: finalY,
        head: [["Total Earning"]],
        body: [[response[0].total_earning]],
        theme: "grid",
      });
    }
    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize("10");
    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        210 - 10,
        297 - 10,
        null,
        null,
        "right"
      );
    }
    if (doc.save(pdfName + ".pdf")) {
      toast.success("Success");
    }
  }

  function validate(isTrainReport) {
    if (isTrainReport) {
      if (
        Number(trainEarningForm.train_id) &&
        Number(trainEarningForm.passenger_count)
      ) {
        return true;
      }
      toast.warn("Select train and No. of Passengers both");
      return false;
    } else {
      if (Number(totalEarningForm.train_id)) {
        return true;
      }
      toast.warn("Select train");
      return false;
    }
  }

  function handleChangeWithName(e, Id, isTrainReport) {
    const { name, value } = e.target;
    let _id = Id + "_name"; //  city/train
    if (isTrainReport) {
      setTrainEarningForm((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
          [_id]: $("#" + Id + " option:selected")
            .text()
            .includes("hoose...")
            ? ""
            : $("#" + Id + " option:selected").text(),
        };
      });
    } else {
      setTotalEarningForm((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
          [_id]: $("#" + Id + " option:selected")
            .text()
            .includes("hoose...")
            ? ""
            : $("#" + Id + " option:selected").text(),
        };
      });
    }
  }

  function handleChange(e, isTrainReport) {
    const { name, value } = e.target;
    if (isTrainReport) {
      setTrainEarningForm((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setTotalEarningForm((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    }
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Reports</h2>
          <p class="text-light">Filter cargo report of your choice</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Total Earning Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => handleFormSubmit(e, 0)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="train_id0"
                        name="train_id"
                        onChange={(e) =>
                          handleChangeWithName(e, "train_id0", 0)
                        }
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option key={train.key} value={train.id}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Starting Date</label>
                          <input
                            id="st_date"
                            type="date"
                            onChange={(e) => handleChange(e, 0)}
                            name="st_date"
                            class="form-control"
                            placeholder="starting date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Ending Date</label>
                          <input
                            id="end_date"
                            type="date"
                            onChange={(e) => handleChange(e, 0)}
                            name="end_date"
                            class="form-control"
                            placeholder="ending date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city0"
                              name="st_city"
                              onChange={(e) =>
                                handleChangeWithName(e, "st_city0", 0)
                              }
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {totalStartingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city0"
                              name="end_city"
                              onChange={(e) =>
                                handleChangeWithName(e, "end_city0", 0)
                              }
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {totalEndingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Train Earning Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => handleFormSubmit(e, 1)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="train_id1"
                        name="train_id"
                        onChange={(e) =>
                          handleChangeWithName(e, "train_id1", 1)
                        }
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option key={train.key} value={train.id}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Starting Date</label>
                          <input
                            id="st_date"
                            type="date"
                            onChange={(e) => handleChange(e, 1)}
                            name="st_date"
                            class="form-control"
                            placeholder="starting date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Ending Date</label>
                          <input
                            id="end_date"
                            type="date"
                            onChange={(e) => handleChange(e, 1)}
                            name="end_date"
                            class="form-control"
                            placeholder="ending date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city1"
                              name="st_city"
                              onChange={(e) =>
                                handleChangeWithName(e, "st_city1", 1)
                              }
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {trainStartingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city1"
                              name="end_city"
                              onChange={(e) =>
                                handleChangeWithName(e, "end_city1", 1)
                              }
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {trainEndingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">No. of Passengers</label>
                      <input
                        type="text"
                        name="passenger_count"
                        value={trainEarningForm.passenger_count}
                        onChange={(e) => handleChange(e, 1)}
                        class="form-control"
                        placeholder="No. of Passengers"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OtherReports;
