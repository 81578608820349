import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { useHistory } from 'react-router-dom';
import DataTable from 'react-data-table-component';

import { allTrains } from '../../store/actions/train.actions';
import { allTours, deleteTour } from '../../store/actions/tour.actions';

import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AllTours() {
    const history = useHistory()
    const columns = [
        {
            name: "Arrival Time",
            selector: "arrival_time",
            sortable: true
        },
        {
            name: "Departure Time",
            selector: "depart_time",
            sortable: true,
            cell: (d) =>
            (
                d.depart_time = (d.depart_time.replace("T", " ")).substr(0, 16)
            )

        },
        {
            name: "Booking Start Time",
            selector: "booking_start_time",
            sortable: true,
            cell: (d) =>
            (
                d.booking_start_time = (d.booking_start_time.replace("T", " ")).substr(0, 16)
            )
        },
        {
            name: "Reserving End Time",
            selector: "reserve_stop_time",
            sortable: true,
            cell: (d) =>
            (
                d.reserve_stop_time = (d.reserve_stop_time.replace("T", " ")).substr(0, 16)
            )
        },
        {
            name: "No. of Coaches",
            selector: "coach_count",
            sortable: true
        },
        {
            name: "Created By",
            selector: "creator",
            sortable: true
        },
        {
            name: "Actions",
            selector: "actions",
            cell: (d) =>
            (
                <><button className="btn btn-primary" onClick={() => {
                    history.push({
                        pathname: 'update-tour',
                        state: {
                            id: d.id,
                            arrival_time: d.arrival_time,
                            depart_time: d.depart_time,
                            booking_start_time: d.booking_start_time,
                            reserve_stop_time: d.reserve_stop_time
                        }
                    })
                }} type="button"><i class="icon-edit"></i></button>
                    <button class="btn btn-danger" type="button" onClick={() => _deleteTour(d.id)} ><i class="icon-trash" aria-hidden="true"></i></button></>
            )
        }
    ];
    const [pageNo, setPageNo] = useState(1);
    const [trainId, setTrainId] = useState(0);
    const [trains, setTrains] = useState([]);
    const [rows, setRows] = useState({})
    const [totalRows, setTotalRows] = useState(0);
    const [tableData, setTableData] = useState({});
    const [params, setParams] = useState({
        _count: 15,
        _pageNo: 1,
    });
    const trainParams = {
        _count: 150,
        _pageNo: 1,
    };


    useEffect(() => {
        document.title = 'All Tours | AA Enterprise';
        $('.print').hide()
        fetchTrains()
    }, []);
    useEffect(() => {
        setParams((prevValue) => {
            return {
                ...prevValue,
                _pageNo: pageNo
            }
        })
    }, [pageNo]);
    useEffect(() => {
        if (trainId) {
            _allTours()
        }
    }, [trainId, params])
    useEffect(() => {
        setTableData({
            columns: columns,
            data: rows
        })
    }, [rows])


    async function _allTours() {
        if (validate()) {
            let res = await allTours(params, trainId)
            if (res.status === 200) {
                console.log(res.data);
                setRows(res.data.trainTours);
                setTotalRows(res.data.count);
            } else if (res.status >= 400) {
                toast.error("Error")
            }
        } else {
            toast.warn("Please Select Train")
        }
    }

    async function fetchTrains() {
        let fetch_trains = await allTrains(trainParams);
        if (fetch_trains.status === 200) {
            setTrains(fetch_trains.data.trains)
        } else if (fetch_trains.status >= 400) {
            toast.error("Failed to fetch trains")
        }
    }


    function validate() {
        if (trainId && params) {
            return true;
        }
        return false
    }
    async function _deleteTour(id) {
        if (JSON.parse(localStorage.getItem('permissionsNumber').includes(12))) {
            if (window.confirm("Delet this train tour?")) {
                let response = await deleteTour(id);
                if (response.status === 200) {
                    toast.success("Tour Deleted")
                    _allTours()
                } else if (response.status >= 400) {
                    toast.error("Tour not deleted");
                }
            }
        } else {
            toast.warn("Access Denied")
        }
    }
    function selectTrain(e) {
        e.preventDefault();
        setTrainId(document.getElementById("trains_select").value);
        document.getElementById("display_train").innerHTML = $("#trains_select").find('option:selected').text();
    }

    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Tour</h2>
                    <p class="text-light">Add a train tour</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Trains</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">
                                <form name="fetch_trains" onSubmit={(e) => selectTrain(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Select Train</label>
                                            <select id="trains_select" name='train_id' class="form-select form-control wide">
                                                <option value=''>Choose...</option>
                                                {trains.map((train) => {
                                                    return (
                                                        <option key={train.key} value={train.id} data-name={train.name}>{train.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <button type="submit" name='fetch_trains_btn' class="btn btn-primary">Select</button>
                                    <div class='row'>
                                        <div class="mb-3 col-md-6">
                                            <p>Selected Train is: <i id="display_train" style={{ color: 'red' }}></i></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Tour List</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <DataTableExtensions {...tableData}>
                                    <DataTable
                                        data={rows}
                                        columns={columns}
                                        highlightOnHover
                                        pagination={true}
                                        paginationServer={true}
                                        defaultSortField={'name'}
                                        paginationTotalRows={totalRows}
                                        paginationPerPage={15}
                                        paginationComponentOptions={{
                                            noRowsPerPage: true
                                        }}
                                        onChangePage={page => setPageNo(page)}
                                    />
                                </DataTableExtensions>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AllTours
