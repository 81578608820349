import React, { useEffect } from 'react';
import Routes from './Routes';
import Cookies from 'universal-cookie';

function App() {
  useEffect(() => {
    const cookies = new Cookies();

    cookies.set('navheaderBg', 'color_1', { path: '/' });
    cookies.set('headerBg', 'color_14', { path: '/' });
    cookies.set('sidebarBg', 'color_14', { path: '/' });
    cookies.set('primary', 'color_14', { path: '/' });
  }, [])

  return (
    <>
      <Routes />
    </>
  )
}

export default App;