import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function AcSleeper() {
 
  const [checkedArray, setCheckedArray] = useState([]);
  

  //checkbox function
  function onBoxChange(value) {
   
  }

  

  
  return (
    <div style={{overflowX: 'auto'}}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}

      <div class="rowBusiness acsleeper ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AU2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AU2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AU2S") ? true : false}
                        onChange={() => {
                          onBoxChange("AU2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">AU2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("AL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">AL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="rowBusiness acsleeper">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          
        </div>
      </div>
      {/* button and cabin row */}

      {/* 2 nd row */}
      <div class="rowBusiness acsleeper ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BU2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BU2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BU2S") ? true : false}
                        onChange={() => {
                          onBoxChange("BU2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="4S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">BU2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("BL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="5S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">BL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness acsleeper ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CU2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CU2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CU2S") ? true : false}
                        onChange={() => {
                          onBoxChange("CU2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="7S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">CU2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("CL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="8" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness acsleeper ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          
        </div>
      </div>

      {/* 3rd row */}
      <div class="rowBusiness  acsleeper">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CU4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CU4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CU4S") ? true : false}
                        onChange={() => {
                          onBoxChange("CU4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="10S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">CU4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CL3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CL3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CL3S") ? true : false}
                        onChange={() => {
                          onBoxChange("CL3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="11S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CL3S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness acsleeper ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DU2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DU2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DU2S") ? true : false}
                        onChange={() => {
                          onBoxChange("DU2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="12S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DU2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("DL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="11S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness  acsleeper">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DU4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DU4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DU4S") ? true : false}
                        onChange={() => {
                          onBoxChange("DU4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="12S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DU4S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DL3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DL3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DL3S") ? true : false}
                        onChange={() => {
                          onBoxChange("DL3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="11S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DL3S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness  acsleeper">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EU2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EU2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EU2S") ? true : false}
                        onChange={() => {
                          onBoxChange("EU2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="12S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">EU2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("EL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="11S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">EL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness  acsleeper">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FU2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FU2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FU2S") ? true : false}
                        onChange={() => {
                          onBoxChange("FU2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="12S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">FU2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("FL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="11S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">FL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* <div class="card-body pt-0 row">
          <button type="button" name='create_ticket_btn' class="btn btn-primary" onClick={() => { submit() }}>Next</button>
       </div> */}
    </div>
  );
}

export default AcSleeper;
