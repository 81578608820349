import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
// import { createBrowserHistory } from "history";
import App from './components/App';

// const history = createBrowserHistory();

ReactDOM.render((
    // history={history}
    <BrowserRouter>
        <App />
    </BrowserRouter>
    ),document.getElementById('root'));
