import axios from 'axios';
import jsCookie from 'js-cookie';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

// Set config defaults when creating the instance
// console.log("baseURL:", "http://localhost:3310");

let user = JSON.parse(localStorage.getItem('user'));
let options = {
    baseURL: "https://devapp.aaenterpris.com",
    // baseURL: "http://localhost:3310",
    withCredentials: true,
};

if (user && user.token && user) {
    options.headers = {
        'Authorization': `Bearer ${user.token}`
    }
}

const axiosInstance = axios.create(options);


// axiosInstance.interceptors.response.use(function (response) {
//     return response;
// }, function (error) {
//     const history = useHistory();
//     if (error.response && error.response.status === 401) {
//         jsCookie.remove("login");
//         if (
//             !(~window.location.href.indexOf('login')
//                 || ~window.location.href.indexOf('signup')
//                 || ~window.location.href.indexOf('signuporg')
//                 || ~window.location.href.indexOf('reset'))
//         ) {
//             history.push("/login");
//         }
//     }
//     return Promise.reject(error);
// });

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.status === 401) {
        jsCookie.remove("login");
        if (
            !(~window.location.href.indexOf('login')
                || ~window.location.href.indexOf('signup')
                || ~window.location.href.indexOf('signuporg')
                || ~window.location.href.indexOf('reset'))
        ) {
            history.push("/login");
            window.location.href = "/login";
        }
    }
    return Promise.reject(error);
});

// Alter defaults after instance has been created
axiosInstance.defaults.headers.common['token'] = process.env.REACT_APP_SITE_TOKEN;


export default axiosInstance;
