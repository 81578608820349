import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function BrakeGhouri() {
  const location = useLocation();
  let stateData = location.state;
  const history = useHistory();
  const [toBookSeats, setToBookSeats] = useState([]);
  const [seatStatus, setSeatStatus] = useState("");
  const [children, setChildren] = useState(0);
  const [vacantSeats, setVacantSeats] = useState({
    difference: [],
    stop_time: "",
  });
  const [pastData, setPastData] = useState({
    train_id: 0,
    train_name: "",
    date: "",
    tour_id: 0,
    tour_name: "",
    coachTypeId: 0,
    coach_name: "",
  });
  //initial Hook
  useEffect(() => {
    document.title = "Available Seats | AA Enterprise";

    if (JSON.stringify(location.state) !== undefined) {
      setPastData({
        train_id: location.state.train_id,
        train_name: location.state.train_name,
        date: location.state.date,
        tour_id: location.state.tourId,
        tour_name: location.state.tour_name,
        coachTypeId: location.state.coachTypeId,
        coach_name: location.state.coach_name,
        coach_number: location.state.coach_number,
        starting_city_id: location.state.starting_city_id,
        starting_city_name: location.state.starting_city_name,
        ending_city_id: location.state.ending_city_id,
        ending_city_name: location.state.ending_city_name,
        status: location.state.status,
        cnic: location.state.cnic,
      });
    } else {
      history.push("/add-ticket");
    }
  }, []);
  //hook to run after we get vacant seats from server
  useEffect(() => {
    if (vacantSeats.stop_time !== "") {
      _setBookedSeats();
    }
  }, [vacantSeats.stop_time]);

  useEffect(() => {
    if (pastData.coachTypeId) {
      _getVacantSeats();
    }
  }, [pastData.coachTypeId]);

  useEffect(() => {
    if (pastData.status || pastData.status !== undefined) {
      setSeatStatus(pastData.status);
      $("#status option:first").text(location.state.status);
      $("#status option:first").val(location.state.status.toLowerCase());
    }
  }, [pastData.status]);

  async function _getVacantSeats() {
    let response = await getVacantSeats(
      pastData.coachTypeId,
      pastData.starting_city_id,
      pastData.ending_city_id
    );
    if (response.status === 200) {
      setVacantSeats(response.data);
    } else if (response.status >= 400) {
      toast.error("Failed");
    }
  }

  function submit() {
    if ((children || toBookSeats.length) && seatStatus !== "") {
      history.push({
        pathname: "add-ticket-customer",
        state: {
          tc_id: pastData.coachTypeId,
          st_city: pastData.starting_city_id,
          end_city: pastData.ending_city_id,
          toBookSeats: toBookSeats,
          status: seatStatus,
          children: children,
          stateData,
        },
      });
    } else {
      toast.warn("Please select all values");
    }
  }

  function _setBookedSeats() {
    // For Seats
    let i = 1;
    for (i = 1; i <= 42; i++) {
      let seat = i + "S";
      let selector = "div[name=" + seat + "]";
      if (!vacantSeats.difference.includes(seat)) {
        $(selector).addClass("booked");
      }
    }

    i = 1;
    for (i = 1; i <= 8; i++) {
      let berth = i + "B";
      let selector = "div[name=" + berth + "]";
      if (!vacantSeats.difference.includes(berth)) {
        $(selector).addClass("booked");
      }
    }
  }

  function bookTicket(e) {
    let elem = `#${e}`;
    if (vacantSeats.difference.includes(e)) {
      if ($(elem).is(":checked")) {
        // Add
        setToBookSeats((prevArray) => [...prevArray, e]);
      } else {
        // Remove
        setToBookSeats(toBookSeats.filter((item) => item !== e));
      }
    } else {
      $(elem)[0].checked = false;
    }
  }

  function handleSeatStatus(e) {
    const { value } = e.target;
    setSeatStatus(value);
  }

  function handleChildrenChange(e) {
    e.preventDefault();
    const { value } = e.target;
    if (value >= 0) {
      setChildren(Number(value));
    } else {
      toast.warn("Enter valid number");
    }
  }

  return (
    <>
      <div class="row">
        <div class="col-1 offset-8 bg-header  no-space">
          <p class="text">GUARD</p>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="1B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("1B");
                  }}
                />
              </label>
            </div>
            <div name="1B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>1B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-1"></div>
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="1S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("1S");
                  }}
                />
              </label>
            </div>
            <div name="1S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>1S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="2S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("2S");
                  }}
                />
              </label>
            </div>
            <div name="2S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>2S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="3S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("3S");
                  }}
                />
              </label>
            </div>
            <div name="3S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>3S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="4S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("4S");
                  }}
                />
              </label>
            </div>
            <div name="4S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>4S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="18S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("18S");
                  }}
                />
              </label>
            </div>
            <div name="18S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>18S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="2B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("2B");
                  }}
                />
              </label>
            </div>
            <div name="2B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>2B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-1 bg-berth"></div>
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="5S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("5S");
                  }}
                />
              </label>
            </div>
            <div name="5S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>5S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="6S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("6S");
                  }}
                />
              </label>
            </div>
            <div name="6S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>6S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="7S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("7S");
                  }}
                />
              </label>
            </div>
            <div name="7S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>7S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="8S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("8S");
                  }}
                />
              </label>
            </div>
            <div name="8S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>8S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="19S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("19S");
                  }}
                />
              </label>
            </div>
            <div name="19S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>19S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="3B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("3B");
                  }}
                />
              </label>
            </div>
            <div name="3B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>3B</h5>
            </div>
          </div>
        </div>
        {/* <div class="col-2 offset-1 bg-berth"></div> */}
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="9S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("9S");
                  }}
                />
              </label>
            </div>
            <div name="9S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>9S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="10S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("10S");
                  }}
                />
              </label>
            </div>
            <div name="10S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>10S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="11S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("11S");
                  }}
                />
              </label>
            </div>
            <div name="11S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>11S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="12S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("12S");
                  }}
                />
              </label>
            </div>
            <div name="12S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>12S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="20S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("20S");
                  }}
                />
              </label>
            </div>
            <div name="20S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>20S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="4B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("4B");
                  }}
                />
              </label>
            </div>
            <div name="4B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>4B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-1 bg-berth"></div>
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="13S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("13S");
                  }}
                />
              </label>
            </div>
            <div name="13S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>13S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="14S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("14S");
                  }}
                />
              </label>
            </div>
            <div name="14S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>14S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="15S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("15S");
                  }}
                />
              </label>
            </div>
            <div name="15S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>15S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="16S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("16S");
                  }}
                />
              </label>
            </div>
            <div name="16S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>16S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="21S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("21S");
                  }}
                />
              </label>
            </div>
            <div name="21S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>21S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* single seat */}
      <div class="row">
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-2 "></div>
        <div class="col-2 bg">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="17S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("17S");
                  }}
                />
              </label>
            </div>
            <div name="17S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>17S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Skip Line */}
      <div class="row">
        <div
          class="col-1 offset-11 empty-box"
          style={{ border: "2px solid black" }}
        >
          <div class=""></div>
        </div>
      </div>

      {/* Berth with a block at end */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="5B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("5B");
                  }}
                />
              </label>
            </div>
            <div name="5B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>5B</h5>
            </div>
          </div>
        </div>
        <div class="col-1 offset-3 bg-header">
          <div></div>
        </div>
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="22S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("22S");
                  }}
                />
              </label>
            </div>
            <div name="22S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>22S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="23S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("23S");
                  }}
                />
              </label>
            </div>
            <div name="23S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>23S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="24S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("24S");
                  }}
                />
              </label>
            </div>
            <div name="24S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>24S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="25S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("25S");
                  }}
                />
              </label>
            </div>
            <div name="25S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>25S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="39S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("39S");
                  }}
                />
              </label>
            </div>
            <div name="39S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>39S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="6B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("6B");
                  }}
                />
              </label>
            </div>
            <div name="6B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>6B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-1 bg-berth"></div>
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="26S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("26S");
                  }}
                />
              </label>
            </div>
            <div name="26S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>26S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="27S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("27S");
                  }}
                />
              </label>
            </div>
            <div name="27S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>27S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="28S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("28S");
                  }}
                />
              </label>
            </div>
            <div name="28S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>28S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="29S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("29S");
                  }}
                />
              </label>
            </div>
            <div name="29S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>29S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="40S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("40S");
                  }}
                />
              </label>
            </div>
            <div name="40S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>40S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="7B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("7B");
                  }}
                />
              </label>
            </div>
            <div name="7B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>7B</h5>
            </div>
          </div>
        </div>
        {/* <div class="col-2 offset-1 bg-berth"></div> */}
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="30S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("30S");
                  }}
                />
              </label>
            </div>
            <div name="30S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>30S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="31S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("31S");
                  }}
                />
              </label>
            </div>
            <div name="31S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>31S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="32S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("32S");
                  }}
                />
              </label>
            </div>
            <div name="32S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>32S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="33S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("33S");
                  }}
                />
              </label>
            </div>
            <div name="33S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>33S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="41S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("41S");
                  }}
                />
              </label>
            </div>
            <div name="41S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>41S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Berth */}
      <div class="row">
        <div class="col-8 bg-berth">
          <div class="d-flex text-center box-berth">
            <div class="form-check">
              <label class="form-check-label">
                <input
                  id="8B"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("8B");
                  }}
                />
              </label>
            </div>
            <div name="8B">
              <img src={berth} alt="seat-img" class="center" />
            </div>
            <div>
              <h5>8B</h5>
            </div>
          </div>
        </div>
        <div class="col-2 offset-1 bg-berth"></div>
      </div>

      {/* Seats */}
      <div class="row">
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="34S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("34S");
                  }}
                />
              </label>
            </div>
            <div name="34S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>34S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="35S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("35S");
                  }}
                />
              </label>
            </div>
            <div name="35S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>35S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="36S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("36S");
                  }}
                />
              </label>
            </div>
            <div name="36S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>36S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="37S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("37S");
                  }}
                />
              </label>
            </div>
            <div name="37S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>37S</h5>
            </div>
          </div>
        </div>
        <div class="col-2 bg offset-1">
          <div class="box">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="42S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("42S");
                  }}
                />
              </label>
            </div>
            <div name="42S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>42S</h5>
            </div>
          </div>
        </div>
      </div>

      {/* Single seat */}
      <div class="row">
        <div class="col-2"></div>
        <div class="col-2"></div>
        <div class="col-2 "></div>
        <div class="col-2 bg">
          <div class="box" onclick="">
            <div class="form-check">
              <label class="form-check-label p">
                <input
                  id="38S"
                  type="checkbox"
                  class="form-check-input"
                  onClick={() => {
                    bookTicket("38S");
                  }}
                />
              </label>
            </div>
            <div name="38S" class="col-4">
              <img src={seat} alt="seat-img" class="center" />
            </div>
            <div class="col-4">
              <h5>38S</h5>
            </div>
          </div>
        </div>
      </div>

      <div class="card-body pt-0 row">
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Seat Type</h4>
          </div>
          <div class="row">
            <div class="mb-3 col-6">
              <select
                id="status"
                value={seatStatus}
                onChange={handleSeatStatus}
                name="status"
                class="form-select form-control wide"
              >
                <option value="">Choose...</option>
                <option value="booked">Booked</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card-header">
            <h4 class="card-title">Children Seats</h4>
          </div>
          <div class="col-6">
            <input
              type="number"
              value={children}
              class="form-control"
              placeholder="Number of childeren tickets"
              name="children"
              onChange={handleChildrenChange}
            />
          </div>
        </div>
        <button
          type="button"
          name="create_ticket_btn"
          class="btn btn-primary"
          onClick={() => {
            submit();
          }}
        >
          Next
        </button>
      </div>
    </>
  );
}

export default BrakeGhouri;
