import City from '../services/city/city';

export const addCity = (form) => {

    return City.addCity(form).then(response => {
        return {status: response.status,data:response.data, message: "CIty Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const allCities = (params) => {

    return City.allCities(params).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteCity = (id) => {

    return City.deleteCity(id).then(response => {
        return {status: response.status,data:response.data, message: response.message};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getCityProfile = (id) => {
    
    return City.getCityProfile(id).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const updateCity = (form, id) => {
    
    return City.updateCity(form, id).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};
