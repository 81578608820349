import React, { useState, useEffect } from 'react'
import $ from 'jquery'

import { allTrains } from '../../store/actions/train.actions';
import { addTour } from '../../store/actions/tour.actions';

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AddTour() {
    const [trainId, setTrainId] = useState(0);
    const [trains, setTrains] = useState([]);
    const [params, setParams] = useState({
        _count: 100,
        _pageNo: 1,
    })
    const [tourForm, setTourForm] = useState({
        train_id: 0,
        depart_time: "",
        arrival_time: "",
        booking_start_time: "",
        reserve_stop_time: ""
    })


    useEffect(() => {
        document.title = 'Add Tour | AA Enterprise';
    }, []);
    useEffect(() => {
        fetchTrains()
    }, [params])
    useEffect(() => {
        setTourForm(prevValue => {
            return {
                ...prevValue,
                train_id: trainId,
            }
        })
    }, [trainId])


    async function fetchTrains() {
        let fetch_trains = await allTrains(params);
        if (fetch_trains.status === 200) {
            setTrains(fetch_trains.data.trains)
        } else if (fetch_trains.status >= 400) {
            toast.error(fetch_trains.message.message)
        }
    }

    async function onSubmitForm(e) {
        e.preventDefault();
        if (validate()) {
            let response = await addTour(tourForm);
            if (response.status === 200) {
                toast.success(response.message)
                setTourForm(prevValue => {
                    return {
                        ...prevValue,
                        depart_time: '',
                        arrival_time: '',
                        booking_start_time: '',
                        reserve_stop_time: ''
                    }
                })
            } else if (response.status >= 400) {
                toast.error(response.message.message)
            }
        } else {
            toast.warn("Please fill all fields")
        }
    }


    function validate() {
        if (tourForm.train_id && tourForm.depart_time && tourForm.arrival_time && tourForm.booking_start_time && tourForm.reserve_stop_time) {
            return true;
        }
        return false
    }

    function onChangeHandler(event) {
        const { name, value } = event.target;
        setTourForm(prevValue => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    function selectTrain(e) {
        e.preventDefault();
        setTrainId(document.getElementById("trains_select").value);
        document.getElementById("display_train").innerHTML = $("#trains_select").find('option:selected').text();
    }
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Tour</h2>
                    <p class="text-light">Add a train tour</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Trains</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">
                                <form type="submit" name="fetch_trains" onSubmit={(e) => selectTrain(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Select Train</label>
                                            <select id="trains_select" name='train_id' class="form-select form-control wide">
                                                <option value=''>Choose...</option>
                                                {trains.map((train) => {
                                                    return (
                                                        <option key={train.key} value={train.id} data-name={train.name}>{train.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </div>
                                    </div>

                                    <button type="submit" name='fetch_trains_btn' class="btn btn-primary">Select</button>
                                    <div class='row'>
                                        <div class="mb-3 col-md-6">
                                            <p>Selected Train is: <i id="display_train" style={{ color: 'red' }}></i></p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add New Tour</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">
                                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Train Departure Time</label>
                                            <input type="datetime-local" name='depart_time' value={tourForm.depart_time} onChange={onChangeHandler} class="form-control" required />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Train Arrival Time</label>
                                            <input type="datetime-local" name='arrival_time' value={tourForm.arrival_time} onChange={onChangeHandler} class="form-control" required />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Reservation Start Time</label>
                                            <input type="datetime-local" name='booking_start_time' value={tourForm.booking_start_time} onChange={onChangeHandler} class="form-control" required />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Reservation Stop Time</label>
                                            <input type="datetime-local" name='reserve_stop_time' value={tourForm.reserve_stop_time} onChange={onChangeHandler} class="form-control" required />
                                        </div>

                                    </div>
                                    <button type="submit" name='submit' class='btn btn-primary'>Submit</button>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddTour
