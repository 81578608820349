import React, { useEffect, useState } from "react";
import $ from "jquery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../ticket/logo.jpg";
import { print } from "../../store/actions/ticket.actions";
import { getMyBookingAmountToday } from "../../store/actions/user.actions";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function Index() {
  const [todayBooking, setTodayBooking] = useState({
    adv_booking: 0,
    curr_booking: 0,
  });
  const [date , setDate] = useState("")

  document.body.removeAttribute("class");
  document.getElementById("root").removeAttribute("class");
  document.getElementById("root").removeAttribute("class");
  useEffect(() => {
    document.title = "Index | AA Enterprise";
    $("#preloader").delay(333).fadeOut("slow");
    $("body").delay(333);
    $("#main-wrapper").css("opacity", "1");
  });
  //   useEffect(() => {
  //      $('[data-loader="circle-side"]').fadeOut()
  //      $('#preloader').delay(333).fadeOut('slow')
  //      $('body').delay(333)
  //   })

  async function onSubmitForm(event) {
    event.preventDefault();

    if (!window.navigator.userAgent.toLowerCase().includes("windows")) {
      var printWin = window.open(
        "_blank",
        "left=0,top=0,width=744,height=1052"
      );
      printWin.document.open();
      printWin.document.clear();
      printWin.document.write(
        `'<center> <img src=${logo} width='120' height='60' alt="seat-img" class="center" /> </center>'`
      );

      printWin.document.writeln("<p> Test Print </p>");
      printWin.document.writeln("<p> Test Print </p>");

      printWin.print();
      printWin.document.close();
      toast.success("Test Ticket Printed");
    } else {
      let obj = {
        seat: "test",
        fare: "500",
        st_city: "test",
        end_city: "test",
        phone: "test",
        departure_time: "test",
        arrival_time: "test",
        customer_name: "test",
        cnic: "test",
        coach: "test",
        train_name: "test",
        id: "testid",
      };
      print(obj);
      toast.success("Test Print");
    }
  }


  async function getUserBookingAmount() {
    let fetchDate = date==="" ? "today" : date
    let myBookingToday = await getMyBookingAmountToday(fetchDate);
    if (myBookingToday.status === 200) {
      setTodayBooking(myBookingToday.data);
    } else {
      toast.error("Unable to fetch your today booking.");
    }
  }

  function handleDateChange(e){
    setDate(e.target.value)
  }

  useEffect(() => {
    getUserBookingAmount();
  }, []);

  useEffect(() => {
    if(date!==""){
      getUserBookingAmount()
    }
  }, [date]);

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Index</h2>
          <p class="text-light">Page Description</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="basic-form">
                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="d-flex justify-content-evenly">
                    <button type="submit" name="submit" class="btn btn-success">
                      Test Print
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Your Total</h4>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    
                      <div class="row">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">
                            Select  Date
                          </label>
                          <input
                            id="select_date"
                            type="date"
                            name="depart_time"
                            class="form-control"
                            placeholder="Departure Time"
                            onChange={(e) =>handleDateChange(e)}
                            value={date}
                            required
                          />
                        </div>
                      </div>

                      <div class="row">
                        <p>
                          Selected Date:{" "}
                          <i id="display_date" style={{ color: "red" }}>{date}</i>
                        </p>
                      </div>
                    
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="row">
                      <div class="mb-3 col-md-6">
                        <label class="form-label">Curr Total</label>
                        <h2 style={{ color: "red" }}>
                          {todayBooking.curr_booking}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="row">
                      <div class="mb-3 col-md-6">
                        <label class="form-label">Adv Total</label>
                        <h2 style={{ color: "red" }}>
                          {todayBooking.adv_booking}
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Index;
