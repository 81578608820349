import Pref from '../services/preference/preference';

export const allPref = (form) => {

    return Pref.allPref(form).then(response => {
        return { status: response.status, data: response.data, message: "success" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};

export const updatePref = (updateId, form) => {

    return Pref.updatePref(updateId, form).then(response => {
        return { status: response.status, data: response.data, message: "Preference Updated" };
    }).catch(e => {
        return { status: e.response.status, message: e.response.data };
    })

};
