import Ticket from "../services/ticket/ticket";
import axios from "axios";

export const getVacantSeats = (tc_id, st_city, end_city) => {
  return Ticket.getVacantSeats(tc_id, st_city, end_city)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "Vacant Seats fetched",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export function print(data) {
  axios.post("http://localhost:3320/printpos", { ...data });
}

export function testPrinter() {
  return axios.get("http://localhost:3320/hello");
}

export async function generateTicket(
  cst_id,
  tc_id,
  status,
  st_city,
  end_city,
  toBookSeats,
  isOnTrain,
  isDiscounted,
  children,
  isFreeTicket = false
) {
  let form = {
    cst_id: Number(cst_id),
    tc_id: Number(tc_id),
    status: status,
    st_city: Number(st_city),
    end_city: Number(end_city),
    seats: toBookSeats,
    isOnTrain: isOnTrain,
    isDiscounted: isDiscounted,
    children: children,
  };

  return Ticket.generateTicket(form)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: response.message,
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
}

export const getAllTickets = (params) => {
  return Ticket.getAllTickets(params)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "tickets fetched",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const cancelTicket = (id, amount) => {
  return Ticket.cancelTicket(id, amount)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "Ticket Canceled",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const getTicketById = (id) => {
  return Ticket.getTicketById(id)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "success",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const getTicketByTourId = (tour_id) => {
  return Ticket.getTicketByTourId(tour_id)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "success",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const bookReservedTicket = (ticket_id) => {
  return Ticket.bookReservedTicket(ticket_id)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "success",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const getTicketDetailsById = (id) => {
  return Ticket.getTicketDetailsById(id)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "success",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const printOnlineTicket = (data) => {
  return Ticket.printOnlineTicket(data)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "success",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};

export const checkDiscountByTour = (id) => {
  return Ticket.checkDiscountByTour(id)
    .then((response) => {
      return {
        status: response.status,
        data: response.data,
        message: "success",
      };
    })
    .catch((e) => {
      return { status: e.response.status, message: e.response.data };
    });
};
