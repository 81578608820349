import axiosInstance from '../../../utils/axios';

class Pref {

    static allPref = (params) => {

        let apiPath = `/api/prefs?_count=${params._count}&_pageNo=${params._pageNo}`;
        return axiosInstance.get(apiPath).then(response => { return response })

    };

    static updatePref = (updateId, form) => {

        let apiPath = '/api/prefs/' + Number(updateId);
        return axiosInstance.put(apiPath, {
            ...form
        });

    };

}
export default Pref;