import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import seat from "./new-seat.png";
import berth from "./new-berth.png";
import "./layout.css";
import { toast } from "react-toastify";
import $ from "jquery";
import { getVacantSeats } from "../../store/actions/ticket.actions";

function AcBusiness() {
  
  const [checkedArray, setCheckedArray] = useState([]);
  

  //checkbox function
  function onBoxChange(value) {
    
  }

  

  return (
    <div style={{ overflowX: "auto" }}>
      <div class="row row-border-top">
        <div class="col-2 offset-4 bg-header  no-space">
          <h3 class="text-uppercase text-center">Path</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 text-center col-border">
          <i class="fas fa-toilet fa-3x"></i>
        </div>
        <div class="col-4 ">
          <h4 class="text-uppercase text-center entrance pt-2">Entrance</h4>
        </div>
      </div>

      {/* 1st row */}

      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("AU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="1S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">AU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("AM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="2S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">AM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("AL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="3S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">AL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* button and cabin row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* button and cabin row */}

      {/* 2 nd row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("AU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="4S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">AU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("AM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="5S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">AM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("AL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="AL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("AL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("AL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="6S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">AL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("BU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="7S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">BU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("BM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="8" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">BM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("BL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="9S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">BL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>

      {/* 3rd row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("BU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="10S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">BU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("BM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="11S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">BM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("BL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="BL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("BL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("BL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="12S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">BL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("CU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="13S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("CM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="14S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("CL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="15S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 4 row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("CU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="16S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">CU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("CM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="17S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("CL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="CL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("CL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("CL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="18S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">CL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("DU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="19S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">DU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("DM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="20S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("DL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 5th */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("DU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">DU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("DM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("DL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="DL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("DL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("DL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">DL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("EU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">EU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("EM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">EM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("EL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">EL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 6th row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("EU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">EU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("EM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">EM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("EL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="EL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("EL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("EL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">EL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("FU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">FU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("FM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">FM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("FL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">FL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 7 row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("FU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">FU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("FM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">FM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("FL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="FM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("FL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("FL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">FL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("GU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="GU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("GU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("GU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">GU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("GM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="GM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("GM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("GM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">GM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("GL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="GL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("GL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("GL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">GL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 8th row */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("GU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="GU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("GU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("GU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">GU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("GM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="GM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("GM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("GM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">GM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("GL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="GL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("GL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("GL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">GL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("HU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="HU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("HU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("HU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">HU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("HM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="HM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("HM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("HM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">HM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("HL1S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="HL1S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("HL1S") ? true : false}
                        onChange={() => {
                          onBoxChange("HL1S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">HL1S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 9th row */}

      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("HU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="HU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("HU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("HU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">HU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("HM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="HM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("HM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("HM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">HM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("HL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="HL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("HL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("HL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">HL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("IU3S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="IU3S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("IU3S") ? true : false}
                        onChange={() => {
                          onBoxChange("IU3S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">IU3S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("IM2S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="IM2S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("IM2S") ? true : false}
                        onChange={() => {
                          onBoxChange("IM2S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">IM2S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("ILIS")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="ILIS"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("ILIS") ? true : false}
                        onChange={() => {
                          onBoxChange("ILIS");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">ILIS</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  "></div>
          <div class="col-2  ">
            <h5 class="h5c">Cabin</h5>
          </div>
          <div class="col-2  ">
            <button class="button">Table</button>
          </div>
        </div>
      </div>
      {/* 10 */}
      <div class="rowBusiness ">
        <div className="right">
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("IU6S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="IU6S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("IU6S") ? true : false}
                        onChange={() => {
                          onBoxChange("IU6S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="78S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4 ">
                  <h5 class="h5c">IU6S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("IM5S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="IM5S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("IM5S") ? true : false}
                        onChange={() => {
                          onBoxChange("IM5S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="79S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">IM5S</h5>
                </div>
              </div>
            </div>
          </div>
          <div class="col-2  ">
            <div
              class="box"
              style={{
                background: checkedArray?.includes("IL4S")
                  ? "rgb(255, 0, 0)"
                  : "",
              }}
            >
              <div class="row">
                <div class="col-4">
                  <div class="form-check">
                    <label class="form-check-label p">
                      <input
                        id="IL4S"
                        type="checkbox"
                        class="form-check-input"
                        disabled={checkedArray?.includes("IL4S") ? true : false}
                        onChange={() => {
                          onBoxChange("IL4S");
                        }}
                      />
                    </label>
                  </div>
                </div>
                <div name="80S" class="col-4">
                  <img src={seat} alt="seat-img" class="center" />
                </div>
                <div class="col-4">
                  <h5 class="h5c">IL4S</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
}

export default AcBusiness;
