import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { updateTrainCity } from '../../store/actions/train.actions'

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery'

toast.configure({
    autoClose: 4000,
    draggable: true
});

function UpdateTrainCity() {
    const location = useLocation()
    const [updateId, setUpdateId] = useState()
    const [cityName, setCityName] = useState('')
    const history = useHistory()
    const [isStarting, setIsStarting] = useState(0)
    const [isEnding, setIsEnding] = useState(0)
    const [trainCityForm, setTrainCityForm] = useState({
        arrival_time: 0,
        departure_time: 0,
        sr_no: 1,
        name: ''
    });
    const [valid, setValid] = useState(false);


    useEffect(() => {
        document.title = 'Update Train City | AA Enterprise';

        if (JSON.stringify(location.state) !== undefined) {
            setUpdateId(Number(location.state.id));
            setTrainCityForm({
                arrival_time: Number(location.state.arrival_time),
                departure_time: Number(location.state.departure_time),
                sr_no: Number(location.state.sr_no),
            })
            setIsStarting(Boolean(location.state.is_starting))
            setIsEnding(Boolean(location.state.is_ending))
            setCityName(location.state.name);
            if (Number(location.state.is_starting)) {
                $('input[name="is_starting"]').prop('checked', true);
                $('input[name="is_ending"]').prop('checked', false);
            } else if (Number(location.state.is_ending)) {
                $('input[name="is_starting"]').prop('checked', false);
                $('input[name="is_ending"]').prop('checked', true);
            } else {
                $('input[name="is_starting"]').prop('checked', false);
                $('input[name="is_ending"]').prop('checked', false);
            }
        }
    }, []);
    useEffect(() => {
        if (isStarting) {
            setIsEnding(false)
            $('input[name="is_ending"]').prop('checked', false);
        } else {
            // setIsStarting(false);
            // $('input[name="is_starting"]').prop('checked', false);
        }
    }, [isStarting])
    useEffect(() => {
        if (isEnding) {
            setIsStarting(false);
            $('input[name="is_starting"]').prop('checked', false);
        } else {
            // setIsEnding(false)
        }
    }, [isEnding]);
    useEffect(() => {
        if (valid) {

        }
    }, [valid]);

    async function handleFormSubmit(e) {
        e.preventDefault();
        let objj = {
            arrival_time: trainCityForm.arrival_time,
            departure_time: trainCityForm.departure_time,
            is_ending: isEnding,
            is_starting: isStarting,
            sr_no: trainCityForm.sr_no
        }
        if (validate()) {
            let obj = {
                arrival_time: trainCityForm.arrival_time,
                departure_time: trainCityForm.departure_time,
                is_ending: isEnding ? 1 : 0,
                is_starting: isStarting ? 1 : 0,
                sr_no: trainCityForm.sr_no
            }
            let response = await updateTrainCity(obj, updateId);
            if (response.status === 200) {
                toast.success("City updated successfully");
                history.push('/all-train-cities')
            } else if (response.status >= 400) {
                toast.error(response.message.message);
            }
        }
    }

    function handleFormChange(e) {
        let { name, value } = e.target;

        value = parseInt(value);
        setTrainCityForm(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })
    }

    function validate() {
        if (trainCityForm.sr_no === 1 && isEnding) {
            toast.warn("Invalid serial number OR ending city attribute");
            return false;
        } else if (trainCityForm.sr_no !== 1 && isStarting) {
            toast.warn("Invalid serial number OR starting city attribute");
            return false;
        }
        if (trainCityForm.sr_no === 1 && (!isEnding && !isStarting)) {
            toast.warn("Serial No 1 should be Starting City");
            return false;
        }
        if (isStarting && trainCityForm.arrival_time !== 0) {
            toast.warn("Starting city arrival time should be ZERO");
            return false;
        }
        if (isEnding && trainCityForm.departure_time !== 0) {
            toast.warn("Ending city departure time should be ZERO");
            return false;
        }
        if (!isEnding && !isStarting) {
            if (trainCityForm.arrival_time === 0 || trainCityForm.departure_time === 0) {
                toast.warn("Enter Arrival and Departure Time");
                return false;
            }
        }
        return true
    }

    function isStartingHandle(e) {
        setIsStarting(e.target.checked)
    }
    function isEndingHandle(e) {
        setIsEnding(e.target.checked)
    }


    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Trains</h2>
                    <p class="text-light">Update city for train</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Update Train City</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form type="submit" name="create_train_form" onSubmit={(e) => handleFormSubmit(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">City</label>
                                            <input type="text" name='name' value={cityName} class="form-control" placeholder="City . . ." disabled readonly />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Serial Number</label>
                                            <input type="number" name='sr_no' onChange={handleFormChange} value={trainCityForm.sr_no} class="form-control" placeholder="City serial number" min='1' max='200' />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Arrival Time</label>
                                            <input type="number" name='arrival_time' onChange={handleFormChange} value={trainCityForm.arrival_time} class="form-control" placeholder="Value in Minutes" min='0' />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Departure Time</label>
                                            <input type="number" name='departure_time' onChange={handleFormChange} value={trainCityForm.departure_time} class="form-control" placeholder="Value in Minutes" min='0' />
                                        </div>
                                        <div class="mb-3 row">
                                            <div class="col-sm-12">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="is_starting" onChange={isStartingHandle} />
                                                    <label class="form-check-label">
                                                        This is Starting City
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" name="is_ending" onChange={isEndingHandle} />
                                                    <label class="form-check-label">
                                                        This is Ending City
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <button type="submit" name='create_train_btn' class="btn btn-success">Update</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateTrainCity
