import axiosInstance from '../../../utils/axios';

class Offline {
    
    static addOfflineDevice = (form) => {
    
        let apiPath = '/api/offline';
        return axiosInstance.post(apiPath, {
            ...form
        });
    
    };

    static ListOfflineDevice = () => {
    
        
        let apiPath = `/api/offline/`;
        return axiosInstance.get(apiPath);
    
    };
    
    
    
    static deleteOfflineDevice = (id) => {
    
        let apiPath = '/api/offline/'+id;
        return axiosInstance.delete(apiPath);
    
    };
    
    
}
export default Offline;
