import axiosInstance from '../../../utils/axios';

class Tour {

    static addTour = (form) => {

        let apiPath = '/api/trainTours';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static allTours = (params, id) => {

        let apiPath = '/api/trainTours/' + id + '?_count=' + parseInt(params._count) + '&_pageNo=' + parseInt(params._pageNo);
        return axiosInstance.get(apiPath);

    };

    static deleteTour = (id) => {

        let apiPath = '/api/trainTours/' + id;
        return axiosInstance.delete(apiPath);

    };

    static updateTour = (form, id) => {

        let apiPath = '/api/trainTours/' + id;
        return axiosInstance.put(apiPath, {
            ...form
        });

    };

    static getToursByDate = (date, train_id) => {

        let apiPath = '/api/trainTours/bydate/' + date;
        return axiosInstance.get(apiPath, {
            train_id: train_id
        });

    };

    static addTourCoach = (form) => {

        let apiPath = '/api/tourCoaches';
        return axiosInstance.post(apiPath, {
            ...form
        });

    };

    static getTrainCoaches = (id, params) => {
        var apiPath = '';
        if (params === undefined || params === null) {
            apiPath = '/api/tourCoaches/' + id;
        } else {
            apiPath = '/api/tourCoaches/' + id + '?_count=' + params._count + '&__pageNo=' + params._pageNo;
        }
        // let apiPath = '/api/tourCoaches/' + id;
        return axiosInstance.get(apiPath);

    };


    static getTourCoaches = (id, params) => {
        var apiPath = '';
        if (params === undefined || params === null) {
            apiPath = '/api/tourCoaches/webapp/' + id;
        } else {
            apiPath = '/api/tourCoaches/webapp/' + id + '?_count=' + params._count + '&__pageNo=' + params._pageNo;
        }
        // let apiPath = '/api/tourCoaches/' + id;
        return axiosInstance.get(apiPath);

    };

    static deleteTourCoach = (id) => {

        let apiPath = '/api/tourCoaches/' + id;
        return axiosInstance.delete(apiPath);

    };

    static updateTourCoach = (form, id) => {

        let apiPath = '/api/tourCoaches/' + id;
        return axiosInstance.put(apiPath, {
            ...form
        });

    };

    static getTrainToursByDate = (date, train_id, status) => {

        let apiPath = '/api/trainTours/byTrainDate/' + date;
        if (status) {
            return axiosInstance.post(apiPath, {
                train_id: train_id,
                status: status
            });
        } else {
            return axiosInstance.post(apiPath, {
                train_id: train_id
            });
        }


    };

    static activateTour = (id) => {

        let apiPath = '/api/trainTours/activate/' + id;
        return axiosInstance.get(apiPath);

    };

    static copyTour = (id , days) => {
        console.log("data from service is",id , days);
        let apiPath = '/api/trainTours/copy/' + id;
        return axiosInstance.post(apiPath , {
            days
        });

    };

}
export default Tour;
