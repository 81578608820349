import React, { useState, useEffect } from 'react'
import $ from 'jquery';

function Testing() {
  const [counter, setCounter] = useState(1);
  const check = false;
  const [num, setNum] = useState(0);
  const [dom, setDom] = useState({});
  const train_id = "train_id" + counter;
  const coach_id = "coach_id" + counter;
  const elem = (divId, train_id, coach_id) =>
    <div id={divId}>
      <button type="button" onClick={() => { removeElem(divId) }}>remove</button>
      <input type="text" value={dom[train_id]} name={train_id} onChange={change} />
      <input type="text" value={dom[coach_id]} name={coach_id} onChange={change} />
    </div>
    ;
  const [array, setArary] = useState([elem("div" + counter, train_id, coach_id)]);

  useEffect(() => {
    if (dom) {
      console.log(dom);
    }
  }, [dom])
  useEffect(() => {
    if (num) {
      console.log(num);
    }
  }, [num]);


  function change(e) {
    const { name, value } = e.target;
    setDom((preveValue) => {
      return {
        ...preveValue,
        [name]: value
      }
    })
  }

  function addMore() {
    const value = counter + 1;
    console.log(counter, value);
    console.log(array);
    let newElem = elem("div" + value, "train_id" + value, "coach_id" + value);
    console.log("newElem.props: ", newElem.props);
    setArary([...array, newElem]);
    setCounter(counter + 1);
  }

  function removeElem(divId) {
    $("#" + divId).remove();
  }

  function checkId() {

  }

  return (
    <>
      {array.map((arr) => {
        return arr;
      })}
      <button onClick={() => { addMore() }} >add</button>
      <div id="newid">this is div</div>
      <button onClick={() => { checkId() }} >Check</button>
    </>
  )
}

export default Testing
