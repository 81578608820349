import $ from "jquery";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCustomerProfileByCnic,
  addCustomer,
} from "../../store/actions/customer.actions";
import {
  generateTicket,
  getTicketDetailsById,
  print,
  checkDiscountByTour,
  testPrinter,
} from "../../store/actions/ticket.actions";
import { getTicketFareByData } from "../../store/actions/fare.actions";
import logo from "./logo.jpg";
import jsPDF from "jspdf";
import { isAuthenticUser } from "../../utils/validateUser";

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const makePaperTicket = async (data , user) => {
  const doc = new jsPDF("p", "mm", [203, 90]);
  let i = 0;
  let seats = data.seat.split(",");
  let seatCount = seats.length;
  for (let seat of seats) {
    if (i !== 0) doc.addPage();
    doc.setFontSize("13");
    var startX = 35;
    var finalY = 135;
    //

    //line 1
    doc.text(startX, finalY, "Ticket Id:", null, 90);
    finalY -= 20;
    doc.text(startX, finalY, `${data.id}`, null, 90);
    doc.setFontSize("11");

    startX += 7;
    finalY = 180;

    //line 2
    doc.text(startX, finalY, "Train Name:", null, 90);
    finalY -= 25;
    doc.text(startX, finalY, `${data.train_name}`, null, 90);
    finalY -= 40;
    doc.text(startX, finalY, "Coach:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, `${data.coach}`, null, 90);

    startX += 6;
    finalY = 180;

    //line 2
    doc.text(startX, finalY, "From:", null, 90);
    finalY -= 20;
    doc.text(startX, finalY, `${data.st_city}`, null, 90);
    finalY -= 45;
    doc.text(startX, finalY, "To:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, `${data.end_city}`, null, 90);

    startX += 6;
    finalY = 180;

    //line 2
    doc.text(startX, finalY, "Departure:", null, 90);
    finalY -= 20;
    doc.text(startX, finalY, `${data.departure_time}`, null, 90);
    finalY -= 45;
    doc.text(startX, finalY, "Arrival:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, `${data.arrival_time}`, null, 90);

    startX += 6;
    finalY = 180;

    //line 3
    doc.text(startX, finalY, "Customer Name: ", null, 90);
    finalY -= 30;
    doc.text(startX, finalY, `${data.customer_name}`, null, 90);
    finalY -= 50;
    doc.text(startX, finalY, "CNIC:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, `${data.cnic}`, null, 90);

    //line 3
    doc.setFontSize("11");
    finalY = 140;
    startX += 12;

    doc.text(startX, finalY, "Seat:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, `${seat} (${i + 1} of ${seatCount})`, null, 90);

    finalY = 140;
    startX += 5;
    doc.text(startX, finalY, "Fare:", null, 90);
    finalY -= 15;
    doc.text(startX, finalY, `${data.fare}`, null, 90);

    doc.setFontSize("11");
    finalY = 140;
    startX += 5;
    doc.text(startX, finalY, "Created By:", null, 90);
    finalY -= 22;
    doc.text(startX, finalY, `${user.name}`, null, 90);

    finalY = 140;
    startX += 5;
    doc.text(startX, finalY, "Print:", null, 90);
    finalY -= 22;
    doc.text(startX, finalY, new Date().toLocaleString(), null, 90);
    //side ticket printing
    //line 2

    startX = 35;
    finalY = 45;
    doc.setFontSize("12");

    doc.text(startX, finalY, "ID:", null, 90);
    finalY -= 13;
    doc.text(startX, finalY, `${data.id}`, null, 90);

    startX += 7;
    finalY = 45;
    doc.setFontSize("7");

    doc.text(startX, finalY, "Coach:", null, 90);
    finalY -= 10;
    doc.text(startX, finalY, `${data.coach}`, null, 90);

    startX += 7;
    finalY = 45;
    doc.setFontSize("10");

    doc.text(startX, finalY, "Seat:", null, 90);
    finalY -= 10;
    doc.text(startX, finalY, `${seat}  (${i + 1} of ${seatCount})`, null, 90);

    startX += 14;
    finalY = 45;
    doc.setFontSize("12");

    doc.text(startX, finalY, "From:", null, 90);
    finalY -= 13;
    doc.text(startX, finalY, `${data.st_city}`, null, 90);

    startX += 7;
    finalY = 45;

    doc.text(startX, finalY, "To:", null, 90);
    finalY -= 10;
    doc.text(startX, finalY, `${data.end_city}`, null, 90);

    startX += 9;
    finalY = 45;
    doc.setFontSize("13");

    doc.text(startX, finalY, "Fare:", null, 90);
    finalY -= 12;
    doc.text(startX, finalY, `${data.fare}`, null, 90);

    i++;
  }

  // startX+=7;
  // finalY=180

  // doc.autoPrint()
  var blobPdf = new Blob([doc.output("blob")], { type: "application/pdf" });
  var blobUrl = URL.createObjectURL(blobPdf);
  var w = window.open(blobUrl, "_system", "location=yes");
  w.window.print();
  await sleep(15000);
  w.window.close();
}

function TicketCustomer() {
  const location = useLocation();
  let customer_state = location.state;
  const history = useHistory();
  const [pastData, setPastData] = useState({});
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const [cstId, setSctId] = useState(0);
  const [isOnTrain, setIsOnTrain] = useState(false);
  const [oldCustomer, setOldCustomer] = useState(false);
  const [customerAdded, setCustomerAdded] = useState(false);
  const [isDiscounted, setIsDiscounted] = useState(false);
  const [ticketFare, setTicketFare] = useState(0);
  let [user, setUser] = useState({});
  const [customerProfile, setCustomerProfile] = useState({
    name: "",
    phone: "",
    dob: "",
    cnic: "",
    address: "",
    gender: "",
  });
  const [customer, setCustomer] = useState({
    customer_cnic: "",
  });

  var ticketIds = [];
  var printTicketDetails = [];

  useEffect(() => {
    document.title = "Generate Ticket | AA Enterprise";
    if (JSON.stringify(customer_state) !== undefined) {
      //   console.log("customer state is ", customer_state);
      setPastData({
        tc_id: customer_state.tc_id,
        st_city: customer_state.st_city,
        end_city: customer_state.end_city,
        toBookSeats: customer_state.toBookSeats,
        status: customer_state.status,
        train_name: customer_state.train_name,
        coach_name: customer_state.coach_name,
        coach_number: customer_state.coach_number,
        cnic: customer_state.cnic,
        children: customer_state.children,
        // cst_name: customerProfile.name,
        // cst_cnic: customerProfile.cnic
      });
      let dispObject = {
        train_id: customer_state.stateData.train_id,
        st_city: customer_state.st_city,
        end_city: customer_state.end_city,
        tc_id: customer_state.tc_id,
        seat_number: customer_state.toBookSeats,
        children: customer_state.children,
      };
      getTicketFare(dispObject);
      if (isAuthenticUser()) {
        setUser(JSON.parse(localStorage.getItem("user")).user);
      }
    } else {
      history.push("/add-ticket");
    }
  }, []);

  useEffect(() => {
    if (oldCustomer && !addNewCustomer && customerAdded && cstId !== 0) {
      _generateTicket();
    }
  }, [oldCustomer, addNewCustomer, customerAdded, cstId]);
  useEffect(() => {
    if (
      customer_state.stateData.cnic ||
      customer_state.stateData.cnic !== undefined
    ) {
      setCustomer((prevValue) => {
        return {
          ...prevValue,
          customer_cnic: customer_state.stateData.cnic,
        };
      });
    }
  }, [customer_state.stateData.cnic]);
  useEffect(() => {
    if (customer.customer_cnic !== "") {
      $("#add_cnic_form").submit();
    }
  }, [customer.customer_cnic]);

  async function getTicketFare(dispObject) {
    let resp = await getTicketFareByData(dispObject);
    if (resp.status === 200) {
      setTicketFare(resp.data.fare);
    } else {
      toast.error("Failed to get ticket fare.");
    }
    // console.log("the fare from test api is",resp);
  }

  async function onSubmitForm(e) {
    // To fetch cutomer profile, not generating ticket here
    e.preventDefault();
    if (customer.customer_cnic.length < 13) {
      toast.warn("Invalid CNIC");
    } else {
      let response = await getCustomerProfileByCnic(customer.customer_cnic);
      if (response.status === 200) {
        if (response.data.count) {
          $("#generate_ticket").hide();
          setCustomerProfile(response.data.customers[0]);
          setSctId(response.data.customers[0].id);
          setOldCustomer(true);
          setAddNewCustomer(false);
          setCustomerAdded(true);
        } else {
          toast.warn("Customer not found, Please fill customer details");
          setCustomerProfile({
            name: "",
            phone: "",
            dob: "",
            cnic: "",
            address: "",
            gender: "",
          });
          setCustomerProfile((prevValue) => {
            return {
              ...prevValue,
              cnic: customer.customer_cnic,
            };
          });
          setAddNewCustomer(true);
          setOldCustomer(false);
        }
      } else if (response.status >= 400) {
        toast.error("Failed to fetch customer profile");
      }
    }
  }

  async function addNewCustomerFunc(e) {
    e.preventDefault();
    if (authenticateForm()) {
      let response = await addCustomer(customerProfile);
      if (response.status === 200) {
        toast.success(response.message);
        setCustomerAdded(true);
        setSctId(response.data.id);
      } else if (response.status >= 400) {
        toast.error(response.message.message);
      }
    } else {
      toast.warn("Please enter correct data");
    }
  }
  



  function authenticateForm() {
    if (
      customerProfile.name.length !== 0 &&
      customerProfile.cnic.length !== 0 &&
      customerProfile.gender.length !== 0 &&
      !isNaN(Number(customerProfile.cnic))
    ) {
      return true;
    } else {
      return false;
    }
  }

  function onChangeHandler(e) {
    const { value } = e.target;
    setCustomer((prevValue) => {
      return {
        ...prevValue,
        customer_cnic: value,
      };
    });
  }

  function onChangeNewHandler(event) {
    const { name, value } = event.target;
    setCustomerProfile((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  async function isOnTrainHandle(e) {
    const { name } = e.target;
    if (name === "is_discounted") {
      if (e.target.checked) {
        let res = await checkDiscountByTour(
          Number(customer_state.stateData.tour_id)
        );
        if (res.status === 200) {
          setIsDiscounted(e.target.checked);
        } else if (res.status === 403) {
          toast.warn(res.message.message);
        }
      } else {
        setIsDiscounted(e.target.checked);
      }
    } else {
      setIsOnTrain(e.target.checked);
    }
  }

  function _checkPermissions(checkAllowed) {
    let permissionsNumber = JSON.parse(
      localStorage.getItem("permissionsNumber")
    );
    if (permissionsNumber) {
      for (let i = 0; i < checkAllowed.length; i++) {
        if (permissionsNumber.includes(checkAllowed[i])) {
          return true;
        }
      }
    }
    return false;
  }

  function _checkTT() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.user.role.toLowerCase() === "tt") {
      return true;
    }
    return false;
  }

  function _isAdmin() {
    let user = JSON.parse(localStorage.getItem("user"));
    if (user.user.role.toLowerCase() === "admin") {
      return true;
    }
    return false;
  }

  async function _generateTicket() {
    if (customerAdded) {
      let isTT = await _checkTT();
      //Prelaoder
      $("#preloader").delay(333).fadeIn("slow");

      Date.prototype.today = function () {
        return (
          (this.getDate() < 10 ? "0" : "") +
          this.getDate() +
          "/" +
          (this.getMonth() + 1 < 10 ? "0" : "") +
          (this.getMonth() + 1) +
          "/" +
          this.getFullYear()
        );
      };
      Date.prototype.timeNow = function () {
        return (
          (this.getHours() < 10 ? "0" : "") +
          this.getHours() +
          ":" +
          (this.getMinutes() < 10 ? "0" : "") +
          this.getMinutes() +
          ":" +
          (this.getSeconds() < 10 ? "0" : "") +
          this.getSeconds()
        );
      };

      // if (window.navigator.userAgent.toLowerCase().includes('windows')) {
      //     try {
      //         await testPrinter();
      //     } catch (e) {
      //         toast.error("Printer Error");
      //     }
      //     return
      // }
      // console.log("Params: ", {
      //     isOnTrain: isTT ? true : isOnTrain,
      //     isDiscounted: isDiscounted
      // })
      // console.log({
      //     1: Number(cstId),
      //     2: Number(pastData.tc_id),
      //     3: pastData.status,
      //     4: Number(pastData.st_city),
      //     5: Number(pastData.end_city),
      //     6: pastData.toBookSeats,
      //     7: isTT ? true : isOnTrain,
      //     // customer_state.stateData,
      //     // customerProfile.name,
      //     // customerProfile.cnic,
      //     8: isDiscounted,
      //     9: Number(pastData.children)
      // })
      var generateTicketResponse = await generateTicket(
        Number(cstId),
        Number(pastData.tc_id),
        pastData.status,
        Number(pastData.st_city),
        Number(pastData.end_city),
        pastData.toBookSeats,
        isTT ? true : isOnTrain,
        // customer_state.stateData,
        // customerProfile.name,
        // customerProfile.cnic,
        isDiscounted,
        Number(pastData.children)
      );

      //Printing Ticket
      if (pastData.status !== "reserved") {
        if (generateTicketResponse.status === 200) {
          var ticketDetails = await getTicketDetailsById(
            generateTicketResponse.data.id
          );
          console.log("ticket details are , ", ticketDetails);
          if (ticketDetails.status === 200) {
            let array = [];
            array.push(ticketDetails.data.customer_name);
            array.push(ticketDetails.data.cnic);
            array.push(ticketDetails.data.train_name);
            array.push(ticketDetails.data.coach);
            array.push(pastData.toBookSeats);
            array.push(ticketDetails.data.arrival_time);
            array.push(ticketDetails.data.departure_time);
            array.push(ticketDetails.data.fare);
            printTicketDetails.push(array);
            ticketIds.push(generateTicketResponse.data.id);

            if (!window.navigator.userAgent.toLowerCase().includes("windows")) {
              var printWin = window.open(
                "_blank",
                "left=0,top=0,width=744,height=1052"
              );
              printWin.document.open();
              printWin.document.clear();
              var newDate = new Date();

              printWin.document.write(
                `'<center> <img src=${logo} width='120' height='60' alt="seat-img" class="center" /> </center>'`
              );
              printWin.document.writeln(
                "<p>Ticket Id : <b>" +
                  generateTicketResponse.data.id +
                  "</b></p>"
              );
              printWin.document.writeln(
                "<p>Printing Date : " +
                new Date().toLocaleString() + 
                  "</p>"
              );
              printWin.document.writeln(
                "<p>Name : " + ticketDetails.data.customer_name + "</p>"
              );
              printWin.document.writeln(
                "<p>CNIC : " + ticketDetails.data.cnic + "</p>"
              );
              printWin.document.writeln(
                "<p>Train : " + ticketDetails.data.train_name + "</p>"
              );
              printWin.document.writeln(
                "<p>Coach : " + ticketDetails.data.coach + "</p>"
              );
              printWin.document.writeln(
                "<p>Seats : " + ticketDetails.data.seat + "</p>"
              );
              if (pastData.children) {
                printWin.document.writeln("<p>Child Seats </p>");
              }
              printWin.document.writeln(
                "<p>Fare : " + ticketDetails.data.fare + "</p>"
              );
              if (isTT ? true : isOnTrain) {
                printWin.document.writeln(
                  "<p>=> Penalty added due to without ticket in train</p>"
                );
              }
              if (ticketDetails.data.coach.toLowerCase().includes("open")) {
                printWin.document.writeln(
                  "<p>=> Seat/Berth on this ticket is not confirm</p>"
                );
              }
              await sleep(1000);
              printWin.document.writeln(
                "<p>From : " + ticketDetails.data.st_city + "</p>"
              );
              printWin.document.writeln(
                "<p>To : " + ticketDetails.data.end_city + "</p>"
              );
              printWin.document.writeln(
                "<p>Departure : " + ticketDetails.data.departure_time + "</p>"
              );
              printWin.document.writeln(
                "<p> Developed By: Brown Developers</p>"
              );
              printWin.document.writeln(
                '<p style="text-decoration: overline"></p>'
              );

              printWin.print();
              printWin.onfocus = function () { setTimeout(function () { printWin.close(); }, 100); }
            } else {
              //windows printing section
              try {
                ticketDetails.data.fare = ticketDetails.data.fare.toString();
                if (ticketDetails.data.coach.toLowerCase().includes("open")) {
                  ticketDetails.data.seat = `${ticketDetails.data.seat} (Not Confirmed)`;
                }
                if (user.printer_type === "pos") {
                  print(ticketDetails.data);
                } else {
                  makePaperTicket(ticketDetails.data , user);
                }

                //
                toast.success("Ticket Printed");
              } catch (e) {
                toast.error("Failed to print");
              }
            }
          } else if (ticketDetails.status >= 400) {
            toast.error("Ticket added but failed to print");
          }
        } else if (generateTicketResponse.status >= 400) {
          toast.error(generateTicketResponse.message.message);
        }
      } else {
        if (generateTicketResponse.status === 200) {
          alert("Ticket Id: " + generateTicketResponse.data.id);
        } else if (generateTicketResponse.status >= 400) {
          toast.error(generateTicketResponse.message.message);
        }
      }
      history.push({
        pathname: "/add-ticket",
        state: {
          train_id: Number(customer_state.stateData.train_id),
          date: customer_state.stateData.date,
          tour_id: Number(customer_state.stateData.tour_id),
          coachTypeId: Number(customer_state.stateData.coachTypeId),
          train_name: customer_state.stateData.train_name,
          coach_name: customer_state.stateData.coach_name,
          tour_name: customer_state.stateData.tour_name,
          starting_city_id: customer_state.st_city,
          starting_city_name: customer_state.stateData.starting_city_name,
          status: customer_state.status,
          cnic: customerProfile.cnic,
          tc_id: customer_state.tc_id,
        },
      });

      $("#preloader").delay(333).fadeOut("slow");
    } else {
      toast.warn("Please add customer details");
    }
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Ticket</h2>
          <p class="text-light">ticket out</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Add Customer Profile</h4>
              <h4 class="card-title text-danger">
                Expected Fare is -- Rs.{ticketFare}
              </h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <form
                  id="add_cnic_form"
                  name="add_cnic_form"
                  onSubmit={(e) => onSubmitForm(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">CNIC</label>
                      <input
                        type="text"
                        name="customer_cnic"
                        value={customer.customer_cnic}
                        onChange={onChangeHandler}
                        class="form-control"
                        placeholder="enter customer CNIC"
                      />
                    </div>
                  </div>
                  <button type="submit" name="submit" class="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>

            {oldCustomer && (
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Customer</h4>
                    </div>

                    <div class="card-body">
                      <div class="basic-form">
                        {/* <form name="create_customer_form" onSubmit={(e) => submitCustomer(e)}> */}

                        <div class="row">
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Name</label>
                            <input
                              type="text"
                              name="name"
                              value={customerProfile.name}
                              class="form-control"
                              placeholder="name"
                              disabled
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Phone</label>
                            <input
                              type="text"
                              name="phone"
                              value={customerProfile.phone}
                              class="form-control"
                              placeholder="03123456789 ..."
                              disabled
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">CNIC</label>
                            <input
                              type="text"
                              name="cnic"
                              value={customerProfile.cnic}
                              class="form-control"
                              placeholder="1234567891234  (without dashes)"
                              disabled
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">DOB</label>
                            <input
                              type="date"
                              name="dob"
                              value={customerProfile.dob}
                              class="form-control"
                              placeholder="date of birth"
                              disabled
                            />
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Address</label>
                            <textarea
                              class="form-control"
                              name="address"
                              value={customerProfile.address}
                              rows="4"
                              placeholder="address..."
                              disabled
                            ></textarea>
                            {/* <textarea name="address" value={customerForm.address} onChange={onChangeHandler} rows="3" cols="50" placeholder='address...' /> */}
                          </div>
                          <div class="mb-3 col-md-6">
                            <label class="form-label">Gender</label>
                            <select
                              id="gender"
                              name="gender"
                              value={customerProfile.gender}
                              class="form-select form-control wide"
                              disabled
                            >
                              <option value="">Choose...</option>
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="others">Others</option>
                            </select>
                          </div>
                        </div>
                        {/* <button type="submit" name='create_customer_btn' class={updateId ? "btn btn-success" : "btn btn-primary"}>{updateId ? "Update" : "Submit"}</button> */}
                        {/* </form> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {addNewCustomer && (
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-header">
                      <h4 class="card-title">Add Customer</h4>
                    </div>

                    <div class="card-body">
                      <div class="basic-form">
                        <form
                          name="create_customer_form"
                          onSubmit={(e) => addNewCustomerFunc(e)}
                        >
                          <div class="row">
                            <div class="mb-3 col-md-6">
                              <label class="form-label">
                                Name <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="name"
                                value={customerProfile.name}
                                onChange={onChangeNewHandler}
                                class="form-control"
                                placeholder="name"
                              />
                            </div>
                            <div class="mb-3 col-md-6">
                              <label class="form-label">Phone</label>
                              <input
                                type="text"
                                name="phone"
                                value={customerProfile.phone}
                                onChange={onChangeNewHandler}
                                class="form-control"
                                placeholder="03123456789 ..."
                              />
                            </div>
                            <div class="mb-3 col-md-6">
                              <label class="form-label">
                                CNIC <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                type="text"
                                name="cnic"
                                value={customerProfile.cnic}
                                onChange={onChangeNewHandler}
                                class="form-control"
                                placeholder="1234567891234  (without dashes)"
                                disabled
                              />
                            </div>
                            <div class="mb-3 col-md-6">
                              <label class="form-label">DOB</label>
                              <input
                                type="date"
                                name="dob"
                                value={customerProfile.dob}
                                onChange={onChangeNewHandler}
                                class="form-control"
                                placeholder="date of birth"
                              />
                            </div>
                            <div class="mb-3 col-md-6">
                              <label class="form-label">
                                Gender <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                id="gender"
                                name="gender"
                                value={customerProfile.gender}
                                onChange={onChangeNewHandler}
                                class="form-select form-control wide"
                              >
                                <option value="">Choose...</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="others">Others</option>
                              </select>
                            </div>
                            <div class="mb-3 col-md-6">
                              <label class="form-label">Address</label>
                              <textarea
                                class="form-control"
                                name="address"
                                value={customerProfile.address}
                                onChange={onChangeNewHandler}
                                rows="4"
                                placeholder="address..."
                              ></textarea>
                              {/* <textarea name="address" value={customerForm.address} onChange={onChangeHandler} rows="3" cols="50" placeholder='address...' /> */}
                            </div>
                          </div>
                          <button
                            type="submit"
                            name="create_customer_btn"
                            class="btn btn-primary"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div class="ms-3 row">
              {_checkPermissions([23]) && (
                <div class="col-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="is_discounted"
                      onChange={isOnTrainHandle}
                    />
                    <label class="form-check-label">Railway Discount</label>
                  </div>
                </div>
              )}
              {!_isAdmin() && (
                <div class="col-4">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      name="is_on_train"
                      onChange={isOnTrainHandle}
                    />
                    <label class="form-check-label">Add Penalty Fee</label>
                  </div>
                </div>
              )}
            </div>

            <button
              id="generate_ticket"
              type="button"
              name="generate_ticket"
              onClick={() => {
                _generateTicket();
              }}
              class="btn btn-primary"
            >
              Generate Ticket
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TicketCustomer;
