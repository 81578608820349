import React, { useEffect, useState } from 'react'
import { getUserProfile } from '../../store/actions/user.actions';
import { allCities } from '../../store/actions/city.actions';
import { useHistory } from 'react-router-dom'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery'

toast.configure({
    autoClose: 4000,
    draggable: true
});

function AddAdmin() {
    const history = useHistory();
    const [updateId, setUpdateId] = useState(0);
    const [adminForm, setAdminForm] = useState({
        name: '',
        user_name: '',
        email: '',
        password: '',
        phone: '',
        role: '',
        station: '',
        printer_type:'pos',
        allowed_trains:null
    })
    const [cities, setCities] = useState([])

    useEffect(() => {
        document.title = 'Add Admin | AA Enterprise';

        let params = new URLSearchParams(document.location.search.substring(1));
        setUpdateId(Number(params.get("id")));

        _allCities()

    }, []);
    useEffect(() => {
        if (updateId) {
            _getUserProfile()
        }
    }, [updateId])

    async function _getUserProfile() {
        let response = await getUserProfile(updateId);
        if (response.status === 200) {

            for (const property in response.data) {
                setAdminForm(prevValue => {
                    return {
                        ...prevValue,
                        [property]: response.data[property],
                    }
                })
            }
            $("#station option:selected").text(response.data.station_name);
            $("#station option:selected").val(response.data.station_id);

        } else if (response.status >= 400) {
            toast.error(response.message.message)
        }
    }

    async function _allCities() {
        let response = await allCities({ _count: 500, _pageNo: 1 })
        if (response.status === 200) {
            setCities(response.data.cities);
        } else if (response.status >= 400) {
            toast.error(response.message.message);
        }
    }

    function onChangeHandler(event) {
        const { name, value } = event.target;
        // console.log({ name, value });
        setAdminForm(prevValue => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }

    function authenticateForm() {
        if (adminForm.name.length !== 0
            && adminForm.email.length !== 0
            && adminForm.user_name.length !== 0
            && adminForm.phone.length !== 0
            && adminForm.role.length !== 0
            && adminForm.station) {
            if (updateId) {
                return true;
            } else if (adminForm.password.length !== 0 && adminForm.password.length >= 8) {
                return true;
            } else {
                toast.warn('password must be at least 8 characters long');
                return false;
            }
        } else {
            toast.warn("Some fields are required");
            return false;
        }
    }

    async function onSubmitForm(event) {
        event.preventDefault();
        var obj = {
            name: adminForm.name,
            user_name: adminForm.user_name,
            email: adminForm.email,
            password: adminForm.password,
            phone: adminForm.phone,
            role: adminForm.role,
            updateId: updateId,
            permissions: adminForm.permissions,
            station: Number(adminForm.station),
            allowed_trains: adminForm.allowed_trains,
            printer_type: adminForm.printer_type
        }
        if (authenticateForm()) {
            if (obj.password.length === 0) {
                delete obj.password;
            }
            console.log("final data is", obj);
            history.push({
                pathname: 'admin-permissions',
                state: obj
            })
        }
    }

    function myFunction() {
        var x = document.getElementById("password");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }

    // useEffect(() => {console.log(adminForm);},[adminForm])
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Admins</h2>
                    <p class="text-light">{updateId ? "Update Admin" : "Create a new admin"}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{updateId ? "Update Admin" : "Add New Admin"}</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form name="add_admin_form" onSubmit={(e) => onSubmitForm(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Name{updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <input type="text" name='name' value={adminForm.name} onChange={onChangeHandler} class="form-control" placeholder="name" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Username{updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <input type="text" name='user_name' value={adminForm.user_name} onChange={onChangeHandler} class="form-control" placeholder="username" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Email{updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <input type="email" name='email' value={adminForm.email} onChange={onChangeHandler} class="form-control" placeholder="email" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Password</label>
                                            <input id="password" type="password" name='password' value={adminForm.password} onChange={onChangeHandler} class="form-control" placeholder="password" />
                                            <input type="checkbox" onClick={() => { myFunction() }} /> Show Password
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Phone{updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <input type="text" name='phone' value={adminForm.phone} onChange={onChangeHandler} class="form-control" placeholder="phone" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Designation{updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <select id="role" value={adminForm.role} onChange={onChangeHandler} name='role' class="form-select form-control wide">
                                                <option value=''>Choose...</option>
                                                <option value='admin'>Admin</option>
                                                <option value='station_manager'>Station Manager</option>
                                                <option value='booking_agent'>Booking Agent</option>
                                                <option value='tt'>TT</option>
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Booking Station {updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <select id="station" value={adminForm.station} onChange={onChangeHandler} name='station' class="form-select form-control wide">
                                                <option value=''>Choose...</option>
                                                {cities.map((city) => {
                                                    return (
                                                        <option value={city.id}>{city.name}</option>
                                                    )

                                                })}
                                            </select>
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Printer Type{updateId ? <span style={{ color: 'red' }}>*</span> : null}</label>
                                            <select id="printer_type" value={adminForm.printer_type} onChange={onChangeHandler} name='printer_type' class="form-select form-control wide">
                                                <option value='pos'>POS</option>
                                                <option value='regular'>Regular</option>
                                               
                                            </select>
                                        </div>
                                    </div>
                                    <button type="submit" name='submit' class={updateId ? "btn btn-success" : "btn btn-primary"}>Next</button>

                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddAdmin
