import axiosInstance from '../../../utils/axios';

class Coach {
    
    static addCoach = (form) => {
    
        let apiPath = '/api/ctypes';
        return axiosInstance.post(apiPath , {
            ...form
        });
    
    };

    static allCoaches = (params) => {

        let apiPath = `/api/ctypes?_count=${params._count}&_pageNo=${params._pageNo}`;
        return axiosInstance.get(apiPath).then(response => {return response})
    
    };

    static deleteCoach = (id) => {
    
        let apiPath = '/api/ctypes/'+parseInt(id);
        return axiosInstance.delete(apiPath).then(response => {return response})
    
    };
    
    static updateCoach = (form, id) => {

        let apiPath = '/api/ctypes/'+id;
        return axiosInstance.put(apiPath , {
            ...form
        });
    
    };
    
    static getCoachProfile = (id) => {

        let apiPath = '/api/ctypes/'+id;
        return axiosInstance.get(apiPath);
    
    };
    
}
export default Coach;