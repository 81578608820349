import React from 'react'

function Footer() {
    return (
        <>
            {/* Footer Starts */}
            <div class="footer">
                <div class="copyright">
                    <p>Copyright © Developed by <a href="http://browndevelopers.com/" target="_blank" rel='noreferrer'>BrownDevelopers</a> 2021</p>
                </div>
            </div>
            {/* Footer Ends */}
        </>
    )
}

export default Footer
