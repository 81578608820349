import Tour from '../services/tour/tour';

export const addTour = (form) => {

    return Tour.addTour(form).then(response => {
        return {status: response.status,data:response.data, message: "Tour Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const allTours = (params, id) => {

    return Tour.allTours(params, id).then(response => {
        return {status: response.status,data:response.data, message: "Tours fetched"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const deleteTour = (id) => {

    return Tour.deleteTour(id).then(response => {
        return {status: response.status,data:response.data, message: "Tours Deleted"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const updateTour = (form, id) => {

    return Tour.updateTour(form, id).then(response => {
        return {status: response.status,data:response.data, message: "Tours Updated"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getToursByDate = (date, train_id) => {

    return Tour.getToursByDate(date, train_id).then(response => {
        return {status: response.status,data:response.data, message: "Tours Updated"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const addTourCoach = (form) => {

    return Tour.addTourCoach(form).then(response => {
        return {status: response.status,data:response.data, message: "Coach Added"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getTrainCoaches = (id,params) => {

    return Tour.getTrainCoaches(id, params).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};


export const getTourCoaches = (id,params) => {

    return Tour.getTourCoaches(id, params).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};


export const deleteTourCoach = (id) => {

    return Tour.deleteTourCoach(id).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const updateTourCoach = (form, id) => {

    return Tour.updateTourCoach(form, id).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const getTrainToursByDate = (date, train_id, status) => {

    return Tour.getTrainToursByDate(date, train_id, status).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const activateTour = (id) => {

    return Tour.activateTour(id).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};

export const copyTrainTours = (id , days) => {
    
    return Tour.copyTour(id , days).then(response => {
        return {status: response.status,data:response.data, message: "success"};
    }).catch(e => {
        return {status: e.response.status, message: e.response.data};
    })

};
