import React, { useState, useEffect } from "react";
import $ from "jquery";
import {
  addTourCoach,
  getTrainToursByDate,
} from "../../store/actions/tour.actions";
import { allCoaches } from "../../store/actions/coach.actions";
import { allTrains } from "../../store/actions/train.actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AddTourCoach() {
  const [date, setDate] = useState("");
  const [trainTour, setTrainTour] = useState([]);
  const [coaches, setCoaches] = useState([]);
  const [trainId, setTrainId] = useState(0);
  const [trains, setTrains] = useState([]);
  const [trainCoachForm, setTrainCoachForm] = useState({
    coach_number: 0,
    tour_id: 0,
    coach_type: 0,
    is_online: false,
  });
  const params = {
    _count: 150,
    _pageNo: 1,
  };

  useEffect(() => {
    document.title = "Add Tour Coach | AA Enterprise";
    _allCoaches();
    fetchTrains();
  }, []);
  useEffect(() => {
    if (date !== "" && trainId) {
      _getTrainToursByDate();
    }
  }, [date, trainId]);
  useEffect(() => {
    if (!$.isEmptyObject(trainTour)) {
    }
  }, [trainTour]);

  async function _getTrainToursByDate() {
    let response = await getTrainToursByDate(date, trainId);
    if (response.status === 200) {
      if (response.data) {
        setTrainTour(response.data);
      } else {
        toast.info("No tours were found on this date");
      }
    } else if (response.status >= 400) {
      toast.error("Error fetching tours");
    }
  }
  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }
  async function _allCoaches() {
    let response = await allCoaches(params);
    if (response.status === 200) {
      if (response.data.types) {
        setCoaches(response.data.types);
      } else {
        toast.info("Please add coaches first");
      }
    } else if (response.status >= 400) {
      toast.error("Failed to get Coaches");
    }
  }
  async function submitForm(e) {
    e.preventDefault();
    let obj = {
      coach_number: Number(trainCoachForm.coach_number),
      tour_id: Number(trainCoachForm.tour_id),
      coach_type: Number(trainCoachForm.coach_type),
      is_online: trainCoachForm.is_online,
    };
    if (validate(obj)) {
      let response = await addTourCoach(obj);
      if (response.status === 200) {
        toast.success("Coach Added");
        setTrainCoachForm((prevValue) => {
          return {
            ...prevValue,
            coach_number: 0,
          };
        });
      } else if (response.status >= 400) {
        toast.error("There was an error while Adding");
      }
    } else {
      toast.warn("Please fill add fields");
    }
  }

  function validate(obj) {
    if (obj.tour_id && obj.coach_number && obj.coach_type) {
      return true;
    }
    return false;
  }

  function onChangeHandler(e) {
    const { name, value } = e.target;
    setTrainCoachForm((prevValue) => {
      return {
        ...prevValue,
        [name]: name === "is_online" ? e.target.checked : value,
      };
    });
  }
  function selectDateSubmit(e) {
    setDate($("#select_date")[0].value.substr(0, 10));
  }

  function selectTrain(e) {
    e.preventDefault();
    setTrainId(Number(document.getElementById("trains_select").value));
  }
  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Tour Coach</h2>
          <p class="text-light">Add a train tour coach</p>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Tours</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form type="submit" name="fetch_coaches">
                  <div class="row">
                    <div class="mb-3 col-6">
                      <label class="form-label">Select Departure Time</label>
                      <input
                        id="select_date"
                        type="date"
                        name="depart_time"
                        class="form-control"
                        onChange={() => selectDateSubmit()}
                        placeholder="Departure Time"
                        required
                      />
                    </div>
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="trains_select"
                        name="train_id"
                        onChange={selectTrain}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option
                              key={train.key}
                              value={train.id}
                              data-name={train.name}
                            >
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Tours</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form
                  type="submit"
                  name="display_coaches"
                  onSubmit={(e) => submitForm(e)}
                >
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Tour</label>
                      <select
                        id="trains_select"
                        name="tour_id"
                        onChange={onChangeHandler}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trainTour.map((tour) => {
                          return (
                            <option key={tour.key} value={tour.id}>
                              {tour.name} {tour.depart_time.substr(11, 5)}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Coach type</label>
                      <select
                        id="trains_select"
                        name="coach_type"
                        onChange={onChangeHandler}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {coaches.map((coach) => {
                          return <option value={coach.id}>{coach.name}</option>;
                        })}
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Coach Number</label>
                      <input
                        type="text"
                        name="coach_number"
                        onChange={onChangeHandler}
                        value={trainCoachForm.coach_number}
                        class="form-control"
                        placeholder="Coach Number"
                      />
                    </div>
                    <div class="mb-3 col-md-6">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          name="is_online"
                          onChange={onChangeHandler}
                        />
                        <label class="form-check-label">Allowed Online</label>
                      </div>
                    </div>
                  </div>

                  <button type="submit" name="submit" class="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddTourCoach;
