import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";
import DataTable from "react-data-table-component";
import { allCustomers } from "../../store/actions/customer.actions";
import $ from "jquery";

import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./allCustomers.css";

toast.configure({
  autoClose: 4000,
  draggable: true,
});

function AllCustomers() {
  const history = useHistory();
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "CNIC",
      selector: (row) => row.cnic,
      sortable: true,
    },
    {
      name: "DOB",
      selector: (row) => row.dob,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      cell: (d) => (
        <button
          className="btn btn-primary"
          onClick={() => {
            history.push(`/add-customer?id=${d.id}`);
          }}
          type="button"
        >
          <i class="icon-edit"></i>
        </button>
      ),
    },
  ];

  const [pageNo, setPageNo] = useState(1);
  let [totalRows, setTotalRows] = useState(0);
  const [rows, setRows] = useState([]);
  const [tableData, setTableData] = useState({});
  let response = [];
  const [params, setParams] = useState({
    _count: 15,
    _pageNo: pageNo,
  });
  const [filterText, setFilterText] = useState({
    name: "",
    cnic: "",
    gender: "",
    id: "",
    phone: "",
  });

  useEffect(() => {
    document.title = "All Customers | AA Enterprise";
    $(".print").hide();
    _filterAllCustomer();
    $(".data-table-extensions .data-table-extensions-filter").hide();
  }, []);

  useEffect(() => {
    setParams((prevValue) => {
      return {
        ...prevValue,
        _pageNo: pageNo,
      };
    });
  }, [pageNo]);

  useEffect(() => {
    setTableData({
      columns: columns,
      data: rows,
    });
  }, [rows]);

  useEffect(() => {
    _filterAllCustomer();
  }, [params, filterText]);

  async function _filterAllCustomer() {
    let object = {
      ...params,
      name: filterText.name,
      cnic: filterText.cnic,
      gender: filterText.gender,
      id: filterText.id,
      phone: filterText.phone,
    };
    let response = await allCustomers(object);
    if (response.status === 200) {
      setTotalRows(response.data.count);
      setRows(response.data.customers);
    } else if (response.status >= 400) {
      toast.error(response.message.message);
    }
  }

  const subHeaderComponent = () => {
    return (
      <div class="data-table-extensions-filter row">
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <div className="row">
                <div className="col-1">
                  <label class="filter-icon"></label>
                </div>
                <div className="col-2">
                  <input
                    type="number"
                    name="id"
                    onChange={handleFilterChange}
                    value={filterText.id}
                    class="filter-class"
                    placeholder="Filter By ID"
                  />
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-1">
                  <label class="filter-icon"></label>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    name="name"
                    onChange={handleFilterChange}
                    value={filterText.name}
                    class="filter-class"
                    placeholder="Filter By Name"
                  />
                </div>
              </div>
            </div>
            
            <div className="col-3">
              <div className="row">
                <div className="col-1">
                  <label class="filter-icon"></label>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    name="cnic"
                    onChange={handleFilterChange}
                    value={filterText.cnic}
                    class="filter-class"
                    placeholder="Filter By CNIC"
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-1">
                  <label class="filter-icon"></label>
                </div>
                <div className="col-4">
                  <input
                    type="text"
                    name="phone"
                    onChange={handleFilterChange}
                    value={filterText.phone}
                    class="filter-class"
                    placeholder="Filter By Phone"
                  />
                </div>
              </div>
            </div>
            <div className="col-2">
              <div className="row">
                <div className="col-1">
                  <label class="filter-icon"></label>
                </div>
                <div className="col-8">
                  {/* <input type="text" name="gender" onChange={handleFilterChange} value={filterText.gender} class="filter-class" placeholder="Filter By Gender" /> */}
                  <select
                    id="gender"
                    value={filterText.gender}
                    onChange={handleFilterChange}
                    name="gender"
                    class="form-select "
                  >
                    <option value="">Choose...</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Others</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function handleFilterChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    setFilterText((preveValue) => {
      return {
        ...preveValue,
        [name]: value,
      };
    });
  }

  return (
    <div>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Customers</h2>
          <p class="text-light">List of all customers</p>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Customer List</h4>
            </div>

            <div class="card-body">
              <div class="basic-form">
                <DataTableExtensions {...tableData}>
                  <DataTable
                    // data={rows}
                    // columns={columns}
                    highlightOnHover
                    pagination={true}
                    paginationServer={true}
                    dense
                    striped
                    pointerOnHover
                    defaultSortField={"name"}
                    paginationTotalRows={totalRows}
                    paginationPerPage={15}
                    subHeader
                    subHeaderComponent={subHeaderComponent()}
                    paginationComponentOptions={{
                      noRowsPerPage: true,
                    }}
                    onChangePage={(page) => setPageNo(page)}
                  />
                </DataTableExtensions>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="btn-group btn-group-sm" role="group"><button type="button" class="btn btn-success" onClick={downloadPdf}>Download PDF</button></div> */}
    </div>
  );
}

export default AllCustomers;
