import React, { useState, useEffect } from 'react'
import { addCustomer, updateCustomer, getCustomerProfile } from '../../store/actions/customer.actions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
    autoClose: 4000,
    draggable: true
});


function AddCustomer() {
    const [updateId, setUpdateId] = useState(0);
    const [customerForm, setCustomerForm] = useState({
        name: '',
        cnic: '',
        dob: '',
        gender: '',
        phone: '',
        address: ''
    })

    useEffect(() => {
        document.title = 'Add Customer | AA Enterprise';

        let params = new URLSearchParams(document.location.search.substring(1));
        setUpdateId(Number(params.get("id")));
    }, [customerForm]);

    useEffect(() => {
        if (updateId) {
            fetchCustomerData()
        }
    }, [updateId])


    function authenticateForm() {
        if (customerForm.name.length !== 0
            && customerForm.cnic.length !== 0
            && customerForm.dob.length !== 0
            && customerForm.gender.length !== 0
            && customerForm.phone.length !== 0
            && customerForm.address.length !== 0
            && !isNaN(Number(customerForm.cnic))
            && !isNaN(Number(customerForm.phone))) {
            return true;
        } else {
            return false;
        }
    }
    async function onSubmitForm(event) {
        event.preventDefault();
        let authentic = authenticateForm();
        if (authentic) {
            let response = '';
            console.log("customerForm: ", customerForm)
            if (updateId) {
                response = await updateCustomer(customerForm, updateId);
            } else {
                response = await addCustomer(customerForm);
            }

            if (response.status === 200) {
                toast.success(response.message)
                setCustomerForm({
                    name: '',
                    cnic: '',
                    dob: '',
                    gender: '',
                    phone: '',
                    address: ''
                })
                setUpdateId(0)
            } else if (response.status >= 400) {
                toast.error(response.message.message)
            }
            console.log("Response: ", response);
        } else {
            toast.warn("Please enter correct data")
        }
    }

    async function fetchCustomerData() {

        let response = await getCustomerProfile(updateId);
        if (response.status === 200) {

            for (const property in response.data) {
                setCustomerForm(prevValue => {
                    return {
                        ...prevValue,
                        [property]: response.data[property],
                    }
                })
            }

        } else if (response.status >= 400) {
            toast.error(response.message.message)
        }

    }

    function onChangeHandler(event) {
        const { name, value } = event.target;
        setCustomerForm(prevValue => {
            return {
                ...prevValue,
                [name]: value,
            }
        })
    }


    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Customers</h2>
                    <p class="text-light">{updateId ? "Update the customer" : "Add a new customer"}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">{updateId ? "Update customer" : "Create New Customer"}</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">
                                <form name="create_customer_form" onSubmit={(e) => onSubmitForm(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Name</label>
                                            <input type="text" name='name' value={customerForm.name} onChange={onChangeHandler} class="form-control" placeholder="name" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Phone</label>
                                            <input type="text" name='phone' value={customerForm.phone} onChange={onChangeHandler} class="form-control" placeholder="03123456789 ..." />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">CNIC</label>
                                            <input type="text" name='cnic' value={customerForm.cnic} onChange={onChangeHandler} class="form-control" placeholder="1234567891234  (without dashes)" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">DOB</label>
                                            <input type="date" name='dob' value={customerForm.dob} onChange={onChangeHandler} class="form-control" placeholder="date of birth" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Address</label>
                                            <textarea class="form-control" name='address' value={customerForm.address} onChange={onChangeHandler} rows="4" placeholder='address...'></textarea>
                                            {/* <textarea name="address" value={customerForm.address} onChange={onChangeHandler} rows="3" cols="50" placeholder='address...' /> */}
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Gender</label>
                                            <select id="gender" name='gender' value={customerForm.gender} onChange={onChangeHandler} class="form-select form-control wide">
                                                <option value=''>Choose...</option>
                                                <option value='male'>Male</option>
                                                <option value='female'>Female</option>
                                                {/* <option value='others'>Others</option> */}
                                            </select>
                                        </div>

                                    </div>
                                    <button type="submit" name='create_customer_btn' class={updateId ? "btn btn-success" : "btn btn-primary"}>{updateId ? "Update" : "Submit"}</button>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddCustomer
