import React, { useState, useEffect } from 'react'
import $ from 'jquery'
import { allTrains } from '../../store/actions/train.actions';
import { downloadPdf } from '../../store/actions/report.actions'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import jsPDF from 'jspdf'
import 'jspdf-autotable'

function Reports() {
    const [trains, setTrains] = useState([]);
    const [trainId, setTrainId] = useState(0);
    const [response, setResponse] = useState([]);
    const trainParams = {
        _count: 150,
        _pageNo: 1,
    };

    const columns = [
        {
            name: "Staring City",
            selector: "name",
            sortable: true
        },
        {
            name: "Ending City",
            selector: "user_name",
            sortable: true
        },
        {
            name: "Seats",
            selector: "email",
            sortable: true
        },
        {
            name: "Berts",
            selector: "phone",
            sortable: true
        },
        {
            name: "Collection",
            selector: "permissions",
            sortable: true
        }
    ];

    useEffect(() => {
        document.title = 'Reports | AA Enterprise';
        $('.print').hide()
        fetchTrains()
    }, []);
    useEffect(() => {
        if(JSON.stringify(response) !== '[]') {
            download();
        }
    }, [response]);

    async function fetchTrains() {
        let fetch_trains = await allTrains(trainParams);
        if (fetch_trains.status === 200) {
            setTrains(fetch_trains.data.trains);
        } else if (fetch_trains.status >= 400) {
            toast.error("Failed to fetch trains")
        }
    }

    async function _downloadPdf() {

        if (validate()) {
            let response = await downloadPdf(trainId);
            if (response.status === 200) {
                setResponse(response.data);
                toast.success("Dowloaded");
            } else if (response.status >= 400) {
                toast.error("Error downloading");
            }
        } else {
            toast.warn("Enter train Id")
        }
    }

    function validate() {
        if (trainId) {
            return true;
        }
        return false
    }
    function selectTrain() {
        setTrainId(document.getElementById("trains_select").value);
        document.getElementById("display_train").innerHTML = $("#trains_select").find('option:selected').text();
    }

    function tableHead() {
        var array = []
        for (let i = 0; i < columns.length; i++) {
            array.push(columns[i].name);
        }
        return array
    }

    function tableRows() {
        let array = []
        let data = response;
        console.log(response);
        let totalCollection = 0;
        let totalSeats = 0;
        let totalBerts = 0;
        for (let i = 1; i < data.length; i++) {
            let nestedArray = [];
            nestedArray.push(data[i][0])
            nestedArray.push(data[i][1])
            nestedArray.push(data[i][2])
            nestedArray.push(data[i][3])
            nestedArray.push(data[i][4])
            // nestedArray.push(data[i][5])
            array.push(nestedArray)
            totalCollection += Number(data[i][4]);
            totalSeats += Number(data[i][2]);
            totalBerts += Number(data[i][3]);
        }
        array.push(['Total:','',totalSeats,totalBerts,totalCollection]);
        return array
    }

    function download() {
        const doc = new jsPDF()
        console.log("HEad: ",tableHead());
        doc.autoTable({
            head: [tableHead()],
            body: tableRows(),
        });
        doc.save('reports.pdf')
    }
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Reports</h2>
                    <p class="text-light">Train earning reports</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Train Earning Reports</h4>
                        </div>
                        <div class="card-body">
                            <div class="basic-form">

                                <div class="row">
                                    <div class="mb-3 col-md-6">
                                        <label class="form-label">Select Train</label>
                                        <select id="trains_select" name='train_id' onChange={selectTrain} class="form-select form-control wide">
                                            <option value=''>Choose...</option>
                                            {trains.map((train) => {
                                                return (
                                                    <option key={train.key} value={train.id} data-name={train.name}>{train.name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div class='row'>
                                    <div class="mb-3 col-md-6">
                                        <p>Selected Train is: <i id="display_train" style={{ color: 'red' }}></i></p>
                                    </div>
                                </div>

                                <button type="button" onClick={() => { _downloadPdf() }} name='fetch_trains_btn' class="btn btn-primary">Download</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Reports
