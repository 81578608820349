import React, { useState, useEffect } from "react";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { cargoIndividualReport } from "../../store/actions/report.actions";
import {
  allTrains,
  getTrainStartingCities,
  getTrainEndingCities,
} from "../../store/actions/train.actions";
import { allUsers } from "../../store/actions/user.actions";
import { func } from "prop-types";

function CargoIndividualReport() {
  const [trains, setTrains] = useState([]);
  const [startingCities, setStartingCities] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [endingCities, setEndingCities] = useState([]);
  const [form, setForm] = useState({
    train_id: 0,
    st_date: "",
    end_date: "",
    status: "",
    st_city: 0,
    end_city: 0,
    st_city_name: "",
    end_city_name: "",
    train_id_name: "",
    delivery_status: "",
    creator: 0,
    creator_name: "",
  });

  useEffect(() => {
    document.title = "Cargo Individual Report | AA Enterprise";
    $(".print").hide();
    fetchTrains();
    _allUsers();
  }, []);

  useEffect(() => {
    if (form.train_id) {
      _getTrainStartingCities();
    }
  }, [form.train_id]);

  useEffect(() => {
    if (form.st_city) {
      _getTrainEndingCities();
    }
  }, [form.st_city]);

  function resetForm(){
    setForm({
      train_id: 0,
      st_date: "",
      end_date: "",
      status: "",
      st_city: 0,
      end_city: 0,
      st_city_name: "",
      end_city_name: "",
      train_id_name: "",
      delivery_status: "",
      creator: 0,
      creator_name: "",
    });
  }

  async function _allUsers() {
    let response = await allUsers({ _count: 500, _pageNo: 1 });
    if (response.status === 200) {
      setAdmins(response.data.users);
    } else if (response.status >= 400) {
      toast.error("Failed to fetch Admins");
    }
  }

  async function fetchTrains() {
    let fetch_trains = await allTrains({ _count: 1000, _pageNo: 1 });
    if (fetch_trains.status === 200) {
      setTrains(fetch_trains.data.trains);
    } else if (fetch_trains.status >= 400) {
      toast.error(fetch_trains.message.message);
    }
  }

  async function _getTrainEndingCities() {
    let response = await getTrainEndingCities(form.train_id, form.st_city);
    if (response.status === 200) {
      setEndingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setEndingCities([]);
    }
  }

  async function _getTrainStartingCities() {
    let response = await getTrainStartingCities(form.train_id);
    if (response.status === 200 && response.data.length !== 0) {
      setStartingCities(response.data);
    } else if (response.status >= 400) {
      toast.error("Starting Cities are not defined for the train");
      setStartingCities([]);
    }
  }

  async function onSubmitForm(e) {
    e.preventDefault();
    if (validate()) {
      let obj = {
        ...form,
        end_city: Number(form.end_city),
        st_city: Number(form.st_city),
        train_id: Number(form.train_id),
      };
      $('#preloader').delay(333).fadeIn('slow')
      let response = await cargoIndividualReport(obj);
      $('#preloader').delay(333).fadeOut('slow');
      if (response.status === 200) {
        if (response.data.length) {
          downloadPdf(response.data);
        } else {
          toast.info("No records found");
          // resetForm()
        }
      } else if (response.status >= 400) {
        toast.error(response.message.message);
      }
    }
  }
  

  function downloadPdf(response) {
    const doc = new jsPDF("l");

    var startX = 60;
    var finalY = 10;
    doc.text(85, finalY, "Cargo Individual Report");
    finalY += 10;

    let namesPrint = {
      train_id_name: "Train",
      st_date: "Starting Date",
      end_date: "Ending Date",
      status: "Amount Status",
      st_city_name: "From",
      end_city_name: "To",
      delivery_status: "Status",
      creator_name: "Creator",
    };

    var i = 1;
    for (const property in form) {
      if (
        form[property] &&
        property !== "st_city" &&
        property !== "end_city" &&
        property !== "train_id" &&
        property !== "creator"
      ) {
        if (i % 2 !== 0) {
          //Left
          startX = 25;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + form[property]
          );
        } else {
          //Right
          startX = 115;
          doc.text(
            startX,
            finalY,
            namesPrint[property] + ": " + form[property]
          );
          finalY += 12;
        }
        i++;
      }
    }

    finalY += 10;

    doc.autoTable({
      startY: finalY,
      head: [
        [
          "ID",
          "Starting City",
          "Ending City",
          "Booked by",
          "Status",
          "Delev. at",
          "Delev. by",
          "Weight",
          "Status",
          "Created. At",
          "Recv.Name",
          "Recv. Cnic",
          "Recv. Phone",
          "Amount"
        ],
      ],
      body: tableRows(response),
      theme: "grid",
    });

    const pageCount = doc.internal.getNumberOfPages();
    doc.setFontSize("10");
    // For each page, print the page number and the total pages
    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Print Page 1 of 4 for example
      doc.text(
        "Page " + String(i) + " of " + String(pageCount),
        210 - 10,
        297 - 10,
        null,
        null,
        "right"
      );
    }

    if (doc.save("cargo_individual_report.pdf")) {
      toast.success("Success");
      // resetForm()
    }
  }

  function tableRows(response) {
    var body = [];
    let total_amount = 0;
    let total_weight = 0;
    let to_pay = 0;
    let pay = 0;
    for (let i = 0; i < response.length; i++) {
      var array = [];
      array.push(response[i].id);
      array.push(response[i].st_city);
      array.push(response[i].end_city);
      array.push(response[i].created_by);
      array.push(response[i].delivery_status);
      response[i].delivered_at
        ? array.push(response[i].delivered_at.substr(0, 16).replace("T", " "))
        : array.push(response[i].delivered_at);
      array.push(response[i].delivered_by);
      array.push(response[i].weight);
      array.push(response[i].cargo_status);
      response[i].created_at
        ? array.push(response[i].created_at.substr(0, 16).replace("T", " "))
        : array.push(response[i].created_at);
      
      array.push(response[i].recv_name);
      array.push(response[i].recv_phone);
      array.push(response[i].recv_cnic);
      array.push(response[i].amount);
      total_amount += Number(response[i].amount);
      total_weight += Number(response[i].weight);
      response[i].cargo_status.includes("to") ? (to_pay += 1) : (pay += 1);
      body.push(array);
    }
    body.push([
      "",
      "Total:",
      "",
      "",
      "",
      "",
      "",
      total_weight,
      "",
      "",
      "",
      "",
      "",
      total_amount,
    ]);
    body.push(["", "toPay:", to_pay, "paid:", pay, "", "", "", "", ""]);
    return body;
  }

  function validate() {
    if (Number(form.train_id)) {
      return true;
    }
    toast.warn("Select train");
    return false;
  }

  function handleChangeWithName(e, cityId) {
    const { name, value } = e.target;
    let city = cityId + "_name";
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
        [city]: $("#" + cityId + " option:selected")
          .text()
          .includes("hoose...")
          ? ""
          : $("#" + cityId + " option:selected").text(),
      };
    });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setForm((prevValue) => {
      return {
        ...prevValue,
        [name]: value,
      };
    });
  }

  function selectCreator() {
    const id = Number(document.getElementById("creator").value);
    setForm((prevValue) => {
      return {
        ...prevValue,
        creator: id,
        creator_name: $("#creator :selected").text(),
      };
    });
    
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Reports</h2>
          <p class="text-light">Filter cargo report of your choice</p>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Cargo Report</h4>
            </div>
            <div class="card-body">
              <div class="basic-form">
                <form id="form" onSubmit={(e) => onSubmitForm(e)}>
                  <div class="row">
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Select Train</label>
                      <select
                        id="train_id"
                        name="train_id"
                        onChange={(e) => handleChangeWithName(e, "train_id")}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {trains.map((train) => {
                          return (
                            <option key={train.key} value={train.id}>
                              {train.name}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Starting Date</label>
                          <input
                            id="st_date"
                            type="date"
                            onChange={handleChange}
                            name="st_date"
                            class="form-control"
                            placeholder="starting date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6">
                      <div class="basic-form">
                        <div class="mb-3 col-md-12">
                          <label class="form-label">Ending Date</label>
                          <input
                            id="end_date"
                            type="date"
                            onChange={handleChange}
                            name="end_date"
                            class="form-control"
                            placeholder="ending date"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Amount Status</label>
                      <select
                        id="type_select"
                        name="status"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="paid">
                          Paid
                        </option>
                        <option key="2" value="topay">
                          To-Pay
                        </option>
                      </select>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">from</label>
                            <select
                              id="st_city"
                              name="st_city"
                              onChange={(e) =>
                                handleChangeWithName(e, "st_city")
                              }
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {startingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <div class="basic-form">
                        <div class="row">
                          <div class="mb-3 col-md-12">
                            <label class="form-label">To</label>
                            <select
                              id="end_city"
                              name="end_city"
                              onChange={(e) =>
                                handleChangeWithName(e, "end_city")
                              }
                              class="form-select form-control wide"
                            >
                              <option value="">Choose...</option>
                              {endingCities.map((city) => {
                                return (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="mb-3 col-md-6">
                      <label class="form-label">Delivery Status</label>
                      <select
                        id="type_select"
                        name="delivery_status"
                        onChange={handleChange}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        <option key="1" value="delivered">
                          Delivered
                        </option>
                        <option key="2" value="undelivered">
                          Undelivered
                        </option>
                      </select>
                    </div>
                    <div class="mb-3 col-md-6">
                      <label class="form-label">Creator</label>
                      <select
                        id="creator"
                        name="creator"
                        onChange={selectCreator}
                        class="form-select form-control wide"
                      >
                        <option value="">Choose...</option>
                        {admins.map((admins) => {
                          return (
                            <option key={admins.id} value={admins.id}>
                              {admins.name} / {admins.role}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-3">
                      <button
                        type="submit"
                        name="submit"
                        class="btn btn-primary"
                      >
                        Download
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CargoIndividualReport;
