import React from 'react'
import { Link } from 'react-router-dom'

function Navbar() {
    return (
        <>
            {/* Navbar Starts */}
            <div class="nav-header">
                <Link to="index" class="brand-logo">
                    
                <img src="images/logo-full.jpg" width="97" height="25" alt="" class="logo-abbr" />
                        {/* <img src="images/logo-full.jpg" width="97" height="25" alt="" class="brand-title" /> */}
                    
                    
                    {/* <div class="logo-abbr" width="54" height="54" viewBox="0 0 54 54" fill="none" style={{backgroundImage: `URL('images/logo.jpg')`}} ></div> */}
                </Link>
                <div class="nav-control">
                    <div class="hamburger">
                        <span class="line"></span><span class="line"></span><span class="line"></span>
                    </div>
                </div>
            </div>
            {/* Navbar Ends */}
        </>
    )
}

export default Navbar
