import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";

import logo from "../ticket/logo.jpg";
import $ from "jquery";
import jsPDF from "jspdf";
import "jspdf-autotable";

function CargoDetails() {
  const history = useHistory();
  const location = useLocation();
  const form = location.state
    ? location.state.cargo
      ? location.state.cargo
      : 0
    : 0;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    document.title = "Cargo Details | AA Enterprise";
    if (!form) {
      history.push({
        pathname: "/cargo-by-id",
      });
    } else {
      $("#select_date").text(
        form.booking_time.replace("T", " ").substring(0, 16)
      );
      var today = form.booking_time.split("T")[0];
      $("#select_date").val(today);
      var newDate = new Date();
      // console.log(`${dayjs().format('DD-MM-{YYYY} THH:mm:ss SSS [Z] A')} `);
      // setForm(stateData);
    }
  }, []);
  useEffect(() => {
    if (form.train_number) {
      let amount = 0;
      for (let i = 0; i < form.items.length; i++) {
        amount += form.items[i].amount;
      }
      setTotal(amount);
    }
  }, [form]);

  // function downloadPDF() {
  //   const doc = new jsPDF()

  //   var startX = 60;
  //   var finalY = 10;
  //   doc.text(startX, finalY, "Cargo Details");
  //   finalY += 10;
  //   let namesPrint = {
  //     train_name: 'Train',
  //     party_name: 'Party',
  //     id: 'Cargo ID',
  //     st_city: 'Starting City',
  //     end_city: 'Ending City',
  //     cargo_status: 'Amount',
  //   }

  //   var i = 1;
  //   for (const property in form) {
  //     if (form[property] && namesPrint[property]) {
  //       if (i % 2 !== 0) {
  //         //Left
  //         startX = 25;
  //         doc.text(startX, finalY, namesPrint[property] + ': ' + form[property]);
  //       } else {
  //         //Right
  //         startX = 115;
  //         doc.text(startX, finalY, namesPrint[property] + ': ' + form[property]);
  //         finalY += 12;
  //       }
  //       i++;
  //     }
  //   }

  //   let head = [
  //     'Item Name',
  //     'Weight',
  //     'Rate',
  //     'Miscellaneous',
  //     'Amount'
  //   ]

  //   finalY += 10;

  //   doc.autoTable({
  //     startY: finalY,
  //     head: [head],
  //     body: tableRows(form.items),
  //     theme: 'grid'
  //   })
  //   doc.save('cargo_details.pdf')

  // }

  // function tableRows(items) {
  //   let head = [
  //     'name',
  //     'weight',
  //     'rate',
  //     'misc',
  //     'amount'
  //   ]
  //   let total = 0;
  //   let rows = [];
  //   for (let i = 0; i < items.length; i++) {
  //     let temp = [];
  //     for (let j = 0; j < head.length; j++) {
  //       if (head[j] === 'amount') {
  //         total += Number(items[i][head[j]]);
  //       }
  //       temp.push(items[i][head[j]])
  //     }
  //     rows.push(temp);
  //   }
  //   rows.push(['', '', '', 'Total', total])
  //   return rows;
  // }

  const printDiv = () => {
    $(".print-button").hide();
    $(".update-button").hide();
    if (!window.navigator.userAgent.toLowerCase().includes("windows")) {
      var printWin = window.open(
        "_blank",
        "left=0,top=0,width=744,height=1052"
      );
      printWin.document.open();
      printWin.document.clear();
      

      printWin.document.write(
        `<center> <img src='images/logo-ie.jpg' width='120' height='60' alt="seat-img" class="center" /> </center>`
      );
      printWin.document.writeln(
        "<p>Cargo Id : <b>" +
        form.id +
          "</b></p>"
      );
      printWin.document.writeln(
        "<p>Printing Date : " +
        new Date().toLocaleString() + 
          "</p>"
      );
      printWin.document.writeln(
        "<p>Train : " + form.train_name + "</p>"
      );
      printWin.document.writeln(
        "<p>From : " + form.st_city + "</p>"
      );
      printWin.document.writeln(
        "<p>To : " + form.end_city + "</p>"
      );
      printWin.document.writeln(
        "<p>Name : " + form.party_name + "</p>"
      );
      printWin.document.writeln(
        "<p>Recv Name : " + form.recv_name + "</p>"
      );
      printWin.document.writeln(
        "<p>Items : " + form.items.length + "</p>"
      );
      printWin.document.writeln(
        "<p>Total Weight : " + form.weight + " KG</p>"
      );
      printWin.document.writeln(
        "<p>Total : Rs." + `${form.amount} (${form.cargo_status})` +  "</p>"
      );


      //second slip seperator
      printWin.document.writeln(
        "<br><p>------------------------------------------</p>"
      );


      printWin.document.write(
        `<center> <img src=${logo} width='120' height='60' alt="seat-img" class="center" /> </center>`
      );
      printWin.document.writeln(
        "<p>Cargo Id : <b>" +
        form.id +
          "</b></p>"
      );
      printWin.document.writeln(
        "<p>Printing Date : " +
        new Date().toLocaleString() + 
          "</p>"
      );
      printWin.document.writeln(
        "<p>Train : " + form.train_name + "</p>"
      );
      printWin.document.writeln(
        "<p>From : " + form.st_city + "</p>"
      );
      printWin.document.writeln(
        "<p>To : " + form.end_city + "</p>"
      );
      printWin.document.writeln(
        "<p>Name : " + form.party_name + "</p>"
      );
      printWin.document.writeln(
        "<p>Recv Name : " + form.recv_name + "</p>"
      );
      printWin.document.writeln(
        "<p>Items : " + form.items.length + "</p>"
      );
      printWin.document.writeln(
        "<p>Total Weight : " + form.weight + " KG</p>"
      );
      printWin.document.writeln(
        "<p>Total : Rs." + `${form.amount} (${form.cargo_status})` +  "</p>"
      );

      printWin.print();
      printWin.onfocus = function () { setTimeout(function () { printWin.close(); }, 100); }
      // printWin.document.close();
    } else {
      var myDiv = document.getElementById("cargo-form");
    // myDiv.
    var newWindow = window.open("", "SecondWindow", "toolbar=0,stat=0");
    var style = newWindow.document.createElement("link");
    style.type = "text/css";
    style.rel = "stylesheet";
    style.href =
      "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css";
    style.media = "all";
    newWindow.document.write(
      "<html><body " +
        "class='responsive light2012-home-switcher home switcher' " +
        " önload='window.print()'>" +
        myDiv.innerHTML +
        "</body></html>"
    );
    newWindow.document.getElementsByTagName("head")[0].appendChild(style);
    newWindow.print();
    newWindow.document.close();
    $(".print-button").show();
    }
    
  };

  function UpdateCargo() {
    if (form != 0) {
      let d = form.dataObj;
      history.push({
        pathname: "/update-cargo",
        state: {
          id: d.id,
          end_city: d.end_city,
          cargo_status: d.cargo_status,
          delivery_status: d.delivery_status,
          train_id: d.train_id,
          st_city: d.st_city_id,
          end_city_id: d.end_city_id,
          recv_cnic: d.recv_cnic,
          recv_name: d.recv_name,
          recv_phone: d.recv_phone,
        },
      });
    }
  }

  return (
    <>
      <div class="form-head mb-4 d-flex flex-wrap align-items-center">
        <div class="me-auto">
          <h2 class="font-w600 mb-0">Cargo</h2>
          <p class="text-light">Single Cargo Details</p>
        </div>
      </div>

      <div id="cargo-form" class="row">
        <div class="col-xl-12">
          <div class="card shadow">
            <div class="card-header">
              <h4 class="card-title">Cargo Details</h4>
              <p class="card-title">
                Cargo ID : {form.id}{" "}
                <button
                  type="button"
                  onClick={printDiv}
                  class="btn btn-primary p-3 print-button"
                >
                  Print
                </button>
                <button
                  type="button"
                  onClick={UpdateCargo}
                  class="btn btn-warning p-3 update-button"
                >
                  Update
                </button>
              </p>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Train</label>
                      <input
                        id="train_number"
                        type="text"
                        name="train_number"
                        value={form.train_name + " - " + form.train_number}
                        class="form-control"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Booking Date</label>
                      <input
                        id="select_date"
                        type="date"
                        name="date"
                        class="form-control"
                        placeholder="date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Party</label>
                      <input
                        id="party_name"
                        type="text"
                        name="party_name"
                        value={form.party_name}
                        class="form-control"
                        placeholder="date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">From</label>
                      <input
                        id="st_city"
                        type="text"
                        name="st_city"
                        value={form.st_city}
                        class="form-control"
                        placeholder="date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">To</label>
                      <input
                        id="end_city"
                        type="text"
                        name="end_city"
                        value={form.end_city}
                        class="form-control"
                        placeholder="date"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Amount</label>
                      <input
                        id="cargo_status"
                        type="text"
                        name="cargo_status"
                        value={form.cargo_status}
                        class="form-control"
                        placeholder="date"
                        disabled
                      />
                    </div>
                  </div>
                </div>



                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Reciever Name</label>
                      <input
                        id="st_city"
                        type="text"
                        name="st_city"
                        value={form.recv_name}
                        class="form-control"
                        placeholder="N/A"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Reciever CNIC</label>
                      <input
                        id="end_city"
                        type="text"
                        name="end_city"
                        value={form.recv_cnic}
                        class="form-control"
                        placeholder="N/A"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Reciever Phone</label>
                      <input
                        id="cargo_status"
                        type="text"
                        name="cargo_status"
                        value={form.recv_phone}
                        class="form-control"
                        placeholder="N/A"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {/* <div class="mb-3 col-md-4">
                  <div class="basic-form">
                    <div class="mb-3 col-md-12">
                      <label class="form-label">Bilty Date</label>
                      <p>{}</p>
                    </div>
                  </div>
                </div> */}

                <div class="row" id="items">
                  <div class="col-xl-12">
                    <div class="card shadow">
                      <div class="card-header">
                        <h4 class="card-title">Items</h4>
                        {/* <button class="btn btn-outline-danger btn-sm remove_btn" type="button" ><i class="icon-trash"></i></button> */}
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="mb-3 col-md-2 text-center">
                            <label class="form-label">Item Name</label>
                          </div>
                          <div class="mb-3 col-md-2 text-center">
                            <label class="form-label">Weight</label>
                          </div>
                          <div class="mb-3 col-md-2 text-center">
                            <label class="form-label">Rate</label>
                          </div>
                          <div class="mb-3 col-md-2 text-center">
                            <label class="form-label">Miscellaneous</label>
                          </div>
                          <div class="mb-3 col-md-2"></div>
                          <div class="mb-3 col-md-2 text-center">
                            <label class="form-label">Amount</label>
                          </div>

                          {form.items.map((item) => {
                            return (
                              <>
                                <div class="mb-3 col-md-2 text-center">
                                  <input
                                    type="text"
                                    name={item.id}
                                    value={item.name}
                                    class="text-center"
                                    placeholder="name"
                                    disabled
                                  />
                                </div>

                                <div class="mb-3 col-md-2 text-center">
                                  <input
                                    type="text"
                                    name={item.id}
                                    value={item.weight}
                                    class="text-center"
                                    placeholder="weight"
                                    disabled
                                  />
                                </div>

                                <div class="mb-3 col-md-2 text-center">
                                  <input
                                    type="text"
                                    name={item.id}
                                    value={item.rate}
                                    class="text-center"
                                    placeholder="rate"
                                    disabled
                                  />
                                </div>

                                <div class="mb-3 col-md-2 text-center">
                                  <input
                                    type="text"
                                    name={item.id}
                                    value={item.misc}
                                    class="text-center"
                                    placeholder="misc"
                                    disabled
                                  />
                                </div>

                                <div class="mb-3 col-md-2 text-center"></div>

                                <div class="mb-3 col-md-2 text-center">
                                  <p class="text-center">{item.amount}</p>
                                </div>
                              </>
                            );
                          })}

                          <div class="row">
                            <div class="mt-3 col-md-6 col-6">
                              <label class="form-label">Total: {form.amount} </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CargoDetails;
