import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { getTicketDetailsById, print , printOnlineTicket } from '../../store/actions/ticket.actions';
import logo from './logo.jpg';
import {makePaperTicket} from './TicketCustomer'
import { isAuthenticUser } from "../../utils/validateUser";

function PrintOnlineTicket() {

    const [form, setForm] = useState({ticketId:0 , otp:0});
    let [user, setUser] = useState({});
    var printTicketDetails = [];
    // const [fare, setFare] = useState(0);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    useEffect(() => {
        document.title = 'Print Online Ticket | AA Enterprise';
        if (isAuthenticUser()) {
            setUser(JSON.parse(localStorage.getItem("user")).user);
        }
    }, []);

    async function onSubmit(e) {
        e.preventDefault();

        if (validate()) {
            let response = await printOnlineTicket({ticket_id:form.ticketId , otp:form.otp});
            if (response.status === 200) {
                //Get Ticket Details

                var ticketDetails = await getTicketDetailsById(form.ticketId);
                // await sleep(3000);
                await sleep(1000);
                if (ticketDetails.status === 200) {
                    printTicketDetails.push(ticketDetails.data.customer_name);
                    printTicketDetails.push(ticketDetails.data.cnic);
                    printTicketDetails.push(ticketDetails.data.train_name);
                    printTicketDetails.push(ticketDetails.data.coach);
                    printTicketDetails.push(ticketDetails.data.seat);
                    printTicketDetails.push(ticketDetails.data.arrival_time);
                    printTicketDetails.push(ticketDetails.data.departure_time);
                    printTicketDetails.push(ticketDetails.data.fare);

                    if (!(window.navigator.userAgent.toLowerCase().includes('windows'))) {
                        Date.prototype.today = function () {
                            return ((this.getDate() < 10) ? "0" : "") + this.getDate() + "/" + (((this.getMonth() + 1) < 10) ? "0" : "") + (this.getMonth() + 1) + "/" + this.getFullYear();
                        }

                        Date.prototype.timeNow = function () {
                            return ((this.getHours() < 10) ? "0" : "") + this.getHours() + ":" + ((this.getMinutes() < 10) ? "0" : "") + this.getMinutes() + ":" + ((this.getSeconds() < 10) ? "0" : "") + this.getSeconds();
                        }

                        var newDate = new Date();
                        var printWin = window.open('_blank', 'left=0,top=0,width=744,height=1052');
                        printWin.document.open();
                        printWin.document.clear();
                        await sleep(2000);
                        printWin.document.write(`'<center> <img src=${logo} width='120' height='60' alt="seat-img" class="center" /> </center>'`);
                        printWin.document.writeln('<p>Ticket Id : <b>' + form.ticketId + '</b></p>');
                        printWin.document.writeln('<p>Name : ' + printTicketDetails[0] + '</p>');
                        printWin.document.writeln('<p>CNIC : ' + printTicketDetails[1] + '</p>');
                        printWin.document.writeln('<p>Train : ' + printTicketDetails[2] + '</p>');
                        printWin.document.writeln('<p>Coach : ' + printTicketDetails[3] + '</p>');
                        printWin.document.writeln('<p>Seat : ' + printTicketDetails[4] + '</p>');
                        // setFare(Number(printTicketDetails[7]));
                        printWin.document.writeln('<p>Fare : ' + printTicketDetails[7] + '</p>');
                        printWin.document.writeln('<p>Printing Date : ' + newDate.today() + " " + newDate.timeNow() + '</p>');
                        printWin.document.writeln('<p>Departure : ' + printTicketDetails[6] + '</p>');
                        printWin.document.writeln('<p>Departure : This ticket was booked from www.traintravels.pk</p>');
                        printWin.document.writeln('<p style="text-decoration: overline"></p>')

                        printWin.print();
                        printWin.onfocus = function () { setTimeout(function () { printWin.close(); }, 100); }
                        
                    } else {
                        // var ticketDetail = await getTicketDetailsById(ticketId);
                        // let k = 1;
                        // try {
                        //     print(ticketDetails.data);
                        // } catch (e) {
                        //     toast.error("Failed to print");
                        //     k--
                        // }
                        // if (k) {
                        //     toast.success("Ticket Printed");
                        //     setTicketId({ticketId:0 , otp:0});
                        // }
                        try {
                            ticketDetails.data.fare = ticketDetails.data.fare.toString();
                            if (user.printer_type === "pos") {
                              print(ticketDetails.data);
                            } else {
                              makePaperTicket(ticketDetails.data ,user);
                            }
                            toast.success("Ticket Printed");
                          } catch (e) {
                            toast.error("Failed to print");
                          }
                    }
                } else if (ticketDetails.status >= 400) {
                    toast.error("Failed to get ticket details");
                }
            } else if (response.status >= 400) {
                if (response.message.message.toLowerCase().includes('bad request')) {
                    toast.info("Ticket is already booked");
                    setForm({ticketId:0 , otp:0});
                } else {
                    toast.error(response.message.message);
                }
            }
        } else {
            toast.warn("Please enter ticket id")
        }
    }

    function validate() {
        if (!isNaN(form.ticketId) && !isNaN(form.otp)) {
            return true;
        } else {
            toast.warn("Enter valid ticket details");
        }
    }

    function onChangeHandler(event) {
        const { value , name } = event.target;
        setForm(prevValue => {
            return {
                ...prevValue,
                [name]:Number(value)
            }
        })
    }
    return (
        <>
            <div class="form-head mb-4 d-flex flex-wrap align-items-center">
                <div class="me-auto">
                    <h2 class="font-w600 mb-0">Tickets</h2>
                    <p class="text-light">Print Online Ticket</p>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Print Online Ticket</h4>
                        </div>

                        <div class="card-body">
                            <div class="basic-form">

                                <form name="update_ticket_form" onSubmit={(e) => onSubmit(e)}>

                                    <div class="row">
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">Ticket Id</label>
                                            <input type="text" name='ticketId' value={form.ticketId} onChange={onChangeHandler} class="form-control" placeholder="enter ticket id" />
                                        </div>
                                        <div class="mb-3 col-md-6">
                                            <label class="form-label">OTP</label>
                                            <input type="text" name='otp' value={form.otp} onChange={onChangeHandler} class="form-control" placeholder="enter otp" />
                                        </div>
                                    </div>

                                    {/* <div class="mb-3 col-md-6">
                                        <p id="fare" class="form-label">Fare: {fare}</p>
                                    </div> */}
                                    <button type="submit" name='submit' class="btn btn-primary">Print</button>

                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default PrintOnlineTicket
